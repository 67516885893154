import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import SurveyResultsCharts from '../components/SurveyResultsCharts';

const SurveyResultsPage = () => {
  const { companyId, surveyId } = useParams();
  const [data, setData] = useState([]);
  const [surveyQuestions, setSurveyQuestions] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchSurveyQuestions = async () => {
    try {
      const response = await fetch(`/surveyquestions/${surveyId}.json`);
      const data = await response.json();
      console.log('Survey Questions:', data);
      setSurveyQuestions(data);
    } catch (error) {
      console.error('Error fetching survey questions:', error);
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_GO_BASE}/survey/results`, {
        params: { companyId, surveyId }
      });
      console.log('Survey Results:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching survey results:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSurveyQuestions();
    fetchData();
  }, [companyId, surveyId]);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <SurveyResultsCharts results={data} surveyQuestions={surveyQuestions} surveyID={surveyId} />
      )}
    </div>
  );
};

export default SurveyResultsPage;


// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import SurveyResultsCharts from '../components/SurveyResultsCharts';

// const SurveyResultsPage = () => {
//   const { companyId, surveyId } = useParams();
//   const [data, setData] = useState([]);
//   const [surveyQuestions, setSurveyQuestions] = useState({});
//   const [loading, setLoading] = useState(true);

//   const fetchSurveyQuestions = async () => {
//     try {
//       const response = await fetch(`/surveyquestions/${surveyId}.json`);
//       const data = await response.json();
//       console.log('Survey Questions:', data); // Log survey questions
//       setSurveyQuestions(data);
//     } catch (error) {
//       console.error('Error fetching survey questions:', error);
//     }
//   };

//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_GO_BASE}/survey/results`, {
//         params: { companyId, surveyId }
//       });
//       console.log('Survey Results:', response.data); // Log survey results
//       setData(response.data);
//     } catch (error) {
//       console.error('Error fetching survey results:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchSurveyQuestions();
//     fetchData();
//   }, [companyId, surveyId]);

//   return (
//     <div>
//       {loading ? (
//         <div>Loading...</div>
//       ) : (
//       <SurveyResultsCharts results={data} surveyQuestions={surveyQuestions} surveyID={surveyId} />
//       )}
//     </div>
//   );
// };

// export default SurveyResultsPage;

