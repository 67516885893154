import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SurveyContainer from '../components/SurveyContainer';

// Mapping of survey names to their corresponding JSON file names
const surveyMapping = {
  'executive-snapshot': 'survey-0724',
  'ai-readiness': 'survey-8347',
  'tech-ai-readiness': 'survey-1962',
  'by-department': 'survey-5093',
  'ai-strategy': 'survey-6281',
  'adv-data-science': 'survey-3109',
};

const SurveyPage = () => {
  const { companyId, surveyname } = useParams();
  const [surveyData, setSurveyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSurveyData = async () => {
      try {
        // Get the JSON file name from the mapping
        const surveyFileName = surveyMapping[surveyname];
        if (!surveyFileName) {
          throw new Error(`Survey "${surveyname}" not found in mapping`);
        }

        // Fetch the survey JSON file
        const filePath = `/surveyquestions/${surveyFileName}.json`;
        console.log(`Fetching survey data from: ${filePath}`); // Debugging log
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error(`Survey file "${filePath}" not found`);
        }
        const data = await response.json();
        setSurveyData(data);
      } catch (error) {
        console.error('Error loading survey data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSurveyData();
  }, [surveyname]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      {surveyData ? (
        <SurveyContainer 
          surveyData={surveyData} 
          companyId={companyId} 
          surveyId={surveyMapping[surveyname]} 
        />
      ) : (
        <div>Error loading survey data.</div>
      )}
    </div>
  );
};

export default SurveyPage;