import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import SEO from '../components/SEO';


const HeroBanner = () => {
    return (
      <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-man_works_intently.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Jobs to be done, <em>easier</em></h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


const LevelOneBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-new_tools_at_fingertips.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">AI In Their Cloud</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

  const LevelTwoBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-purple_strobe_of_AI_information.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">AI Running Locally</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };
  const LevelThreeBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-vast_computing_resources.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">AI In Your Cloud</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

  const ContactBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-bot_helping_work.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Designing Perfect Solutions</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


  const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
  });
  
  const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
        className={className}
      >
        {children}
      </motion.div>
    );
  };
  

  const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
  };

const Solutions = () => {
    return (
        <div className='homepage'>
        <HeroBanner />
            <h1 className='standard-pad'>Strategies designed for your complex needs</h1>
            <h3 className='standard-pad'>Not a one-sized-fits-all recommendation for all businesses</h3>
        <p className='standard-pad'>
        At HYE Partners, we help companies implement a range of AI solutions tailored to meet the unique needs of your business. 
        These solutions are designed to enhance productivity, streamline workflows, and ensure the security and confidentiality of your data. 
        </p>
        <p className='standard-pad'>
        Below are the three levels of solutions we advise on, each comes with its own set of benefits and considerations.
        </p>

        <LevelOneBanner />

        <h3 className='standard-pad'>Level 1: Third-Party Tools</h3>
        <p className='standard-pad no-btm'>
        Leveraging third-party tools such as the foundational models by OpenAI (ChatGPT), Google (Gemini), and Anthropic (Claude) or smaller workflow products specifically tailored to drive business outcomes. 
        They provide businesses with quick access to advanced AI capabilities without the need for extensive in-house development. 
        These tools are front-end consumer products designed to be user-friendly and easy to adopt for various business applications.
        </p>
        <div className="twobytwo">
        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="10%"
        y="10%"
        >
        <h4>Benefits</h4> 
        <ul>
        <li><b>Ease of Use:</b> Third-party tools are typically designed with user-friendliness in mind, allowing teams to quickly adopt and integrate AI into their workflows without extensive training.</li>
        <li><b>Rapid Deployment:</b> Since these solutions are ready-made SaaS, businesses can deploy them rapidly, often with minimal setup.</li>
        <li><b>Continuous Updates:</b> Third-party providers frequently update their tools with the latest advancements in AI, ensuring your users continually benefit from cutting-edge technology.</li>
        </ul>
        </ParallaxGlowBox>
        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="-10%"
        y="10%"
        >
        <h4>Considerations</h4>
        <ul>
        <li><b>Data Security:</b> Using third-party tools involves sharing data with external providers, which can pose a risk to confidentiality and security, especially for sensitive business information. You should assume all of your data will be used for training their models unless otherwise stated.</li>
        <li><b>Limited Customization:</b> These solutions may offer limited customization options compared to more tailored, in-house solutions.</li>
        <li><b>Dependency on Providers:</b> Businesses may become reliant on third-party providers for ongoing support and updates.</li>
        </ul>
        </ParallaxGlowBox>
        </div>

        <LevelTwoBanner />

        <h3 className='standard-pad'>Level 2: Open-Source Technology Running Locally</h3>

        <p className='standard-pad no-btm'>
        For businesses prioritizing confidentiality and control, implementing open-source technology locally on employee computers is an ideal solution. This approach enables teams to work privately, confidentially, and offline, ensuring that sensitive data remains within the organization.        
        </p>
        <div className="twobytwo">
        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="10%"
        y="10%"
        >
          <h4>Benefits</h4>
        <ul>
        <li><b>Data Privacy:</b> Running AI tools locally ensures that data never leaves the organization's premises, significantly reducing the risk of data breaches and leaks.</li>
        <li><b>Customization and Flexibility:</b> Open-source solutions offer a high degree of customization, allowing businesses to tailor the technology to their specific needs and workflows.</li>
        <li><b>Offline Capability:</b> Employees can work with AI tools even when not connected to the internet, ensuring uninterrupted access.</li>
        </ul>
        </ParallaxGlowBox>

        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="-10%"
        y="10%"
        >
        <h4>Considerations</h4>
        <ul>
        <li><b>Technical Requirements:</b> Running AI tools locally requires employee computers to have high specifications to handle the computational demands of AI processing.</li>
        <li><b>Maintenance and Support:</b> Organizations need to manage the maintenance, updates, and support for these tools, which may require dedicated IT resources.</li>
        <li><b>Implementation Complexity:</b> Setting up open-source tools can seem daunting without specialized knowledge and expertise.</li>
        </ul>
        </ParallaxGlowBox>
        </div>
        
        <LevelThreeBanner />

        <h3 className='standard-pad'>Level 3: Centrally Managed Cloud-Hosted LLM</h3>

        <p className='standard-pad no-btm'>
        For businesses seeking a comprehensive, secure, and scalable AI solution, a centrally managed cloud-hosted Large Language Model (LLM) is the optimal choice. This approach combines the benefits of advanced AI capabilities with robust security, governance, and compliance measures.
        </p>
        <div className="twobytwo">
          <div>
        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="10%"
        y="10%"
        >
        <h4>Benefits</h4>
        <ul>
        <li><b>Business-Specific Training:</b> LLMs can be fine-tuned to your business, ensuring that the AI understands and aligns with the company's unique market, terminology, and requirements.</li>
        <li><b>Fact-Based Responses:</b> Incorporating Retrieval-Augmented Generation (RAG) ensures that the AI provides accurate, fact-based responses with citations, enhancing reliability and trustworthiness.</li>
        <li><b>Centralized Management:</b> A centrally managed LLM provides unified control over AI resources, ensuring consistent performance, security, and compliance across the organization.</li>
        <li><b>Enhanced Security:</b> Cloud-hosted solutions are equipped with advanced security features, including encryption, role-based access control (RBAC), and rigorous compliance standards to protect sensitive data.</li>
        <li><b>Scalability:</b> Cloud solutions can easily scale to meet the growing needs of the business, accommodating more users and higher workloads without significant new infrastructure investments.</li>
        </ul>
        </ParallaxGlowBox>

        </div>
        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="-10%"
        y="10%"
        >
          <h4>Considerations</h4>
      <ul>
        <li><b>Initial Investment:</b> Implementing a cloud-hosted LLM requires an initial investment in infrastructure and training, though the long-term benefits often outweigh the costs.</li>
        <li><b>Ongoing Management:</b> Continuous monitoring and management are necessary to maintain security, compliance, and optimal performance.</li>
        <li><b>Data Residency Concerns:</b> Depending on the cloud provider, there may be considerations regarding data residency and compliance with local regulations.</li>
        <li><b>Maintaining Models and Fine-Tuning:</b> Running your own cloud-hosted LLM requires continuous maintainance and fine-tuning. This involves regular updates to the model based on new data and changing business needs, which can be complex and resource-intensive.</li>
        <li><b>Reversing Model Drift:</b> Over time, the performance of an LLM can degrade as it encounters new and evolving data distributions. Addressing model drift requires ongoing monitoring and periodic re-training, which can be complex and resource-intensive.</li>
        </ul>
        </ParallaxGlowBox>
        </div>

<ContactBanner />
<h3 className='standard-pad'>Choosing the Right Solution</h3>
<p className='standard-pad'>HYE Partners understands that each business has unique needs and priorities. 
We help you choose the AI solutions to best fits your organization's requirements. Whether you opt for third-party tools, open-source technology, or a centrally managed cloud-hosted LLM, we are here to guide you every step of the way.
</p>
<p className='standard-pad no-top'><a href="/contact">Contact us today to learn more</a> about how we accelerate the power of AI to surpass your goals.</p>

<p>
    &nbsp;
</p>
<p>
    &nbsp;
</p>
        </div>
    );
};

const Solute = () => {
    return (
      <ParallaxProvider>
        <Solutions />
        <SEO
                title="How to use AI"
                description="Refine your approach to working with AI, whether it's local, in the cloud, or your own servers."
                url="/about"
              />
      </ParallaxProvider>
    );
  };
  
  export default Solute;