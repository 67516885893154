// /redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import undoable, { includeAction } from 'redux-undo';
import sceneReducer from './slices/sceneSlice';
import { setScene, setChapter } from './slices/sceneSlice';

// Wrap your reducer with undoable, and optionally specify actions to include
const undoableSceneReducer = undoable(sceneReducer, {
  filter: includeAction([setScene.type, setChapter.type]), // Only include these actions in your undo history if needed
});

const store = configureStore({
  reducer: {
    scene: undoableSceneReducer, // Use the undoable version of the scene reducer here
  },
});

export default store;