import { useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';

function useDebouncedResizeObserver(callback, delay = 100, options) {
  const debouncedCallback = useCallback(
    debounce((entries) => callback(entries), delay),
    [callback, delay]
  );

  useEffect(() => {
    const observer = new ResizeObserver(debouncedCallback);
    observer.observe(document.body, options);
    return () => observer.disconnect();
  }, [debouncedCallback, options]);
}

export default useDebouncedResizeObserver;
