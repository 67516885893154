import React from 'react';

const SurveyRubric = ({ overallScore, surveyID, surveyTitle, percentage, maxScore }) => {
    let interpretation = '';
  let color = '';
  let topRange = 70; // Default value, will be adjusted per survey
  let letterGrade = '';

  if (surveyID === 'survey-8347') {
    topRange = 156;
    if (overallScore <= 39) {
      interpretation = 'Significant gaps in AI readiness. Immediate action required to address critical areas and build AI competency.';
      color = '#3b3484'; // Medium purple
      letterGrade = 'D';
    } else if (overallScore <= 78) {
      interpretation = 'Basic AI readiness, but substantial improvements needed. Focus on building foundational skills and awareness.';
      color = '#7174b0';
      letterGrade = 'C';
    } else if (overallScore <= 117) {
      interpretation = 'Good AI readiness with room for enhancement in certain areas. Continue to support and develop AI skills and adoption.';
      color = '#ebb844';
      letterGrade = 'B';
    } else {
      interpretation = 'Excellent AI readiness with comprehensive coverage across all dimensions. Maintain and further develop AI capabilities.';
      color = '#e08a3c'; // Dark orange
      letterGrade = 'A';
    }
  } else if (surveyID === 'survey-1962') {
    topRange = 78;
    if (overallScore <= 22) {
      interpretation = 'Significant gaps in AI talent readiness. Immediate action required to address critical areas and upskill the workforce. Next Steps: Conduct a thorough skills assessment to identify key gaps. Develop and implement foundational AI training programs. Focus on building awareness and understanding of AI concepts.';
      color = '#3b3484'; // Medium purple
      letterGrade = 'D';
    } else if (overallScore <= 44) {
      interpretation = 'Basic AI talent readiness, but substantial improvements needed. Focus on targeted upskilling and training programs. Next Steps: Enhance technical training programs for AI tools and technologies. Provide hands-on experience with AI projects and real-world applications. Mentor employees to develop domain-specific AI expertise.';
      color = '#7174b0';
      letterGrade = 'C';
    } else if (overallScore <= 64) {
      interpretation = 'Good AI talent readiness with room for enhancement in certain areas. Continue to develop and support AI skills within the organization. Next Steps: Invest in advanced AI training and certification programs. Encourage innovation and experimentation with AI solutions. Strengthen organizational support for AI initiatives.';
      color = '#ebb844';
      letterGrade = 'B';
    } else {
      interpretation = 'Excellent AI talent readiness with comprehensive coverage across all dimensions. Maintain and further develop AI capabilities. Next Steps: Maintain a culture of continuous improvement and innovation. Expand AI capabilities to new areas within the organization. Share best practices and success stories across teams.';
      color = '#e08a3c'; // Dark orange
      letterGrade = 'A';
    }  
} else if (surveyID === 'survey-0724') {
    topRange = 100;
    if (overallScore <= 25) {
      interpretation = 'Significant gaps in AI readiness across the organization. Immediate action required to address critical areas and enhance AI integration. Next Steps: Establish an AI task force to drive adoption. Provide foundational AI training to key departments. Develop a strategic AI roadmap for company-wide integration.';
      color = '#3b3484'; // Medium purple
      letterGrade = 'D';
    } else if (overallScore <= 50) {
      interpretation = 'Basic AI readiness with limited integration and sporadic training. Substantial improvements needed. Next Steps: Standardize AI tools across departments to ensure consistency. Increase frequency and quality of AI training programs. Encourage inter-departmental collaboration on AI projects.';
      color = '#7174b0';
      letterGrade = 'C';
    } else if (overallScore <= 75) {
      interpretation = 'Good AI readiness with regular use of AI tools, but some areas need enhancement. Next Steps: Automate more workflows to reduce manual intervention. Establish regular feedback loops for continuous AI improvement. Expand training programs to cover advanced AI topics and use cases.';
      color = '#ebb844';
      letterGrade = 'B';
    } else {
      interpretation = 'Excellent AI readiness with comprehensive integration and optimization. Maintain and expand AI capabilities. Next Steps: Maintain a culture of continuous improvement and innovation. Scale AI use to new areas within the organization. Share best practices and success stories across teams.';
      color = '#e08a3c'; // Dark orange
      letterGrade = 'A';
    }
} else if (surveyID === 'survey-5093') {
    topRange = 25;
    if (overallScore <= 6) {
      interpretation = 'Initial Experimentation: Limited use of AI in specific departments only. Minimal integration with existing workflows. Teams lack structured training and awareness. Next Steps: Establish foundational AI training programs. Start small-scale AI projects to build familiarity. Identify quick wins to demonstrate AI’s value.';
      color = '#3b3484'; // Medium purple
      letterGrade = 'D';
    } else if (overallScore <= 12) {
      interpretation = 'Ad-Hoc Implementation: Some AI tools are used across a few departments. Customization and integration exist but remain inconsistent. Training is sporadic and not standardized. Next Steps: Standardize AI tools and practices across departments. Increase the frequency and consistency of training programs. Foster collaboration between departments to share AI knowledge.';
      color = '#7174b0';
      letterGrade = 'C';
    } else if (overallScore <= 18) {
      interpretation = 'Scaling and Integration: Multiple departments regularly use AI in workflows. Standardized tools and platforms exist with some customization. Training is frequent, and employees suggest new use cases. Next Steps: Automate more workflows to reduce manual intervention. Establish regular feedback loops for continuous AI improvement. Expand training programs to cover advanced AI topics and use cases.';
      color = '#ebb844';
      letterGrade = 'B';
    } else {
      interpretation = 'Optimization and Automation: AI is embedded in most core business workflows. Highly automated workflows require minimal manual intervention. Teams regularly refine AI tools, providing feedback and expanding usage. Next Steps: Maintain a culture of continuous improvement and innovation. Scale AI use to new areas within the organization. Share best practices and success stories across teams.';
      color = '#e08a3c'; // Dark orange
      letterGrade = 'A';
    }
} else if (surveyID === 'survey-6281') {
    topRange = 96;
    if (overallScore <= 19) {
      interpretation = 'Beginner (1): Limited experience with strategic AI utilization. Familiar with basic AI concepts and tools. Next Steps: Focus on building foundational knowledge and implementing initial AI projects. Consider developing a formal AI strategy and investing in AI training for staff.';
      color = '#3b3484'; // Medium purple
      letterGrade = 'D';
    } else if (overallScore <= 38) {
      interpretation = 'Intermediate (2): Moderate experience with several strategic AI practices. Implemented AI in some business areas with initial success. Next Steps: Continue expanding AI initiatives and aligning them with business goals. Invest in advanced AI tools and infrastructure, and foster cross-functional collaboration.';
      color = '#7174b0';
      letterGrade = 'C';
    } else if (overallScore <= 57) {
      interpretation = 'Proficient (3): Significant experience with many strategic AI practices. AI initiatives are aligned with business goals and show measurable impact. Next Steps: Leverage your significant experience to scale AI initiatives across the organization. Develop robust data governance practices and continuously monitor AI performance.';
      color = '#ebb844';
      letterGrade = 'B';
    } else if (overallScore <= 76) {
      interpretation = 'Advanced (4): Extensive experience with most strategic AI practices. AI is integrated across multiple business functions and drives innovation. Next Steps: Utilize your extensive expertise to drive innovation and efficiency with AI. Establish partnerships with research institutions and stay updated with cutting-edge AI technologies.';
      color = '#e08a3c'; // Dark orange
      letterGrade = 'A';
    } else {
      interpretation = 'Expert (5): Comprehensive experience with all strategic AI practices listed. Leader in AI strategy, with AI driving significant business transformation and competitive advantage. Next Steps: As a leader in AI strategy, focus on pushing the boundaries of AI research and application. Share your knowledge through publications and conferences, and continuously explore new AI opportunities.';
      color = '#80ba55'; // Green
      letterGrade = 'A+';
    }
} else if (surveyID === 'survey-3109') {
    topRange = 369;
    if (overallScore <= 74) {
      interpretation = 'Beginner (1): Focus on gaining foundational knowledge and hands-on experience with a variety of data science tools and techniques. Next Steps: Consider enrolling in specialized courses and engaging in small projects to build your skills. Participate in online forums and communities to expand your understanding. Apply what you learn to real-world scenarios to solidify your knowledge.';
      color = '#3b3484'; // Medium purple
      letterGrade = 'D';
    } else if (overallScore <= 148) {
      interpretation = 'Intermediate (2): Continue to expand your knowledge and practical experience. Next Steps: Work on more complex projects, collaborate with peers, and start exploring advanced topics in greater depth. Seek out mentorship or join study groups to enhance your learning. Contribute to open-source projects to gain practical experience and network with other professionals.';
      color = '#7174b0';
      letterGrade = 'C';
    } else if (overallScore <= 222) {
      interpretation = 'Proficient (3): Leverage your significant experience to tackle more challenging problems. Next Steps: Consider leading small teams or projects, mentoring junior data scientists, and contributing to the development of best practices in your organization. Focus on mastering advanced techniques and staying current with industry trends. Publish your findings and share your knowledge with the broader community.';
      color = '#ebb844';
      letterGrade = 'B';
    } else if (overallScore <= 296) {
      interpretation = 'Advanced (4): Utilize your extensive expertise to drive innovation and efficiency in data science projects. Next Steps: Take on leadership roles, lead complex projects, and engage in continuous learning to stay at the cutting edge of the field. Develop and implement innovative solutions to complex problems. Build strong relationships with stakeholders to drive impactful data-driven decisions.';
      color = '#e08a3c'; // Dark orange
      letterGrade = 'A';
    } else {
      interpretation = 'Expert (5): As a leader in the field, focus on pushing the boundaries of data science through research, innovation, and collaboration. Next Steps: Share your knowledge through publications, conferences, and teaching, and continue to explore emerging technologies and methodologies. Mentor the next generation of data scientists and build a culture of data-driven decision-making within your organization. Collaborate with industry leaders to influence the future direction of data science.';
      color = '#de6736'; // Darker orange
      letterGrade = 'A+';
    }
  }

  const scoreText = (typeof overallScore === 'number') ? overallScore.toFixed(0) : '0';
  const percentageText = (typeof percentage === 'string') ? percentage : '0.0';

  return (
    <div style={{ backgroundColor: color, padding: '1rem', borderRadius: '8px', color: 'white', marginBottom: '1rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <h2>Maturity Model for {surveyTitle} Survey</h2>
        <h5>Score is {scoreText} out of {maxScore} ({percentageText}%)</h5>
        <h6>Analysis: {interpretation}</h6>
      </div>
      <div style={{ fontSize: '8rem', lineHeight: '0', margin: '2rem', marginBottom: '0', marginTop: '3rem', marginRight: '0'  }}>{letterGrade}</div>
    </div>
  );
};

export default SurveyRubric;
