import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NavHeader from './NavHeader';
import FooterNav from './FooterNav';
import ErrorBoundary from './components/ErrorBoundary';
import SEO from './components/SEO';
import { ResponsiveWrapper } from './components/ResponsiveWrapper';
import { MantineProvider } from '@mantine/core';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Solutions from './pages/Solutions';
import Plan from './pages/Plan';
import About from './pages/About';
import { Provider } from 'react-redux';
import store from './redux/store';
import Homepage from './pages/Homepage';
import useDebouncedResize from './components/ScreenResized';
import SurveyPage from './assessments/pages/SurveyPage';
import SurveyResultsPage from './assessments/pages/SurveyResultsPage';
import Pricing from './pages/Pricing';
import SurveyExperience from './pages/SurveyExperience';


const routeConfig = {
  Homepage: { component: Homepage, title: 'Home', description: 'HYE Partners AI Consulting: Jobs to be done, easier.', url: '/' },
  Services: { component: Services, title: 'Services', description: 'HYE Partners AI Consulting Services Offerings', url: '/services' },
  Solutions: { component: Solutions, title: 'Solutions', description: 'HYE Partners AI Consulting Solutions in AI Recommendations', url: '/solutions' },
  Plan: { component: Plan, title: 'Design an Experience', description: 'HYE Partners Plan for New Clients', url: '/plan' },
  Pricing: { component: Pricing, title: 'Pricing', description: 'HYE Partners Pricing is based on company size', url: '/pricing' },
  Surveys: { component: SurveyExperience, title: 'Surveys', description: 'HYE Partners assessment surveys to monitor benchmarks and growth', url: '/surveys' },
  About: { component: About, title: 'About', description: 'About Principal Consultant, Adam New-Waterson', url: '/about' },
  Contact: { component: Contact, title: 'Contact', description: 'Contact HYE Partners for more information', url: '/contact' },
};

const Content = React.memo(({ resizeKey }) => {
  // console.log("Content component rerendered");
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(location.pathname.replace('/', ''));
  const { title, description, url } = routeConfig[currentPage] || {};
  const pages = Object.keys(routeConfig).map(key => ({
    name: routeConfig[key].title,
    route: routeConfig[key].url,
  }));

  // console.log('App component rendering'); 

  
  const onNavigate = (route) => {
    // console.log('onNavigate called with route:', route);
    setCurrentPage(route);
    // console.log('Current page after:', currentPage);
  };

  const navigate = useNavigate();

  
  useEffect(() => {
    return () => {
      console.log('App component unmounted');
    };
  }, []);

  const { width } = useDebouncedResize(300); // Adjust the delay as needed

return (
    <div className="relative min-h-screen scroll" key={resizeKey}>
      <div className="outerwrapper">
    <div className="flex-container">
        <div className="innerwrapper">
            <>
                <ResponsiveWrapper>
                <NavHeader className="navheader" pages={pages} onNavigate={onNavigate}/>
                    <SEO title={title} description={description} url={url} />
                    <Routes>
                    {/* {console.log('Rendering defined routes')} */}
                        <Route path="/" element={<Homepage />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/solutions" element={<Solutions />} />
                        <Route path="/plan" element={<Plan />} />
                        <Route path="/surveys" element={<SurveyExperience />} />
                        <Route path="/pricing" element={<Pricing />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/contact" element={<Contact />} />
                       
                        <Route path="*" element={<Navigate to="/intro" />} />

                    </Routes>
                    <FooterNav className="footernav" pages={pages} onNavigate={onNavigate}/>

                </ResponsiveWrapper>
            </>
        </div>
      </div>          
      </div> 
    </div>
  );
 });

//  function darken(color, amount) {
//   const usePound = color[0] === "#";
//   const num = parseInt(color.slice(1), 16);

//   let r = (num >> 16) - amount;
//   let g = ((num >> 8) & 0x00FF) - amount;
//   let b = (num & 0x0000FF) - amount;

//   r = r < 0 ? 0 : r;
//   g = g < 0 ? 0 : g;
//   b = b < 0 ? 0 : b;

//   return (usePound ? "#" : "") + (r.toString(16).padStart(2, '0')) + (g.toString(16).padStart(2, '0')) + (b.toString(16).padStart(2, '0'));
// }

//  const mantineTheme = {
//   colors: {
//     dkpurple: ['#2c294b'],
//     mdpurple: ['#3b3484'],
//     ltpurple: ['#7174b0'],
//     bgpurple: ['#ebecfb'],
//     dkorange: ['#de6736'],
//     mdorange: ['#e08a3c'],
//     ltorange: ['#ebb844'],
//     bgorange: ['#f6edd3'],
//     cranberry: ['#762861'],
//     magenta: ['#c5316a'],
//     ltgray: ['#ebe5df'],
//     mdgray: ['#958e86'],
//     dkgray: ['#373737'],
//     brown: ['#5d3c23'],
//     mdgreen: ['#80ba55'],
//   },
//   components: {
//     Button: {
//       styles: (theme) => ({
//         root: {
//           backgroundColor: theme.colors.dkpurple[0],
//           color: theme.colors.white,
//           borderColor: theme.colors.dkpurple[0],
//           '&:hover': {
//             backgroundColor: darken(theme.colors.dkpurple[0], 0.1),
//           },
//         },
//       }),
//     },
//     TextInput: {
//       styles: (theme) => ({
//         input: {
//           backgroundColor: theme.colors.bgpurple[0],
//           color: theme.colors.dkpurple[0],
//           borderColor: theme.colors.mdpurple[0],
//           '::placeholder': {
//             color: theme.colors.ltpurple[0],
//           },
//           '&:focus': {
//             borderColor: theme.colors.dkpurple[0],
//           },
//         },
//       }),
//     },
//     Select: {
//       styles: (theme) => ({
//         input: {
//           backgroundColor: theme.colors.bgorange[0],
//           color: theme.colors.dkorange[0],
//           borderColor: theme.colors.mdorange[0],
//           '&:focus': {
//             borderColor: theme.colors.dkorange[0],
//           },
//         },
//         dropdown: {
//           backgroundColor: theme.colors.bgorange[0],
//         },
//         item: {
//           color: theme.colors.mdorange[0],
//         },
//       }),
//     },
//     Checkbox: {
//       styles: (theme) => ({
//         input: {
//           backgroundColor: theme.colors.ltgray[0],
//           borderColor: theme.colors.mdgray[0],
//           '&:checked': {
//             backgroundColor: theme.colors.dkgray[0],
//           },
//         },
//         label: {
//           color: theme.colors.dkgray[0],
//         },
//       }),
//     },
//     Radio: {
//       styles: (theme) => ({
//         input: {
//           backgroundColor: theme.colors.ltgray[0],
//           borderColor: theme.colors.mdgray[0],
//           '&:checked': {
//             backgroundColor: theme.colors.mdorange[0],
//           },
//         },
//         label: {
//           color: theme.colors.dkgray[0],
//         },
//       }),
//     },
//     Switch: {
//       styles: (theme) => ({
//         input: {
//           backgroundColor: theme.colors.ltgray[0],
//           borderColor: theme.colors.mdgray[0],
//           '&:checked': {
//             backgroundColor: theme.colors.dkpurple[0],
//           },
//         },
//         thumb: {
//           backgroundColor: theme.colors.ltpurple[0],
//         },
//         label: {
//           color: theme.colors.dkpurple[0],
//         },
//         '&:focus': {
//           borderColor: theme.colors.mdgray[0],
//         },
//       }),
//     },
//     Tooltip: {
//       styles: (theme) => ({
//         body: {
//           backgroundColor: theme.colors.cranberry[0],
//           color: theme.colors.white,
//         },
//         arrow: {
//           backgroundColor: theme.colors.cranberry[0],
//         },
//       }),
//     },
//     Badge: {
//       styles: (theme) => ({
//         root: {
//           backgroundColor: theme.colors.magenta[0],
//           color: theme.colors.white,
//           borderColor: theme.colors.magenta[0],
//         },
//       }),
//     },
//     Table: {
//       styles: (theme) => ({
//         head: {
//           backgroundColor: theme.colors.dkpurple[0],
//           color: theme.colors.white,
//         },
//         body: {
//           backgroundColor: theme.colors.bgpurple[0],
//           color: theme.colors.dkpurple[0],
//         },
//         root: {
//           borderColor: theme.colors.mdpurple[0],
//         },
//         row: {
//           '&:hover': {
//             backgroundColor: theme.colors.ltpurple[0],
//           },
//         },
//       }),
//     },
//     Card: {
//       styles: (theme) => ({
//         root: {
//           backgroundColor: theme.colors.bgorange[0],
//           color: theme.colors.dkgray[0],
//           borderColor: theme.colors.mdorange[0],
//           boxShadow: `0 2px 4px ${theme.colors.ltgray[0]}`,
//           padding: '16px',
//         },
//       }),
//     },
//     Modal: {
//       styles: (theme) => ({
//         root: {
//           backgroundColor: theme.colors.bgpurple[0],
//           color: theme.colors.dkpurple[0],
//         },
//         overlay: {
//           backgroundColor: theme.colors.ltgray[0],
//         },
//         closeButton: {
//           color: theme.colors.mdgray[0],
//         },
//       }),
//     },
//     Notification: {
//       styles: (theme) => ({
//         root: {
//           backgroundColor: theme.colors.mdorange[0],
//           color: theme.colors.white,
//           borderColor: theme.colors.mdorange[0],
//         },
//         icon: {
//           color: theme.colors.dkgray[0],
//         },
//         closeButton: {
//           color: theme.colors.dkgray[0],
//         },
//       }),
//     },
//   },
// };


const App = () => {
  const { width } = useDebouncedResize(300); // Adjust the delay as needed
  const [resizeKey, setResizeKey] = useState(0);

  useEffect(() => {
    // Force a re-render by updating the key
    setResizeKey(prevKey => prevKey + 1);
  }, [width]);

  return (
    <ErrorBoundary>
    <Provider store={store}>
    {/* <MantineProvider theme={mantineTheme}> */}
    <MantineProvider>
    <Router>
            <Routes>
              <Route path="/survey/:companyId/:surveyname" element={<SurveyPage />} />
              <Route path="/results/:companyId/:surveyId" element={<SurveyResultsPage />} />
              <Route path="*" element={<Content resizeKey={resizeKey} />} />
            </Routes>
          </Router>
    </MantineProvider>
    </Provider>
    </ErrorBoundary>
  );
};

export default App;
