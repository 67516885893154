import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import SurveyQuestions from './SurveyQuestions';
import SurveyNavigationButtons from './SurveyNavigationButtons';

const SurveyBody = ({ questions, responses, onResponseChange, filterby, submitSurvey }) => {
  const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
  const [department, setDepartment] = useState('');

  const groupedQuestions = questions.reduce((acc, question) => {
    const dimension = question.dimension;
    if (!acc[dimension]) {
      acc[dimension] = [];
    }
    acc[dimension].push(question);
    return acc;
  }, {});

  const dimensions = Object.keys(groupedQuestions);

  useEffect(() => {
    setCurrentDimensionIndex(0);
  }, [department]);

  const handlePrevious = () => {
    setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    const currentDimension = dimensions[currentDimensionIndex];

    const allAnswered = groupedQuestions[currentDimension].every((question) => {
      console.log(`Checking question: ${question.text}`);
      console.log(`Response: ${responses[question.text]}`);
      return responses[question.text] !== undefined && responses[question.text] !== null;
    });

    if (allAnswered) {
      if (filterby === "demographics" && department) {
        const departmentQuestions = groupedQuestions[department] || [];
        const allDepartmentQuestionsAnswered = departmentQuestions.every((question) => {
          return responses[question.text] !== undefined && responses[question.text] !== null;
        });

        if (allDepartmentQuestionsAnswered) {
          submitSurvey();
          return;
        }
      }

      if (isLastDimension) {
        submitSurvey();
      } else {
        setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
      }
    } else {
      alert('Please answer all required questions before proceeding.');
    }
  };

  const handleResponseChange = (questionText, response) => {
    if (responses[questionText] !== response) {
      onResponseChange(questionText, response);
      if (filterby === "demographics" && questionText === "What is your job function?" && department !== response) {
        setDepartment(response);
        console.log('Department set to:', response);
      }
    }
    console.log('Updated Responses in SurveyBody:', responses);
  };

  const currentDimension = dimensions[currentDimensionIndex];
  const isLastDimension = currentDimensionIndex === dimensions.length - 1;

  const filteredQuestions = filterby === "demographics" && department
    ? groupedQuestions[department] || []
    : groupedQuestions[currentDimension];

  useEffect(() => {
    console.log('Filtered Questions:', filteredQuestions);
  }, [filteredQuestions]);

  return (
    <div>
      <motion.div
        key={currentDimension}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
      >
        <h2>{currentDimension}</h2>
        {filteredQuestions.map((question, index) => (
          <SurveyQuestions
            key={`${currentDimension}-${index}`}
            question={question}
            response={responses[question.text]}
            onResponseChange={handleResponseChange}
          />
        ))}
      </motion.div>
      <SurveyNavigationButtons
        currentIndex={currentDimensionIndex}
        totalDimensions={dimensions.length}
        onPrevious={handlePrevious}
        onNext={handleNext}
        isLast={isLastDimension}
      />
    </div>
  );
};

export default SurveyBody;


// import React, { useState, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import SurveyQuestions from './SurveyQuestions';
// import SurveyNavigationButtons from './SurveyNavigationButtons';

// const SurveyBody = ({ questions, responses, onResponseChange, filterby }) => {
//   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
//   const [department, setDepartment] = useState('');

//   const groupedQuestions = questions.reduce((acc, question) => {
//     const dimension = question.dimension;
//     if (!acc[dimension]) {
//       acc[dimension] = [];
//     }
//     acc[dimension].push(question);
//     return acc;
//   }, {});

//   const dimensions = Object.keys(groupedQuestions);

//   useEffect(() => {
//     setCurrentDimensionIndex(0);
//   }, [department]);

//   const handlePrevious = () => {
//     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
//   };

//   const handleNext = () => {
//     const currentDimension = dimensions[currentDimensionIndex];

//     const allAnswered = groupedQuestions[currentDimension].every((question) => {
//       console.log(`Checking question: ${question.text}`);
//       console.log(`Response: ${responses[question.text]}`);
//       return responses[question.text] !== undefined && responses[question.text] !== null;
//     });

//     if (allAnswered) {
//       if (isLastDimension) {
//         // Trigger form submission here if all questions are answered
//         console.log('All questions answered. Ready to submit.');
//         // Call the submit handler from SurveyContainer here if needed
//         // submitSurvey();
//       } else {
//         setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
//       }
//     } else {
//       alert('Please answer all required questions before proceeding.');
//     }
//   };

//   const handleResponseChange = (questionText, response) => {
//     if (responses[questionText] !== response) {
//       onResponseChange(questionText, response);
//       if (filterby === "demographics" && questionText === "What is your job function?" && department !== response) {
//         setDepartment(response);
//         console.log('Department set to:', response);
//       }
//     }
//     console.log('Updated Responses in SurveyBody:', responses);
//   };

//   const currentDimension = dimensions[currentDimensionIndex];
//   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

//   const filteredQuestions = filterby === "demographics" && department
//     ? groupedQuestions[department] || []
//     : groupedQuestions[currentDimension];

//   useEffect(() => {
//     console.log('Filtered Questions:', filteredQuestions);
//   }, [filteredQuestions]);

//   return (
//     <div>
//       <motion.div
//         key={currentDimension}
//         initial={{ opacity: 0 }}
//         animate={{ opacity: 1 }}
//         exit={{ opacity: 0 }}
//         transition={{ duration: 0.3, ease: 'easeInOut' }}
//       >
//         <h2>{currentDimension}</h2>
//         {filteredQuestions.map((question, index) => (
//           <SurveyQuestions
//             key={`${currentDimension}-${index}`}
//             question={question}
//             response={responses[question.text]}
//             onResponseChange={handleResponseChange}
//           />
//         ))}
//       </motion.div>
//       <SurveyNavigationButtons
//         currentIndex={currentDimensionIndex}
//         totalDimensions={dimensions.length}
//         onPrevious={handlePrevious}
//         onNext={handleNext}
//         isLast={isLastDimension}
//       />
//     </div>
//   );
// };

// export default SurveyBody;


// // import React, { useState, useEffect } from 'react';
// // import { motion } from 'framer-motion';
// // import SurveyQuestions from './SurveyQuestions';
// // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // const SurveyBody = ({ questions, responses, onResponseChange, filterby }) => {
// //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
// //   const [department, setDepartment] = useState('');

// //   const groupedQuestions = questions.reduce((acc, question) => {
// //     const dimension = question.dimension;
// //     if (!acc[dimension]) {
// //       acc[dimension] = [];
// //     }
// //     acc[dimension].push(question);
// //     return acc;
// //   }, {});

// //   const dimensions = Object.keys(groupedQuestions);

// //   useEffect(() => {
// //     setCurrentDimensionIndex(0);
// //   }, [department]);

// //   const handlePrevious = () => {
// //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// //   };

// //   const handleNext = () => {
// //     const currentDimension = dimensions[currentDimensionIndex];

// //     const allAnswered = groupedQuestions[currentDimension].every((question) => {
// //       console.log(`Checking question: ${question.text}`);
// //       console.log(`Response: ${responses[question.text]}`);
// //       return responses[question.text] !== undefined && responses[question.text] !== null;
// //     });

// //     if (allAnswered) {
// //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// //     } else {
// //       alert('Please answer all required questions before proceeding.');
// //     }
// //   };

// //   const handleResponseChange = (questionText, response) => {
// //     if (responses[questionText] !== response) {
// //       onResponseChange(questionText, response);
// //       if (filterby === "demographics" && questionText === "What is your job function?" && department !== response) {
// //         setDepartment(response);
// //         console.log('Department set to:', response);
// //       }
// //     }
// //     console.log('Updated Responses in SurveyBody:', responses);
// //   };

// //   const currentDimension = dimensions[currentDimensionIndex];
// //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// //   // Filter questions to only show those relevant to the selected department if filterby is set to demographics
// //   const filteredQuestions = filterby === "demographics" && department
// //     ? groupedQuestions[department] || []
// //     : groupedQuestions[currentDimension];

// //   useEffect(() => {
// //     console.log('Filtered Questions:', filteredQuestions);
// //   }, [filteredQuestions]);

// //   return (
// //     <div>
// //       <motion.div
// //         key={currentDimension}
// //         initial={{ opacity: 0 }}
// //         animate={{ opacity: 1 }}
// //         exit={{ opacity: 0 }}
// //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// //       >
// //         <h2>{currentDimension}</h2>
// //         {filteredQuestions.map((question, index) => (
// //           <SurveyQuestions
// //             key={`${currentDimension}-${index}`}
// //             question={question}
// //             response={responses[question.text]}
// //             onResponseChange={handleResponseChange}
// //           />
// //         ))}
// //       </motion.div>
// //       <SurveyNavigationButtons
// //         currentIndex={currentDimensionIndex}
// //         totalDimensions={dimensions.length}
// //         onPrevious={handlePrevious}
// //         onNext={handleNext}
// //         isLast={isLastDimension}
// //       />
// //     </div>
// //   );
// // };

// // export default SurveyBody;



// // // import React, { useState, useEffect } from 'react';
// // // import { motion } from 'framer-motion';
// // // import SurveyQuestions from './SurveyQuestions';
// // // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // // const SurveyBody = ({ questions, responses, onResponseChange, filterby }) => {
// // //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
// // //   const [department, setDepartment] = useState('');

// // //   const groupedQuestions = questions.reduce((acc, question) => {
// // //     const dimension = question.dimension;
// // //     if (!acc[dimension]) {
// // //       acc[dimension] = [];
// // //     }
// // //     acc[dimension].push(question);
// // //     return acc;
// // //   }, {});

// // //   const dimensions = Object.keys(groupedQuestions);

// // //   useEffect(() => {
// // //     setCurrentDimensionIndex(0);
// // //   }, [department]);

// // //   const handlePrevious = () => {
// // //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// // //   };

// // //   const handleNext = () => {
// // //     const currentDimension = dimensions[currentDimensionIndex];

// // //     const allAnswered = groupedQuestions[currentDimension].every((question) => {
// // //       console.log(`Checking question: ${question.text}`);
// // //       console.log(`Response: ${responses[question.text]}`);
// // //       return responses[question.text] !== undefined && responses[question.text] !== null;
// // //     });

// // //     if (allAnswered) {
// // //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // //     } else {
// // //       alert('Please answer all required questions before proceeding.');
// // //     }
// // //   };

// // //   const handleResponseChange = (questionText, response) => {
// // //     if (responses[questionText] !== response) {
// // //       onResponseChange(questionText, response);
// // //       if (filterby === "demographics" && questionText === "What is your job function?" && department !== response) {
// // //         setDepartment(response);
// // //       }
// // //     }
// // //     console.log('Updated Responses in SurveyBody:', responses);
// // //   };

// // //   const currentDimension = dimensions[currentDimensionIndex];
// // //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// // //   // Filter questions to only show those relevant to the selected department if filterby is set to demographics
// // //   const filteredQuestions = filterby === "demographics" && department
// // //     ? groupedQuestions[currentDimension].filter(
// // //         (question) => question.dimension === department || question.dimension === "Demographics"
// // //       )
// // //     : groupedQuestions[currentDimension];

// // //   useEffect(() => {
// // //     console.log('Filtered Questions:', filteredQuestions);
// // //   }, [filteredQuestions]);

// // //   return (
// // //     <div>
// // //       <motion.div
// // //         key={currentDimension}
// // //         initial={{ opacity: 0 }}
// // //         animate={{ opacity: 1 }}
// // //         exit={{ opacity: 0 }}
// // //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// // //       >
// // //         <h2>{currentDimension}</h2>
// // //         {filteredQuestions.map((question, index) => (
// // //           <SurveyQuestions
// // //             key={`${currentDimension}-${index}`}
// // //             question={question}
// // //             response={responses[question.text]}
// // //             onResponseChange={handleResponseChange}
// // //           />
// // //         ))}
// // //       </motion.div>
// // //       <SurveyNavigationButtons
// // //         currentIndex={currentDimensionIndex}
// // //         totalDimensions={dimensions.length}
// // //         onPrevious={handlePrevious}
// // //         onNext={handleNext}
// // //         isLast={isLastDimension}
// // //       />
// // //     </div>
// // //   );
// // // };

// // // export default SurveyBody;



// // // // import React, { useState, useEffect } from 'react';
// // // // import { motion } from 'framer-motion';
// // // // import SurveyQuestions from './SurveyQuestions';
// // // // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // // // const SurveyBody = ({ questions, responses, onResponseChange, filterby }) => {
// // // //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
// // // //   const [department, setDepartment] = useState('');

// // // //   const groupedQuestions = questions.reduce((acc, question) => {
// // // //     const dimension = question.dimension;
// // // //     if (!acc[dimension]) {
// // // //       acc[dimension] = [];
// // // //     }
// // // //     acc[dimension].push(question);
// // // //     return acc;
// // // //   }, {});

// // // //   const dimensions = Object.keys(groupedQuestions);

// // // //   useEffect(() => {
// // // //     setCurrentDimensionIndex(0);
// // // //   }, [department]);

// // // //   const handlePrevious = () => {
// // // //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// // // //   };

// // // //   const handleNext = () => {
// // // //     const currentDimension = dimensions[currentDimensionIndex];

// // // //     const allAnswered = groupedQuestions[currentDimension].every((question) => {
// // // //       console.log(`Checking question: ${question.text}`);
// // // //       console.log(`Response: ${responses[question.text]}`);
// // // //       return responses[question.text] !== undefined && responses[question.text] !== null;
// // // //     });

// // // //     if (allAnswered) {
// // // //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // // //     } else {
// // // //       alert('Please answer all required questions before proceeding.');
// // // //     }
// // // //   };

// // // //   const handleResponseChange = (questionText, response) => {
// // // //     if (responses[questionText] !== response) {
// // // //       onResponseChange(questionText, response);
// // // //       if (filterby === "demographics" && questionText === "What is your job function?" && department !== response) {
// // // //         setDepartment(response);
// // // //       }
// // // //     }
// // // //     console.log('Updated Responses in SurveyBody:', responses);
// // // //   };

// // // //   const currentDimension = dimensions[currentDimensionIndex];
// // // //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// // // //   const filteredQuestions = filterby === "demographics" && department
// // // //     ? groupedQuestions[currentDimension].filter(
// // // //         (question) => question.dimension === department || question.dimension === "Demographics"
// // // //       )
// // // //     : groupedQuestions[currentDimension];

// // // //   useEffect(() => {
// // // //     console.log('Filtered Questions:', filteredQuestions);
// // // //   }, [filteredQuestions]);

// // // //   return (
// // // //     <div>
// // // //       <motion.div
// // // //         key={currentDimension}
// // // //         initial={{ opacity: 0 }}
// // // //         animate={{ opacity: 1 }}
// // // //         exit={{ opacity: 0 }}
// // // //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// // // //       >
// // // //         <h2>{currentDimension}</h2>
// // // //         {filteredQuestions.map((question, index) => (
// // // //           <SurveyQuestions
// // // //             key={`${currentDimension}-${index}`}
// // // //             question={question}
// // // //             response={responses[question.text]}
// // // //             onResponseChange={handleResponseChange}
// // // //           />
// // // //         ))}
// // // //       </motion.div>
// // // //       <SurveyNavigationButtons
// // // //         currentIndex={currentDimensionIndex}
// // // //         totalDimensions={dimensions.length}
// // // //         onPrevious={handlePrevious}
// // // //         onNext={handleNext}
// // // //         isLast={isLastDimension}
// // // //       />
// // // //     </div>
// // // //   );
// // // // };

// // // // export default SurveyBody;


// // // // // import React, { useState, useEffect } from 'react';
// // // // // import { motion } from 'framer-motion';
// // // // // import SurveyQuestions from './SurveyQuestions';
// // // // // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // // // // const SurveyBody = ({ questions, responses, onResponseChange, filterby }) => {
// // // // //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
// // // // //   const [department, setDepartment] = useState('');

// // // // //   const groupedQuestions = questions.reduce((acc, question) => {
// // // // //     const dimension = question.dimension;
// // // // //     if (!acc[dimension]) {
// // // // //       acc[dimension] = [];
// // // // //     }
// // // // //     acc[dimension].push(question);
// // // // //     return acc;
// // // // //   }, {});

// // // // //   const dimensions = Object.keys(groupedQuestions);

// // // // //   useEffect(() => {
// // // // //     // Reset the current dimension index when the department changes
// // // // //     setCurrentDimensionIndex(0);
// // // // //   }, [department]);

// // // // //   const handlePrevious = () => {
// // // // //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// // // // //   };

// // // // //   // const handleNext = () => {
// // // // //   //   const currentDimension = dimensions[currentDimensionIndex];
// // // // //   //   const allAnswered = groupedQuestions[currentDimension].every((question) => {
// // // // //   //     // Only check non-demographic questions or if there's no filterby attribute
// // // // //   //     return filterby !== 'demographics' || question.dimension !== 'Demographics' || responses[question.text];
// // // // //   //   });

// // // // //   //   if (allAnswered) {
// // // // //   //     setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // // // //   //   } else {
// // // // //   //     alert('Please answer all required questions before proceeding.');
// // // // //   //   }
// // // // //   // };

// // // // //   const handleNext = () => {
// // // // //     const currentDimension = dimensions[currentDimensionIndex];
  
// // // // //     // Check if all required questions are answered
// // // // //     const allAnswered = groupedQuestions[currentDimension].every((question) => {
// // // // //       // Debugging logs
// // // // //       console.log(`Checking question: ${question.text}`);
// // // // //       console.log(`Question type: ${question.type}`);
// // // // //       console.log(`Question dimension: ${question.dimension}`);
// // // // //       console.log(`Response: ${responses[question.text]}`);
  
// // // // //       // If filterby is set to demographics, only check non-demographic questions or if demographic responses are provided
// // // // //       if (filterby === 'demographics' && question.dimension === 'Demographics') {
// // // // //         return responses[question.text] !== undefined && responses[question.text] !== null;
// // // // //       }
// // // // //       return responses[question.text] !== undefined && responses[question.text] !== null;
// // // // //     });
  
// // // // //     if (allAnswered) {
// // // // //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // // // //     } else {
// // // // //       alert('Please answer all required questions before proceeding.');
// // // // //     }
// // // // //   };
  
  
// // // // //   // const handleResponseChange = (questionText, response) => {
// // // // //   //   // Check if the response has changed before updating state
// // // // //   //   if (responses[questionText] !== response) {
// // // // //   //     onResponseChange(questionText, response);
// // // // //   //     // Check if the demographic question is answered and if the department has actually changed
// // // // //   //     if (filterby === "demographics" && questionText === "What is your job function?" && department !== response) {
// // // // //   //       setDepartment(response);
// // // // //   //     }
// // // // //   //   }
// // // // //   // };

// // // // //   const handleResponseChange = (questionText, response) => {
// // // // //     // Check if the response has changed before updating state
// // // // //     if (responses[questionText] !== response) {
// // // // //       // Update the state with the new response
// // // // //       onResponseChange(questionText, response);
// // // // //       // Check if the demographic question is answered and if the department has actually changed
// // // // //       if (filterby === "demographics" && questionText === "What is your job function?" && department !== response) {
// // // // //         setDepartment(response);
// // // // //       }
// // // // //     }
// // // // //     console.log('Updated Responses:', responses);
// // // // //   };
  
  
// // // // //   const currentDimension = dimensions[currentDimensionIndex];
// // // // //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// // // // //   // Filter questions to only show those relevant to the selected department if filterby is set to demographics
// // // // //   const filteredQuestions = filterby === "demographics" && department
// // // // //     ? groupedQuestions[currentDimension].filter(
// // // // //         (question) => question.dimension === department || question.dimension === "Demographics"
// // // // //       )
// // // // //     : groupedQuestions[currentDimension];

// // // // //   useEffect(() => {
// // // // //     console.log('Filtered Questions:', filteredQuestions); // Debugging log
// // // // //   }, [filteredQuestions]);

// // // // //   return (
// // // // //     <div>
// // // // //       <motion.div
// // // // //         key={currentDimension}
// // // // //         initial={{ opacity: 0 }}
// // // // //         animate={{ opacity: 1 }}
// // // // //         exit={{ opacity: 0 }}
// // // // //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// // // // //       >
// // // // //         <h2>{currentDimension}</h2>
// // // // //         {filteredQuestions.map((question, index) => (
// // // // //           <SurveyQuestions
// // // // //             key={`${currentDimension}-${index}`}
// // // // //             question={question}
// // // // //             response={responses[question.text]}
// // // // //             onResponseChange={handleResponseChange}
// // // // //           />
// // // // //         ))}
// // // // //       </motion.div>
// // // // //       <SurveyNavigationButtons
// // // // //         currentIndex={currentDimensionIndex}
// // // // //         totalDimensions={dimensions.length}
// // // // //         onPrevious={handlePrevious}
// // // // //         onNext={handleNext}
// // // // //         isLast={isLastDimension}
// // // // //       />
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default SurveyBody;


// // // // // // import React, { useState, useEffect } from 'react';
// // // // // // import { motion } from 'framer-motion';
// // // // // // import SurveyQuestions from './SurveyQuestions';
// // // // // // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // // // // // const SurveyBody = ({ questions, responses, onResponseChange, filterby }) => {
// // // // // //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
// // // // // //   const [department, setDepartment] = useState('');

// // // // // //   const groupedQuestions = questions.reduce((acc, question) => {
// // // // // //     const dimension = question.dimension;
// // // // // //     if (!acc[dimension]) {
// // // // // //       acc[dimension] = [];
// // // // // //     }
// // // // // //     acc[dimension].push(question);
// // // // // //     return acc;
// // // // // //   }, {});

// // // // // //   const dimensions = Object.keys(groupedQuestions);

// // // // // //   useEffect(() => {
// // // // // //     // Reset the current dimension index when the department changes
// // // // // //     setCurrentDimensionIndex(0);
// // // // // //   }, [department]);

// // // // // //   const handlePrevious = () => {
// // // // // //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// // // // // //   };

// // // // // //   const handleNext = () => {
// // // // // //     const currentDimension = dimensions[currentDimensionIndex];
// // // // // //     const allAnswered = groupedQuestions[currentDimension].every((question) => responses[question.text]);

// // // // // //     if (allAnswered) {
// // // // // //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // // // // //     } else {
// // // // // //       alert('Please answer all questions before proceeding.');
// // // // // //     }
// // // // // //   };

// // // // // //   const handleResponseChange = (questionText, response) => {
// // // // // //     // Check if the response has changed before updating state
// // // // // //     if (responses[questionText] !== response) {
// // // // // //       onResponseChange(questionText, response);
// // // // // //       // Check if the demographic question is answered and if the department has actually changed
// // // // // //       if (filterby === "demographics" && questionText === "What is your job function?" && department !== response) {
// // // // // //         setDepartment(response);
// // // // // //       }
// // // // // //     }
// // // // // //   };

// // // // // //   const currentDimension = dimensions[currentDimensionIndex];
// // // // // //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// // // // // //   // Filter questions to only show those relevant to the selected department if filterby is set to demographics
// // // // // //   const filteredQuestions = filterby === "demographics" && department
// // // // // //     ? groupedQuestions[currentDimension].filter(
// // // // // //         (question) => question.dimension === department || question.dimension === "Demographics"
// // // // // //       )
// // // // // //     : groupedQuestions[currentDimension];

// // // // // //   useEffect(() => {
// // // // // //     console.log('Filtered Questions:', filteredQuestions); // Debugging log
// // // // // //   }, [filteredQuestions]);

// // // // // //   return (
// // // // // //     <div>
// // // // // //       <motion.div
// // // // // //         key={currentDimension}
// // // // // //         initial={{ opacity: 0 }}
// // // // // //         animate={{ opacity: 1 }}
// // // // // //         exit={{ opacity: 0 }}
// // // // // //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// // // // // //       >
// // // // // //         <h2>{currentDimension}</h2>
// // // // // //         {filteredQuestions.map((question, index) => (
// // // // // //           <SurveyQuestions
// // // // // //             key={`${currentDimension}-${index}`}
// // // // // //             question={question}
// // // // // //             response={responses[question.text]}
// // // // // //             onResponseChange={handleResponseChange}
// // // // // //           />
// // // // // //         ))}
// // // // // //       </motion.div>
// // // // // //       <SurveyNavigationButtons
// // // // // //         currentIndex={currentDimensionIndex}
// // // // // //         totalDimensions={dimensions.length}
// // // // // //         onPrevious={handlePrevious}
// // // // // //         onNext={handleNext}
// // // // // //         isLast={isLastDimension}
// // // // // //       />
// // // // // //     </div>
// // // // // //   );
// // // // // // };

// // // // // // export default SurveyBody;


// // // // // // // import React, { useState, useEffect } from 'react';
// // // // // // // import { motion } from 'framer-motion';
// // // // // // // import SurveyQuestions from './SurveyQuestions';
// // // // // // // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // // // // // // const SurveyBody = ({ questions, responses, onResponseChange }) => {
// // // // // // //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
// // // // // // //   const [department, setDepartment] = useState('');

// // // // // // //   const groupedQuestions = questions.reduce((acc, question) => {
// // // // // // //     const dimension = question.dimension;
// // // // // // //     if (!acc[dimension]) {
// // // // // // //       acc[dimension] = [];
// // // // // // //     }
// // // // // // //     acc[dimension].push(question);
// // // // // // //     return acc;
// // // // // // //   }, {});

// // // // // // //   const dimensions = Object.keys(groupedQuestions);

// // // // // // //   useEffect(() => {
// // // // // // //     // Reset the current dimension index when the department changes
// // // // // // //     setCurrentDimensionIndex(0);
// // // // // // //   }, [department]);

// // // // // // //   const handlePrevious = () => {
// // // // // // //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// // // // // // //   };

// // // // // // //   const handleNext = () => {
// // // // // // //     const currentDimension = dimensions[currentDimensionIndex];
// // // // // // //     const allAnswered = groupedQuestions[currentDimension].every((question) => responses[question.text]);

// // // // // // //     if (allAnswered) {
// // // // // // //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // // // // // //     } else {
// // // // // // //       alert('Please answer all questions before proceeding.');
// // // // // // //     }
// // // // // // //   };

// // // // // // //   const handleResponseChange = (questionText, response) => {
// // // // // // //     // Check if the response has changed before updating state
// // // // // // //     if (responses[questionText] !== response) {
// // // // // // //       onResponseChange(questionText, response);
// // // // // // //       // Check if the demographic question is answered and if the department has actually changed
// // // // // // //       if (questionText === "What is your job function?" && department !== response) {
// // // // // // //         setDepartment(response);
// // // // // // //       }
// // // // // // //     }
// // // // // // //   };

// // // // // // //   const currentDimension = dimensions[currentDimensionIndex];
// // // // // // //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// // // // // // //   // Filter questions to only show those relevant to the selected department
// // // // // // //   const filteredQuestions = department
// // // // // // //     ? groupedQuestions[currentDimension].filter(
// // // // // // //         (question) => question.dimension === department || question.dimension === "Demographics"
// // // // // // //       )
// // // // // // //     : groupedQuestions[currentDimension];

// // // // // // //   useEffect(() => {
// // // // // // //     console.log('Filtered Questions:', filteredQuestions); // Debugging log
// // // // // // //   }, [filteredQuestions]);

// // // // // // //   return (
// // // // // // //     <div>
// // // // // // //       <motion.div
// // // // // // //         key={currentDimension}
// // // // // // //         initial={{ opacity: 0 }}
// // // // // // //         animate={{ opacity: 1 }}
// // // // // // //         exit={{ opacity: 0 }}
// // // // // // //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// // // // // // //       >
// // // // // // //         <h2>{currentDimension}</h2>
// // // // // // //         {filteredQuestions.map((question, index) => (
// // // // // // //           <SurveyQuestions
// // // // // // //             key={`${currentDimension}-${index}`}
// // // // // // //             question={question}
// // // // // // //             response={responses[question.text]}
// // // // // // //             onResponseChange={handleResponseChange}
// // // // // // //           />
// // // // // // //         ))}
// // // // // // //       </motion.div>
// // // // // // //       <SurveyNavigationButtons
// // // // // // //         currentIndex={currentDimensionIndex}
// // // // // // //         totalDimensions={dimensions.length}
// // // // // // //         onPrevious={handlePrevious}
// // // // // // //         onNext={handleNext}
// // // // // // //         isLast={isLastDimension}
// // // // // // //       />
// // // // // // //     </div>
// // // // // // //   );
// // // // // // // };

// // // // // // // export default SurveyBody;


// // // // // // // // import React, { useState, useEffect } from 'react';
// // // // // // // // import { motion } from 'framer-motion';
// // // // // // // // import SurveyQuestions from './SurveyQuestions';
// // // // // // // // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // // // // // // // const SurveyBody = ({ questions, responses, onResponseChange }) => {
// // // // // // // //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
// // // // // // // //   const [department, setDepartment] = useState('');

// // // // // // // //   const groupedQuestions = questions.reduce((acc, question) => {
// // // // // // // //     const dimension = question.dimension;
// // // // // // // //     if (!acc[dimension]) {
// // // // // // // //       acc[dimension] = [];
// // // // // // // //     }
// // // // // // // //     acc[dimension].push(question);
// // // // // // // //     return acc;
// // // // // // // //   }, {});

// // // // // // // //   const dimensions = Object.keys(groupedQuestions);

// // // // // // // //   useEffect(() => {
// // // // // // // //     // Reset the current dimension index when the department changes
// // // // // // // //     setCurrentDimensionIndex(0);
// // // // // // // //   }, [department]);

// // // // // // // //   const handlePrevious = () => {
// // // // // // // //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// // // // // // // //   };

// // // // // // // //   const handleNext = () => {
// // // // // // // //     const currentDimension = dimensions[currentDimensionIndex];
// // // // // // // //     const allAnswered = groupedQuestions[currentDimension].every((question) => responses[question.text]);

// // // // // // // //     if (allAnswered) {
// // // // // // // //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // // // // // // //     } else {
// // // // // // // //       alert('Please answer all questions before proceeding.');
// // // // // // // //     }
// // // // // // // //   };

// // // // // // // //   const handleResponseChange = (questionText, response) => {
// // // // // // // //     onResponseChange(questionText, response);
// // // // // // // //     // Check if the demographic question is answered and if the department has actually changed
// // // // // // // //     if (questionText === "What is your job function?" && department !== response) {
// // // // // // // //       setDepartment(response);
// // // // // // // //     }
// // // // // // // //   };  

// // // // // // // //   const currentDimension = dimensions[currentDimensionIndex];
// // // // // // // //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// // // // // // // //   // Filter questions to only show those relevant to the selected department
// // // // // // // //   const filteredQuestions = department
// // // // // // // //     ? groupedQuestions[currentDimension].filter(
// // // // // // // //         (question) => question.dimension === department || question.dimension === "Demographics"
// // // // // // // //       )
// // // // // // // //     : groupedQuestions[currentDimension];

// // // // // // // //   useEffect(() => {
// // // // // // // //     console.log('Filtered Questions:', filteredQuestions); // Debugging log
// // // // // // // //   }, [filteredQuestions]);

// // // // // // // //   return (
// // // // // // // //     <div>
// // // // // // // //       <motion.div
// // // // // // // //         key={currentDimension}
// // // // // // // //         initial={{ opacity: 0 }}
// // // // // // // //         animate={{ opacity: 1 }}
// // // // // // // //         exit={{ opacity: 0 }}
// // // // // // // //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// // // // // // // //       >
// // // // // // // //         <h2>{currentDimension}</h2>
// // // // // // // //         {filteredQuestions.map((question, index) => (
// // // // // // // //           <SurveyQuestions
// // // // // // // //             key={`${currentDimension}-${index}`}
// // // // // // // //             question={question}
// // // // // // // //             response={responses[question.text]}
// // // // // // // //             onResponseChange={handleResponseChange}
// // // // // // // //           />
// // // // // // // //         ))}
// // // // // // // //       </motion.div>
// // // // // // // //       <SurveyNavigationButtons
// // // // // // // //         currentIndex={currentDimensionIndex}
// // // // // // // //         totalDimensions={dimensions.length}
// // // // // // // //         onPrevious={handlePrevious}
// // // // // // // //         onNext={handleNext}
// // // // // // // //         isLast={isLastDimension}
// // // // // // // //       />
// // // // // // // //     </div>
// // // // // // // //   );
// // // // // // // // };

// // // // // // // // export default SurveyBody;

// // // // // // // // // import React, { useState, useEffect } from 'react';
// // // // // // // // // import { motion } from 'framer-motion';
// // // // // // // // // import SurveyQuestions from './SurveyQuestions';
// // // // // // // // // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // // // // // // // // const SurveyBody = ({ questions, responses, onResponseChange }) => {
// // // // // // // // //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);
// // // // // // // // //   const [department, setDepartment] = useState('');

// // // // // // // // //   const groupedQuestions = questions.reduce((acc, question) => {
// // // // // // // // //     const dimension = question.dimension;
// // // // // // // // //     if (!acc[dimension]) {
// // // // // // // // //       acc[dimension] = [];
// // // // // // // // //     }
// // // // // // // // //     acc[dimension].push(question);
// // // // // // // // //     return acc;
// // // // // // // // //   }, {});

// // // // // // // // //   const dimensions = Object.keys(groupedQuestions);

// // // // // // // // //   useEffect(() => {
// // // // // // // // //     // Reset the current dimension index when the department changes
// // // // // // // // //     setCurrentDimensionIndex(0);
// // // // // // // // //   }, [department]);

// // // // // // // // //   const handlePrevious = () => {
// // // // // // // // //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// // // // // // // // //   };

// // // // // // // // //   const handleNext = () => {
// // // // // // // // //     const currentDimension = dimensions[currentDimensionIndex];
// // // // // // // // //     const allAnswered = groupedQuestions[currentDimension].every((question) => responses[question.text]);

// // // // // // // // //     if (allAnswered) {
// // // // // // // // //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // // // // // // // //     } else {
// // // // // // // // //       alert('Please answer all questions before proceeding.');
// // // // // // // // //     }
// // // // // // // // //   };

// // // // // // // // //   const handleResponseChange = (questionText, response) => {
// // // // // // // // //     onResponseChange(questionText, response);
// // // // // // // // //     // Check if the demographic question is answered
// // // // // // // // //     if (questionText === "What is your job function?") {
// // // // // // // // //       setDepartment(response);
// // // // // // // // //     }
// // // // // // // // //   };

// // // // // // // // //   const currentDimension = dimensions[currentDimensionIndex];
// // // // // // // // //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// // // // // // // // //   // Filter questions to only show those relevant to the selected department
// // // // // // // // //   const filteredQuestions = department
// // // // // // // // //     ? groupedQuestions[currentDimension].filter(
// // // // // // // // //         (question) => question.dimension === department || question.dimension === "Demographics"
// // // // // // // // //       )
// // // // // // // // //     : groupedQuestions[currentDimension];

// // // // // // // // //   return (
// // // // // // // // //     <div>
// // // // // // // // //       <motion.div
// // // // // // // // //         key={currentDimension}
// // // // // // // // //         initial={{ opacity: 0 }}
// // // // // // // // //         animate={{ opacity: 1 }}
// // // // // // // // //         exit={{ opacity: 0 }}
// // // // // // // // //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// // // // // // // // //       >
// // // // // // // // //         <h2>{currentDimension}</h2>
// // // // // // // // //         {filteredQuestions.map((question, index) => (
// // // // // // // // //           <SurveyQuestions
// // // // // // // // //             key={`${currentDimension}-${index}`}
// // // // // // // // //             question={question}
// // // // // // // // //             response={responses[question.text]}
// // // // // // // // //             onResponseChange={handleResponseChange}
// // // // // // // // //           />
// // // // // // // // //         ))}
// // // // // // // // //       </motion.div>
// // // // // // // // //       <SurveyNavigationButtons
// // // // // // // // //         currentIndex={currentDimensionIndex}
// // // // // // // // //         totalDimensions={dimensions.length}
// // // // // // // // //         onPrevious={handlePrevious}
// // // // // // // // //         onNext={handleNext}
// // // // // // // // //         isLast={isLastDimension}
// // // // // // // // //       />
// // // // // // // // //     </div>
// // // // // // // // //   );
// // // // // // // // // };

// // // // // // // // // export default SurveyBody;


// // // // // // // // // // import React, { useState } from 'react';
// // // // // // // // // // import { motion } from 'framer-motion';
// // // // // // // // // // import SurveyQuestions from './SurveyQuestions';
// // // // // // // // // // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // // // // // // // // // const SurveyBody = ({ questions, responses, onResponseChange }) => {
// // // // // // // // // //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);

// // // // // // // // // //   const groupedQuestions = questions.reduce((acc, question) => {
// // // // // // // // // //     const dimension = question.dimension;
// // // // // // // // // //     if (!acc[dimension]) {
// // // // // // // // // //       acc[dimension] = [];
// // // // // // // // // //     }
// // // // // // // // // //     acc[dimension].push(question);
// // // // // // // // // //     return acc;
// // // // // // // // // //   }, {});

// // // // // // // // // //   const dimensions = Object.keys(groupedQuestions);

// // // // // // // // // //   const handlePrevious = () => {
// // // // // // // // // //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// // // // // // // // // //   };

// // // // // // // // // //   const handleNext = () => {
// // // // // // // // // //     const currentDimension = dimensions[currentDimensionIndex];
// // // // // // // // // //     const allAnswered = groupedQuestions[currentDimension].every((question) => {
// // // // // // // // // //       const response = responses[question.text];
// // // // // // // // // //       return question.type === 'multi_select' ? response && response.length > 0 : response;
// // // // // // // // // //     });

// // // // // // // // // //     if (allAnswered) {
// // // // // // // // // //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // // // // // // // // //     } else {
// // // // // // // // // //       alert('Please answer all questions before proceeding.');
// // // // // // // // // //     }
// // // // // // // // // //   };

// // // // // // // // // //   const currentDimension = dimensions[currentDimensionIndex];
// // // // // // // // // //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// // // // // // // // // //   return (
// // // // // // // // // //     <div>
// // // // // // // // // //       <motion.div
// // // // // // // // // //         key={currentDimension}
// // // // // // // // // //         initial={{ opacity: 0 }}
// // // // // // // // // //         animate={{ opacity: 1 }}
// // // // // // // // // //         exit={{ opacity: 0 }}
// // // // // // // // // //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// // // // // // // // // //       >
// // // // // // // // // //         <h2>{currentDimension}</h2>
// // // // // // // // // //         {groupedQuestions[currentDimension].map((question, index) => (
// // // // // // // // // //           <SurveyQuestions
// // // // // // // // // //             key={`${currentDimension}-${index}`}
// // // // // // // // // //             question={question}
// // // // // // // // // //             response={responses[question.text]}
// // // // // // // // // //             onResponseChange={(response) => onResponseChange(question.text, response)}
// // // // // // // // // //           />
// // // // // // // // // //         ))}
// // // // // // // // // //       </motion.div>
// // // // // // // // // //       <SurveyNavigationButtons
// // // // // // // // // //         currentIndex={currentDimensionIndex}
// // // // // // // // // //         totalDimensions={dimensions.length}
// // // // // // // // // //         onPrevious={handlePrevious}
// // // // // // // // // //         onNext={handleNext}
// // // // // // // // // //         isLast={isLastDimension}
// // // // // // // // // //       />
// // // // // // // // // //     </div>
// // // // // // // // // //   );
// // // // // // // // // // };

// // // // // // // // // // export default SurveyBody;


// // // // // // // // // // // import React, { useState } from 'react';
// // // // // // // // // // // import { motion } from 'framer-motion';
// // // // // // // // // // // import SurveyQuestions from './SurveyQuestions';
// // // // // // // // // // // import SurveyNavigationButtons from './SurveyNavigationButtons';

// // // // // // // // // // // const SurveyBody = ({ questions, responses, onResponseChange }) => {
// // // // // // // // // // //   const [currentDimensionIndex, setCurrentDimensionIndex] = useState(0);

// // // // // // // // // // //   const groupedQuestions = questions.reduce((acc, question) => {
// // // // // // // // // // //     const dimension = question.dimension;
// // // // // // // // // // //     if (!acc[dimension]) {
// // // // // // // // // // //       acc[dimension] = [];
// // // // // // // // // // //     }
// // // // // // // // // // //     acc[dimension].push(question);
// // // // // // // // // // //     return acc;
// // // // // // // // // // //   }, {});

// // // // // // // // // // //   const dimensions = Object.keys(groupedQuestions);

// // // // // // // // // // //   const handlePrevious = () => {
// // // // // // // // // // //     setCurrentDimensionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
// // // // // // // // // // //   };

// // // // // // // // // // //   const handleNext = () => {
// // // // // // // // // // //     const currentDimension = dimensions[currentDimensionIndex];
// // // // // // // // // // //     const allAnswered = groupedQuestions[currentDimension].every(
// // // // // // // // // // //       (question) => responses[question.text]
// // // // // // // // // // //     );

// // // // // // // // // // //     if (allAnswered) {
// // // // // // // // // // //       setCurrentDimensionIndex((prevIndex) => Math.min(prevIndex + 1, dimensions.length - 1));
// // // // // // // // // // //     } else {
// // // // // // // // // // //       alert('Please answer all questions before proceeding.');
// // // // // // // // // // //     }
// // // // // // // // // // //   };

// // // // // // // // // // //   const currentDimension = dimensions[currentDimensionIndex];
// // // // // // // // // // //   const isLastDimension = currentDimensionIndex === dimensions.length - 1;

// // // // // // // // // // //   return (
// // // // // // // // // // //     <div>
// // // // // // // // // // //       <motion.div
// // // // // // // // // // //         key={currentDimension}
// // // // // // // // // // //         initial={{ opacity: 0 }}
// // // // // // // // // // //         animate={{ opacity: 1 }}
// // // // // // // // // // //         exit={{ opacity: 0 }}
// // // // // // // // // // //         transition={{ duration: 0.3, ease: 'easeInOut' }}
// // // // // // // // // // //       >
// // // // // // // // // // //         <h2>{currentDimension}</h2>
// // // // // // // // // // //         {groupedQuestions[currentDimension].map((question, index) => (
// // // // // // // // // // //           <SurveyQuestions
// // // // // // // // // // //             key={`${currentDimension}-${index}`}
// // // // // // // // // // //             question={question}
// // // // // // // // // // //             response={responses[question.text]}
// // // // // // // // // // //             onResponseChange={(response) => onResponseChange(question.text, response)}
// // // // // // // // // // //           />
// // // // // // // // // // //         ))}
// // // // // // // // // // //       </motion.div>
// // // // // // // // // // //       <SurveyNavigationButtons
// // // // // // // // // // //         currentIndex={currentDimensionIndex}
// // // // // // // // // // //         totalDimensions={dimensions.length}
// // // // // // // // // // //         onPrevious={handlePrevious}
// // // // // // // // // // //         onNext={handleNext}
// // // // // // // // // // //         isLast={isLastDimension}
// // // // // // // // // // //       />
// // // // // // // // // // //     </div>
// // // // // // // // // // //   );
// // // // // // // // // // // };

// // // // // // // // // // // export default SurveyBody;