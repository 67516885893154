import React, { useEffect, useState } from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import SEO from '../components/SEO';
import ReactPlayer from 'react-player';
import { useSearchParams } from 'react-router-dom';

const HeroBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1', height: '500px' }} className="parallax-hero">
            <ParallaxBannerLayer
                image="/images/hyepartners-a_little_self_reflection.webp"
                speed={-20}
                expanded={true}
                className="parallax-banner-layer"
            />
            <ParallaxBannerLayer speed={5}>
                <div className="parallax-hero-text-div">
                    <h1 className="parallax-hero-text">Assessment Surveys</h1>
                </div>
            </ParallaxBannerLayer>
        </ParallaxBanner>
    );
};

const ContactBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1', height: '500px' }} className="parallax-hero">
            <ParallaxBannerLayer
                image="/images/hyepartners-bot_helping_work.webp"
                speed={-20}
                expanded={true}
                className="parallax-banner-layer"
            />
            <ParallaxBannerLayer speed={5}>
                <div className="parallax-hero-text-div">
                    <h1 className="parallax-hero-text">Benchmark Your Teams</h1>
                </div>
            </ParallaxBannerLayer>
        </ParallaxBanner>
    );
};

const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
        opacity: 1,
        x: '0%',
        y: '0%',
        transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
});

const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });

    return (
        <motion.div
            ref={ref}
            initial="hidden"
            animate={isInView ? "visible" : "hidden"}
            exit="exit"
            variants={fadeInOut(x, y)}
        >
            <GlowBox glowColor={glowColor} bgColor={bgColor}>
                {children}
            </GlowBox>
        </motion.div>
    );
};

const Solutions = ({ autoplay }) => {
    return (
        <div className='homepage'>

            <HeroBanner />
            <h1 className='standard-pad'>Monitor team benchmarks as you climb in maturity.</h1>

            <h3 className='standard-pad'>Surveys are anonymous, secure, and confidential.</h3>

            <div className="twobytwo">
                <ParallaxGlowBox 
                    glowColor="rgba(130, 2, 99, 0.125)" 
                    bgColor="bg-ltgray"
                    x="10%"
                    y="10%"
                >
                    <h4>Survey | AI Talent Readiness</h4> 
                    <ul>
                        <li>Evaluate the current skills and readiness of an organization's workforce to utilize AI technologies</li>
                        {/* <li>Comprehensive analysis of the workforce's proficiency in AI-related skills, identifies gaps</li> */}
                    </ul>
                </ParallaxGlowBox>
                <ParallaxGlowBox 
                    glowColor="rgba(130, 2, 99, 0.125)" 
                    bgColor="bg-ltgray"
                    x="-10%"
                    y="10%"
                >
                    <h4>Survey | AI Strategy Assessment</h4> 
                    <ul>
                        <li>Whether the team understands, aligns with, and supports the strategies for artificial intelligence</li>
                        {/* <li>li</li> */}
                    </ul>
                </ParallaxGlowBox>

                <ParallaxGlowBox 
                    glowColor="rgba(130, 2, 99, 0.125)" 
                    bgColor="bg-ltgray"
                    x="10%"
                    y="10%"
                >
                    <h4>Survey | AI Development & Data Science</h4> 
                    <ul>
                        <li>Monitor the proficiency and maturity of engineers and data scientists in using advanced techniques</li>
                        {/* <li>li</li> */}
                    </ul>
                </ParallaxGlowBox>
                <ParallaxGlowBox 
                    glowColor="rgba(130, 2, 99, 0.125)" 
                    bgColor="bg-ltgray"
                    x="-10%"
                    y="10%"
                >
                    <h4>Survey | Ethical AI Development</h4> 
                    <ul>
                        <li>Assess current AI practices against established ethical guidelines and standards</li>
                        {/* <li>li</li> */}
                    </ul>
                </ParallaxGlowBox>
            </div>

            <h3 className='standard-pad'>Each survey provides leadership with a maturity model score and ability to drill in to results.</h3>

            <h5 className='standard-pad'>Example Assessment and Results:</h5>
            {/* Video will render in normal mode if autoplay is not true */}
            {!autoplay && (
                <div className='video-container standard-pad'>
                    <ReactPlayer
                        url="https://storage.googleapis.com/hyepartners-pub-videos/HYESurvey-AIReadiness-1short.mp4"
                        controls={true}
                        muted={true}
                        width="100%"
                        height="500px"  // Adjust height as per your design
                    />
                </div>
            )}

            <ContactBanner />
            <h3 className='standard-pad'>Get Started with Survey</h3>
            {/* <p className='standard-pad'>description</p> */}
            <p className='standard-pad no-top'>
                <a href="/contact">Contact us today to benchmark your team's</a> confidence and readiness.
            </p>
        </div>
    );
};

const FullScreenVideoPlayer = ({ url, onClose }) => {
    useEffect(() => {
        const handleFullScreen = () => {
            const videoElement = document.getElementById('full-screen-video');
            if (videoElement.requestFullscreen) {
                videoElement.requestFullscreen();
            } else if (videoElement.mozRequestFullScreen) { /* Firefox */
                videoElement.mozRequestFullScreen();
            } else if (videoElement.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                videoElement.webkitRequestFullscreen();
            } else if (videoElement.msRequestFullscreen) { /* IE/Edge */
                videoElement.msRequestFullscreen();
            }
        };
        handleFullScreen();
    }, []);

    return (
        <div id="full-screen-video" className="video-player-modal">
            <ReactPlayer
                url={url}
                playing={true}
                controls={true}
                muted={true}
                width="100%"
                height="100%"
                style={{ objectFit: 'contain' }}
            />
            <button className="close-button" onClick={onClose}>X</button>
        </div>
    );
};


const SurveyExperience = () => {
    const [autoplay, setAutoplay] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const autoplayVideo = searchParams.get('autoplayVideo');
        if (autoplayVideo === 'true') {
            setAutoplay(true);
        }
    }, [searchParams]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                handleCloseFullScreenVideo();  // Close the video when Escape is pressed
            }
        };
    
        if (autoplay) {
            window.addEventListener('keydown', handleKeyDown);
        }
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [autoplay]);
    

    const handleCloseFullScreenVideo = () => {
        setAutoplay(false);
    };

    return (
        <ParallaxProvider>
            {autoplay ? (
                <FullScreenVideoPlayer 
                    url="https://storage.googleapis.com/hyepartners-pub-videos/HYESurvey-AIReadiness-1short.mp4"
                    onClose={handleCloseFullScreenVideo}
                />
            ) : (
                <Solutions autoplay={autoplay} />
            )}
            <SEO
                title="Survey Experience"
                description="Description."
                url="/surveyexperience"
            />
        </ParallaxProvider>
    );
};

export default SurveyExperience;





// import React, { useEffect, useState } from 'react';
// import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
// import GlowBox from '../components/GlowBox';
// import { motion, useInView } from 'framer-motion';
// import SEO from '../components/SEO';
// import ReactPlayer from 'react-player';
// import { useSearchParams } from 'react-router-dom';

// const HeroBanner = () => {
//     return (
//         <ParallaxBanner style={{ aspectRatio: '2 / 1', height: '500px' }} className="parallax-hero">
//             <ParallaxBannerLayer
//                 image="/images/hyepartners-man_works_intently.webp"
//                 speed={-20}
//                 expanded={true}
//                 className="parallax-banner-layer"
//             />
//             <ParallaxBannerLayer speed={5}>
//                 <div className="parallax-hero-text-div">
//                     <h1 className="parallax-hero-text">Survey Experience</h1>
//                 </div>
//             </ParallaxBannerLayer>
//         </ParallaxBanner>
//     );
// };

// const ContactBanner = () => {
//     return (
//         <ParallaxBanner style={{ aspectRatio: '2 / 1', height: '500px' }} className="parallax-hero">
//             <ParallaxBannerLayer
//                 image="/images/hyepartners-bot_helping_work.webp"
//                 speed={-20}
//                 expanded={true}
//                 className="parallax-banner-layer"
//             />
//             <ParallaxBannerLayer speed={5}>
//                 <div className="parallax-hero-text-div">
//                     <h1 className="parallax-hero-text">Benchmark Your Teams</h1>
//                 </div>
//             </ParallaxBannerLayer>
//         </ParallaxBanner>
//     );
// };

// const fadeInOut = (x, y) => ({
//     hidden: { opacity: 0, x, y },
//     visible: {
//         opacity: 1,
//         x: '0%',
//         y: '0%',
//         transition: { duration: 0.5, ease: 'easeInOut' }
//     },
//     exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
// });

// const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
//     const ref = React.useRef();
//     const isInView = useInView(ref, { amount: 0.5 });

//     return (
//         <motion.div
//             ref={ref}
//             initial="hidden"
//             animate={isInView ? "visible" : "hidden"}
//             exit="exit"
//             variants={fadeInOut(x, y)}
//         >
//             <GlowBox glowColor={glowColor} bgColor={bgColor}>
//                 {children}
//             </GlowBox>
//         </motion.div>
//     );
// };

// const Solutions = ({ autoplay }) => {
//     return (
//         <div className='homepage'>
//             <HeroBanner />
//             <h1 className='standard-pad'>Pricing to support any company size</h1>

//             <div className="twobytwo">
//                 <ParallaxGlowBox 
//                     glowColor="rgba(130, 2, 99, 0.125)" 
//                     bgColor="bg-ltgray"
//                     x="10%"
//                     y="10%"
//                 >
//                     <h4>h4</h4> 
//                     <ul>
//                         <li>li.</li>
//                         <li>li</li>
//                     </ul>
//                 </ParallaxGlowBox>
//                 <ParallaxGlowBox 
//                     glowColor="rgba(130, 2, 99, 0.125)" 
//                     bgColor="bg-ltgray"
//                     x="-10%"
//                     y="10%"
//                 >
//                     <h4>h4</h4> 
//                     <ul>
//                         <li>li.</li>
//                         <li>li</li>
//                     </ul>
//                 </ParallaxGlowBox>

//                 <ParallaxGlowBox 
//                     glowColor="rgba(130, 2, 99, 0.125)" 
//                     bgColor="bg-ltgray"
//                     x="10%"
//                     y="10%"
//                 >
//                     <h4>h4</h4> 
//                     <ul>
//                         <li>li.</li>
//                         <li>li</li>
//                     </ul>
//                 </ParallaxGlowBox>
//                 <ParallaxGlowBox 
//                     glowColor="rgba(130, 2, 99, 0.125)" 
//                     bgColor="bg-ltgray"
//                     x="-10%"
//                     y="10%"
//                 >
//                     <h4>h4</h4> 
//                     <ul>
//                         <li>li.</li>
//                         <li>li</li>
//                     </ul>
//                 </ParallaxGlowBox>
//             </div>

//             {/* Embed the video player directly into the content */}
//             <div className='video-container standard-pad'>
//                 <ReactPlayer
//                     url="https://storage.googleapis.com/hyepartners-pub-videos/HYESurvey-AIReadiness-1short.mp4"
//                     playing={autoplay}
//                     controls={true}
//                     muted={true}
//                     width="100%"
//                     height="500px"  // Adjust height as per your design
//                 />
//             </div>

//             <ContactBanner />
//             <h3 className='standard-pad'>Get Started with Survey</h3>
//             <p className='standard-pad'>description</p>
//             <p className='standard-pad no-top'>
//                 <a href="/contact">Contact us today to learn more</a> about how we accelerate the power of AI to surpass your goals.
//             </p>
//         </div>
//     );
// };

// const SurveyExperience = () => {
//     const [autoplay, setAutoplay] = useState(false);
//     const [searchParams] = useSearchParams();

//     useEffect(() => {
//         const autoplayVideo = searchParams.get('autoplayVideo');
//         if (autoplayVideo === 'true') {
//             setAutoplay(true);
//         }
//     }, [searchParams]);

//     return (
//         <ParallaxProvider>
//             <Solutions autoplay={autoplay} />
//             <SEO
//                 title="Survey Experience"
//                 description="Description."
//                 url="https://storage.googleapis.com/hyepartners-pub-videos/HYESurvey-AIReadiness-1short.mp4"
//             />
//         </ParallaxProvider>
//     );
// };

// export default SurveyExperience;



// // import React, { useEffect, useState } from 'react';
// // import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
// // import GlowBox from '../components/GlowBox';
// // import { motion, useInView } from 'framer-motion';
// // import SEO from '../components/SEO';
// // import ReactPlayer from 'react-player';
// // import { useSearchParams } from 'react-router-dom';

// // const HeroBanner = () => {
// //     return (
// //         <ParallaxBanner style={{ aspectRatio: '2 / 1', height: '500px' }} className="parallax-hero">
// //             <ParallaxBannerLayer
// //                 image="/images/hyepartners-man_works_intently.webp"
// //                 speed={-20}
// //                 expanded={true}
// //                 className="parallax-banner-layer"
// //             />
// //             <ParallaxBannerLayer speed={5}>
// //                 <div className="parallax-hero-text-div">
// //                     <h1 className="parallax-hero-text">Survey Experience</h1>
// //                 </div>
// //             </ParallaxBannerLayer>
// //         </ParallaxBanner>
// //     );
// // };

// // const ContactBanner = () => {
// //     return (
// //         <ParallaxBanner style={{ aspectRatio: '2 / 1', height: '500px' }} className="parallax-hero">
// //             <ParallaxBannerLayer
// //                 image="/images/hyepartners-bot_helping_work.webp"
// //                 speed={-20}
// //                 expanded={true}
// //                 className="parallax-banner-layer"
// //             />
// //             <ParallaxBannerLayer speed={5}>
// //                 <div className="parallax-hero-text-div">
// //                     <h1 className="parallax-hero-text">Benchmark Your Teams</h1>
// //                 </div>
// //             </ParallaxBannerLayer>
// //         </ParallaxBanner>
// //     );
// // };

// // const fadeInOut = (x, y) => ({
// //     hidden: { opacity: 0, x, y },
// //     visible: {
// //         opacity: 1,
// //         x: '0%',
// //         y: '0%',
// //         transition: { duration: 0.5, ease: 'easeInOut' }
// //     },
// //     exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
// // });

// // const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
// //     const ref = React.useRef();
// //     const isInView = useInView(ref, { amount: 0.5 });

// //     return (
// //         <motion.div
// //             ref={ref}
// //             initial="hidden"
// //             animate={isInView ? "visible" : "hidden"}
// //             exit="exit"
// //             variants={fadeInOut(x, y)}
// //         >
// //             <GlowBox glowColor={glowColor} bgColor={bgColor}>
// //                 {children}
// //             </GlowBox>
// //         </motion.div>
// //     );
// // };

// // const Solutions = () => {
// //     return (
// //         <div className='homepage'>
// //             <HeroBanner />
// //             <h1 className='standard-pad'>Pricing to support any company size</h1>

// //             <div className="twobytwo">
// //                 <ParallaxGlowBox 
// //                     glowColor="rgba(130, 2, 99, 0.125)" 
// //                     bgColor="bg-ltgray"
// //                     x="10%"
// //                     y="10%"
// //                 >
// //                     <h4>h4</h4> 
// //                     <ul>
// //                         <li>li.</li>
// //                         <li>li</li>
// //                     </ul>
// //                 </ParallaxGlowBox>
// //                 <ParallaxGlowBox 
// //                     glowColor="rgba(130, 2, 99, 0.125)" 
// //                     bgColor="bg-ltgray"
// //                     x="-10%"
// //                     y="10%"
// //                 >
// //                     <h4>h4</h4> 
// //                     <ul>
// //                         <li>li.</li>
// //                         <li>li</li>
// //                     </ul>
// //                 </ParallaxGlowBox>

// //                 <ParallaxGlowBox 
// //                     glowColor="rgba(130, 2, 99, 0.125)" 
// //                     bgColor="bg-ltgray"
// //                     x="10%"
// //                     y="10%"
// //                 >
// //                     <h4>h4</h4> 
// //                     <ul>
// //                         <li>li.</li>
// //                         <li>li</li>
// //                     </ul>
// //                 </ParallaxGlowBox>
// //                 <ParallaxGlowBox 
// //                     glowColor="rgba(130, 2, 99, 0.125)" 
// //                     bgColor="bg-ltgray"
// //                     x="-10%"
// //                     y="10%"
// //                 >
// //                     <h4>h4</h4> 
// //                     <ul>
// //                         <li>li.</li>
// //                         <li>li</li>
// //                     </ul>
// //                 </ParallaxGlowBox>
// //             </div>

// //             <ContactBanner />
// //             <h3 className='standard-pad'>Get Started with Survey</h3>
// //             <p className='standard-pad'>description</p>
// //             <p className='standard-pad no-top'>
// //                 <a href="/contact">Contact us today to learn more</a> about how we accelerate the power of AI to surpass your goals.
// //             </p>
// //         </div>
// //     );
// // };

// // const VideoPlayerModal = ({ url, onClose }) => {
// //     return (
// //         <div className="video-player-modal">
// //             <ReactPlayer 
// //                 url={url}
// //                 playing={true}
// //                 controls={true}
// //                 muted={true} 
// //                 width="100%"
// //                 height="100%"
// //                 onError={(e) => console.error('Error loading video:', e)}
// //             />
// //             <button className="close-button" onClick={onClose}>X</button>
// //         </div>
// //     );
// // };


// // const SurveyExperience = () => {
// //     const [isVideoOpen, setIsVideoOpen] = useState(false);
// //     const [searchParams] = useSearchParams();

// //     useEffect(() => {
// //         const autoplayVideo = searchParams.get('autoplayVideo');
// //         if (autoplayVideo === 'true') {
// //             console.log("Autoplay triggered"); // Add this for debugging
// //             setIsVideoOpen(true);
// //         }
// //     }, [searchParams]);

// //     useEffect(() => {
// //         const handleKeyDown = (e) => {
// //             if (e.key === 'Escape') {
// //                 handleCloseVideo();
// //             }
// //         };
    
// //         if (isVideoOpen) {
// //             window.addEventListener('keydown', handleKeyDown);
// //         }
    
// //         return () => {
// //             window.removeEventListener('keydown', handleKeyDown);
// //         };
// //     }, [isVideoOpen]);
    

// //     const handleCloseVideo = () => {
// //         setIsVideoOpen(false);
// //     };

// //     return (
// //         <ParallaxProvider>
// //             <Solutions />
// //             {isVideoOpen && <VideoPlayerModal url="https://storage.googleapis.com/hyepartners-pub-videos/HYESurvey-AIReadiness-1short.mp4" onClose={handleCloseVideo} />}
// //             <SEO
// //                 title="Survey Experience"
// //                 description="Description."
// //                 url="https://storage.googleapis.com/redacted/surveyexperience.mp4"
// //             />
// //         </ParallaxProvider>
// //     );
// // };

// // export default SurveyExperience;