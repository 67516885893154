// slices/sceneSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const sceneSlice = createSlice({
  name: 'scene',
  initialState: {
    currentScene: 'scene1',
    currentChapter: 'chapter1', // Add initial chapter state
  },
  reducers: {
    setScene: (state, action) => {
        console.log(`redux setScene:`, setScene);
        console.log(`redux setScene action:`, action);
        console.log(`redux setScene state:`, state);
      state.currentScene = action.payload.sceneId;
      // Optionally update the chapter as part of the scene change action
      if (action.payload.chapterId) {
        state.currentChapter = action.payload.chapterId;
        console.log(`redux state.currentChapter:`, state.currentChapter);
      }
    },
    // Add a separate reducer for changing chapters if needed
    setChapter: (state, action) => {
      state.currentChapter = action.payload;
      console.log(`redux state.currentChapter:`, state.currentChapter);
    },
  },
});

export const { setScene, setChapter } = sceneSlice.actions;

export default sceneSlice.reducer;
