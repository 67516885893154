import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const SurveyResultsSingle = ({ questionData, keys, colors, average, averageColor }) => {
  console.log('Rendering SurveyResultsSingle with data:', { questionData, keys, colors, average, averageColor });

  return (
    <div style={{ width: '45%', margin: '2.5%', height: 450, marginBottom: '65px' }}>
      <h6>{questionData.question}</h6>
      <ResponsiveBar
        data={[questionData]}
        keys={keys}
        indexBy="question"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        innerPadding={10}
        layout="vertical"
        groupMode="grouped"
        colors={colors}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Response',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Count',
          legendPosition: 'middle',
          legendOffset: -40,
          format: value => Number.isInteger(value) ? value : '',
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor="white"
        tooltip={({ id, value, color }) => (
          <div
            style={{
              padding: '12px',
              color: '#2c294b', // Set hover text color to dark purple
              background: 'white',
              border: `1px solid ${color}`,
              borderRadius: '4px',
            }}
          >
            <strong>{id.split(' - ')[1]} (Count: {value})</strong>
          </div>
        )}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
      <div style={{ textAlign: 'center', fontSize: '1.5em', color: averageColor }}>
        Average: {average}
      </div>
    </div>
  );
};

export default SurveyResultsSingle;

