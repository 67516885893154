import React, { useState, useContext, useEffect } from 'react';
import { motion, AnimatePresence, useInView } from 'framer-motion';
import SEO from '../components/SEO';
import { DimensionContext } from '../components/ResponsiveWrapper';
import SanitizedInput from '../components/SanitizedInput';
import SanitizedText from '../components/SanitizedText';
import axios from 'axios';
import { NativeSelect } from '@mantine/core';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../components/GlowBox';


const ContactBanner = () => {
  return (
    <ParallaxProvider>
    <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
      <ParallaxBannerLayer
        image="/images/hyepartners-harnessing_all_of_collective_knowledge.webp"
        speed={-20}
        expanded={true}
        className="parallax-banner-layer"
      />
      <ParallaxBannerLayer speed={5}>
        <div className="parallax-hero-text-div">
          <h1 className="parallax-hero-text">Contact Request</h1>
        </div>
      </ParallaxBannerLayer>
    </ParallaxBanner>
    </ParallaxProvider>
  );
};

const BetaRequest = () => {
    const [submissionSuccess, setSubmissionSuccess] = useState(false);
    const dimensions = useContext(DimensionContext);
    // console.log('dimensions', dimensions);
    const [page, setPage] = useState(1);
    const [formData, setFormData] = useState({
    companyName: '',
    // appUrl: '',
    // monthlyActiveUsers: '',
    yourName: '',
    yourEmail: '',
    howHear: '',
    // integrate: '',
  });
  const [formValid, setFormValid] = useState({
    companyName: false,
    // appUrl: false,
    // monthlyActiveUsers: false,
    yourName: false,
    yourEmail: false,
    howHear: false,
    // integrate: true,
  });


  const validateInput = (name, value) => {
    // console.log(`Validating ${name}: ${value}`);
    switch (name) {
      case 'yourEmail':
        return value.includes('@');
      // case 'monthlyActiveUsers':
      //   return /^\d{2,}$/.test(value); // Regex to check for at least 2 digits.
      default:
        return value.length >= 3;
    }
  };

  const inputClassName = (name, isValid) => {
    let baseClass = 'form-input';
    let errorClass = !isValid && formData[name] !== '' ? 'invalid-input' : '';
    return `${baseClass} ${errorClass}`;
  };

  const handleInputChange = (e) => {
    // More robust handling to accommodate both standard events and custom objects
    let name, value;
  
    if (e.target) { // Handling standard events
      name = e.target.name;
      value = e.target.value;
    } else if (e.name && e.value !== undefined) { // Handling custom objects
      name = e.name;
      value = e.value;
    } else {
      console.error("handleInputChange was called with an unexpected object:", e);
      return; // Exit the function if the event object is not in the expected format
    }
  
    const isValid = validateInput(name, value);
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  
    setFormValid((prevFormValid) => ({
      ...prevFormValid,
      [name]: isValid,
    }));
  
    // console.log(`Form formData ${formData}:`, formData);
    // console.log(`Form formValid ${formValid}:`, formValid);
  };
  

  const handleSubmit = async () => {
    console.log("Starting form submission...");
  
    const formSubmit = {
      contactDetails: {
        companyName: formData.companyName,
        yourName: formData.yourName,
        yourEmail: formData.yourEmail,
        howHear: formData.howHear,
      }
    };
  
    const emailBodyJson = JSON.stringify(formSubmit, null, 2);
  
    console.log("Constructed email body Json:", emailBodyJson);
  
    const config = {
      headers: {'Content-Type': 'application/json'},
      withCredentials: true,
    };
  
    const GO_BASE = process.env.REACT_APP_GO_BASE;
    
    try {
      console.log(`Sending request to ${GO_BASE}/api`);
      const response = await axios.post(`${GO_BASE}/api`, emailBodyJson, config);
  
      console.log("Contact Request Successful", response.data);
      setSubmissionSuccess(true);
    } catch (error) {
      if (error.response) {
          if (error.response.status === 401) {
              console.error("Unauthorized: ", error.response.data);
          } else if (error.response.status === 500) {
              console.error("Internal Server Error: ", error.response.data);
          } else {
              console.error("API Error: ", error.response.data);
          }
      } else if (error.request) {
          if (error.message.includes("blocked by CORS policy")) {
              console.error("CORS Error:", error.message);
              alert("There was a problem with your request. Please try again later.");
          } else {
              console.error("No Response Received: ", error);
          }
      } else {
          console.error("Request Setup Error:", error.message);
      }
    }
  };
  
//   const handleSubmit = async () => {
//     // Log the start of the submission process
//     console.log("Starting form submission...");
  
//     // Retrieve the authentication token from localStorage
//     // const token = localStorage.getItem('authToken');
//     // console.log("Retrieved auth token:", token);
  
//     // Construct JSON email body
//     const formSubmit = {
//       companyName: formData.companyName,
//       yourName: formData.yourName,
//       yourEmail: formData.yourEmail,
//       howHear: formData.howHear
//     };

// // Construct JSON email body
// const emailBodyJson = JSON.stringify(formSubmit, null, 2);

//     // // Example HTML email body
//     // const emailBody = `
//     //   <h1>HYE Partners Contact Request Submission</h1>
//     //   <p><strong>Company Name:</strong> ${formData.companyName}</p>
//     //   <p><strong>Your Name:</strong> ${formData.yourName}</p>
//     //   <p><strong>Your Email:</strong> ${formData.yourEmail}</p>
//     //   <p><strong>How did you hear about us?:</strong> ${formData.howHear}</p>
//     // `;
//     // Log the constructed email body
//     console.log("Constructed email body Json:", emailBodyJson);
  
//     // Prepare the payload
//     // const payload = {
//     //   to: "hyepartners@gmail.com",
//     //   subject: "HYE Partners Contact",
//     //   htmlContent: emailBodyJson,
//     // };
//     // Log the payload
//     // console.log("Payload to send:", payload);
  
//     // Configure the request with the auth token
//     const config = {
//       headers: {'Content-Type': 'application/json'},
//       withCredentials: true,
//     };
//     // Log the request configuration
//     // console.log("Request config:", config);
  
//     // Determine the base URL for your Go server
//     const GO_BASE = process.env.REACT_APP_GO_BASE;
//     // const GO_BASE = 'http://localhost:8080';
  
//     try {
//       // Make the POST request using Axios
//       console.log(`Sending request to ${GO_BASE}/api`);
//       const response = await axios.post(`${GO_BASE}/api`, emailBodyJson, config);
    
//       // Log the successful response
//       console.log("Contact Request Successful", response.data);
//       setSubmissionSuccess(true); // Set success state
//     // } catch (error) {
//     //   // Log detailed error information
//     //   // console.error("Error sending email", error);
//     //   if (error.response) {
//     //     console.error("Error response data:", error.response.data);
//     //     console.error("Error response status:", error.response.status);
//     //     console.error("Error response headers:", error.response.headers);
//     //   } else if (error.request) {
//     //     console.error("No response was received", error.request);
//     //   } else {
//     //     console.error("Error setting up the request", error.message);
//     //   }
//   } catch (error) {
//     if (error.response) {
//         // Server responded with an error status code (e.g., 4xx, 5xx)
//         if (error.response.status === 401) {
//             // Unauthorized
//             console.error("Unauthorized: ", error.response.data);
//             // Consider showing a user-friendly message about authentication
//         } else if (error.response.status === 500) {
//             // Internal Server Error
//             console.error("Internal Server Error: ", error.response.data);
//             // Show a generic error message, perhaps suggest trying again later
//         } else {
//             console.error("API Error: ", error.response.data);
//             // Handle other specific API errors if needed
//         }
//     } else if (error.request) {
//         // The request was made but no response was received
//         if (error.message.includes("blocked by CORS policy")) {
//             // Specific CORS error handling
//             console.error("CORS Error:", error.message);
//             // Display a user-friendly message about the CORS issue
//             alert("There was a problem with your request. Please try again later."); // Example alert
//         } else {
//             console.error("No Response Received: ", error);
//             // Generic no-response message
//         }
//     } else {
//         // Something happened in setting up the request that triggered an error
//         console.error("Request Setup Error:", error.message);
//         // Show a generic error message
//     }
//       // console.error("Config:", error.config);
//     }
//   };

  const pageVariants = {
    initial: { opacity: 0, x: '-100vw' },
    in: { opacity: 1, x: 0 },
    out: { opacity: 0, x: '100vw' }
  };

  const isFormValidForPage = (pageNum) => {
    // console.log(`Validating page ${pageNum}`);
    if (pageNum === 1) {
      return (
        validateInput('companyName', formData.companyName) &&
        validateInput('yourName', formData.yourName) &&
        validateInput('yourEmail', formData.yourEmail)
      );
    } else if (pageNum === 2) {
      return (
        validateInput('howHear', formData.howHear)
      );
    }
  };

  // useEffect(() => {
  //   let timer;
  //   if (submissionSuccess) {
  //     // Show success message for 3 seconds before redirecting
  //     timer = setTimeout(() => {
  //       window.location.href = '/'; // Redirect to the informational page
  //     }, 3000);
  //   }
  //   return () => clearTimeout(timer); // Cleanup the timer
  // }, [submissionSuccess]); // Effect runs when submissionSuccess changes


  const renderPageContent = () => {
    switch (page) {
      case 1:
        return (
          <motion.div className="beta-request-content" initial="initial" animate="in" exit="out" variants={pageVariants}>
            <label className="form-label">Company Name</label>
            <SanitizedInput type="text" className={inputClassName('companyName', formValid.companyName)} name="companyName" value={formData.companyName} onChange={handleInputChange} />
            <label className="form-label">Your Name</label>
            <SanitizedInput className={inputClassName('yourName', formValid.yourName)} type="text" name="yourName" value={formData.yourName} onChange={handleInputChange} />
            <label className="form-label">Your Email</label>
            <SanitizedInput className={inputClassName('yourEmail', formValid.yourEmail)} type="text" name="yourEmail" value={formData.yourEmail} onChange={handleInputChange} />
            <button className="form-button" onClick={() => setPage(2)} disabled={!isFormValidForPage(1)}>Next</button>
          </motion.div>
        );
      case 2:
        return (
          <motion.div className="beta-request-content" initial="initial" animate="in" exit="out" variants={pageVariants}>
            <label className="form-label">How did you hear about HYE Partners?</label>
            <SanitizedInput className={inputClassName('howHear', formValid.howHear)} type="text" name="howHear" value={formData.howHear} onChange={handleInputChange} />
            <button className="form-button" onClick={handleSubmit} disabled={!isFormValidForPage(2)}>Submit</button>
            {submissionSuccess && <div className="alert-text"><br/><br/>Your contact request has been received! We'll be in touch shortly to discuss.</div>}
          </motion.div>
        );
      default:
        return null;
    }
  };

  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  const containerStyle = {
    maxWidth: '950px',
    width: isMobile ? '100%' : '50%',
  };

  return (
    <>
    <AnimatePresence>
        <div className="contact">
        <ContactBanner />
        <p className="standard-pad">Fill out this quick form, and our team will reach out to discuss your AI needs.</p>
           <div className="standard-pad" style={containerStyle}>
       {/* <div className="standard-pad" style={{ maxWidth: '950px', width: '50%' }}> */}
      {renderPageContent()}
      </div>
      </div>
    </AnimatePresence>
    <div>
    <SEO
      title="Request HYE Partners Contact"
      description="Requests contact from HYE Partners to learn about AI Consulting"
      url="/contact"
    />
    </div>
    </>
  );
};

export default BetaRequest;
