import React, { useState, useEffect, useRef, useMemo } from 'react';

const MonthlyView = ({ suggestions }) => {
  const [isMonthlyViewing, setMonthlyViewing] = useState(false);
  const calendarRef = useRef(null);

  const days = useMemo(() => {
    console.log("Generating days array");
    return Array.from({ length: 5 * 5 }, (_, i) => `Day ${i + 1}`);
  }, []);
  const maxDayDuration = 4 * 60; // 4 hours in minutes

  useEffect(() => {
    console.log("Monthly View Mounted");
    setMonthlyViewing(true);
    if (calendarRef.current) {
      console.log("Scrolling into view");
      calendarRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    return () => {
      console.log("Monthly View Unmounted");
      setMonthlyViewing(false);
    };
  }, []);

  const processSuggestions = (suggestions) => {
    console.log("Processing suggestions");
    let suggestionIndex = 0;
    const calendar = days.map((day, dayIndex) => {
      const daySuggestions = [];
      let currentTime = 9 * 60; // Start at 9 AM in minutes
      console.log(`Processing ${day}`);

      while (suggestionIndex < suggestions.length && currentTime < (9 + 4) * 60) {
        let asset = { ...suggestions[suggestionIndex] };
        let assetDuration = asset.AdjustedDuration;
        console.log(`Processing suggestion: ${asset.Name}, Duration: ${assetDuration}`);

        if (currentTime + assetDuration <= (9 + 4) * 60) {
          console.log(`Adding full suggestion: ${asset.Name}, Duration: ${assetDuration}`);
          daySuggestions.push(asset);
          currentTime += assetDuration;
          suggestionIndex++;
        } else {
          const remainingTime = (9 + 4) * 60 - currentTime;
          console.log(`Splitting long suggestion: ${asset.Name}, Remaining Time: ${remainingTime}`);

          daySuggestions.push({ ...asset, AdjustedDuration: remainingTime });
          asset.AdjustedDuration -= remainingTime;
          console.log(`After splitting, remaining duration of ${asset.Name} for next day: ${asset.AdjustDuration}`);

          suggestions[suggestionIndex] = asset;
          break;
        }
      }

      console.log(`Finished processing ${day}, Total suggestions: ${daySuggestions.length}`);
      return (
        <div key={dayIndex} className={`calendar-cell col-start-${(dayIndex % 5) + 1} row-start-${Math.floor(dayIndex / 5) + 1}`}>
          <h3>{day}</h3>
          {daySuggestions.map((asset, index) => (
            <div key={index} className="course">
              <h4>{asset.Name}</h4>
              <p>Duration: {asset.AdjustDuration} minutes</p>
              <p>Type: {asset['Asset Type']}</p>
              <p>Topic: {asset['Topic Cluster (six)']}</p>
              <p>Audience: {asset.Audience}</p>
            </div>
          ))}
        </div>
      );
    });

    return calendar;
  };

  const calendar = useMemo(() => {
    console.log("Memoizing calendar");
    return processSuggestions(suggestions);
  }, [suggestions]);

  console.log("Rendering calendar");
  return (
    <div ref={calendarRef} className={`calendar ${isMonthlyViewing ? 'MonthlyView' : ''}`}>
      <div className="calendar-grid">
        {calendar}
      </div>
    </div>
  );
};

export default MonthlyView;





// import React, { useState, useEffect, useRef, useMemo } from 'react';

// const MonthlyView = ({ suggestions }) => {
//   const [isMonthlyViewing, setMonthlyViewing] = useState(false);
//   const calendarRef = useRef(null);

//   const days = useMemo(() => Array.from({ length: 5 * 5 }, (_, i) => `Day ${i + 1}`), []);
//   const maxDayDuration = 4 * 60; // 4 hours in minutes

//   useEffect(() => {
//     console.log("Monthly View Mounted");
//     setMonthlyViewing(true);
//     if (calendarRef.current) {
//       console.log("Scrolling into view");
//       calendarRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//     return () => {
//       console.log("Monthly View Unmounted");
//       setMonthlyViewing(false);
//     };
//   }, []);

//   const processSuggestions = (suggestions) => {
//     let suggestionIndex = 0;
//     const calendar = days.map((day, dayIndex) => {
//       const daySuggestions = [];
//       let currentTime = 9 * 60; // Start at 9 AM in minutes
//       console.log(`Processing ${day}`);

//       while (suggestionIndex < suggestions.length && currentTime < (9 + 4) * 60) {
//         let asset = { ...suggestions[suggestionIndex] };
//         let assetDuration = asset.AdjustedDuration;
//         console.log(`Processing suggestion: ${asset.Name}, Duration: ${assetDuration}`);

//         if (currentTime + assetDuration <= (9 + 4) * 60) {
//           console.log(`Adding full suggestion: ${asset.Name}, Duration: ${assetDuration}`);
//           daySuggestions.push(asset);
//           currentTime += assetDuration;
//           suggestionIndex++;
//         } else {
//           const remainingTime = (9 + 4) * 60 - currentTime;
//           console.log(`Splitting long suggestion: ${asset.Name}, Remaining Time: ${remainingTime}`);

//           daySuggestions.push({ ...asset, AdjustedDuration: remainingTime });
//           asset.AdjustedDuration -= remainingTime;
//           console.log(`After splitting, remaining duration of ${asset.Name} for next day: ${asset.AdjustedDuration}`);

//           suggestions[suggestionIndex] = asset;
//           break;
//         }
//       }

//       console.log(`Finished processing ${day}, Total suggestions: ${daySuggestions.length}`);
//       return (
//         <div key={dayIndex} className={`calendar-cell col-start-${(dayIndex % 5) + 1} row-start-${Math.floor(dayIndex / 5) + 1}`}>
//           <h3>{day}</h3>
//           {daySuggestions.map((asset, index) => (
//             <div key={index} className="course">
//               <h4>{asset.Name}</h4>
//               <p>Duration: {asset.AdjustedDuration} minutes</p>
//               <p>Type: {asset['Asset Type']}</p>
//               <p>Topic: {asset['Topic Cluster (six)']}</p>
//               <p>Audience: {asset.Audience}</p>
//             </div>
//           ))}
//         </div>
//       );
//     });

//     return calendar;
//   };

//   const calendar = useMemo(() => processSuggestions(suggestions), [suggestions]);

//   console.log("Rendering calendar");
//   return (
//     <div ref={calendarRef} className={`calendar ${isMonthlyViewing ? 'MonthlyView' : ''}`}>
//       <div className="calendar-grid">
//         {calendar}
//       </div>
//     </div>
//   );
// };

// export default MonthlyView;




// import React, { useState, useEffect, useRef } from 'react';

// const MonthlyView = ({ suggestions, onMonthlyView }) => {
//   const [isMonthlyViewing, setMonthlyViewing] = useState(false);
//   const calendarRef = useRef(null);

//   const days = Array.from({ length: 5 * 5 }, (_, i) => `Day ${i + 1}`);
//   const maxDayDuration = 4 * 60; // 4 hours in minutes
//   const breaks = [
//     { time: 11 * 60, duration: 15, name: 'Bio Break' } // 11:00 AM
//   ];
//   let suggestionIndex = 0;

//   useEffect(() => {
//     console.log("Monthly View Mounted");
//     setMonthlyViewing(true);
//     onMonthlyView(true); // Notify parent component
//     if (calendarRef.current) {
//       console.log("Scrolling into view");
//       calendarRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//     return () => {
//       console.log("Monthly View Unmounted");
//       setMonthlyViewing(false);
//       onMonthlyView(false); // Notify parent component
//     };
//   }, [onMonthlyView]);

//   const insertBreaks = (currentTime, daySuggestions) => {
//     console.log(`Inserting breaks at currentTime: ${currentTime}`);
//     const nextBreak = breaks.find(b => b.time === currentTime);
//     if (nextBreak) {
//       console.log(`Inserting break: ${nextBreak.name}`);
//       daySuggestions.push({ Name: nextBreak.name, AdjustedDuration: nextBreak.duration });
//       currentTime += nextBreak.duration;
//     }
//     return currentTime;
//   };

//   const calendar = days.map((day, dayIndex) => {
//     const daySuggestions = [];
//     let currentTime = 9 * 60; // Start at 9 AM in minutes
//     console.log(`Processing ${day}`);

//     while (suggestionIndex < suggestions.length && currentTime < (9 + 4) * 60) {
//       currentTime = insertBreaks(currentTime, daySuggestions);

//       if (suggestionIndex >= suggestions.length) break;

//       let asset = { ...suggestions[suggestionIndex], AdjustedDuration: suggestions[suggestionIndex].AdjustedDuration * 2 };
//       let assetDuration = asset.AdjustedDuration;
//       console.log(`Processing suggestion: ${asset.Name}, Duration: ${assetDuration}`);

//       if (currentTime + assetDuration <= (9 + 4) * 60) {
//         const upcomingBreak = breaks.find(b => b.time > currentTime && b.time < currentTime + assetDuration);
//         if (upcomingBreak) {
//           const timeUntilBreak = upcomingBreak.time - currentTime;
//           if (timeUntilBreak > 0) {
//             console.log(`Adding partial suggestion before break: ${asset.Name}, Duration: ${timeUntilBreak}`);
//             daySuggestions.push({ Name: asset.Name, AdjustedDuration: timeUntilBreak });
//             assetDuration -= timeUntilBreak;
//             currentTime += timeUntilBreak;
//           }
//           console.log(`Adding break: ${upcomingBreak.name}`);
//           daySuggestions.push({ Name: upcomingBreak.name, AdjustedDuration: upcomingBreak.duration });
//           currentTime = upcomingBreak.time + upcomingBreak.duration;
//         } else {
//           console.log(`Adding full suggestion: ${asset.Name}, Duration: ${assetDuration}`);
//           daySuggestions.push(asset);
//           currentTime += assetDuration;
//           suggestionIndex++;
//         }
//       } else if (assetDuration > maxDayDuration) {
//         const remainingTime = (9 + 4) * 60 - currentTime;
//         console.log(`Splitting long suggestion: ${asset.Name}, Remaining Time: ${remainingTime}`);

//         daySuggestions.push({ Name: asset.Name, AdjustedDuration: remainingTime });
//         asset.AdjustedDuration -= remainingTime;
//         console.log(`After splitting, remaining duration of ${asset.Name} for next day: ${asset.AdjustedDuration}`);

//         // Ensure AdjustedDuration is not set to an invalid value
//         if (!isFinite(asset.AdjustedDuration) || asset.AdjustedDuration <= 0) {
//           console.error(`Invalid remaining duration for ${asset.Name}: ${asset.AdjustedDuration}`);
//           asset.AdjustedDuration = remainingTime; // Reset to the actual remaining duration if invalid
//           console.log(`Resetting remaining duration of ${asset.Name} to: ${asset.AdjustedDuration}`);
//         }

//         suggestions[suggestionIndex] = asset;
//         break;
//       } else {
//         console.log(`Pushing shorter suggestion to next day: ${asset.Name}, Duration: ${assetDuration}`);
//         break;
//       }
//     }

//     console.log(`Finished processing ${day}, Total suggestions: ${daySuggestions.length}`);
//     return (
//       <div key={dayIndex} className={`calendar-cell col-start-${(dayIndex % 5) + 1} row-start-${Math.floor(dayIndex / 5) + 1}`}>
//         <h3>{day}</h3>
//         {daySuggestions.map((asset, index) => (
//           <div key={index} className="course">
//             <h4>{asset.Name}</h4>
//             <p>Duration: {asset.AdjustedDuration} minutes</p>
//             {asset.Name !== 'Bio Break' && (
//               <>
//                 <p>Type: {asset['Asset Type']}</p>
//                 <p>Topic: {asset['Topic Cluster (six)']}</p>
//                 <p>Audience: {asset.Audience}</p>
//               </>
//             )}
//           </div>
//         ))}
//       </div>
//     );
//   });

//   // Add any remaining assets to the next days
//   while (suggestionIndex < suggestions.length) {
//     for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
//       const daySuggestions = [];
//       let currentTime = 9 * 60; // Start at 9 AM in minutes
//       console.log(`Filling remaining suggestions into ${days[dayIndex]}`);

//       while (suggestionIndex < suggestions.length && currentTime < (9 + 4) * 60) {
//         currentTime = insertBreaks(currentTime, daySuggestions);

//         let asset = { ...suggestions[suggestionIndex], AdjustedDuration: suggestions[suggestionIndex].AdjustedDuration * 2 };
//         let assetDuration = asset.AdjustedDuration;

//         if (currentTime + assetDuration <= (9 + 4) * 60) {
//           const upcomingBreak = breaks.find(b => b.time > currentTime && b.time < currentTime + assetDuration);
//           if (upcomingBreak) {
//             const timeUntilBreak = upcomingBreak.time - currentTime;
//             if (timeUntilBreak > 0) {
//               console.log(`Adding partial suggestion before break: ${asset.Name}, Duration: ${timeUntilBreak}`);
//               daySuggestions.push({ Name: asset.Name, AdjustedDuration: timeUntilBreak });
//               assetDuration -= timeUntilBreak;
//               currentTime += timeUntilBreak;
//             }
//             console.log(`Adding break: ${upcomingBreak.name}`);
//             daySuggestions.push({ Name: upcomingBreak.name, AdjustedDuration: upcomingBreak.duration });
//             currentTime = upcomingBreak.time + upcomingBreak.duration;
//           } else {
//             console.log(`Adding full suggestion: ${asset.Name}, Duration: ${assetDuration}`);
//             daySuggestions.push(asset);
//             currentTime += assetDuration;
//             suggestionIndex++;
//           }
//         } else if (assetDuration > maxDayDuration) {
//           const remainingTime = (9 + 4) * 60 - currentTime;
//           console.log(`Splitting long suggestion: ${asset.Name}, Remaining Time: ${remainingTime}`);

//           daySuggestions.push({ Name: asset.Name, AdjustedDuration: remainingTime });
//           asset.AdjustedDuration -= remainingTime;
//           console.log(`After splitting, remaining duration of ${asset.Name} for next day: ${asset.AdjustedDuration}`);

//           // Ensure AdjustedDuration is not set to an invalid value
//           if (!isFinite(asset.AdjustedDuration) || asset.AdjustedDuration <= 0) {
//             console.error(`Invalid remaining duration for ${asset.Name}: ${asset.AdjustedDuration}`);
//             asset.AdjustedDuration = remainingTime; // Reset to the actual remaining duration if invalid
//             console.log(`Resetting remaining duration of ${asset.Name} to: ${asset.AdjustedDuration}`);
//           }

//           suggestions[suggestionIndex] = asset;
//           break;
//         } else {
//           console.log(`Pushing shorter suggestion to next day: ${asset.Name}, Duration: ${assetDuration}`);
//           break;
//         }
//       }

//       if (daySuggestions.length > 0) {
//         calendar[dayIndex] = (
//           <div key={dayIndex} className={`calendar-cell col-start-${(dayIndex % 5) + 1} row-start-${Math.floor(dayIndex / 5) + 1}`}>
//             <h3>{days[dayIndex]}</h3>
//             {daySuggestions.map((asset, index) => (
//               <div key={index} className="course">
//                 <h4>{asset.Name}</h4>
//                 <p>Duration: {asset.AdjustedDuration} minutes</p>
//                 {asset.Name !== 'Bio Break' && (
//                   <>
//                     <p>Type: {asset['Asset Type']}</p>
//                     <p>Topic: {asset['Topic Cluster (six)']}</p>
//                     <p>Audience: {asset.Audience}</p>
//                   </>
//                 )}
//               </div>
//             ))}
//           </div>
//         );
//       }
//     }
//   }

//   console.log("Rendering calendar");
//   return (
//     <div ref={calendarRef} className={`calendar ${isMonthlyViewing ? 'MonthlyView' : ''}`}>
//       <div className="calendar-grid">
//         {calendar}
//       </div>
//     </div>
//   );
// };

// export default MonthlyView;
