import React, { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service here
    console.error("Caught an error:", error, errorInfo);
    this.setState({ error, errorInfo });
  }

render() {
    if (this.state.hasError) {
        return (
            <div>
                <h1>Something went wrong.</h1>
                <details>
                    {this.state.error && this.state.error.toString()}
                    <br />
                    {this.state.errorInfo && this.state.errorInfo.componentStack}
                </details>
            </div>
        );
    }

    return this.props.children; 
}
}
export default ErrorBoundary;
