import React, { useRef } from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import SEO from '../components/SEO';


const HeroBanner = () => {
    return (
      <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-assistants_are_ready_to_help.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">About HYE Partners</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


  const ValuesBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-working_together_for_fruits_of_labors.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">About HYE Partners</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


  const ContactBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-treasure_chest_of_new_wonders.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Dig Deeper</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


  const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
  });
  
  const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
        className={className}
      >
        {children}
      </motion.div>
    );
  };
  

  const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
  };


const Homepage = () => {

    return (
        <div className='homepage'>

<ValuesBanner />

<h2 className='standard-pad no-btm'>Our Values</h2>
<p className='standard-pad no-top no-btm'>Our core values guide every project we undertake, ensuring our clients achieve groundbreaking results.</p>

<div className="fivebyfive">
<ParallaxGlowBox 
glowColor="rgba(235, 184, 68, 0.25)" 
bgColor="rgba(246, 237, 211, 1)"
x="-10%"
y="-10%"
>
<h5>Inquisitiveness</h5>
<p>We relentlessly explore new ideas and approaches to deliver innovative AI solutions that solve real-world business problems.</p>
</ParallaxGlowBox>
<ParallaxGlowBox 
glowColor="rgba(235, 184, 68, 0.25)" 
bgColor="rgba(246, 237, 211, 1)"
x="-10%"
y="-10%"
>
<h5>Clarity</h5>
<p>By demystifying AI solutions, we ensure that our clients feel confident in every step of the process and can easily leverage these tools.</p>
</ParallaxGlowBox>
<ParallaxGlowBox 
glowColor="rgba(235, 184, 68, 0.25)" 
bgColor="rgba(246, 237, 211, 1)"
x="0%"
y="-10%"
>
<h5>Action</h5>
<p>We believe in moving swiftly from strategy to execution, turning ideas into impactful results as quickly as possible.</p>
</ParallaxGlowBox>
<ParallaxGlowBox 
glowColor="rgba(235, 184, 68, 0.25)" 
bgColor="rgba(246, 237, 211, 1)"
x="10%"
y="10%"
>
<h5>Decision-Making</h5>
<p>Our data-driven approach enables us to identify opportunities quickly, make bold decisions, and refine strategies dynamically.</p>
</ParallaxGlowBox>
<ParallaxGlowBox 
glowColor="rgba(235, 184, 68, 0.25)" 
bgColor="rgba(246, 237, 211, 1)"
x="10%"
y="10%"
>
<h5>Innovation</h5>
<p>We constantly push the boundaries of what's possible, delivering revolutionary tools that redefine business productivity.</p>
</ParallaxGlowBox>
</div>

        <HeroBanner />
            <h2 className='standard-pad no-btm'>Principal Consultant</h2>
            <h3 className='standard-pad no-top no-btm'>Adam New-Waterson</h3>

        <h5 className='standard-pad no-btm'>Leading the Charge in AI-Driven Business Transformation</h5>
        <p className='standard-pad no-top no-btm'>
        For the past 12 years, I've been one of the hidden forces behind some of the world's most recognizable brands, 
        marketing AI solutions that drove their success. My journey has taken me from helping the world's top shoe brands design 
        cutting-edge gear to assisting e-commerce giants in their fierce competition with Amazon. I've guided the world's largest bank, 
        car insurer and home repair retailer to running hundreds of millions in advertising and supported top department stores in dominating 
        the luxury e-commerce space.
        </p>

        <img className="image-circ" src="/headshots/Headshot-Adam_New-Waterson.jpeg" alt="Headshot of Adam New-Waterson"></img>

        <h5 className='standard-pad no-btm'>Versatile Leadership Across Functions</h5>
        <p className='standard-pad no-top no-btm'>
        My executive roles span a diverse range of functions, from Chief Marketing Officer to interim Chief Technology Officer, 
        Chief Product Officer, and now, Chief Executive Officer. This breadth of experience equips me with a holistic understanding 
        of business operations, allowing me to empathize with employees and streamline workflows. My mission is to eliminate tedious tasks, 
        freeing teams to focus on creative, uniquely human endeavors.
        </p>

        <h5 className='standard-pad no-btm'>A Year Deep in AI Exploration</h5>
        <p className='standard-pad no-top no-btm'>
        This past year, I've immersed myself in the world of AI, dedicating over 40 hours a week to harnessing its potential. 
        I built a SaaS software product, without ever having written code before. Using React, Go, and Python I created a software business solo.
        From coding and brand building to user prospecting and product launching, I've utilized AI to revolutionize various business 
        processes. Beyond my professional commitments, my passion for AI has driven me to explore personal projects, such as developing 
        AI agents, running large language models (LLMs) locally on my machine, and perfecting agents to write code using these LLMs.
        </p>

        <h5 className='standard-pad no-btm'>Martech Mastery and Procurement Expertise</h5>
        <p className='standard-pad no-top no-btm'>
        My career began in Marketing Technology, where I've acquired extensive experience purchasing a wide array of software for 
        numerous companies. My proficiency in navigating procurement processes, securing approvals, and confirming budgets is unparalleled. 
        I can train your team to manage these processes efficiently, ensuring seamless integration of AI solutions into your business operations.
        </p>

        <h5 className='standard-pad no-btm'>Why Hire Me?</h5>
        <ul className='standard-pad no-top'>
        <li><b>Deep Expertise</b>: I bring a profound understanding of both AI and business operations, offering a wealth of knowledge to every engagement.</li>
        <li><b>Innovative Mindset</b>: My forward-thinking approach ensures your company remains at the forefront of AI adoption and implementation.</li>
        <li><b>Empathetic Leadership</b>: I connect with every team member, demonstrating how AI can enhance their roles and simplify their tasks.</li>
        </ul>

        

<ContactBanner />
<h3 className='standard-pad'>Time to 10x your team</h3>
<p className='standard-pad no-top no-btm'>Ready to simplify your AI journey and maximize productivity? Contact us today to schedule a consultation or learn more about 
    how our AI consultancy can help you boost employee satisfaction along with productivity.</p>
<p className='standard-pad no-top'><a href="/contact">Request a meeting</a></p>

<p>
    &nbsp;
</p>
<p>
    &nbsp;
</p>
        </div>
    );
};

const Home = () => {
    return (
      <ParallaxProvider>
        <Homepage />
        <SEO
                title="About HYE Partners"
                description="Learn more about Principal Consultant, Adam New-Waterson"
                url="/about"
              />
      </ParallaxProvider>
      
    );
  };
  
  export default Home;