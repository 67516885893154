import React, { useState, useEffect, useRef } from 'react';
import { Select, RadioGroup, Radio, CheckboxGroup, Checkbox, Button } from '@mantine/core';
import assetsData from '../assets/HYE_Consulting_Topics.json';
import CalendarView from './PlanCalendar';
import MonthlyView from './PlanMonthlyCalendar';
import ContactUs from './PlanContact';
import SEO from '../components/SEO';


const SelfAssessmentForm = () => {
  const [formData, setFormData] = useState({
    companySize: '',
    engagementType: '',
    presentationType: '',
    involvedTeams: ['Everyone'],
    discussionTopics: [],
  });

  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isCalendarView, setIsCalendarView] = useState(false);
  const [isMonthlyView, setIsMonthlyView] = useState(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    if (formData.involvedTeams.length > 1 && formData.involvedTeams.includes('Everyone')) {
      setFormData((prevData) => ({
        ...prevData,
        involvedTeams: prevData.involvedTeams.filter((team) => team !== 'Everyone')
      }));
    }
  }, [formData.involvedTeams]);

  const handleChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleTeamChange = (value) => {
    if (value.includes('Everyone') && value.length === 1) {
      setFormData((prevData) => ({
        ...prevData,
        involvedTeams: ['Everyone'],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        involvedTeams: value.filter((team) => team !== 'Everyone'),
      }));
    }
  };

  const handleSubmit = () => {
    console.log("Form Data: ", formData);

    const topicClusterMapping = {
      'Intro to AI': 'Education',
      'Data Privacy & Security': 'Security',
      'Ethics & Morality of AI': 'Compliance',
      'AI Governance & Compliance': 'Governance, Compliance',
      'AI for Business Strategy': 'Operations',
      'Impact of AI on Workforce': 'Innovation',
      'AI in Decision Making': 'Operations',
      'AI and Innovation': 'Innovation',
      'AI and Customer Experience': 'Operations',
      'Future Trends in AI': 'Innovation'
    };

    const selectedClusters = formData.discussionTopics.map(topic => topicClusterMapping[topic]);

    const filteredAssets = assetsData.filter(asset => {
      const matchesTopicCluster = selectedClusters.includes(asset['Topic Cluster (six)']);
      const matchesAudience = formData.involvedTeams.includes('Everyone')
        ? true
        : formData.involvedTeams.some(team => asset.Audience.includes(team) || asset.Audience === 'All Employees');

      return matchesTopicCluster && matchesAudience;
    });

    const sortedAssets = filteredAssets.sort((a, b) => a['Maturity Phase'] - b['Maturity Phase']);
    const companySizeMultiplier = formData.companySize === 'Under 100' ? 1
      : formData.companySize === '101-1000' ? 2
      : 6;

    const durationLimit = (formData.engagementType === 'Workshop' ? 24 * 60 : 80 * 60);
    let totalDuration = 0;
    const suggestedAssets = [];

    for (const asset of sortedAssets) {
      const adjustedDuration = asset.Duration * companySizeMultiplier;
      if (totalDuration + adjustedDuration <= durationLimit) {
        suggestedAssets.push({ ...asset, AdjustedDuration: adjustedDuration });
        totalDuration += adjustedDuration;
      } else {
        break;
      }
    }

    setSuggestions(suggestedAssets);
    setShowSuggestions(true);
    setIsCalendarView(formData.engagementType === 'Workshop');
    setIsMonthlyView(formData.engagementType === 'Consulting');
  };

  const discussionTopics = [
    'Intro to AI',
    'Data Privacy & Security',
    'Ethics & Morality of AI',
    'AI Governance & Compliance',
    'AI for Business Strategy',
    'Impact of AI on Workforce',
    'AI in Decision Making',
    'AI and Innovation',
    'AI and Customer Experience',
    'Future Trends in AI'
  ];

  return (
    <div className={`newClientAssessment ${isCalendarView || isMonthlyView ? 'calendarView' : ''}`}>
      <h2>Design an Engagement</h2>
      <p>Answer a few questions to see an example three-day workshop or one-month consulting engagement. 
      </p>
      <Select
        label="Company Size"
        placeholder="Pick one"
        data={['Under 100', '101-1000', 'Above 1001']}
        value={formData.companySize}
        onChange={(value) => handleChange('companySize', value)}
        className="ncaSelect"
      />

      <RadioGroup
        label="Engagement Type"
        value={formData.engagementType}
        onChange={(value) => handleChange('engagementType', value)}
        className="ncaRadio"
      >
        <label className="flex-label" htmlFor="workshop">
          <Radio id="workshop" value="Workshop" style={{ marginRight: '1rem' }} />
          Workshop
        </label>
        <label className="flex-label" htmlFor="consulting">
          <Radio id="consulting" value="Consulting" style={{ marginRight: '1rem' }} />
          Consulting
        </label>
      </RadioGroup>

      <RadioGroup
        label="Presentation Type"
        value={formData.presentationType}
        onChange={(value) => handleChange('presentationType', value)}
        className="ncaRadio"
      >
        <label className="flex-label" htmlFor="online">
          <Radio id="online" value="Online" style={{ marginRight: '1rem' }} />
          Online
        </label>
        <label className="flex-label" htmlFor="in-person">
          <Radio id="in-person" value="In Person" style={{ marginRight: '1rem' }} />
          In Person
        </label>
      </RadioGroup>

      <CheckboxGroup
        label="Which Teams Are Involved"
        value={formData.involvedTeams}
        onChange={(value) => handleTeamChange(value)}
        className="ncaCheckbox"
      >
        <label className="flex-label" htmlFor="everyone">
          <Checkbox id="everyone" value="Everyone" style={{ marginRight: '1rem' }} />
          Everyone
        </label>
        {['Sales', 'Marketing', 'Customer Success', 'Product', 'Engineering', 'Finance', 'G&A', 'Other'].map((team) => (
          <label key={team} className={`flex-label ${formData.involvedTeams.includes('Everyone') ? 'deselected' : ''}`} htmlFor={team}>
            <Checkbox id={team} value={team} style={{ marginRight: '1rem' }} />
            {team}
          </label>
        ))}
      </CheckboxGroup>

      <CheckboxGroup
        label="Discussion Topics"
        value={formData.discussionTopics}
        onChange={(value) => handleChange('discussionTopics', value)}
        className="ncaCheckbox"
      >
        {discussionTopics.map((topic, index) => (
          <label key={index} className="flex-label" htmlFor={topic}>
            <Checkbox id={topic} value={topic} style={{ marginRight: '1rem' }} />
            {topic}
          </label>
        ))}
      </CheckboxGroup>

      {/* <Button onClick={handleSubmit} className="ncaButton">Submit</Button> */}

      <Button
        onClick={handleSubmit}
        className="ncaButton"
        style={{
          backgroundColor: '#2c294b',
        }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3b3484'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#2c294b'}
      >
        Submit
      </Button>

      {showSuggestions && (
        <>
          <div className="suggestions" ref={calendarRef}>
            <h3>Suggested Courses/Workshops:</h3>
            {isCalendarView && <CalendarView suggestions={suggestions} />}
            {isMonthlyView && <MonthlyView suggestions={suggestions} />}
          </div>

          <ContactUs
            jsonData={{ ...formData }}
            calendarRef={calendarRef}
            apiUrl="https://api.hyepartners.com/api"
          />
        </>
      )}
      <SEO
                title="Design your Consulting Engagement"
                description="Answer a few questions to get a personalized consulting recommendation for your business."
                url="/about"
              />
    </div>
    
  );
};

export default SelfAssessmentForm;



