import React, { useState, useEffect, useRef } from 'react';

const CalendarView = ({ suggestions }) => {
  const [isCalendarInView, setCalendarInView] = useState(false);
  const calendarRef = useRef(null);

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const maxDayDuration = 8 * 60; // 8 hours in minutes
  const daysWithData = ['Tuesday', 'Wednesday', 'Thursday'];
  const breaks = [
    { time: 10 * 60, duration: 15, name: 'Bio Break' }, // 10:00 AM
    { time: 12 * 60, duration: 60, name: 'Lunch Break' }, // 12:00 PM
    { time: 14.5 * 60, duration: 15, name: 'Bio Break' } // 2:30 PM
  ];
  let suggestionIndex = 0;

  useEffect(() => {
    console.log("CalendarView Mounted");
    setCalendarInView(true);
    if (calendarRef.current) {
      console.log("Scrolling into view");
      calendarRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    return () => {
      console.log("CalendarView Unmounted");
      setCalendarInView(false);
    };
  }, []);

  const insertBreaks = (currentTime, daySuggestions) => {
    const nextBreak = breaks.find(b => b.time === currentTime);
    if (nextBreak) {
      daySuggestions.push({ Name: nextBreak.name, AdjustedDuration: nextBreak.duration });
      currentTime += nextBreak.duration;
    }
    return currentTime;
  };

  const renderDay = (day, dayIndex) => {
    const daySuggestions = [];
    let currentTime = 9 * 60; // Start at 9 AM in minutes

    if (daysWithData.includes(day)) {
      while (suggestionIndex < suggestions.length && currentTime < (9 + 8) * 60) {
        currentTime = insertBreaks(currentTime, daySuggestions);

        if (suggestionIndex >= suggestions.length) break;

        let asset = { ...suggestions[suggestionIndex] };
        let assetDuration = asset.AdjustedDuration;

        if (currentTime + assetDuration <= (9 + 8) * 60) {
          console.log(`Adding full suggestion: ${asset.Name}, Duration: ${assetDuration}`);
          daySuggestions.push(asset);
          currentTime += assetDuration;
          suggestionIndex++;
        } else {
          const remainingTime = (9 + 8) * 60 - currentTime;
          console.log(`Splitting long suggestion: ${asset.Name}, Remaining Time: ${remainingTime}`);

          daySuggestions.push({ ...asset, AdjustedDuration: remainingTime });
          asset.AdjustedDuration -= remainingTime;
          console.log(`After splitting, remaining duration of ${asset.Name} for next day: ${asset.AdjustedDuration}`);

          suggestions[suggestionIndex] = asset;
          break;
        }
      }
    }

    return (
      <div key={dayIndex} className={`calendar-cell ${day.toLowerCase()}`}>
        <h3>{day}</h3>
        {daySuggestions.map((asset, index) => (
          <div key={index} className="course">
            <h4>{asset.Name}</h4>
            <p>Duration: {asset.AdjustedDuration} minutes</p>
            {asset.Name !== 'Bio Break' && asset.Name !== 'Lunch Break' && (
              <>
                <p>Type: {asset['Asset Type']}</p>
                <p>Topic: {asset['Topic Cluster (six)']}</p>
                <p>Audience: {asset.Audience}</p>
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  const calendar = days.map(renderDay);

  return (
    <div ref={calendarRef} className={`calendar ${isCalendarInView ? 'calendarView' : ''}`}>
      <div className="calendar-row">{calendar}</div>
    </div>
  );
};

export default CalendarView;



// import React, { useState, useEffect, useRef } from 'react';

// const CalendarView = ({ suggestions }) => {
//   const [isCalendarInView, setCalendarInView] = useState(false);
//   const calendarRef = useRef(null);

//   const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
//   const maxDayDuration = 8 * 60; // 8 hours in minutes
//   const daysWithData = ['Tuesday', 'Wednesday', 'Thursday'];
//   const breaks = [
//     { time: 10 * 60, duration: 15, name: 'Bio Break' }, // 10:00 AM
//     { time: 12 * 60, duration: 60, name: 'Lunch Break' }, // 12:00 PM
//     { time: 14.5 * 60, duration: 15, name: 'Bio Break' } // 2:30 PM
//   ];
//   let suggestionIndex = 0;

//   useEffect(() => {
//     setCalendarInView(true);
//     if (calendarRef.current) {
//       calendarRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//     return () => {
//       setCalendarInView(false);
//     };
//   }, []);

//   const insertBreaks = (currentTime, daySuggestions) => {
//     const nextBreak = breaks.find(b => b.time === currentTime);
//     if (nextBreak) {
//       daySuggestions.push({ Name: nextBreak.name, AdjustedDuration: nextBreak.duration });
//       currentTime += nextBreak.duration;
//     }
//     return currentTime;
//   };

//   const calendar = days.map((day, dayIndex) => {
//     const daySuggestions = [];
//     let currentTime = 9 * 60; // Start at 9 AM in minutes

//     if (daysWithData.includes(day)) {
//       while (suggestionIndex < suggestions.length && currentTime < (9 + 8) * 60) {
//         currentTime = insertBreaks(currentTime, daySuggestions);

//         if (suggestionIndex >= suggestions.length) break;

//         const asset = suggestions[suggestionIndex];
//         const assetDuration = asset.AdjustedDuration;

//         if (currentTime + assetDuration <= (9 + 8) * 60) {
//           const upcomingBreak = breaks.find(b => b.time > currentTime && b.time < currentTime + assetDuration);
//           if (upcomingBreak) {
//             const timeUntilBreak = upcomingBreak.time - currentTime;
//             if (timeUntilBreak > 0) {
//               daySuggestions.push({ Name: asset.Name, AdjustedDuration: timeUntilBreak });
//               currentTime += timeUntilBreak;
//             }
//             daySuggestions.push({ Name: upcomingBreak.name, AdjustedDuration: upcomingBreak.duration });
//             currentTime = upcomingBreak.time + upcomingBreak.duration;
//           } else {
//             daySuggestions.push(asset);
//             currentTime += assetDuration;
//             suggestionIndex++;
//           }
//         } else {
//           break;
//         }
//       }
//     }

//     return (
//       <div key={dayIndex} className={`calendar-cell ${day.toLowerCase()}`}>
//         <h3>{day}</h3>
//         {daySuggestions.map((asset, index) => (
//           <div key={index} className="course">
//             <h4>{asset.Name}</h4>
//             <p>Duration: {asset.AdjustedDuration} minutes</p>
//             {asset.Name !== 'Bio Break' && asset.Name !== 'Lunch Break' && (
//               <>
//                 <p>Type: {asset['Asset Type']}</p>
//                 <p>Topic: {asset['Topic Cluster (six)']}</p>
//                 <p>Audience: {asset.Audience}</p>
//               </>
//             )}
//           </div>
//         ))}
//       </div>
//     );
//   });

//   while (suggestionIndex < suggestions.length) {
//     for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
//       if (!daysWithData.includes(days[dayIndex])) {
//         continue;
//       }
//       const daySuggestions = [];
//       let currentTime = 9 * 60; // Start at 9 AM in minutes
//       while (suggestionIndex < suggestions.length && currentTime < (9 + 8) * 60) {
//         currentTime = insertBreaks(currentTime, daySuggestions);

//         const asset = suggestions[suggestionIndex];
//         const assetDuration = asset.AdjustedDuration;

//         if (currentTime + assetDuration <= (9 + 8) * 60) {
//           const upcomingBreak = breaks.find(b => b.time > currentTime && b.time < currentTime + assetDuration);
//           if (upcomingBreak) {
//             const timeUntilBreak = upcomingBreak.time - currentTime;
//             if (timeUntilBreak > 0) {
//               daySuggestions.push({ Name: asset.Name, AdjustedDuration: timeUntilBreak });
//               currentTime += timeUntilBreak;
//             }
//             daySuggestions.push({ Name: upcomingBreak.name, AdjustedDuration: upcomingBreak.duration });
//             currentTime = upcomingBreak.time + upcomingBreak.duration;
//           } else {
//             daySuggestions.push(asset);
//             currentTime += assetDuration;
//             suggestionIndex++;
//           }
//         } else {
//           break;
//         }
//       }

//       if (daySuggestions.length > 0) {
//         calendar[dayIndex] = (
//           <div key={dayIndex} className={`calendar-cell ${days[dayIndex].toLowerCase()}`}>
//             <h3>{days[dayIndex]}</h3>
//             {daySuggestions.map((asset, index) => (
//               <div key={index} className="course">
//                 <h4>{asset.Name}</h4>
//                 <p>Duration: {asset.AdjustedDuration} minutes</p>
//                 {asset.Name !== 'Bio Break' && asset.Name !== 'Lunch Break' && (
//                   <>
//                     <p>Type: {asset['Asset Type']}</p>
//                     <p>Topic: {asset['Topic Cluster (six)']}</p>
//                     <p>Audience: {asset.Audience}</p>
//                   </>
//                 )}
//               </div>
//             ))}
//           </div>
//         );
//       }
//     }
//   }

//   return (
//     <div ref={calendarRef} className={`calendar ${isCalendarInView ? 'calendarView' : ''}`}>
//       <div className="calendar-row">{calendar}</div>
//     </div>
//   );
// };

// export default CalendarView;


// import React, { useState, useEffect, useRef } from 'react';

// const CalendarView = ({ suggestions, onCalendarView }) => {
//   const [isCalendarInView, setCalendarInView] = useState(false);
//   const calendarRef = useRef(null);

//   const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
//   const maxDayDuration = 8 * 60; // 8 hours in minutes
//   const daysWithData = ['Tuesday', 'Wednesday', 'Thursday'];
//   const breaks = [
//     { time: 10 * 60, duration: 15, name: 'Bio Break' }, // 10:00 AM
//     { time: 12 * 60, duration: 60, name: 'Lunch Break' }, // 12:00 PM
//     { time: 14.5 * 60, duration: 15, name: 'Bio Break' } // 2:30 PM
//   ];
//   let suggestionIndex = 0;

//   useEffect(() => {
//     setCalendarInView(true);
//     onCalendarView(true); // Notify parent component
//     if (calendarRef.current) {
//       calendarRef.current.scrollIntoView({ behavior: 'smooth' });
//     }
//     return () => {
//       setCalendarInView(false);
//       onCalendarView(false); // Notify parent component
//     };
//   }, [onCalendarView]);

//   const insertBreaks = (currentTime, daySuggestions) => {
//     const nextBreak = breaks.find(b => b.time === currentTime);
//     if (nextBreak) {
//       daySuggestions.push({ Name: nextBreak.name, AdjustedDuration: nextBreak.duration });
//       currentTime += nextBreak.duration;
//     }
//     return currentTime;
//   };

//   const calendar = days.map((day, dayIndex) => {
//     const daySuggestions = [];
//     let currentTime = 9 * 60; // Start at 9 AM in minutes

//     if (daysWithData.includes(day)) {
//       while (suggestionIndex < suggestions.length && currentTime < (9 + 8) * 60) {
//         currentTime = insertBreaks(currentTime, daySuggestions);

//         if (suggestionIndex >= suggestions.length) break;

//         const asset = suggestions[suggestionIndex];
//         const assetDuration = asset.AdjustedDuration;

//         if (currentTime + assetDuration <= (9 + 8) * 60) {
//           const upcomingBreak = breaks.find(b => b.time > currentTime && b.time < currentTime + assetDuration);
//           if (upcomingBreak) {
//             const timeUntilBreak = upcomingBreak.time - currentTime;
//             if (timeUntilBreak > 0) {
//               daySuggestions.push({ Name: asset.Name, AdjustedDuration: timeUntilBreak });
//               currentTime += timeUntilBreak;
//             }
//             daySuggestions.push({ Name: upcomingBreak.name, AdjustedDuration: upcomingBreak.duration });
//             currentTime = upcomingBreak.time + upcomingBreak.duration;
//           } else {
//             daySuggestions.push(asset);
//             currentTime += assetDuration;
//             suggestionIndex++;
//           }
//         } else {
//           // Push asset to the next day if it doesn't fit in the current day
//           break;
//         }
//       }
//     }

//     return (
//       <div key={dayIndex} className={`calendar-cell ${day.toLowerCase()}`}>
//         <h3>{day}</h3>
//         {daySuggestions.map((asset, index) => (
//           <div key={index} className="course">
//             <h4>{asset.Name}</h4>
//             <p>Duration: {asset.AdjustedDuration} minutes</p>
//             {asset.Name !== 'Bio Break' && asset.Name !== 'Lunch Break' && (
//               <>
//                 <p>Type: {asset['Asset Type']}</p>
//                 <p>Topic: {asset['Topic Cluster (six)']}</p>
//                 <p>Audience: {asset.Audience}</p>
//               </>
//             )}
//           </div>
//         ))}
//       </div>
//     );
//   });

//   // Add any remaining assets to the next days
//   while (suggestionIndex < suggestions.length) {
//     for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
//       if (!daysWithData.includes(days[dayIndex])) {
//         continue;
//       }
//       const daySuggestions = [];
//       let currentTime = 9 * 60; // Start at 9 AM in minutes
//       while (suggestionIndex < suggestions.length && currentTime < (9 + 8) * 60) {
//         currentTime = insertBreaks(currentTime, daySuggestions);

//         const asset = suggestions[suggestionIndex];
//         const assetDuration = asset.AdjustedDuration;

//         if (currentTime + assetDuration <= (9 + 8) * 60) {
//           const upcomingBreak = breaks.find(b => b.time > currentTime && b.time < currentTime + assetDuration);
//           if (upcomingBreak) {
//             const timeUntilBreak = upcomingBreak.time - currentTime;
//             if (timeUntilBreak > 0) {
//               daySuggestions.push({ Name: asset.Name, AdjustedDuration: timeUntilBreak });
//               currentTime += timeUntilBreak;
//             }
//             daySuggestions.push({ Name: upcomingBreak.name, AdjustedDuration: upcomingBreak.duration });
//             currentTime = upcomingBreak.time + upcomingBreak.duration;
//           } else {
//             daySuggestions.push(asset);
//             currentTime += assetDuration;
//             suggestionIndex++;
//           }
//         } else {
//           break;
//         }
//       }

//       if (daySuggestions.length > 0) {
//         calendar[dayIndex] = (
//           <div key={dayIndex} className={`calendar-cell ${days[dayIndex].toLowerCase()}`}>
//             <h3>{days[dayIndex]}</h3>
//             {daySuggestions.map((asset, index) => (
//               <div key={index} className="course">
//                 <h4>{asset.Name}</h4>
//                 <p>Duration: {asset.AdjustedDuration} minutes</p>
//                 {asset.Name !== 'Bio Break' && asset.Name !== 'Lunch Break' && (
//                   <>
//                     <p>Type: {asset['Asset Type']}</p>
//                     <p>Topic: {asset['Topic Cluster (six)']}</p>
//                     <p>Audience: {asset.Audience}</p>
//                   </>
//                 )}
//               </div>
//             ))}
//           </div>
//         );
//       }
//     }
//   }

//   return (
//     <div ref={calendarRef} className={`calendar ${isCalendarInView ? 'calendarView' : ''}`}>
//       <div className="calendar-row">{calendar}</div>
//     </div>
//   );
// };

// export default CalendarView;