import React from 'react';
import { Button, Group } from '@mantine/core';

const SurveyNavigationButtons = ({ currentIndex, totalDimensions, onPrevious, onNext, isLast }) => {
  const handleNextClick = (event) => {
    event.preventDefault();
    onNext();
  };

  return (
    <Group position="apart" mt="md">
      <Button
        onClick={onPrevious}
        disabled={currentIndex === 0}
        variant="outline"
        className="mantine-Button-root"
        style={{
          backgroundColor: '#2c294b',
        }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3b3484'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#2c294b'}
      >
        Previous
      </Button>
      {isLast ? (
        <Button
          type="submit"
          variant="filled"
          className="mantine-Button-root"
          style={{
            backgroundColor: '#2c294b',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3b3484'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#2c294b'}
        >
          Submit Survey
        </Button>
      ) : (
        <Button
          onClick={handleNextClick}
          variant="filled"
          className="mantine-Button-root"
          style={{
            backgroundColor: '#2c294b',
          }}
          onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3b3484'}
          onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#2c294b'}
        >
          Next
        </Button>
      )}
    </Group>
        // <Group position="apart" mt="md">
    //   <Button onClick={onPrevious} disabled={currentIndex === 0} variant="outline" className="mantine-Button-root">
    //     Previous
    //   </Button>
    //   {isLast ? (
    //     <Button type="submit" variant="filled" className="SurveyNavigationButtons mantine-Button-root">
    //       Submit Survey
    //     </Button>
    //   ) : (
    //     <Button onClick={handleNextClick} variant="filled" className="SurveyNavigationButtons mantine-Button-root">
    //       Next
    //     </Button>
    //   )}
    // </Group>
  );
};

export default SurveyNavigationButtons;
