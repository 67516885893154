import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../components/ScrollButton'; // Adjust the path as needed
import SEO from '../components/SEO';



const HeroBanner = () => {
    return (
      <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-learning_new_skills_perpetually.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Accelerate Innovation</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


const AssessmentBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-synthesizing_data_at_unprecedented_rates.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer-top"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Assessment</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

  const ImplementationBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-Team_benefitting_from_AI.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer-top"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Implementation</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


  const TrainingBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-unprecedented_collaboration.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Training</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

  const OptimizationBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-woman_over_flat_screen.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer-top"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Optimization</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


  const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
  });
  
  const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
        className={className}
      >
        {children}
      </motion.div>
    );
  };
  

  const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
  };


const Services = () => {
    return (
        <div className='homepage'>
        <HeroBanner />
            <h1 className='standard-pad'>Guided workshops facilitate team growth</h1>
            <h3 className='standard-pad'>Framework for integrated workflows minimizes distruption</h3>
        <p className='standard-pad no-top'>
        Our consulting services are crafted to help your team harness the power of AI models, boosting productivity and decision-making 
        without complexity. We guide you through every step of the journey, from assessing your AI readiness to implementation, 
        training, and long-term optimization.
        </p>

        <AssessmentBanner />

        <h3 className='standard-pad'>Assessment & Discovery</h3>
        <p className='standard-pad no-top no-btm'>
        Start your AI journey with a clear understanding of where you stand and what to aim for.
        </p>
        <div className="twobytwo">
        <ParallaxGlowBox 
        glowColor="rgba(243, 91, 4, 0.25)" 
        bgColor="rgba(246, 237, 211, 1)"
        x="-10%"
        y="10%"
        >
        <h4>Client Assessment Framework</h4>
        <ul>
        <li>We provide a structured questionnaire to assess your team readiness, infrastructure, and skills.</li>
        <li>Our maturity model categorizes your AI readiness, helping us tailor a strategic plan.</li>
        <li>We identify pain points, opportunities, and blockers to align AI projects with your core business goals.</li>
        </ul>
        </ParallaxGlowBox>
        <ParallaxGlowBox 
        glowColor="rgba(243, 91, 4, 0.25)" 
        bgColor="rgba(246, 237, 211, 1)"
        x="10%"
        y="-10%"
        >
        <h4>Alignment Workshops</h4>
        <ul>
        <li>Bringing teams together to brainstorm and strategize about how best to remove monotonous tasks from their routine.</li>
        <li>Used for determining requirements, priorities, and align with existing initiatives.</li>
        </ul>
        </ParallaxGlowBox>
        </div>

        <ImplementationBanner />

        <h3 className='standard-pad'>Implementation & Integration</h3>
        <p className='standard-pad no-top no-btm'>Implement and integrate AI models into daily workstreams without disruption.</p>

        <div className="threebythree">
        <ParallaxGlowBox 
        glowColor="rgba(235, 184, 68, 0.25)" 
        bgColor="rgba(246, 237, 211, 1)"
        x="-10%"
        y="10%"
        >
        <h4>AI Strategy Blueprint</h4>
        <ul>
        <li>We create a strategy document that outlines existing, immediate, short-term, and long-term opportunities.</li>
        <li>Our strategic recommendations ensure that AI initiatives align seamlessly with your business objectives.</li>
        </ul>
        </ParallaxGlowBox>
        <ParallaxGlowBox 
        glowColor="rgba(235, 184, 68, 0.25)" 
        bgColor="rgba(246, 237, 211, 1)"
        x="10%"
        y="-10%"
        >
        <h4>Proof of Concept Toolkit</h4>
        <ul>
        <li>Our modular toolkit helps you quickly onboard or pilot AI solutions with minimal investment.</li>
        <li>We have standardized workflows for selection, training, and evaluation.</li>
        <li>Our curated list of open-source and commercial tools speeds up the PoC phase.</li>
        </ul>
        </ParallaxGlowBox>
        <ParallaxGlowBox 
        glowColor="rgba(235, 184, 68, 0.25)" 
        bgColor="rgba(246, 237, 211, 1)"
        x="-10%"
        y="10%"
        >
        <h4>AI Implementation Playbook:</h4>
        <ul>
        <li>We provide guides for setting up infrastructure, managing risks, and ensuring stakeholder alignment.</li>
        <li>Our best practices help you integrate AI models efficiently into your existing workflows.</li>
        <li>We offer continuous improvement to monitor and refine workflows over time.</li>
        </ul>
        </ParallaxGlowBox>
        </div>

        <ScrollToTopButton 
          to="/solutions" 
          className="inlink-button-orange standard-pad no-top"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            alignSelf: 'center'
          }}
        >
        Explore AI Solutions
        </ScrollToTopButton>


<TrainingBanner />
<h3 className='standard-pad'>Training & Change Management</h3>
<p className='standard-pad no-top no-btm'>Empower your teams to adopt AI confidently and overcome resistance to change.</p>

        <div className="twobytwo">
        <ParallaxGlowBox 
        glowColor="rgba(243, 91, 4, 0.25)" 
        bgColor="rgba(246, 237, 211, 1)"
        x="-10%"
        y="-10%"
        >
        <h4>Training Workshops</h4>
        <ul>
        <li>Facilitated workshops where teams can collaboratively learn from each other's best practices.</li>
        <li>Discussions focused on sharing experiences, strategies, and insights related to AI implementation.</li>
        <li>Roundtable discussions about the company's future and the strategic role of AI in achieving business objectives.</li>
        </ul>
        </ParallaxGlowBox>
        <ParallaxGlowBox 
        glowColor="rgba(243, 91, 4, 0.25)" 
        bgColor="rgba(246, 237, 211, 1)"
        x="10%"
        y="10%"
        >
        <h4>Change Management Materials</h4>
        <ul>
        <li>Our comprehensive training materials include user guides, technical documentation, and workshops.</li>
        <li>We prepare customized training programs to help teams understand AI fundamentals and specific implementation needs.</li>
        <li>Our change management framework addresses cultural resistance and fosters a positive attitude toward AI.</li>
        </ul>
        </ParallaxGlowBox>
        </div>

<OptimizationBanner />

<h3 className='standard-pad'>Performance Optimization</h3>
<p className='standard-pad no-top'>Measure, refine, and optimize your AI models for long-term impact.</p>

<div className="twobytwo">
        <ParallaxGlowBox 
        glowColor="rgba(235, 184, 68, 0.25)" 
        bgColor="rgba(246, 237, 211, 1)"
        x="-10%"
        y="-10%"
        >
        <h4>Performance Measurement Framework</h4>
        <ul>
        <li>We design a set of KPIs and metrics that align with your business goals to measure AI impact.</li>
        <li>Our ROI calculation templates provide actionable insights for optimization.</li>
        <li>We offer guidelines for iteratively refining workflows based on observed performance.</li>
        </ul>
        </ParallaxGlowBox>
        <ParallaxGlowBox 
        glowColor="rgba(235, 184, 68, 0.25)" 
        bgColor="rgba(246, 237, 211, 1)"
        x="10%"
        y="10%"
        >
        <h4>Governance and Compliance Guidelines</h4>
        <ul>
        <li>We help inform quality, security, and compliance to build a strong governance foundation.</li>
        <li>Our recommended templates for policies and procedures ensure ethical and secure data use.</li>
        <li>We provide guidelines on bias mitigation and privacy compliance.</li>
        </ul>
        </ParallaxGlowBox>
        </div>

        <ScrollToTopButton 
          to="/plan" 
          className="inlink-button-orange standard-pad no-top"
          style={{
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            alignSelf: 'center'
          }}
        >
        Consulting Designed For You
        </ScrollToTopButton>


<p className='standard-pad no-btm'>Contact us today to learn more about how our AI consultancy can boost employee satisfaction in parallel to productivity.</p>
<p className='standard-pad no-top'><a href="/contact">Let's chat</a></p>
<p>
    &nbsp;
</p>
<p>
    &nbsp;
</p>
        </div>
    );
};

const Serve = () => {
    return (
      <ParallaxProvider>
        <Services />
        <SEO
                title="HYE Partners AI Services"
                description="With HYE Partners services are tailored to your uninque business needs"
                url="/about"
              />
      </ParallaxProvider>
    );
  };
  
  export default Serve;