import React, { useState, useMemo } from 'react';
import { MultiSelect } from '@mantine/core';
import SurveyResultsSingle from './SurveyResultsSingle';
import SurveyResultsMulti from './SurveyResultsMulti';
import useDebouncedResizeObserver from './useDebouncedResizeObserver';
import SurveyRubric from './SurveyRubric';

const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
  const valueColors = {
    1: '#2c294b',
    2: '#3b3484',
    3: '#7174b0',
    4: '#ebb844',
    5: '#e08a3c',
    6: '#de6736',
    7: '#c5316a',
    8: '#762861',
    9: '#ebe5df',
    10: '#958e86',
    11: '#373737',
    12: '#5d3c23',
    13: '#80ba55',
  };

  const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

  const handleFilterChange = (values) => {
    setSelectedJobFunctions(values || []);
  };

  const aggregateData = (results) => {
    const filteredResults = selectedJobFunctions.length > 0
      ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
      : results;

    const aggregatedResponses = {};

    filteredResults.forEach((submission) => {
      submission.responses.forEach((responseObj) => {
        const questionText = responseObj.question;
        const response = responseObj.answer;

        const question = surveyQuestions.questions.find(q => q.text === questionText);

        if (question) {
          const dimension = question.dimension || 'General';
          if (!aggregatedResponses[dimension]) {
            aggregatedResponses[dimension] = {};
          }

          if (!aggregatedResponses[dimension][question.text]) {
            aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
          }

          if (Array.isArray(response)) {
            response.forEach((resp) => {
              const answerText = `${resp} - ${question.options[resp - 1]}`;

              if (!aggregatedResponses[dimension][question.text][answerText]) {
                aggregatedResponses[dimension][question.text][answerText] = 0;
              }

              aggregatedResponses[dimension][question.text][answerText] += 1;
              aggregatedResponses[dimension][question.text].responses.push(resp);
              aggregatedResponses[dimension][question.text].total += 1;
            });
          } else {
            const answerText = `${response} - ${question.options[response - 1]}`;

            if (!aggregatedResponses[dimension][question.text][answerText]) {
              aggregatedResponses[dimension][question.text][answerText] = 0;
            }

            aggregatedResponses[dimension][question.text][answerText] += 1;
            aggregatedResponses[dimension][question.text].responses.push(response);
            aggregatedResponses[dimension][question.text].total += 1;
          }
        }
      });
    });

    const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
      dimension,
      questions: Object.entries(questions).map(([question, responses]) => ({
        question,
        ...responses,
      })),
    }));

    return aggregatedData;
  };

  const data = aggregateData(results);

  const jobFunctions = useMemo(() => {
    return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
  }, [results]);

  const hasJobFunctionData = jobFunctions.length > 0;

  const getColors = (keys) => {
    return keys.map(key => {
      const value = parseInt(key.split(' - ')[0]);
      return valueColors[value] || '#2c294b';
    });
  };

  const getAverageColor = (average) => {
    if (average < 2) return valueColors[1];
    if (average < 3) return valueColors[2];
    if (average < 4) return valueColors[3];
    if (average < 5) return valueColors[4];
    return valueColors[5];
  };

  const handleResize = (entries) => {
    // Your resize logic here
  };

  useDebouncedResizeObserver(handleResize);

  const numberOfRespondents = results.length;

  let overallScore = 0;
  let maxScore = 0;

  const surveyMaxScores = {
    'survey-5093': 75, // Add other challenging surveys and their max scores here
    // Add other surveys and their empirical max scores as needed
  };

  if (surveyMaxScores[surveyID]) {
    console.log(`If Use empirical max score`);
    // Use empirical max score
    maxScore = surveyMaxScores[surveyID];
  } else if (surveyQuestions.filterby === 'demographics') {
    console.log(`Else If Filterby Demo?`);

    // Calculate the max score based on the actual questions answered by each respondent
    maxScore = results.reduce((sum, submission) => {
      return sum + submission.responses.reduce((qSum, responseObj) => {
        const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
        if (!question) return qSum;

        if (question.type === 'multi_select') {
          return qSum + question.options.length;
        } else if (question.type === 'single_choice') {
          return qSum + 5; // Assuming 5 is the max value for single choice
        }
        return qSum;
      }, 0);
    }, 0);

    console.log(`Calculated max score: ${maxScore}`);

    // Sum all non-null values for the total score
    overallScore = results.reduce((sum, submission) => {
      return sum + submission.responses.reduce((qSum, responseObj) => {
        console.log(`Determining overallScore`);

        const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
        if (!question || responseObj.answer === null) return qSum;

        if (Array.isArray(responseObj.answer)) {
          const subTotal = responseObj.answer.reduce((total, val) => total + val, 0);
          console.log(`Adding multi-select subtotal: ${subTotal}`);
          return qSum + subTotal;
        }
        console.log(`Adding single-select value: ${responseObj.answer}`);
        return qSum + responseObj.answer;
      }, 0);
    }, 0);
  } else {
    console.log(`Else flow for Determining overallScore`);
    // Logic for non-filterby scenarios
    overallScore = data.reduce((sum, dimensionData) => {
      return sum + dimensionData.questions.reduce((qSum, questionData) => {
        const question = surveyQuestions.questions.find(q => q.text === questionData.question);
        if (!question || question.dimension === 'Demographics') return qSum;

        let scoreSum = 0;
        if (question.type === 'multi_select') {
          scoreSum = questionData.responses.length;
        } else if (question.type === 'single_choice') {
          scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
        }
        console.log(`Score for question ${questionData.question}: ${scoreSum}`);
        return qSum + scoreSum;
      }, 0);
    }, 0);

    maxScore = surveyQuestions.questions.reduce((sum, question) => {
      if (question.dimension === 'Demographics') return sum;

      if (question.type === 'multi_select') {
        return sum + (question.options.length * numberOfRespondents);
      } else if (question.type === 'single_choice') {
        return sum + (5 * numberOfRespondents);
      }
      return sum;
    }, 0);
  }

  // const averageScorePerRespondent = numberOfRespondents > 0 ? overallScore / numberOfRespondents : 0;
  // const percentage = maxScore > 0 ? ((overallScore / maxScore) * 100).toFixed(1) : '0.0';

  console.log('Max Score:', maxScore);
  console.log('Overall Score:', overallScore);
  console.log('numberOfRespondents:', numberOfRespondents);

  const averageScorePerRespondent = numberOfRespondents > 0 ? overallScore / numberOfRespondents : 0;
  const averageScore = numberOfRespondents > 0 ? maxScore / numberOfRespondents : 0;
  const percentage = maxScore > 0 ? ((averageScorePerRespondent / averageScore) * 100).toFixed(1) : '0.0';

  console.log('averageScore:', averageScore);
  console.log('averageScorePerRespondent:', averageScorePerRespondent);
  console.log('Percentage:', percentage);

  return (
    <div>
      <div className="navheader-container">
        <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
        {hasJobFunctionData && (
          <div className="navheader-filter">
            <MultiSelect
              data={jobFunctions}
              value={selectedJobFunctions}
              onChange={handleFilterChange}
              placeholder="Filter by Job Function"
              searchable
              clearable
            />
          </div>
        )}
        <div className="navheader-logo">
          <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
        </div>
      </div>

      <div className='surveyResultsContainer'>
        <SurveyRubric overallScore={averageScorePerRespondent} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} maxScore={averageScore} />
        {data.length === 0 ? (
          <div>No data available</div>
        ) : (
          data.map((dimensionData, dimIndex) => (
            <div key={dimIndex} className='surveyQuestions'>
              <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
              <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
                {dimensionData.questions.map((questionData, index) => {
                  const keys = Object.keys(questionData)
                    .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
                    .sort((a, b) => parseInt(a) - parseInt(b));

                  const colors = getColors(keys);
                  const totalResponses = questionData.responses.length;
                  const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
                  const averageColor = getAverageColor(average);

                  const question = surveyQuestions.questions.find(q => q.text === questionData.question);

                  if (question && question.type === 'multi_select') {
                    return (
                      <SurveyResultsMulti
                        key={index}
                        questionData={questionData}
                        keys={keys}
                        colors={colors}
                        average={average}
                        averageColor={averageColor}
                        surveyQuestions={surveyQuestions.questions}
                      />
                    );
                  } else {
                    return (
                      <SurveyResultsSingle
                        key={index}
                        questionData={questionData}
                        keys={keys}
                        colors={colors}
                        average={average}
                        averageColor={averageColor}
                      />
                    );
                  }
                })}
              </div>
              <hr className='standard-pad'></hr>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default SurveyResultsCharts;



// import React, { useState, useMemo } from 'react';
// import { MultiSelect } from '@mantine/core';
// import SurveyResultsSingle from './SurveyResultsSingle';
// import SurveyResultsMulti from './SurveyResultsMulti';
// import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// import SurveyRubric from './SurveyRubric';

// const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
//   const valueColors = {
//     1: '#2c294b',
//     2: '#3b3484',
//     3: '#7174b0',
//     4: '#ebb844',
//     5: '#e08a3c',
//     6: '#de6736',
//     7: '#c5316a',
//     8: '#762861',
//     9: '#ebe5df',
//     10: '#958e86',
//     11: '#373737',
//     12: '#5d3c23',
//     13: '#80ba55',
//   };

//   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

//   const handleFilterChange = (values) => {
//     setSelectedJobFunctions(values || []);
//   };

//   const aggregateData = (results) => {
//     const filteredResults = selectedJobFunctions.length > 0
//       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
//       : results;

//     const aggregatedResponses = {};

//     filteredResults.forEach((submission) => {
//       submission.responses.forEach((responseObj) => {
//         const questionText = responseObj.question;
//         const response = responseObj.answer;

//         const question = surveyQuestions.questions.find(q => q.text === questionText);

//         if (question) {
//           const dimension = question.dimension || 'General';
//           if (!aggregatedResponses[dimension]) {
//             aggregatedResponses[dimension] = {};
//           }

//           if (!aggregatedResponses[dimension][question.text]) {
//             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
//           }

//           if (Array.isArray(response)) {
//             response.forEach((resp) => {
//               const answerText = `${resp} - ${question.options[resp - 1]}`;

//               if (!aggregatedResponses[dimension][question.text][answerText]) {
//                 aggregatedResponses[dimension][question.text][answerText] = 0;
//               }

//               aggregatedResponses[dimension][question.text][answerText] += 1;
//               aggregatedResponses[dimension][question.text].responses.push(resp);
//               aggregatedResponses[dimension][question.text].total += 1;
//             });
//           } else {
//             const answerText = `${response} - ${question.options[response - 1]}`;

//             if (!aggregatedResponses[dimension][question.text][answerText]) {
//               aggregatedResponses[dimension][question.text][answerText] = 0;
//             }

//             aggregatedResponses[dimension][question.text][answerText] += 1;
//             aggregatedResponses[dimension][question.text].responses.push(response);
//             aggregatedResponses[dimension][question.text].total += 1;
//           }
//         }
//       });
//     });

//     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
//       dimension,
//       questions: Object.entries(questions).map(([question, responses]) => ({
//         question,
//         ...responses,
//       })),
//     }));

//     return aggregatedData;
//   };

//   const data = aggregateData(results);

//   const jobFunctions = useMemo(() => {
//     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
//   }, [results]);

//   const hasJobFunctionData = jobFunctions.length > 0;

//   const getColors = (keys) => {
//     return keys.map(key => {
//       const value = parseInt(key.split(' - ')[0]);
//       return valueColors[value] || '#2c294b';
//     });
//   };

//   const getAverageColor = (average) => {
//     if (average < 2) return valueColors[1];
//     if (average < 3) return valueColors[2];
//     if (average < 4) return valueColors[3];
//     if (average < 5) return valueColors[4];
//     return valueColors[5];
//   };

//   const handleResize = (entries) => {
//     // Your resize logic here
//   };

//   useDebouncedResizeObserver(handleResize);

//   const numberOfRespondents = results.length;

//   let overallScore = 0;
//   let maxScore = 0;

//   const surveyMaxScores = {
//     'survey-5093': 75, // Add other challenging surveys and their max scores here
//     // Add other surveys and their empirical max scores as needed
//   };

//   if (surveyMaxScores[surveyID]) {
//     console.log(`If Use empirical max score`);
//     // Use empirical max score
//     maxScore = surveyMaxScores[surveyID];
//   } else if (surveyQuestions.filterby === 'demographics') {
//     console.log(`Else If Filterby Demo?`);

//     // Calculate the max score based on the actual questions answered by each respondent
//     maxScore = results.reduce((sum, submission) => {
//       return sum + submission.responses.reduce((qSum, responseObj) => {
//         const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
//         if (!question) return qSum;

//         if (question.type === 'multi_select') {
//           console.log(`Question is multi_select`);
//           console.log(`qSum is %s`, qSum);
//           console.log(`question.options.length is %s`, question.options.length);
//           return qSum + question.options.length;
//         } else if (question.type === 'single_choice') {
//           console.log(`Question is single_choice`);
//           console.log(`qSum is %s`, qSum);
//           return qSum + 5; // Assuming 5 is the max value for single choice
//         }
//         console.log(`Returning qSum of %s`, qSum);
//         return qSum;
//       }, 0);
//     }, 0);

//     console.log(`Calculated max score: ${maxScore}`);


//     // Sum all non-null values for the total score
//     overallScore = results.reduce((sum, submission) => {
//       return sum + submission.responses.reduce((qSum, responseObj) => {
//         console.log(`Determining overallScore`);

//         const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
//         if (!question || responseObj.answer === null) return qSum;

//         if (Array.isArray(responseObj.answer)) {
//           const subTotal = responseObj.answer.reduce((total, val) => total + val, 0);
//           console.log(`Adding multi-select subtotal: ${subTotal}`);
//           return qSum + subTotal;
//         }
//         console.log(`Adding single-select value: ${responseObj.answer}`);
//         return qSum + responseObj.answer;
//       }, 0);
//     }, 0);
//   } else {
//     console.log(`Else flow for Determining overallScore`);
//     // Logic for non-filterby scenarios
//     overallScore = data.reduce((sum, dimensionData) => {
//       return sum + dimensionData.questions.reduce((qSum, questionData) => {
//         const question = surveyQuestions.questions.find(q => q.text === questionData.question);
//         if (!question || question.dimension === 'Demographics') return qSum;

//         let scoreSum = 0;
//         if (question.type === 'multi_select') {
//           scoreSum = questionData.responses.length;
//         } else if (question.type === 'single_choice') {
//           scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
//         }
//         console.log(`Score for question ${questionData.question}: ${scoreSum}`);
//         return qSum + scoreSum;
//       }, 0);
//     }, 0);

//     maxScore = surveyQuestions.questions.reduce((sum, question) => {
//       if (question.dimension === 'Demographics') return sum;

//       if (question.type === 'multi_select') {
//         return sum + question.options.length;
//       } else if (question.type === 'single_choice') {
//         return sum * 5;
//       }
//       return sum;
//     }, 0);

//     maxScore *= numberOfRespondents;
//   }

//   const averageScorePerRespondent = numberOfRespondents > 0 ? overallScore / numberOfRespondents : 0;
//   const averageScore = numberOfRespondents > 0 ? maxScore / numberOfRespondents : 0;
//   const percentage = maxScore > 0 ? ((averageScorePerRespondent / averageScore) * 100).toFixed(2) : '0.00';

//   console.log('averageScore:', averageScore);
//   console.log('averageScorePerRespondent:', averageScorePerRespondent);
//   console.log('Percentage:', percentage);

//   return (
//     <div>
//       <div className="navheader-container">
//         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
//         {hasJobFunctionData && (
//           <div className="navheader-filter">
//             <MultiSelect
//               data={jobFunctions}
//               value={selectedJobFunctions}
//               onChange={handleFilterChange}
//               placeholder="Filter by Job Function"
//               searchable
//               clearable
//             />
//           </div>
//         )}
//         <div className="navheader-logo">
//           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
//         </div>
//       </div>

//       <div className='surveyResultsContainer'>
//         <SurveyRubric overallScore={averageScorePerRespondent} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} maxScore={averageScore} />
//         {data.length === 0 ? (
//           <div>No data available</div>
//         ) : (
//           data.map((dimensionData, dimIndex) => (
//             <div key={dimIndex} className='surveyQuestions'>
//               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
//               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
//                 {dimensionData.questions.map((questionData, index) => {
//                   const keys = Object.keys(questionData)
//                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
//                     .sort((a, b) => parseInt(a) - parseInt(b));

//                   const colors = getColors(keys);
//                   const totalResponses = questionData.responses.length;
//                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
//                   const averageColor = getAverageColor(average);

//                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

//                   if (question && question.type === 'multi_select') {
//                     return (
//                       <SurveyResultsMulti
//                         key={index}
//                         questionData={questionData}
//                         keys={keys}
//                         colors={colors}
//                         average={average}
//                         averageColor={averageColor}
//                         surveyQuestions={surveyQuestions.questions}
//                       />
//                     );
//                   } else {
//                     return (
//                       <SurveyResultsSingle
//                         key={index}
//                         questionData={questionData}
//                         keys={keys}
//                         colors={colors}
//                         average={average}
//                         averageColor={averageColor}
//                       />
//                     );
//                   }
//                 })}
//               </div>
//               <hr className='standard-pad'></hr>
//             </div>
//           ))
//         )}
//       </div>
//     </div>
//   );
// };

// export default SurveyResultsCharts;



// // import React, { useState, useMemo } from 'react';
// // import { MultiSelect } from '@mantine/core';
// // import SurveyResultsSingle from './SurveyResultsSingle';
// // import SurveyResultsMulti from './SurveyResultsMulti';
// // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // import SurveyRubric from './SurveyRubric';

// // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// //   const valueColors = {
// //     1: '#2c294b',
// //     2: '#3b3484',
// //     3: '#7174b0',
// //     4: '#ebb844',
// //     5: '#e08a3c',
// //     6: '#de6736',
// //     7: '#c5316a',
// //     8: '#762861',
// //     9: '#ebe5df',
// //     10: '#958e86',
// //     11: '#373737',
// //     12: '#5d3c23',
// //     13: '#80ba55',
// //   };

// //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// //   const handleFilterChange = (values) => {
// //     setSelectedJobFunctions(values || []);
// //   };

// //   const aggregateData = (results) => {
// //     const filteredResults = selectedJobFunctions.length > 0
// //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// //       : results;

// //     const aggregatedResponses = {};

// //     filteredResults.forEach((submission) => {
// //       submission.responses.forEach((responseObj) => {
// //         const questionText = responseObj.question;
// //         const response = responseObj.answer;

// //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// //         if (question) {
// //           const dimension = question.dimension || 'General';
// //           if (!aggregatedResponses[dimension]) {
// //             aggregatedResponses[dimension] = {};
// //           }

// //           if (!aggregatedResponses[dimension][question.text]) {
// //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// //           }

// //           if (Array.isArray(response)) {
// //             response.forEach((resp) => {
// //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// //               }

// //               aggregatedResponses[dimension][question.text][answerText] += 1;
// //               aggregatedResponses[dimension][question.text].responses.push(resp);
// //               aggregatedResponses[dimension][question.text].total += 1;
// //             });
// //           } else {
// //             const answerText = `${response} - ${question.options[response - 1]}`;

// //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// //               aggregatedResponses[dimension][question.text][answerText] = 0;
// //             }

// //             aggregatedResponses[dimension][question.text][answerText] += 1;
// //             aggregatedResponses[dimension][question.text].responses.push(response);
// //             aggregatedResponses[dimension][question.text].total += 1;
// //           }
// //         }
// //       });
// //     });

// //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// //       dimension,
// //       questions: Object.entries(questions).map(([question, responses]) => ({
// //         question,
// //         ...responses,
// //       })),
// //     }));

// //     return aggregatedData;
// //   };

// //   const data = aggregateData(results);

// //   const jobFunctions = useMemo(() => {
// //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// //   }, [results]);

// //   const hasJobFunctionData = jobFunctions.length > 0;

// //   const getColors = (keys) => {
// //     return keys.map(key => {
// //       const value = parseInt(key.split(' - ')[0]);
// //       return valueColors[value] || '#2c294b';
// //     });
// //   };

// //   const getAverageColor = (average) => {
// //     if (average < 2) return valueColors[1];
// //     if (average < 3) return valueColors[2];
// //     if (average < 4) return valueColors[3];
// //     if (average < 5) return valueColors[4];
// //     return valueColors[5];
// //   };

// //   const handleResize = (entries) => {
// //     // Your resize logic here
// //   };

// //   useDebouncedResizeObserver(handleResize);

// //   const numberOfRespondents = results.length;

// //   let overallScore = 0;
// //   let maxScore = 0;

// //   const surveyMaxScores = {
// //     'survey-5093': 75, // Add other challenging surveys and their max scores here
// //     // Add other surveys and their empirical max scores as needed
// //   };

// //   if (surveyMaxScores[surveyID]) {
// //     console.log(`If Use empirical max score`);
// //     // Use empirical max score
// //     maxScore = surveyMaxScores[surveyID];
// //   } else if (surveyQuestions.filterby === 'demographics') {
// //     console.log(`Else If Filterby Demo?`);

// //     // Calculate the max score based on the actual questions answered by each respondent
// //     maxScore = results.reduce((sum, submission) => {
// //       return sum + submission.responses.reduce((qSum, responseObj) => {
// //         const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
// //         if (!question) return qSum;

// //         if (question.type === 'multi_select') {
// //           console.log(`Question is multi_select`);
// //           console.log(`qSum is %s`, qSum);
// //           console.log(`question.options.length is %s`, question.options.length);
// //           return qSum + question.options.length;
// //         } else if (question.type === 'single_choice') {
// //           console.log(`Question is single_choice`);
// //           console.log(`qSum is %s`, qSum);
// //           return qSum + 5; // Assuming 5 is the max value for single choice
// //         }
// //         console.log(`Returning qSum of %s`, qSum);
// //         return qSum;
// //       }, 0);
// //     }, 0);

// //     console.log(`Calculated max score: ${maxScore}`);

// //     // Sum all non-null values for the total score
// //     overallScore = results.reduce((sum, submission) => {
// //       return sum + submission.responses.reduce((qSum, responseObj) => {
// //         console.log(`Determining overallScore`);

// //         const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
// //         if (!question || responseObj.answer === null) return qSum;

// //         if (Array.isArray(responseObj.answer)) {
// //           const subTotal = responseObj.answer.reduce((total, val) => total + val, 0);
// //           console.log(`Adding multi-select subtotal: ${subTotal}`);
// //           return qSum + subTotal;
// //         }
// //         console.log(`Adding single-select value: ${responseObj.answer}`);
// //         return qSum + responseObj.answer;
// //       }, 0);
// //     }, 0);
// //   } else {
// //     console.log(`Else flow for Determining overallScore`);
// //     // Logic for non-filterby scenarios
// //     overallScore = data.reduce((sum, dimensionData) => {
// //       return sum + dimensionData.questions.reduce((qSum, questionData) => {
// //         const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// //         if (!question) return qSum;

// //         let scoreSum = 0;
// //         if (question.type === 'multi_select') {
// //           scoreSum = questionData.responses.length;
// //         } else if (question.type === 'single_choice') {
// //           scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// //         }
// //         console.log(`Score for question ${questionData.question}: ${scoreSum}`);
// //         return qSum + scoreSum;
// //       }, 0);
// //     }, 0);

// //     maxScore = surveyQuestions.questions.reduce((sum, question) => {
// //       console.log(`maxScore for surveyQuestions`);
// //       if (question.type === 'multi_select') {
// //         console.log(`MC numberOfRespondents: ${numberOfRespondents}`);
// //         console.log(`MC question.options.length: ${question.options.length}`);
// //         return sum + (numberOfRespondents * question.options.length);
// //       } else if (question.type === 'single_choice') {
// //         console.log(`SC numberOfRespondents: ${numberOfRespondents}`);
// //         console.log(`SC question.options.length: ${question.options.length}`);
// //         return sum + (numberOfRespondents * 5); // Assuming 5 is the max value for single choice
// //       }
// //       return sum;
// //     }, 0);

// //     console.log(`numberOfRespondents: ${numberOfRespondents}`);
// //     console.log(`Calculated max score: ${maxScore}`);
// //   }

// //   const averageScorePerRespondent = numberOfRespondents > 0 ? overallScore / numberOfRespondents : 0;
// //   const percentage = maxScore > 0 ? ((overallScore / maxScore) * 100).toFixed(2) : '0.00';

// //   console.log('Overall Score:', overallScore);
// //   console.log('Max Score:', maxScore);
// //   console.log('Percentage:', percentage);
// //   console.log('averageScorePerRespondent:', averageScorePerRespondent);

// //   return (
// //     <div>
// //       <div className="navheader-container">
// //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// //         {hasJobFunctionData && (
// //           <div className="navheader-filter">
// //             <MultiSelect
// //               data={jobFunctions}
// //               value={selectedJobFunctions}
// //               onChange={handleFilterChange}
// //               placeholder="Filter by Job Function"
// //               searchable
// //               clearable
// //             />
// //           </div>
// //         )}
// //         <div className="navheader-logo">
// //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// //         </div>
// //       </div>

// //       <div className='surveyResultsContainer'>
// //         <SurveyRubric overallScore={overallScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} maxScore={maxScore} />
// //         {data.length === 0 ? (
// //           <div>No data available</div>
// //         ) : (
// //           data.map((dimensionData, dimIndex) => (
// //             <div key={dimIndex} className='surveyQuestions'>
// //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// //                 {dimensionData.questions.map((questionData, index) => {
// //                   const keys = Object.keys(questionData)
// //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// //                     .sort((a, b) => parseInt(a) - parseInt(b));

// //                   const colors = getColors(keys);
// //                   const totalResponses = questionData.responses.length;
// //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// //                   const averageColor = getAverageColor(average);

// //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// //                   if (question && question.type === 'multi_select') {
// //                     return (
// //                       <SurveyResultsMulti
// //                         key={index}
// //                         questionData={questionData}
// //                         keys={keys}
// //                         colors={colors}
// //                         average={average}
// //                         averageColor={averageColor}
// //                         surveyQuestions={surveyQuestions.questions}
// //                       />
// //                     );
// //                   } else {
// //                     return (
// //                       <SurveyResultsSingle
// //                         key={index}
// //                         questionData={questionData}
// //                         keys={keys}
// //                         colors={colors}
// //                         average={average}
// //                         averageColor={averageColor}
// //                       />
// //                     );
// //                   }
// //                 })}
// //               </div>
// //               <hr className='standard-pad'></hr>
// //             </div>
// //           ))
// //         )}
// //       </div>
// //     </div>
// //   );
// // };

// // export default SurveyResultsCharts;




// // // import React, { useState, useMemo } from 'react';
// // // import { MultiSelect } from '@mantine/core';
// // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // import SurveyRubric from './SurveyRubric';

// // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // //   const valueColors = {
// // //     1: '#2c294b',
// // //     2: '#3b3484',
// // //     3: '#7174b0',
// // //     4: '#ebb844',
// // //     5: '#e08a3c',
// // //     6: '#de6736',
// // //     7: '#c5316a',
// // //     8: '#762861',
// // //     9: '#ebe5df',
// // //     10: '#958e86',
// // //     11: '#373737',
// // //     12: '#5d3c23',
// // //     13: '#80ba55',
// // //   };

// // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // //   const handleFilterChange = (values) => {
// // //     setSelectedJobFunctions(values || []);
// // //   };

// // //   const aggregateData = (results) => {
// // //     const filteredResults = selectedJobFunctions.length > 0
// // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // //       : results;

// // //     const aggregatedResponses = {};

// // //     filteredResults.forEach((submission) => {
// // //       submission.responses.forEach((responseObj) => {
// // //         const questionText = responseObj.question;
// // //         const response = responseObj.answer;

// // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // //         if (question) {
// // //           const dimension = question.dimension || 'General';
// // //           if (!aggregatedResponses[dimension]) {
// // //             aggregatedResponses[dimension] = {};
// // //           }

// // //           if (!aggregatedResponses[dimension][question.text]) {
// // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // //           }

// // //           if (Array.isArray(response)) {
// // //             response.forEach((resp) => {
// // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // //               }

// // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // //               aggregatedResponses[dimension][question.text].total += 1;
// // //             });
// // //           } else {
// // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // //             }

// // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // //             aggregatedResponses[dimension][question.text].total += 1;
// // //           }
// // //         }
// // //       });
// // //     });

// // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // //       dimension,
// // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // //         question,
// // //         ...responses,
// // //       })),
// // //     }));

// // //     return aggregatedData;
// // //   };

// // //   const data = aggregateData(results);

// // //   const jobFunctions = useMemo(() => {
// // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // //   }, [results]);

// // //   const hasJobFunctionData = jobFunctions.length > 0;

// // //   const getColors = (keys) => {
// // //     return keys.map(key => {
// // //       const value = parseInt(key.split(' - ')[0]);
// // //       return valueColors[value] || '#2c294b';
// // //     });
// // //   };

// // //   const getAverageColor = (average) => {
// // //     if (average < 2) return valueColors[1];
// // //     if (average < 3) return valueColors[2];
// // //     if (average < 4) return valueColors[3];
// // //     if (average < 5) return valueColors[4];
// // //     return valueColors[5];
// // //   };

// // //   const handleResize = (entries) => {
// // //     // Your resize logic here
// // //   };

// // //   useDebouncedResizeObserver(handleResize);

// // //   const numberOfRespondents = results.length;

// // //   let overallScore = 0;
// // //   let maxScore = 0;

// // //   const surveyMaxScores = {
// // //     'survey-5093': 75, // Add other challenging surveys and their max scores here
// // //     // Add other surveys and their empirical max scores as needed
// // //   };

// // //   if (surveyMaxScores[surveyID]) {
// // //     // Use empirical max score
// // //     maxScore = surveyMaxScores[surveyID];
// // //   } else if (surveyQuestions.filterby === 'demographics') {
// // //     // Calculate the max score based on the actual questions answered by each respondent
// // //     maxScore = results.reduce((sum, submission) => {
// // //       return sum + submission.responses.reduce((qSum, responseObj) => {
// // //         const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
// // //         if (!question) return qSum;

// // //         if (question.type === 'multi_select') {
// // //           return qSum + question.options.length;
// // //         } else if (question.type === 'single_choice') {
// // //           return qSum + 5; // Assuming 5 is the max value for single choice
// // //         }
// // //         return qSum;
// // //       }, 0);
// // //     }, 0);

// // //     // Sum all non-null values for the total score
// // //     overallScore = results.reduce((sum, submission) => {
// // //       return sum + submission.responses.reduce((qSum, responseObj) => {
// // //         const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
// // //         if (!question || responseObj.answer === null) return qSum;

// // //         if (Array.isArray(responseObj.answer)) {
// // //           return qSum + responseObj.answer.reduce((total, val) => total + val, 0);
// // //         }
// // //         return qSum + responseObj.answer;
// // //       }, 0);
// // //     }, 0);
// // //   } else {
// // //     // Logic for non-filterby scenarios
// // //     overallScore = data.reduce((sum, dimensionData) => {
// // //       return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // //         const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// // //         if (!question) return qSum;

// // //         let scoreSum = 0;
// // //         if (question.type === 'multi_select') {
// // //           scoreSum = questionData.responses.length;
// // //         } else if (question.type === 'single_choice') {
// // //           scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // //         }
// // //         return qSum + scoreSum;
// // //       }, 0);
// // //     }, 0);

// // //     maxScore = surveyQuestions.questions.reduce((sum, question) => {
// // //       if (question.type === 'multi_select') {
// // //         return sum + (numberOfRespondents * question.options.length);
// // //       } else if (question.type === 'single_choice') {
// // //         return sum + (numberOfRespondents * 5); // Assuming 5 is the max value for single choice
// // //       }
// // //       return sum;
// // //     }, 0);
// // //   }

// // //   const averageScorePerRespondent = numberOfRespondents > 0 ? overallScore / numberOfRespondents : 0;
// // //   const percentage = maxScore > 0 ? ((overallScore / maxScore) * 100).toFixed(2) : '0.00';

// // //   console.log('Overall Average Score:', averageScorePerRespondent);
// // //   console.log('Max Score:', maxScore);
// // //   console.log('Percentage:', percentage);

// // //   return (
// // //     <div>
// // //       <div className="navheader-container">
// // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // //         {hasJobFunctionData && (
// // //           <div className="navheader-filter">
// // //             <MultiSelect
// // //               data={jobFunctions}
// // //               value={selectedJobFunctions}
// // //               onChange={handleFilterChange}
// // //               placeholder="Filter by Job Function"
// // //               searchable
// // //               clearable
// // //             />
// // //           </div>
// // //         )}
// // //         <div className="navheader-logo">
// // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // //         </div>
// // //       </div>

// // //       <div className='surveyResultsContainer'>
// // //         <SurveyRubric overallScore={averageScorePerRespondent} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} maxScore={maxScore} />
// // //         {data.length === 0 ? (
// // //           <div>No data available</div>
// // //         ) : (
// // //           data.map((dimensionData, dimIndex) => (
// // //             <div key={dimIndex} className='surveyQuestions'>
// // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // //                 {dimensionData.questions.map((questionData, index) => {
// // //                   const keys = Object.keys(questionData)
// // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // //                     .sort((a, b) => parseInt(a) - parseInt(b));

// // //                   const colors = getColors(keys);
// // //                   const totalResponses = questionData.responses.length;
// // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // //                   const averageColor = getAverageColor(average);

// // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // //                   if (question && question.type === 'multi_select') {
// // //                     return (
// // //                       <SurveyResultsMulti
// // //                         key={index}
// // //                         questionData={questionData}
// // //                         keys={keys}
// // //                         colors={colors}
// // //                         average={average}
// // //                         averageColor={averageColor}
// // //                         surveyQuestions={surveyQuestions.questions}
// // //                       />
// // //                     );
// // //                   } else {
// // //                     return (
// // //                       <SurveyResultsSingle
// // //                         key={index}
// // //                         questionData={questionData}
// // //                         keys={keys}
// // //                         colors={colors}
// // //                         average={average}
// // //                         averageColor={averageColor}
// // //                       />
// // //                     );
// // //                   }
// // //                 })}
// // //               </div>
// // //               <hr className='standard-pad'></hr>
// // //             </div>
// // //           ))
// // //         )}
// // //       </div>
// // //     </div>
// // //   );
// // // };

// // // export default SurveyResultsCharts;


// // // // import React, { useState, useMemo } from 'react';
// // // // import { MultiSelect } from '@mantine/core';
// // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // import SurveyRubric from './SurveyRubric';

// // // // const surveyMaxScores = {
// // // //   'survey-5093': 75, // Add other challenging surveys and their max scores here
// // // //   // Add other surveys and their empirical max scores as needed
// // // // };


// // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // //   const valueColors = {
// // // //     1: '#2c294b',
// // // //     2: '#3b3484',
// // // //     3: '#7174b0',
// // // //     4: '#ebb844',
// // // //     5: '#e08a3c',
// // // //     6: '#de6736',
// // // //     7: '#c5316a',
// // // //     8: '#762861',
// // // //     9: '#ebe5df',
// // // //     10: '#958e86',
// // // //     11: '#373737',
// // // //     12: '#5d3c23',
// // // //     13: '#80ba55',
// // // //   };

// // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // //   const handleFilterChange = (values) => {
// // // //     setSelectedJobFunctions(values || []);
// // // //   };

// // // //   const aggregateData = (results) => {
// // // //     const filteredResults = selectedJobFunctions.length > 0
// // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // //       : results;

// // // //     const aggregatedResponses = {};

// // // //     filteredResults.forEach((submission) => {
// // // //       submission.responses.forEach((responseObj) => {
// // // //         const questionText = responseObj.question;
// // // //         const response = responseObj.answer;

// // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // //         if (question) {
// // // //           const dimension = question.dimension || 'General';
// // // //           if (!aggregatedResponses[dimension]) {
// // // //             aggregatedResponses[dimension] = {};
// // // //           }

// // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // //           }

// // // //           if (Array.isArray(response)) {
// // // //             response.forEach((resp) => {
// // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // //               }

// // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // //             });
// // // //           } else {
// // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // //             }

// // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // //           }
// // // //         }
// // // //       });
// // // //     });

// // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // //       dimension,
// // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // //         question,
// // // //         ...responses,
// // // //       })),
// // // //     }));

// // // //     return aggregatedData;
// // // //   };

// // // //   const data = aggregateData(results);

// // // //   const jobFunctions = useMemo(() => {
// // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // //   }, [results]);

// // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // //   const getColors = (keys) => {
// // // //     return keys.map(key => {
// // // //       const value = parseInt(key.split(' - ')[0]);
// // // //       return valueColors[value] || '#2c294b';
// // // //     });
// // // //   };

// // // //   const getAverageColor = (average) => {
// // // //     if (average < 2) return valueColors[1];
// // // //     if (average < 3) return valueColors[2];
// // // //     if (average < 4) return valueColors[3];
// // // //     if (average < 5) return valueColors[4];
// // // //     return valueColors[5];
// // // //   };

// // // //   const handleResize = (entries) => {
// // // //     // Your resize logic here
// // // //   };

// // // //   useDebouncedResizeObserver(handleResize);

// // // //   const numberOfRespondents = results.length;

// // // //   let overallScore = 0;
// // // //   let maxScore = 0;

// // // //   if (surveyQuestions.filterby === 'demographics') {
// // // //     // Calculate the max score based on the actual questions answered by each respondent
// // // //     maxScore = results.reduce((sum, submission) => {
// // // //       return sum + submission.responses.reduce((qSum, responseObj) => {
// // // //         const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
// // // //         if (!question) return qSum;

// // // //         if (question.type === 'multi_select') {
// // // //           return qSum + question.options.length;
// // // //         } else if (question.type === 'single_choice') {
// // // //           return qSum + 5; // Assuming 5 is the max value for single choice
// // // //         }
// // // //         return qSum;
// // // //       }, 0);
// // // //     }, 0);

// // // //     // Sum all non-null values for the total score
// // // //     overallScore = results.reduce((sum, submission) => {
// // // //       return sum + submission.responses.reduce((qSum, responseObj) => {
// // // //         const question = surveyQuestions.questions.find(q => q.text === responseObj.question && q.dimension !== 'Demographics');
// // // //         if (!question || responseObj.answer === null) return qSum;

// // // //         if (Array.isArray(responseObj.answer)) {
// // // //           return qSum + responseObj.answer.reduce((total, val) => total + val, 0);
// // // //         }
// // // //         return qSum + responseObj.answer;
// // // //       }, 0);
// // // //     }, 0);
// // // //   } else {
// // // //     // Logic for non-filterby scenarios
// // // //     overallScore = data.reduce((sum, dimensionData) => {
// // // //       return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // //         const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// // // //         if (!question) return qSum;

// // // //         let scoreSum = 0;
// // // //         if (question.type === 'multi_select') {
// // // //           scoreSum = questionData.responses.length;
// // // //         } else if (question.type === 'single_choice') {
// // // //           scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // // //         }
// // // //         return qSum + scoreSum;
// // // //       }, 0);
// // // //     }, 0);

// // // //     maxScore = surveyQuestions.questions.reduce((sum, question) => {
// // // //       if (question.type === 'multi_select') {
// // // //         return sum + (numberOfRespondents * question.options.length);
// // // //       } else if (question.type === 'single_choice') {
// // // //         return sum + (numberOfRespondents * 5); // Assuming 5 is the max value for single choice
// // // //       }
// // // //       return sum;
// // // //     }, 0);
// // // //   }

// // // //   const averageScorePerRespondent = numberOfRespondents > 0 ? overallScore / numberOfRespondents : 0;
// // // //   const percentage = maxScore > 0 ? ((overallScore / maxScore) * 100).toFixed(2) : '0.00';

// // // //   console.log('Overall Average Score:', averageScorePerRespondent);
// // // //   console.log('Max Score:', maxScore);
// // // //   console.log('Percentage:', percentage);

// // // //   return (
// // // //     <div>
// // // //       <div className="navheader-container">
// // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // //         {hasJobFunctionData && (
// // // //           <div className="navheader-filter">
// // // //             <MultiSelect
// // // //               data={jobFunctions}
// // // //               value={selectedJobFunctions}
// // // //               onChange={handleFilterChange}
// // // //               placeholder="Filter by Job Function"
// // // //               searchable
// // // //               clearable
// // // //             />
// // // //           </div>
// // // //         )}
// // // //         <div className="navheader-logo">
// // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // //         </div>
// // // //       </div>

// // // //       <div className='surveyResultsContainer'>
// // // //         <SurveyRubric overallScore={averageScorePerRespondent} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} maxScore={maxScore} />
// // // //         {data.length === 0 ? (
// // // //           <div>No data available</div>
// // // //         ) : (
// // // //           data.map((dimensionData, dimIndex) => (
// // // //             <div key={dimIndex} className='surveyQuestions'>
// // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // //                 {dimensionData.questions.map((questionData, index) => {
// // // //                   const keys = Object.keys(questionData)
// // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // //                     .sort((a, b) => parseInt(a) - parseInt(b));

// // // //                   const colors = getColors(keys);
// // // //                   const totalResponses = questionData.responses.length;
// // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // //                   const averageColor = getAverageColor(average);

// // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // //                   if (question && question.type === 'multi_select') {
// // // //                     return (
// // // //                       <SurveyResultsMulti
// // // //                         key={index}
// // // //                         questionData={questionData}
// // // //                         keys={keys}
// // // //                         colors={colors}
// // // //                         average={average}
// // // //                         averageColor={averageColor}
// // // //                         surveyQuestions={surveyQuestions.questions}
// // // //                       />
// // // //                     );
// // // //                   } else {
// // // //                     return (
// // // //                       <SurveyResultsSingle
// // // //                         key={index}
// // // //                         questionData={questionData}
// // // //                         keys={keys}
// // // //                         colors={colors}
// // // //                         average={average}
// // // //                         averageColor={averageColor}
// // // //                       />
// // // //                     );
// // // //                   }
// // // //                 })}
// // // //               </div>
// // // //               <hr className='standard-pad'></hr>
// // // //             </div>
// // // //           ))
// // // //         )}
// // // //       </div>
// // // //     </div>
// // // //   );
// // // // };

// // // // export default SurveyResultsCharts;



// // // // // import React, { useState, useMemo } from 'react';
// // // // // import { MultiSelect } from '@mantine/core';
// // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // import SurveyRubric from './SurveyRubric';

// // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // //   const valueColors = {
// // // // //     1: '#2c294b',
// // // // //     2: '#3b3484',
// // // // //     3: '#7174b0',
// // // // //     4: '#ebb844',
// // // // //     5: '#e08a3c',
// // // // //     6: '#de6736',
// // // // //     7: '#c5316a',
// // // // //     8: '#762861',
// // // // //     9: '#ebe5df',
// // // // //     10: '#958e86',
// // // // //     11: '#373737',
// // // // //     12: '#5d3c23',
// // // // //     13: '#80ba55',
// // // // //   };

// // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // //   const handleFilterChange = (values) => {
// // // // //     setSelectedJobFunctions(values || []);
// // // // //   };

// // // // //   const aggregateData = (results) => {
// // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // //       : results;

// // // // //     const aggregatedResponses = {};

// // // // //     filteredResults.forEach((submission) => {
// // // // //       submission.responses.forEach((responseObj) => {
// // // // //         const questionText = responseObj.question;
// // // // //         const response = responseObj.answer;

// // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // //         if (question) {
// // // // //           const dimension = question.dimension || 'General';
// // // // //           if (!aggregatedResponses[dimension]) {
// // // // //             aggregatedResponses[dimension] = {};
// // // // //           }

// // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // //           }

// // // // //           if (Array.isArray(response)) {
// // // // //             response.forEach((resp) => {
// // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // //               }

// // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // //             });
// // // // //           } else {
// // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // //             }

// // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // //           }
// // // // //         }
// // // // //       });
// // // // //     });

// // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // //       dimension,
// // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // //         question,
// // // // //         ...responses,
// // // // //       })),
// // // // //     }));

// // // // //     return aggregatedData;
// // // // //   };

// // // // //   const data = aggregateData(results);

// // // // //   const jobFunctions = useMemo(() => {
// // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // //   }, [results]);

// // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // //   const getColors = (keys) => {
// // // // //     return keys.map(key => {
// // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // //       return valueColors[value] || '#2c294b';
// // // // //     });
// // // // //   };

// // // // //   const getAverageColor = (average) => {
// // // // //     if (average < 2) return valueColors[1];
// // // // //     if (average < 3) return valueColors[2];
// // // // //     if (average < 4) return valueColors[3];
// // // // //     if (average < 5) return valueColors[4];
// // // // //     return valueColors[5];
// // // // //   };

// // // // //   const handleResize = (entries) => {
// // // // //     // Your resize logic here
// // // // //   };

// // // // //   useDebouncedResizeObserver(handleResize);

// // // // //   const numberOfRespondents = results.length;

// // // // //   let overallScore = 0;
// // // // //   let maxScore = 0;

// // // // //   if (surveyQuestions.filterby === 'demographics') {
// // // // //     // Find a relevant set of questions based on demographics
// // // // //     const relevantQuestions = surveyQuestions.questions.filter(q => q.dimension !== 'Demographics');

// // // // //     // Calculate max score
// // // // //     maxScore = relevantQuestions.reduce((sum, question) => {
// // // // //       if (question.type === 'multi_select') {
// // // // //         return sum + question.options.length;
// // // // //       } else if (question.type === 'single_choice') {
// // // // //         return sum + 5; // Assuming 5 is the max value for single choice
// // // // //       }
// // // // //       return sum;
// // // // //     }, 0) * numberOfRespondents;

// // // // //     // Sum all non-null values for the total score
// // // // //     overallScore = results.reduce((sum, submission) => {
// // // // //       return sum + submission.responses.reduce((qSum, responseObj) => {
// // // // //         const question = relevantQuestions.find(q => q.text === responseObj.question);
// // // // //         if (!question || responseObj.answer === null) return qSum;

// // // // //         if (Array.isArray(responseObj.answer)) {
// // // // //           return qSum + responseObj.answer.reduce((total, val) => total + val, 0);
// // // // //         }
// // // // //         return qSum + responseObj.answer;
// // // // //       }, 0);
// // // // //     }, 0);
// // // // //   } else {
// // // // //     // Logic for non-filterby scenarios
// // // // //     overallScore = data.reduce((sum, dimensionData) => {
// // // // //       return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // //         const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// // // // //         if (!question) return qSum;

// // // // //         let scoreSum = 0;
// // // // //         if (question.type === 'multi_select') {
// // // // //           scoreSum = questionData.responses.length;
// // // // //         } else if (question.type === 'single_choice') {
// // // // //           scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // // // //         }
// // // // //         return qSum + scoreSum;
// // // // //       }, 0);
// // // // //     }, 0);

// // // // //     maxScore = surveyQuestions.questions.reduce((sum, question) => {
// // // // //       if (question.type === 'multi_select') {
// // // // //         return sum + (numberOfRespondents * question.options.length);
// // // // //       } else if (question.type === 'single_choice') {
// // // // //         return sum + (numberOfRespondents * 5); // Assuming 5 is the max value for single choice
// // // // //       }
// // // // //       return sum;
// // // // //     }, 0);
// // // // //   }

// // // // //   const averageScorePerRespondent = numberOfRespondents > 0 ? overallScore / numberOfRespondents : 0;
// // // // //   const percentage = maxScore > 0 ? ((averageScorePerRespondent / maxScore) * 100).toFixed(2) : '0.00';

// // // // //   console.log('Overall Average Score:', averageScorePerRespondent);
// // // // //   console.log('Max Score:', maxScore);
// // // // //   console.log('Percentage:', percentage);

// // // // //   return (
// // // // //     <div>
// // // // //       <div className="navheader-container">
// // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // //         {hasJobFunctionData && (
// // // // //           <div className="navheader-filter">
// // // // //             <MultiSelect
// // // // //               data={jobFunctions}
// // // // //               value={selectedJobFunctions}
// // // // //               onChange={handleFilterChange}
// // // // //               placeholder="Filter by Job Function"
// // // // //               searchable
// // // // //               clearable
// // // // //             />
// // // // //           </div>
// // // // //         )}
// // // // //         <div className="navheader-logo">
// // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // //         </div>
// // // // //       </div>

// // // // //       <div className='surveyResultsContainer'>
// // // // //         <SurveyRubric overallScore={averageScorePerRespondent} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} maxScore={maxScore} />
// // // // //         {data.length === 0 ? (
// // // // //           <div>No data available</div>
// // // // //         ) : (
// // // // //           data.map((dimensionData, dimIndex) => (
// // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // //                   const keys = Object.keys(questionData)
// // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));

// // // // //                   const colors = getColors(keys);
// // // // //                   const totalResponses = questionData.responses.length;
// // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // //                   const averageColor = getAverageColor(average);

// // // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // // //                   if (question && question.type === 'multi_select') {
// // // // //                     return (
// // // // //                       <SurveyResultsMulti
// // // // //                         key={index}
// // // // //                         questionData={questionData}
// // // // //                         keys={keys}
// // // // //                         colors={colors}
// // // // //                         average={average}
// // // // //                         averageColor={averageColor}
// // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // //                       />
// // // // //                     );
// // // // //                   } else {
// // // // //                     return (
// // // // //                       <SurveyResultsSingle
// // // // //                         key={index}
// // // // //                         questionData={questionData}
// // // // //                         keys={keys}
// // // // //                         colors={colors}
// // // // //                         average={average}
// // // // //                         averageColor={averageColor}
// // // // //                       />
// // // // //                     );
// // // // //                   }
// // // // //                 })}
// // // // //               </div>
// // // // //               <hr className='standard-pad'></hr>
// // // // //             </div>
// // // // //           ))
// // // // //         )}
// // // // //       </div>
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default SurveyResultsCharts;





// // // // // // import React, { useState, useMemo } from 'react';
// // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // //   const valueColors = {
// // // // // //     1: '#2c294b',
// // // // // //     2: '#3b3484',
// // // // // //     3: '#7174b0',
// // // // // //     4: '#ebb844',
// // // // // //     5: '#e08a3c',
// // // // // //     6: '#de6736',
// // // // // //     7: '#c5316a',
// // // // // //     8: '#762861',
// // // // // //     9: '#ebe5df',
// // // // // //     10: '#958e86',
// // // // // //     11: '#373737',
// // // // // //     12: '#5d3c23',
// // // // // //     13: '#80ba55',
// // // // // //   };

// // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // //   const handleFilterChange = (values) => {
// // // // // //     setSelectedJobFunctions(values || []);
// // // // // //   };

// // // // // //   const aggregateData = (results) => {
// // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // //       : results;

// // // // // //     const aggregatedResponses = {};

// // // // // //     filteredResults.forEach((submission) => {
// // // // // //       submission.responses.forEach((responseObj) => {
// // // // // //         const questionText = responseObj.question;
// // // // // //         const response = responseObj.answer;

// // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // //         if (question) {
// // // // // //           const dimension = question.dimension || 'General';
// // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // //             aggregatedResponses[dimension] = {};
// // // // // //           }

// // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // //           }

// // // // // //           if (Array.isArray(response)) {
// // // // // //             response.forEach((resp) => {
// // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // //               }

// // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // //             });
// // // // // //           } else {
// // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // //             }

// // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // //           }
// // // // // //         }
// // // // // //       });
// // // // // //     });

// // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // //       dimension,
// // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // //         question,
// // // // // //         ...responses,
// // // // // //       })),
// // // // // //     }));

// // // // // //     return aggregatedData;
// // // // // //   };

// // // // // //   const data = aggregateData(results);

// // // // // //   const jobFunctions = useMemo(() => {
// // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // //   }, [results]);

// // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // //   const getColors = (keys) => {
// // // // // //     return keys.map(key => {
// // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // //       return valueColors[value] || '#2c294b';
// // // // // //     });
// // // // // //   };

// // // // // //   const getAverageColor = (average) => {
// // // // // //     if (average < 2) return valueColors[1];
// // // // // //     if (average < 3) return valueColors[2];
// // // // // //     if (average < 4) return valueColors[3];
// // // // // //     if (average < 5) return valueColors[4];
// // // // // //     return valueColors[5];
// // // // // //   };

// // // // // //   const handleResize = (entries) => {
// // // // // //     // Your resize logic here
// // // // // //   };

// // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // //   const numberOfRespondents = results.length;

// // // // // //   let overallScore = 0;
// // // // // //   let maxScorePerRespondent = 0;

// // // // // //   if (surveyQuestions.filterby === 'demographics') {
// // // // // //     // Pick the first set of questions for scoring
// // // // // //     const firstDimension = surveyQuestions.dimensions.find(d => d !== 'Demographics');
// // // // // //     const filteredQuestions = surveyQuestions.questions.filter(q => q.dimension === firstDimension);

// // // // // //     overallScore = results.reduce((sum, submission) => {
// // // // // //       return sum + submission.responses.reduce((qSum, responseObj) => {
// // // // // //         const question = filteredQuestions.find(q => q.text === responseObj.question);
// // // // // //         if (!question) return qSum;

// // // // // //         let scoreSum = 0;
// // // // // //         if (question.type === 'multi_select') {
// // // // // //           scoreSum = responseObj.answer.length;
// // // // // //         } else if (question.type === 'single_choice') {
// // // // // //           scoreSum = responseObj.answer;
// // // // // //         }
// // // // // //         return qSum + scoreSum;
// // // // // //       }, 0);
// // // // // //     }, 0);

// // // // // //     maxScorePerRespondent = results.reduce((maxSum, submission) => {
// // // // // //       const respondentMax = submission.responses.reduce((sum, responseObj) => {
// // // // // //         const question = filteredQuestions.find(q => q.text === responseObj.question);
// // // // // //         if (!question) return sum;

// // // // // //         if (question.type === 'multi_select') {
// // // // // //           return sum + question.options.length;
// // // // // //         } else if (question.type === 'single_choice') {
// // // // // //           return sum + 5; // Assuming 5 is the max value for single choice
// // // // // //         }
// // // // // //         return sum;
// // // // // //       }, 0);
// // // // // //       return maxSum + respondentMax;
// // // // // //     }, 0);
// // // // // //   } else {
// // // // // //     overallScore = data.reduce((sum, dimensionData) => {
// // // // // //       return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// // // // // //         if (!question) return qSum;

// // // // // //         let scoreSum = 0;
// // // // // //         if (question.type === 'multi_select') {
// // // // // //           scoreSum = questionData.responses.length;
// // // // // //         } else if (question.type === 'single_choice') {
// // // // // //           scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // // // // //         }
// // // // // //         return qSum + scoreSum;
// // // // // //       }, 0);
// // // // // //     }, 0);

// // // // // //     maxScorePerRespondent = surveyQuestions.questions.reduce((sum, question) => {
// // // // // //       if (question.type === 'multi_select') {
// // // // // //         return sum + (numberOfRespondents * question.options.length);
// // // // // //       } else if (question.type === 'single_choice') {
// // // // // //         return sum + (numberOfRespondents * 5); // Assuming 5 is the max value for single choice
// // // // // //       }
// // // // // //       return sum;
// // // // // //     }, 0);
// // // // // //   }

// // // // // //   const averageScorePerRespondent = numberOfRespondents > 0 ? overallScore / numberOfRespondents : 0;
// // // // // //   const percentage = maxScorePerRespondent > 0 ? ((averageScorePerRespondent / maxScorePerRespondent) * 100).toFixed(2) : '0.00';

// // // // // //   console.log('Overall Average Score:', averageScorePerRespondent);
// // // // // //   console.log('Max Score Per Respondent:', maxScorePerRespondent);
// // // // // //   console.log('Percentage:', percentage);

// // // // // //   return (
// // // // // //     <div>
// // // // // //       <div className="navheader-container">
// // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // //         {hasJobFunctionData && (
// // // // // //           <div className="navheader-filter">
// // // // // //             <MultiSelect
// // // // // //               data={jobFunctions}
// // // // // //               value={selectedJobFunctions}
// // // // // //               onChange={handleFilterChange}
// // // // // //               placeholder="Filter by Job Function"
// // // // // //               searchable
// // // // // //               clearable
// // // // // //             />
// // // // // //           </div>
// // // // // //         )}
// // // // // //         <div className="navheader-logo">
// // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // //         </div>
// // // // // //       </div>

// // // // // //       <div className='surveyResultsContainer'>
// // // // // //         <SurveyRubric overallScore={averageScorePerRespondent} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} maxScore={maxScorePerRespondent} />
// // // // // //         {data.length === 0 ? (
// // // // // //           <div>No data available</div>
// // // // // //         ) : (
// // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // //                   const keys = Object.keys(questionData)
// // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));

// // // // // //                   const colors = getColors(keys);
// // // // // //                   const totalResponses = questionData.responses.length;
// // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // //                   const averageColor = getAverageColor(average);

// // // // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // // // //                   if (question && question.type === 'multi_select') {
// // // // // //                     return (
// // // // // //                       <SurveyResultsMulti
// // // // // //                         key={index}
// // // // // //                         questionData={questionData}
// // // // // //                         keys={keys}
// // // // // //                         colors={colors}
// // // // // //                         average={average}
// // // // // //                         averageColor={averageColor}
// // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // //                       />
// // // // // //                     );
// // // // // //                   } else {
// // // // // //                     return (
// // // // // //                       <SurveyResultsSingle
// // // // // //                         key={index}
// // // // // //                         questionData={questionData}
// // // // // //                         keys={keys}
// // // // // //                         colors={colors}
// // // // // //                         average={average}
// // // // // //                         averageColor={averageColor}
// // // // // //                       />
// // // // // //                     );
// // // // // //                   }
// // // // // //                 })}
// // // // // //               </div>
// // // // // //               <hr className='standard-pad'></hr>
// // // // // //             </div>
// // // // // //           ))
// // // // // //         )}
// // // // // //       </div>
// // // // // //     </div>
// // // // // //   );
// // // // // // };

// // // // // // export default SurveyResultsCharts;


// // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // //   const valueColors = {
// // // // // // //     1: '#2c294b', 
// // // // // // //     2: '#3b3484',
// // // // // // //     3: '#7174b0',
// // // // // // //     4: '#ebb844',
// // // // // // //     5: '#e08a3c',
// // // // // // //     6: '#de6736', 
// // // // // // //     7: '#c5316a', 
// // // // // // //     8: '#762861', 
// // // // // // //     9: '#ebe5df', 
// // // // // // //     10: '#958e86', 
// // // // // // //     11: '#373737', 
// // // // // // //     12: '#5d3c23', 
// // // // // // //     13: '#80ba55',
// // // // // // //   };

// // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // //   const handleFilterChange = (values) => {
// // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // //   };

// // // // // // //   const aggregateData = (results) => {
// // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // //       : results;

// // // // // // //     const aggregatedResponses = {};

// // // // // // //     filteredResults.forEach((submission) => {
// // // // // // //       submission.responses.forEach((responseObj) => {
// // // // // // //         const questionText = responseObj.question;
// // // // // // //         const response = responseObj.answer;

// // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // //         if (question) {
// // // // // // //           const dimension = question.dimension || 'General';
// // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // //           }

// // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // //           }

// // // // // // //           if (Array.isArray(response)) {
// // // // // // //             response.forEach((resp) => {
// // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // //               }

// // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // //             });
// // // // // // //           } else {
// // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // //             }

// // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // //           }
// // // // // // //         }
// // // // // // //       });
// // // // // // //     });

// // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // //       dimension,
// // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // //         question,
// // // // // // //         ...responses,
// // // // // // //       })),
// // // // // // //     }));

// // // // // // //     return aggregatedData;
// // // // // // //   };

// // // // // // //   const data = aggregateData(results);

// // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // //   }, [results]);

// // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // //   const getColors = (keys) => {
// // // // // // //     return keys.map(key => {
// // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // //     });
// // // // // // //   };

// // // // // // //   const getAverageColor = (average) => {
// // // // // // //     if (average < 2) return valueColors[1];
// // // // // // //     if (average < 3) return valueColors[2];
// // // // // // //     if (average < 4) return valueColors[3];
// // // // // // //     if (average < 5) return valueColors[4];
// // // // // // //     return valueColors[5];
// // // // // // //   };

// // // // // // //   const handleResize = (entries) => {
// // // // // // //     // Your resize logic here
// // // // // // //   };

// // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // //   const numberOfRespondents = results.length;

// // // // // // //   const overallScore = data.reduce((sum, dimensionData) => {
// // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // //       const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// // // // // // //       if (!question) return qSum;

// // // // // // //       let scoreSum = 0;

// // // // // // //       if (question.type === 'multi_select') {
// // // // // // //         scoreSum = questionData.responses.length;
// // // // // // //       } else if (question.type === 'single_choice') {
// // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // // // // // //       }

// // // // // // //       return qSum + scoreSum;
// // // // // // //     }, 0);
// // // // // // //   }, 0);

// // // // // // //   const maxScore = surveyQuestions.questions.reduce((sum, question) => {
// // // // // // //     if (question.type === 'multi_select') {
// // // // // // //       return sum + (numberOfRespondents * question.options.length);
// // // // // // //     } else if (question.type === 'single_choice') {
// // // // // // //       return sum + (numberOfRespondents * question.options.length);
// // // // // // //     }
// // // // // // //     return sum;
// // // // // // //   }, 0);

// // // // // // //   const averageScorePerRespondent = numberOfRespondents > 0 ? overallScore / numberOfRespondents : 0;
// // // // // // //   const percentage = maxScore > 0 ? ((averageScorePerRespondent / maxScore) * 100).toFixed(2) : '0.00';

// // // // // // //   console.log('Overall Average Score:', averageScorePerRespondent);
// // // // // // //   console.log('Max Score:', maxScore);
// // // // // // //   console.log('Percentage:', percentage);

// // // // // // //   return (
// // // // // // //     <div>
// // // // // // //       <div className="navheader-container">
// // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // //         {hasJobFunctionData && (
// // // // // // //         <div className="navheader-filter">
// // // // // // //           <MultiSelect
// // // // // // //             data={jobFunctions}
// // // // // // //             value={selectedJobFunctions}
// // // // // // //             onChange={handleFilterChange}
// // // // // // //             placeholder="Filter by Job Function"
// // // // // // //             searchable
// // // // // // //             clearable
// // // // // // //           />
// // // // // // //         </div>
// // // // // // //         )}
// // // // // // //         <div className="navheader-logo">
// // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // //         </div>
// // // // // // //       </div>

// // // // // // //       <div className='surveyResultsContainer'>
// // // // // // //         <SurveyRubric overallScore={averageScorePerRespondent} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} maxScore={maxScore} />
// // // // // // //         {data.length === 0 ? (
// // // // // // //           <div>No data available</div>
// // // // // // //         ) : (
// // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // //                   const keys = Object.keys(questionData)
// // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));

// // // // // // //                   const colors = getColors(keys);
// // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // //                   const averageColor = getAverageColor(average);

// // // // // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // // // // //                   if (question && question.type === 'multi_select') {
// // // // // // //                     return (
// // // // // // //                       <SurveyResultsMulti
// // // // // // //                         key={index}
// // // // // // //                         questionData={questionData}
// // // // // // //                         keys={keys}
// // // // // // //                         colors={colors}
// // // // // // //                         average={average}
// // // // // // //                         averageColor={averageColor}
// // // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // // //                       />
// // // // // // //                     );
// // // // // // //                   } else {
// // // // // // //                     return (
// // // // // // //                       <SurveyResultsSingle
// // // // // // //                         key={index}
// // // // // // //                         questionData={questionData}
// // // // // // //                         keys={keys}
// // // // // // //                         colors={colors}
// // // // // // //                         average={average}
// // // // // // //                         averageColor={averageColor}
// // // // // // //                       />
// // // // // // //                     );
// // // // // // //                   }
// // // // // // //                 })}
// // // // // // //               </div>
// // // // // // //               <hr className='standard-pad'></hr>
// // // // // // //             </div>
// // // // // // //           ))
// // // // // // //         )}
// // // // // // //       </div>
// // // // // // //     </div>
// // // // // // //   );
// // // // // // // };

// // // // // // // export default SurveyResultsCharts;




// // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // //   const valueColors = {
// // // // // // // //     1: '#2c294b', 
// // // // // // // //     2: '#3b3484',
// // // // // // // //     3: '#7174b0',
// // // // // // // //     4: '#ebb844',
// // // // // // // //     5: '#e08a3c',
// // // // // // // //     6: '#de6736', 
// // // // // // // //     7: '#c5316a', 
// // // // // // // //     8: '#762861', 
// // // // // // // //     9: '#ebe5df', 
// // // // // // // //     10: '#958e86', 
// // // // // // // //     11: '#373737', 
// // // // // // // //     12: '#5d3c23', 
// // // // // // // //     13: '#80ba55',
// // // // // // // //   };

// // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // //   };

// // // // // // // //   const aggregateData = (results) => {
// // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // //       : results;

// // // // // // // //     const aggregatedResponses = {};

// // // // // // // //     filteredResults.forEach((submission) => {
// // // // // // // //       submission.responses.forEach((responseObj) => {
// // // // // // // //         const questionText = responseObj.question;
// // // // // // // //         const response = responseObj.answer;

// // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // //         if (question) {
// // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // //           }

// // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // //           }

// // // // // // // //           if (Array.isArray(response)) {
// // // // // // // //             response.forEach((resp) => {
// // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // //             });
// // // // // // // //           } else {
// // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // //           }
// // // // // // // //         }
// // // // // // // //       });
// // // // // // // //     });

// // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // //       dimension,
// // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // //         question,
// // // // // // // //         ...responses,
// // // // // // // //       })),
// // // // // // // //     }));

// // // // // // // //     return aggregatedData;
// // // // // // // //   };

// // // // // // // //   const data = aggregateData(results);

// // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // //   }, [results]);

// // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // //   const getColors = (keys) => {
// // // // // // // //     return keys.map(key => {
// // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // //     });
// // // // // // // //   };

// // // // // // // //   const getAverageColor = (average) => {
// // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // //     return valueColors[5];
// // // // // // // //   };

// // // // // // // //   const handleResize = (entries) => {
// // // // // // // //     // Your resize logic here
// // // // // // // //   };

// // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // //   const numberOfRespondents = results.length;

// // // // // // // //   const overallScore = data.reduce((sum, dimensionData) => {
// // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // //       const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// // // // // // // //       if (!question) return qSum;

// // // // // // // //       let scoreSum = 0;

// // // // // // // //       if (question.type === 'multi_select') {
// // // // // // // //         scoreSum = questionData.responses.length;
// // // // // // // //       } else if (question.type === 'single_choice') {
// // // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // // // // // // //       }

// // // // // // // //       return qSum + scoreSum;
// // // // // // // //     }, 0);
// // // // // // // //   }, 0);

// // // // // // // //   const maxScore = surveyQuestions.questions.reduce((sum, question) => {
// // // // // // // //     if (question.type === 'multi_select') {
// // // // // // // //       return sum + (numberOfRespondents * question.options.length);
// // // // // // // //     } else if (question.type === 'single_choice') {
// // // // // // // //       return sum + (numberOfRespondents * 5); // Assuming 5 is the max value for single choice
// // // // // // // //     }
// // // // // // // //     return sum;
// // // // // // // //   }, 0);

// // // // // // // //   const averageScorePerRespondent = overallScore / numberOfRespondents;
// // // // // // // //   const percentage = ((averageScorePerRespondent / maxScore) * 100).toFixed(2);

// // // // // // // //   console.log('Overall Average Score:', averageScorePerRespondent);
// // // // // // // //   console.log('Max Score:', maxScore);
// // // // // // // //   console.log('Percentage:', percentage);

// // // // // // // //   return (
// // // // // // // //     <div>
// // // // // // // //       <div className="navheader-container">
// // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // //         {hasJobFunctionData && (
// // // // // // // //         <div className="navheader-filter">
// // // // // // // //           <MultiSelect
// // // // // // // //             data={jobFunctions}
// // // // // // // //             value={selectedJobFunctions}
// // // // // // // //             onChange={handleFilterChange}
// // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // //             searchable
// // // // // // // //             clearable
// // // // // // // //           />
// // // // // // // //         </div>
// // // // // // // //         )}
// // // // // // // //         <div className="navheader-logo">
// // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // //         </div>
// // // // // // // //       </div>

// // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // //         <SurveyRubric overallScore={averageScorePerRespondent} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} />
// // // // // // // //         {data.length === 0 ? (
// // // // // // // //           <div>No data available</div>
// // // // // // // //         ) : (
// // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));

// // // // // // // //                   const colors = getColors(keys);
// // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // //                   const averageColor = getAverageColor(average);

// // // // // // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // // // // // //                   if (question && question.type === 'multi_select') {
// // // // // // // //                     return (
// // // // // // // //                       <SurveyResultsMulti
// // // // // // // //                         key={index}
// // // // // // // //                         questionData={questionData}
// // // // // // // //                         keys={keys}
// // // // // // // //                         colors={colors}
// // // // // // // //                         average={average}
// // // // // // // //                         averageColor={averageColor}
// // // // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // // // //                       />
// // // // // // // //                     );
// // // // // // // //                   } else {
// // // // // // // //                     return (
// // // // // // // //                       <SurveyResultsSingle
// // // // // // // //                         key={index}
// // // // // // // //                         questionData={questionData}
// // // // // // // //                         keys={keys}
// // // // // // // //                         colors={colors}
// // // // // // // //                         average={average}
// // // // // // // //                         averageColor={averageColor}
// // // // // // // //                       />
// // // // // // // //                     );
// // // // // // // //                   }
// // // // // // // //                 })}
// // // // // // // //               </div>
// // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // //             </div>
// // // // // // // //           ))
// // // // // // // //         )}
// // // // // // // //       </div>
// // // // // // // //     </div>
// // // // // // // //   );
// // // // // // // // };

// // // // // // // // export default SurveyResultsCharts;


// // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // //   const valueColors = {
// // // // // // // // //     1: '#2c294b', 
// // // // // // // // //     2: '#3b3484',
// // // // // // // // //     3: '#7174b0',
// // // // // // // // //     4: '#ebb844',
// // // // // // // // //     5: '#e08a3c',
// // // // // // // // //     6: '#de6736', 
// // // // // // // // //     7: '#c5316a', 
// // // // // // // // //     8: '#762861', 
// // // // // // // // //     9: '#ebe5df', 
// // // // // // // // //     10: '#958e86', 
// // // // // // // // //     11: '#373737', 
// // // // // // // // //     12: '#5d3c23', 
// // // // // // // // //     13: '#80ba55',
// // // // // // // // //   };

// // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // //   };

// // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // //       : results;

// // // // // // // // //     console.log('Filtered Results:', filteredResults);

// // // // // // // // //     const aggregatedResponses = {};

// // // // // // // // //     filteredResults.forEach((submission, subIndex) => {
// // // // // // // // //       console.log(`Processing submission ${subIndex + 1}:`, submission);

// // // // // // // // //       submission.responses.forEach((responseObj, index) => {
// // // // // // // // //         const questionText = responseObj.question;
// // // // // // // // //         const response = responseObj.answer;

// // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // // //         if (question) {
// // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // //           }

// // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // //           }

// // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // //             response.forEach((resp) => {
// // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // //               }

// // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // //             });
// // // // // // // // //           } else {
// // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // //             }

// // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // //           }
// // // // // // // // //         } else {
// // // // // // // // //           console.log(`Question not found in surveyQuestions for response:`, responseObj);
// // // // // // // // //         }
// // // // // // // // //       });
// // // // // // // // //     });

// // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // //       dimension,
// // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // //         question,
// // // // // // // // //         ...responses,
// // // // // // // // //       })),
// // // // // // // // //     }));

// // // // // // // // //     console.log('Aggregated Data:', JSON.stringify(aggregatedData, null, 2));

// // // // // // // // //     return aggregatedData;
// // // // // // // // //   };

// // // // // // // // //   const data = aggregateData(results);

// // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // //   }, [results]);

// // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // //   const getColors = (keys) => {
// // // // // // // // //     return keys.map(key => {
// // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // //     });
// // // // // // // // //   };

// // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // //     return valueColors[5];
// // // // // // // // //   };

// // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // //     // Your resize logic here
// // // // // // // // //   };

// // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // //       const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// // // // // // // // //       if (!question) return qSum;

// // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // //       let scoreSum = 0;

// // // // // // // // //       if (question.type === 'multi_select') {
// // // // // // // // //         // For multi-select questions, count each selected option as one point
// // // // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => {
// // // // // // // // //           return sum + value;
// // // // // // // // //         }, 0);
// // // // // // // // //         console.log(`Multi-select score for question ${questionData.question}:`, scoreSum);
// // // // // // // // //       } else if (question.type === 'single_choice') {
// // // // // // // // //         // For single-choice questions, sum the scores directly
// // // // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // // // // // // // //         console.log(`Single-choice score for question ${questionData.question}:`, scoreSum);
// // // // // // // // //       }

// // // // // // // // //       console.log(`Score for question ${questionData.question}:`, scoreSum);

// // // // // // // // //       return qSum + scoreSum;
// // // // // // // // //     }, 0);
// // // // // // // // //   }, 0);

// // // // // // // // //   const maxScore = surveyQuestions.questions.reduce((sum, question) => {
// // // // // // // // //     if (question.type === 'multi_select') {
// // // // // // // // //       // Assume each option is worth 1 point for multi-select
// // // // // // // // //       return sum + question.options.length;
// // // // // // // // //     } else if (question.type === 'single_choice') {
// // // // // // // // //       // Max score for single-choice is the highest option value
// // // // // // // // //       return sum + question.options.length;
// // // // // // // // //     }
// // // // // // // // //     return sum;
// // // // // // // // //   }, 0);

// // // // // // // // //   console.log('Overall Average Score:', overallAverageScore);
// // // // // // // // //   console.log('Max Score:', maxScore);

// // // // // // // // //   const percentage = ((overallAverageScore / maxScore) * 100).toFixed(2);

// // // // // // // // //   return (
// // // // // // // // //     <div>
// // // // // // // // //       <div className="navheader-container">
// // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // //         <div className="navheader-filter">
// // // // // // // // //           <MultiSelect
// // // // // // // // //             data={jobFunctions}
// // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // //             searchable
// // // // // // // // //             clearable
// // // // // // // // //           />
// // // // // // // // //         </div>
// // // // // // // // //         )}
// // // // // // // // //         <div className="navheader-logo">
// // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // //         </div>
// // // // // // // // //       </div>

// // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} />
// // // // // // // // //         {data.length === 0 ? (
// // // // // // // // //           <div>No data available</div>
// // // // // // // // //         ) : (
// // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // //                   const colors = getColors(keys);
// // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // //                   const averageColor = getAverageColor(average);

// // // // // // // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // // // // // // //                   if (question && question.type === 'multi_select') {
// // // // // // // // //                     // Multi-choice question
// // // // // // // // //                     return (
// // // // // // // // //                       <SurveyResultsMulti
// // // // // // // // //                         key={index}
// // // // // // // // //                         questionData={questionData}
// // // // // // // // //                         keys={keys}
// // // // // // // // //                         colors={colors}
// // // // // // // // //                         average={average}
// // // // // // // // //                         averageColor={averageColor}
// // // // // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // // // // //                       />
// // // // // // // // //                     );
// // // // // // // // //                   } else {
// // // // // // // // //                     // Single-choice question
// // // // // // // // //                     return (
// // // // // // // // //                       <SurveyResultsSingle
// // // // // // // // //                         key={index}
// // // // // // // // //                         questionData={questionData}
// // // // // // // // //                         keys={keys}
// // // // // // // // //                         colors={colors}
// // // // // // // // //                         average={average}
// // // // // // // // //                         averageColor={averageColor}
// // // // // // // // //                       />
// // // // // // // // //                     );
// // // // // // // // //                   }
// // // // // // // // //                 })}
// // // // // // // // //               </div>
// // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // //             </div>
// // // // // // // // //           ))
// // // // // // // // //         )}
// // // // // // // // //       </div>
// // // // // // // // //     </div>
// // // // // // // // //   );
// // // // // // // // // };

// // // // // // // // // export default SurveyResultsCharts;

// // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // //   const valueColors = {
// // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // //     2: '#3b3484',
// // // // // // // // // //     3: '#7174b0',
// // // // // // // // // //     4: '#ebb844',
// // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // //     6: '#de6736', 
// // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // //     8: '#762861', 
// // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // //     10: '#958e86', 
// // // // // // // // // //     11: '#373737', 
// // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // //     13: '#80ba55',
// // // // // // // // // //   };

// // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // //   };

// // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // //       : results;

// // // // // // // // // //     console.log('Filtered Results:', filteredResults);

// // // // // // // // // //     const aggregatedResponses = {};

// // // // // // // // // //     filteredResults.forEach((submission, subIndex) => {
// // // // // // // // // //       console.log(`Processing submission ${subIndex + 1}:`, submission);

// // // // // // // // // //       submission.responses.forEach((responseObj, index) => {
// // // // // // // // // //         const questionText = responseObj.question;
// // // // // // // // // //         const response = responseObj.answer;

// // // // // // // // // //         console.log(`Processing response ${index + 1} for submission ${subIndex + 1}:`, responseObj);

// // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // // // //         if (question) {
// // // // // // // // // //           console.log(`Found question in surveyQuestions:`, question);

// // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // //           }

// // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // //           }

// // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // //               }

// // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // //             });
// // // // // // // // // //           } else {
// // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // //             }

// // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // //           }
// // // // // // // // // //         } else {
// // // // // // // // // //           console.log(`Question not found in surveyQuestions for response:`, responseObj);
// // // // // // // // // //         }
// // // // // // // // // //       });
// // // // // // // // // //     });

// // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // //       dimension,
// // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // //         question,
// // // // // // // // // //         ...responses,
// // // // // // // // // //       })),
// // // // // // // // // //     }));

// // // // // // // // // //     console.log('Aggregated Data:', JSON.stringify(aggregatedData, null, 2));

// // // // // // // // // //     return aggregatedData;
// // // // // // // // // //   };

// // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // //   }, [results]);

// // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // //     return keys.map(key => {
// // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // //     });
// // // // // // // // // //   };

// // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // //     return valueColors[5];
// // // // // // // // // //   };

// // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // //     // Your resize logic here
// // // // // // // // // //   };

// // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // //       const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// // // // // // // // // //       if (!question) return qSum;

// // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // //       let scoreSum = 0;

// // // // // // // // // //       if (question.type === 'multi_select') {
// // // // // // // // // //         // For multi-select questions, count each selected option as one point
// // // // // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => sum + value.length, 0);
// // // // // // // // // //       } else if (question.type === 'single_choice') {
// // // // // // // // // //         // For single-choice questions, sum the scores directly
// // // // // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // // // // // // // // //       }

// // // // // // // // // //       console.log(`Score for question ${question.text}:`, scoreSum);
// // // // // // // // // //       return qSum + scoreSum;
// // // // // // // // // //     }, 0);
// // // // // // // // // //   }, 0);

// // // // // // // // // //   const maxScore = surveyQuestions.questions.reduce((sum, question) => {
// // // // // // // // // //     if (question.type === 'multi_select') {
// // // // // // // // // //       // Assume each option is worth 1 point for multi-select
// // // // // // // // // //       return sum + question.options.length;
// // // // // // // // // //     } else if (question.type === 'single_choice') {
// // // // // // // // // //       // Max score for single-choice is the highest option value
// // // // // // // // // //       return sum + question.options.length;
// // // // // // // // // //     }
// // // // // // // // // //     return sum;
// // // // // // // // // //   }, 0);

// // // // // // // // // //   console.log(`Overall Average Score: ${overallAverageScore}`);
// // // // // // // // // //   console.log(`Max Score: ${maxScore}`);

// // // // // // // // // //   const percentage = ((overallAverageScore / maxScore) * 100).toFixed(2);
// // // // // // // // // //   console.log(`Percentage: ${percentage}`);

// // // // // // // // // //   return (
// // // // // // // // // //     <div>
// // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // //           <MultiSelect
// // // // // // // // // //             data={jobFunctions}
// // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // //             searchable
// // // // // // // // // //             clearable
// // // // // // // // // //           />
// // // // // // // // // //         </div>
// // // // // // // // // //         )}
// // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // //         </div>
// // // // // // // // // //       </div>

// // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} percentage={percentage} />
// // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // //           <div>No data available</div>
// // // // // // // // // //         ) : (
// // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // //                   const colors = getColors(keys);
// // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // //                   const averageColor = getAverageColor(average);

// // // // // // // // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // // // // // // // //                   if (question && question.type === 'multi_select') {
// // // // // // // // // //                     // Multi-choice question
// // // // // // // // // //                     return (
// // // // // // // // // //                       <SurveyResultsMulti
// // // // // // // // // //                         key={index}
// // // // // // // // // //                         questionData={questionData}
// // // // // // // // // //                         keys={keys}
// // // // // // // // // //                         colors={colors}
// // // // // // // // // //                         average={average}
// // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // // // // // //                       />
// // // // // // // // // //                     );
// // // // // // // // // //                   } else {
// // // // // // // // // //                     // Single-choice question
// // // // // // // // // //                     return (
// // // // // // // // // //                       <SurveyResultsSingle
// // // // // // // // // //                         key={index}
// // // // // // // // // //                         questionData={questionData}
// // // // // // // // // //                         keys={keys}
// // // // // // // // // //                         colors={colors}
// // // // // // // // // //                         average={average}
// // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // //                       />
// // // // // // // // // //                     );
// // // // // // // // // //                   }
// // // // // // // // // //                 })}
// // // // // // // // // //               </div>
// // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // //             </div>
// // // // // // // // // //           ))
// // // // // // // // // //         )}
// // // // // // // // // //       </div>
// // // // // // // // // //     </div>
// // // // // // // // // //   );
// // // // // // // // // // };

// // // // // // // // // // export default SurveyResultsCharts;



// // // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // //   const valueColors = {
// // // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // //   };

// // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // //   };

// // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // //       : results;

// // // // // // // // // // //     console.log('Filtered Results:', filteredResults);

// // // // // // // // // // //     const aggregatedResponses = {};

// // // // // // // // // // //     filteredResults.forEach((submission, subIndex) => {
// // // // // // // // // // //       console.log(`Processing submission ${subIndex + 1}:`, submission);

// // // // // // // // // // //       submission.responses.forEach((responseObj, index) => {
// // // // // // // // // // //         const questionText = responseObj.question;
// // // // // // // // // // //         const response = responseObj.answer;

// // // // // // // // // // //         // console.log(`Processing response ${index + 1} for submission ${subIndex + 1}:`, responseObj);

// // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // // // // //         if (question) {
// // // // // // // // // // //           // console.log(`Found question in surveyQuestions:`, question);

// // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // //           }

// // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // //           }

// // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // //               }

// // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // //             });
// // // // // // // // // // //           } else {
// // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // //             }

// // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // //           }
// // // // // // // // // // //         } else {
// // // // // // // // // // //           console.log(`Question not found in surveyQuestions for response:`, responseObj);
// // // // // // // // // // //         }
// // // // // // // // // // //       });
// // // // // // // // // // //     });

// // // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // //       dimension,
// // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // //         question,
// // // // // // // // // // //         ...responses,
// // // // // // // // // // //       })),
// // // // // // // // // // //     }));

// // // // // // // // // // //     console.log('Aggregated Data:', JSON.stringify(aggregatedData, null, 2));

// // // // // // // // // // //     return aggregatedData;
// // // // // // // // // // //   };

// // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // //   }, [results]);

// // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // // //     });
// // // // // // // // // // //   };

// // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // //   };

// // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // //   };

// // // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // //       const question = surveyQuestions.questions.find(q => q.text === questionData.question);
// // // // // // // // // // //       if (!question) return qSum;

// // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // //       let scoreSum = 0;

// // // // // // // // // // //       if (question.type === 'multi_select') {
// // // // // // // // // // //         // For multi-select questions, count each selected option as one point
// // // // // // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => sum + value.length, 0);
// // // // // // // // // // //       } else if (question.type === 'single_choice') {
// // // // // // // // // // //         // For single-choice questions, sum the scores directly
// // // // // // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // // // // // // // // // //       }

// // // // // // // // // // //       return qSum + scoreSum;
// // // // // // // // // // //     }, 0);
// // // // // // // // // // //   }, 0);

// // // // // // // // // // //   const maxScore = surveyQuestions.questions.reduce((sum, question) => {
// // // // // // // // // // //     if (question.type === 'multi_select') {
// // // // // // // // // // //       // Assume each option is worth 1 point for multi-select
// // // // // // // // // // //       return sum + question.options.length;
// // // // // // // // // // //     } else if (question.type === 'single_choice') {
// // // // // // // // // // //       // Max score for single-choice is the highest option value
// // // // // // // // // // //       return sum + question.options.length;
// // // // // // // // // // //     }
// // // // // // // // // // //     return sum;
// // // // // // // // // // //   }, 0);

// // // // // // // // // // //   const percentage = ((overallAverageScore / maxScore) * 100).toFixed(2);

// // // // // // // // // // //   return (
// // // // // // // // // // //     <div>
// // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // //           <MultiSelect
// // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // //             searchable
// // // // // // // // // // //             clearable
// // // // // // // // // // //           />
// // // // // // // // // // //         </div>
// // // // // // // // // // //         )}
// // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // //         </div>
// // // // // // // // // // //       </div>

// // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // //         ) : (
// // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // // //                   const colors = getColors(keys);
// // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // //                   const averageColor = getAverageColor(average);

// // // // // // // // // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // // // // // // // // //                   if (question && question.type === 'multi_select') {
// // // // // // // // // // //                     // Multi-choice question
// // // // // // // // // // //                     return (
// // // // // // // // // // //                       <SurveyResultsMulti
// // // // // // // // // // //                         key={index}
// // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // //                         keys={keys}
// // // // // // // // // // //                         colors={colors}
// // // // // // // // // // //                         average={average}
// // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // // // // // // //                       />
// // // // // // // // // // //                     );
// // // // // // // // // // //                   } else {
// // // // // // // // // // //                     // Single-choice question
// // // // // // // // // // //                     return (
// // // // // // // // // // //                       <SurveyResultsSingle
// // // // // // // // // // //                         key={index}
// // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // //                         keys={keys}
// // // // // // // // // // //                         colors={colors}
// // // // // // // // // // //                         average={average}
// // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // //                       />
// // // // // // // // // // //                     );
// // // // // // // // // // //                   }
// // // // // // // // // // //                 })}
// // // // // // // // // // //               </div>
// // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // //             </div>
// // // // // // // // // // //           ))
// // // // // // // // // // //         )}
// // // // // // // // // // //       </div>
// // // // // // // // // // //     </div>
// // // // // // // // // // //   );
// // // // // // // // // // // };

// // // // // // // // // // // export default SurveyResultsCharts;




// // // // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // // //   const valueColors = {
// // // // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // //       : results;

// // // // // // // // // // // //     console.log('Filtered Results:', filteredResults);

// // // // // // // // // // // //     const aggregatedResponses = {};

// // // // // // // // // // // //     filteredResults.forEach((submission, subIndex) => {
// // // // // // // // // // // //       console.log(`Processing submission ${subIndex + 1}:`, submission);

// // // // // // // // // // // //       submission.responses.forEach((responseObj, index) => {
// // // // // // // // // // // //         const questionText = responseObj.question;
// // // // // // // // // // // //         const response = responseObj.answer;

// // // // // // // // // // // //         console.log(`Processing response ${index + 1} for submission ${subIndex + 1}:`, responseObj);

// // // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // // // // // //         if (question) {
// // // // // // // // // // // //           console.log(`Found question in surveyQuestions:`, question);

// // // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // // //           }

// // // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // //           }

// // // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // //               }

// // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // //             });
// // // // // // // // // // // //           } else {
// // // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // //             }

// // // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // //           }
// // // // // // // // // // // //         } else {
// // // // // // // // // // // //           console.log(`Question not found in surveyQuestions for response:`, responseObj);
// // // // // // // // // // // //         }
// // // // // // // // // // // //       });
// // // // // // // // // // // //     });

// // // // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // //       dimension,
// // // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // //         question,
// // // // // // // // // // // //         ...responses,
// // // // // // // // // // // //       })),
// // // // // // // // // // // //     }));

// // // // // // // // // // // //     console.log('Aggregated Data:', JSON.stringify(aggregatedData, null, 2));

// // // // // // // // // // // //     return aggregatedData;
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // // //   }, [results]);

// // // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // // // //     });
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // // //   };

// // // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // // //   };

// // // // // // // // // // // //   useDebouncedResizeObserver(handleResize);


// // // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // // //       const question = surveyQuestions.questions.find(q => q.text === questionData.question);
  
// // // // // // // // // // // //       if (!question) {
// // // // // // // // // // // //         return qSum;
// // // // // // // // // // // //       }
  
// // // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // // //       let scoreSum = 0;
  
// // // // // // // // // // // //       if (question.type === 'multi_select') {
// // // // // // // // // // // //         // For multi-select questions, count each selected option as one point
// // // // // // // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => sum + value.length, 0);
// // // // // // // // // // // //       } else if (question.type === 'single_choice') {
// // // // // // // // // // // //         // For single-choice questions, sum the scores directly
// // // // // // // // // // // //         scoreSum = questionData.responses.reduce((sum, value) => sum + value, 0);
// // // // // // // // // // // //       }
  
// // // // // // // // // // // //       return qSum + scoreSum;
// // // // // // // // // // // //     }, 0);
// // // // // // // // // // // //   }, 0);
  
// // // // // // // // // // // //   const maxScore = surveyQuestions.questions.reduce((sum, question) => {
// // // // // // // // // // // //     if (question.type === 'multi_select') {
// // // // // // // // // // // //       // Assume each option is worth 1 point for multi-select
// // // // // // // // // // // //       return sum + question.options.length;
// // // // // // // // // // // //     } else if (question.type === 'single_choice') {
// // // // // // // // // // // //       // Max score for single-choice is the highest option value
// // // // // // // // // // // //       return sum + question.options.length;
// // // // // // // // // // // //     }
// // // // // // // // // // // //     return sum;
// // // // // // // // // // // //   }, 0);
  
// // // // // // // // // // // //   const percentage = ((overallAverageScore / maxScore) * 100).toFixed(2);

  
// // // // // // // // // // // //   return (
// // // // // // // // // // // //     <div>
// // // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // // //           <MultiSelect
// // // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // // //             searchable
// // // // // // // // // // // //             clearable
// // // // // // // // // // // //           />
// // // // // // // // // // // //         </div>
// // // // // // // // // // // //         )}
// // // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // // //         </div>
// // // // // // // // // // // //       </div>

// // // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // // //         ) : (
// // // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // // // //                   const colors = getColors(keys);
          
// // // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // // //                   const averageColor = getAverageColor(average);

// // // // // // // // // // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // // // // // // // // // //                   if (question && question.type === 'multi_select') {
// // // // // // // // // // // //                     // Multi-choice question
// // // // // // // // // // // //                     return (
// // // // // // // // // // // //                       <SurveyResultsMulti
// // // // // // // // // // // //                         key={index}
// // // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // // //                         keys={keys}
// // // // // // // // // // // //                         colors={colors}
// // // // // // // // // // // //                         average={average}
// // // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // // // // // // // //                       />
// // // // // // // // // // // //                     );
// // // // // // // // // // // //                   } else {
// // // // // // // // // // // //                     // Single-choice question
// // // // // // // // // // // //                     return (
// // // // // // // // // // // //                       <SurveyResultsSingle
// // // // // // // // // // // //                         key={index}
// // // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // // //                         keys={keys}
// // // // // // // // // // // //                         colors={colors}
// // // // // // // // // // // //                         average={average}
// // // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // // //                       />
// // // // // // // // // // // //                     );
// // // // // // // // // // // //                   }
// // // // // // // // // // // //                 })}
// // // // // // // // // // // //               </div>
// // // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // // //             </div>
// // // // // // // // // // // //           ))
// // // // // // // // // // // //         )}
// // // // // // // // // // // //       </div>
// // // // // // // // // // // //     </div>
// // // // // // // // // // // //   );
// // // // // // // // // // // // };

// // // // // // // // // // // // export default SurveyResultsCharts;



// // // // // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // // // //   const valueColors = {
// // // // // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // //       : results;

// // // // // // // // // // // // //     console.log('Filtered Results:', filteredResults);

// // // // // // // // // // // // //     const aggregatedResponses = {};

// // // // // // // // // // // // //     filteredResults.forEach((submission, subIndex) => {
// // // // // // // // // // // // //       console.log(`Processing submission ${subIndex + 1}:`, submission);

// // // // // // // // // // // // //       submission.responses.forEach((responseObj, index) => {
// // // // // // // // // // // // //         const questionText = responseObj.question;
// // // // // // // // // // // // //         const response = responseObj.answer;

// // // // // // // // // // // // //         console.log(`Processing response ${index + 1} for submission ${subIndex + 1}:`, responseObj);

// // // // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // // // // // // //         if (question) {
// // // // // // // // // // // // //           console.log(`Found question in surveyQuestions:`, question);

// // // // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // // // //           }

// // // // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // //           }

// // // // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // //               }

// // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // //             });
// // // // // // // // // // // // //           } else {
// // // // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // //             }

// // // // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // //           }
// // // // // // // // // // // // //         } else {
// // // // // // // // // // // // //           console.log(`Question not found in surveyQuestions for response:`, responseObj);
// // // // // // // // // // // // //         }
// // // // // // // // // // // // //       });
// // // // // // // // // // // // //     });

// // // // // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // //       dimension,
// // // // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // //         question,
// // // // // // // // // // // // //         ...responses,
// // // // // // // // // // // // //       })),
// // // // // // // // // // // // //     }));

// // // // // // // // // // // // //     console.log('Aggregated Data:', JSON.stringify(aggregatedData, null, 2));

// // // // // // // // // // // // //     return aggregatedData;
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // // // //   }, [results]);

// // // // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // // // // //     });
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // // // //   };

// // // // // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // // // //       const average = totalResponses > 0 ? questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses : 0;
// // // // // // // // // // // // //       return qSum + average;
// // // // // // // // // // // // //     }, 0);
// // // // // // // // // // // // //   }, 0);

// // // // // // // // // // // // //   return (
// // // // // // // // // // // // //     <div>
// // // // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // // // //           <MultiSelect
// // // // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // // // //             searchable
// // // // // // // // // // // // //             clearable
// // // // // // // // // // // // //           />
// // // // // // // // // // // // //         </div>
// // // // // // // // // // // // //         )}
// // // // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // // // //         </div>
// // // // // // // // // // // // //       </div>

// // // // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // // // // //                   const colors = getColors(keys);
          
// // // // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // // // //                   const averageColor = getAverageColor(average);

// // // // // // // // // // // // //                   const question = surveyQuestions.questions.find(q => q.text === questionData.question);

// // // // // // // // // // // // //                   if (question && question.type === 'multi_choice') {
// // // // // // // // // // // // //                     // Multi-choice question
// // // // // // // // // // // // //                     return (
// // // // // // // // // // // // //                       <SurveyResultsMulti
// // // // // // // // // // // // //                         key={index}
// // // // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // // // //                         keys={keys}
// // // // // // // // // // // // //                         colors={colors}
// // // // // // // // // // // // //                         average={average}
// // // // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // // // // // // // // //                       />
// // // // // // // // // // // // //                     );
// // // // // // // // // // // // //                   } else {
// // // // // // // // // // // // //                     // Single-choice question
// // // // // // // // // // // // //                     return (
// // // // // // // // // // // // //                       <SurveyResultsSingle
// // // // // // // // // // // // //                         key={index}
// // // // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // // // //                         keys={keys}
// // // // // // // // // // // // //                         colors={colors}
// // // // // // // // // // // // //                         average={average}
// // // // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // // // //                       />
// // // // // // // // // // // // //                     );
// // // // // // // // // // // // //                   }
// // // // // // // // // // // // //                 })}
// // // // // // // // // // // // //               </div>
// // // // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // // // //             </div>
// // // // // // // // // // // // //           ))
// // // // // // // // // // // // //         )}
// // // // // // // // // // // // //       </div>
// // // // // // // // // // // // //     </div>
// // // // // // // // // // // // //   );
// // // // // // // // // // // // // };

// // // // // // // // // // // // // export default SurveyResultsCharts;


// // // // // // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // // // // //   const valueColors = {
// // // // // // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // // //       : results;

// // // // // // // // // // // // // //     console.log('Filtered Results:', filteredResults);

// // // // // // // // // // // // // //     const aggregatedResponses = {};

// // // // // // // // // // // // // //     filteredResults.forEach((submission, subIndex) => {
// // // // // // // // // // // // // //       console.log(`Processing submission ${subIndex + 1}:`, submission);

// // // // // // // // // // // // // //       submission.responses.forEach((responseObj, index) => {
// // // // // // // // // // // // // //         const questionText = responseObj.question;
// // // // // // // // // // // // // //         const response = responseObj.answer;

// // // // // // // // // // // // // //         console.log(`Processing response ${index + 1} for submission ${subIndex + 1}:`, responseObj);

// // // // // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // // // // // // // //         if (question) {
// // // // // // // // // // // // // //           console.log(`Found question in surveyQuestions:`, question);

// // // // // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // // // // //           }

// // // // // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // // //           }

// // // // // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // //               }

// // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // //             });
// // // // // // // // // // // // // //           } else {
// // // // // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // //             }

// // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // //           }
// // // // // // // // // // // // // //         } else {
// // // // // // // // // // // // // //           console.log(`Question not found in surveyQuestions for response:`, responseObj);
// // // // // // // // // // // // // //         }
// // // // // // // // // // // // // //       });
// // // // // // // // // // // // // //     });

// // // // // // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // // //       dimension,
// // // // // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // // //         question,
// // // // // // // // // // // // // //         ...responses,
// // // // // // // // // // // // // //       })),
// // // // // // // // // // // // // //     }));

// // // // // // // // // // // // // //     console.log('Aggregated Data:', JSON.stringify(aggregatedData, null, 2));

// // // // // // // // // // // // // //     return aggregatedData;
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // // // // //   }, [results]);

// // // // // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // // // // // //     });
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // // // // //   };

// // // // // // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // //       const average = totalResponses > 0 ? questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses : 0;
// // // // // // // // // // // // // //       return qSum + average;
// // // // // // // // // // // // // //     }, 0);
// // // // // // // // // // // // // //   }, 0);

// // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // // // // //           <MultiSelect
// // // // // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // // // // //             searchable
// // // // // // // // // // // // // //             clearable
// // // // // // // // // // // // // //           />
// // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // //       </div>

// // // // // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // // // // // //                   const colors = getColors(keys);
          
// // // // // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // // // // //                   const averageColor = getAverageColor(average);
          
// // // // // // // // // // // // // //                   if (Array.isArray(questionData.responses[0])) {
// // // // // // // // // // // // // //                     // Multi-choice question
// // // // // // // // // // // // // //                     return (
// // // // // // // // // // // // // //                       <SurveyResultsMulti
// // // // // // // // // // // // // //                         key={index}
// // // // // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // // // // //                         keys={keys}
// // // // // // // // // // // // // //                         colors={colors}
// // // // // // // // // // // // // //                         average={average}
// // // // // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // // // // // // // // // //                       />
// // // // // // // // // // // // // //                     );
// // // // // // // // // // // // // //                   } else {
// // // // // // // // // // // // // //                     // Single-choice question
// // // // // // // // // // // // // //                     return (
// // // // // // // // // // // // // //                       <SurveyResultsSingle
// // // // // // // // // // // // // //                         key={index}
// // // // // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // // // // //                         keys={keys}
// // // // // // // // // // // // // //                         colors={colors}
// // // // // // // // // // // // // //                         average={average}
// // // // // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // // // // //                       />
// // // // // // // // // // // // // //                     );
// // // // // // // // // // // // // //                   }
// // // // // // // // // // // // // //                 })}
// // // // // // // // // // // // // //               </div>
// // // // // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // //           ))
// // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // };

// // // // // // // // // // // // // // export default SurveyResultsCharts;



// // // // // // // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // // // // // import SurveyResultsSingle from './SurveyResultsSingle';
// // // // // // // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // // // // // //   const valueColors = {
// // // // // // // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // // // //       : results;

// // // // // // // // // // // // // // //     console.log('Filtered Results:', filteredResults);

// // // // // // // // // // // // // // //     const aggregatedResponses = {};

// // // // // // // // // // // // // // //     filteredResults.forEach((submission, subIndex) => {
// // // // // // // // // // // // // // //       console.log(`Processing submission ${subIndex + 1}:`, submission);

// // // // // // // // // // // // // // //       submission.responses.forEach((responseObj, index) => {
// // // // // // // // // // // // // // //         const questionText = responseObj.question;
// // // // // // // // // // // // // // //         const response = responseObj.answer;

// // // // // // // // // // // // // // //         console.log(`Processing response ${index + 1} for submission ${subIndex + 1}:`, responseObj);

// // // // // // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // // // // // // // // //         if (question) {
// // // // // // // // // // // // // // //           console.log(`Found question in surveyQuestions:`, question);

// // // // // // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // // // // // //           }

// // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // // // //           }

// // // // // // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // //               }

// // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // //             });
// // // // // // // // // // // // // // //           } else {
// // // // // // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // //             }

// // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // //           }
// // // // // // // // // // // // // // //         } else {
// // // // // // // // // // // // // // //           console.log(`Question not found in surveyQuestions for response:`, responseObj);
// // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // //       });
// // // // // // // // // // // // // // //     });

// // // // // // // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // // // //       dimension,
// // // // // // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // // // //         question,
// // // // // // // // // // // // // // //         ...responses,
// // // // // // // // // // // // // // //       })),
// // // // // // // // // // // // // // //     }));

// // // // // // // // // // // // // // //     console.log('Aggregated Data:', JSON.stringify(aggregatedData, null, 2));

// // // // // // // // // // // // // // //     return aggregatedData;
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // // // // // //   }, [results]);

// // // // // // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // //       const average = totalResponses > 0 ? questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses : 0;
// // // // // // // // // // // // // // //       return qSum + average;
// // // // // // // // // // // // // // //     }, 0);
// // // // // // // // // // // // // // //   }, 0);

// // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // // // // // //           <MultiSelect
// // // // // // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // // // // // //             searchable
// // // // // // // // // // // // // // //             clearable
// // // // // // // // // // // // // // //           />
// // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // //       </div>

// // // // // // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // // // // // // //                   const colors = getColors(keys);
          
// // // // // // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // // // // // //                   const averageColor = getAverageColor(average);
          
// // // // // // // // // // // // // // //                   if (Array.isArray(questionData.responses[0])) {
// // // // // // // // // // // // // // //                     // Multi-choice question
// // // // // // // // // // // // // // //                     return (
// // // // // // // // // // // // // // //                       <SurveyResultsMulti
// // // // // // // // // // // // // // //                         key={index}
// // // // // // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // // // // // //                         keys={keys}
// // // // // // // // // // // // // // //                         colors={colors}
// // // // // // // // // // // // // // //                         average={average}
// // // // // // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // // // // // //                         surveyQuestions={surveyQuestions.questions}
// // // // // // // // // // // // // // //                       />
// // // // // // // // // // // // // // //                     );
// // // // // // // // // // // // // // //                   } else {
// // // // // // // // // // // // // // //                     // Single-choice question
// // // // // // // // // // // // // // //                     return (
// // // // // // // // // // // // // // //                       <SurveyResultsSingle
// // // // // // // // // // // // // // //                         key={index}
// // // // // // // // // // // // // // //                         questionData={questionData}
// // // // // // // // // // // // // // //                         keys={keys}
// // // // // // // // // // // // // // //                         colors={colors}
// // // // // // // // // // // // // // //                         average={average}
// // // // // // // // // // // // // // //                         averageColor={averageColor}
// // // // // // // // // // // // // // //                       />
// // // // // // // // // // // // // // //                     );
// // // // // // // // // // // // // // //                   }
// // // // // // // // // // // // // // //                 })}
// // // // // // // // // // // // // // //               </div>
// // // // // // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // //           ))
// // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // export default SurveyResultsCharts;


// // // // // // // // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // // // // // // //   const valueColors = {
// // // // // // // // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // // // // //       : results;

// // // // // // // // // // // // // // // //     console.log('Filtered Results:', filteredResults);

// // // // // // // // // // // // // // // //     const aggregatedResponses = {};

// // // // // // // // // // // // // // // //     filteredResults.forEach((submission, subIndex) => {
// // // // // // // // // // // // // // // //       console.log(`Processing submission ${subIndex + 1}:`, submission);

// // // // // // // // // // // // // // // //       submission.responses.forEach((responseObj, index) => {
// // // // // // // // // // // // // // // //         const questionText = responseObj.question;
// // // // // // // // // // // // // // // //         const response = responseObj.answer;

// // // // // // // // // // // // // // // //         console.log(`Processing response ${index + 1} for submission ${subIndex + 1}:`, responseObj);

// // // // // // // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // // // // // // // // // //         if (question) {
// // // // // // // // // // // // // // // //           console.log(`Found question in surveyQuestions:`, question);

// // // // // // // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // // // // // // //           }

// // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // // // // //           }

// // // // // // // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // //               }

// // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // //             });
// // // // // // // // // // // // // // // //           } else {
// // // // // // // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // //             }

// // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // //           }
// // // // // // // // // // // // // // // //         } else {
// // // // // // // // // // // // // // // //           console.log(`Question not found in surveyQuestions for response:`, responseObj);
// // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // //       });
// // // // // // // // // // // // // // // //     });

// // // // // // // // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // // // // //       dimension,
// // // // // // // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // // // // //         question,
// // // // // // // // // // // // // // // //         ...responses,
// // // // // // // // // // // // // // // //       })),
// // // // // // // // // // // // // // // //     }));

// // // // // // // // // // // // // // // //     console.log('Aggregated Data:', JSON.stringify(aggregatedData, null, 2));

// // // // // // // // // // // // // // // //     return aggregatedData;
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // // // // // // //   }, [results]);

// // // // // // // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // //       const average = totalResponses > 0 ? questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses : 0;
// // // // // // // // // // // // // // // //       return qSum + average;
// // // // // // // // // // // // // // // //     }, 0);
// // // // // // // // // // // // // // // //   }, 0);

// // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // // // // // // //           <MultiSelect
// // // // // // // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // // // // // // //             searchable
// // // // // // // // // // // // // // // //             clearable
// // // // // // // // // // // // // // // //           />
// // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // //       </div>

// // // // // // // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // // // // // // // //                   const colors = getColors(keys);
          
// // // // // // // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // // // // // // //                   const averageColor = getAverageColor(average);
          
// // // // // // // // // // // // // // // //                   return (
// // // // // // // // // // // // // // // //                     <SurveyResultsMulti
// // // // // // // // // // // // // // // //                       key={index}
// // // // // // // // // // // // // // // //                       questionData={questionData}
// // // // // // // // // // // // // // // //                       keys={keys}
// // // // // // // // // // // // // // // //                       colors={colors}
// // // // // // // // // // // // // // // //                       average={average}
// // // // // // // // // // // // // // // //                       averageColor={averageColor}
// // // // // // // // // // // // // // // //                       surveyQuestions={surveyQuestions.questions}
// // // // // // // // // // // // // // // //                     />
// // // // // // // // // // // // // // // //                   );
// // // // // // // // // // // // // // // //                 })}
// // // // // // // // // // // // // // // //               </div>
// // // // // // // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // //           ))
// // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // export default SurveyResultsCharts;



// // // // // // // // // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // // // // // // // //   const valueColors = {
// // // // // // // // // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   // const aggregateData = (results) => {
// // // // // // // // // // // // // // // // //   //   const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // // // // // //   //     ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // // // // // //   //     : results;

// // // // // // // // // // // // // // // // //   //   const aggregatedResponses = {};

// // // // // // // // // // // // // // // // //   //   console.log('Filtered Results:', filteredResults); // Log filtered results

// // // // // // // // // // // // // // // // //   //   filteredResults.forEach((submission) => {
// // // // // // // // // // // // // // // // //   //     // Assuming `responses` is an array of objects where each object has a key corresponding to Question and its response
// // // // // // // // // // // // // // // // //   //     submission.responses.forEach((responseObj, index) => {
// // // // // // // // // // // // // // // // //   //       const questionKey = `Question${index + 1}`;
// // // // // // // // // // // // // // // // //   //       const responseKey = `Response${index + 1}`;
// // // // // // // // // // // // // // // // //   //       const questionText = responseObj.question;
// // // // // // // // // // // // // // // // //   //       const response = responseObj.response;

// // // // // // // // // // // // // // // // //   //       const question = surveyQuestions.questions.find(q => q.text === questionText);

// // // // // // // // // // // // // // // // //   //       if (question && response) {
// // // // // // // // // // // // // // // // //   //         const dimension = question.dimension || 'General';
// // // // // // // // // // // // // // // // //   //         if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension] = {};
// // // // // // // // // // // // // // // // //   //         }

// // // // // // // // // // // // // // // // //   //         if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // // // // // //   //         }

// // // // // // // // // // // // // // // // //   //         if (Array.isArray(response)) {
// // // // // // // // // // // // // // // // //   //           response.forEach((resp) => {
// // // // // // // // // // // // // // // // //   //             const answerText = `${resp} - ${question.options[resp - 1]}`;

// // // // // // // // // // // // // // // // //   //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // //   //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // //   //             }

// // // // // // // // // // // // // // // // //   //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // //   //             aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // // // // // //   //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // //   //           });
// // // // // // // // // // // // // // // // //   //         } else {
// // // // // // // // // // // // // // // // //   //           const answerText = `${response} - ${question.options[response - 1]}`;

// // // // // // // // // // // // // // // // //   //           if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // //   //             aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // //   //           }

// // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // //   //         }
// // // // // // // // // // // // // // // // //   //       }
// // // // // // // // // // // // // // // // //   //     });
// // // // // // // // // // // // // // // // //   //   });

// // // // // // // // // // // // // // // // //   //   const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // // // // // //   //     dimension,
// // // // // // // // // // // // // // // // //   //     questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // // // // // //   //       question,
// // // // // // // // // // // // // // // // //   //       ...responses,
// // // // // // // // // // // // // // // // //   //     })),
// // // // // // // // // // // // // // // // //   //   }));

// // // // // // // // // // // // // // // // //   //   console.log('Aggregated Data:', aggregatedData); // Log aggregated data

// // // // // // // // // // // // // // // // //   //   return aggregatedData;
// // // // // // // // // // // // // // // // //   // };

// // // // // // // // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // // // // // //       : results;
  
// // // // // // // // // // // // // // // // //     console.log('Filtered Results:', filteredResults);
  
// // // // // // // // // // // // // // // // //     const aggregatedResponses = {};
  
// // // // // // // // // // // // // // // // //     filteredResults.forEach((submission, subIndex) => {
// // // // // // // // // // // // // // // // //       console.log(`Processing submission ${subIndex + 1}:`, submission);
  
// // // // // // // // // // // // // // // // //       submission.responses.forEach((responseObj, index) => {
// // // // // // // // // // // // // // // // //         const questionText = responseObj.question;
// // // // // // // // // // // // // // // // //         const response = responseObj.answer;  // Ensure we're accessing the 'answer' key
  
// // // // // // // // // // // // // // // // //         console.log(`Processing response ${index + 1} for submission ${subIndex + 1}:`, responseObj);
  
// // // // // // // // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);
  
// // // // // // // // // // // // // // // // //         if (question) {
// // // // // // // // // // // // // // // // //           console.log(`Found question in surveyQuestions:`, question);
  
// // // // // // // // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // // // // // // // //           }
  
// // // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // // // // // //           }
  
// // // // // // // // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;
  
// // // // // // // // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // //               }
  
// // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // //             });
// // // // // // // // // // // // // // // // //           } else {
// // // // // // // // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;
  
// // // // // // // // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // //             }
  
// // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // //           }
// // // // // // // // // // // // // // // // //         } else {
// // // // // // // // // // // // // // // // //           console.log(`Question not found in surveyQuestions for response:`, responseObj);
// // // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // // //       });
// // // // // // // // // // // // // // // // //     });
  
// // // // // // // // // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // // // // // //       dimension,
// // // // // // // // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // // // // // //         question,
// // // // // // // // // // // // // // // // //         ...responses,
// // // // // // // // // // // // // // // // //       })),
// // // // // // // // // // // // // // // // //     }));
  
// // // // // // // // // // // // // // // // //     console.log('Aggregated Data:', JSON.stringify(aggregatedData, null, 2));
  
// // // // // // // // // // // // // // // // //     return aggregatedData;
// // // // // // // // // // // // // // // // //   };
  
  
// // // // // // // // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // // // // // // // //   }, [results]);

// // // // // // // // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // // //       const average = totalResponses > 0 ? questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses : 0;
// // // // // // // // // // // // // // // // //       return qSum + average;
// // // // // // // // // // // // // // // // //     }, 0);
// // // // // // // // // // // // // // // // //   }, 0);

// // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // // // // // // // //           <MultiSelect
// // // // // // // // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // // // // // // // //             searchable
// // // // // // // // // // // // // // // // //             clearable
// // // // // // // // // // // // // // // // //           />
// // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // //       </div>

// // // // // // // // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // // // // // // // // //                   const colors = getColors(keys);
          
// // // // // // // // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // // // // // // // //                   const averageColor = getAverageColor(average);
          
// // // // // // // // // // // // // // // // //                   return (
// // // // // // // // // // // // // // // // //                     <SurveyResultsMulti
// // // // // // // // // // // // // // // // //                       key={index}
// // // // // // // // // // // // // // // // //                       questionData={questionData}
// // // // // // // // // // // // // // // // //                       keys={keys}
// // // // // // // // // // // // // // // // //                       colors={colors}
// // // // // // // // // // // // // // // // //                       average={average}
// // // // // // // // // // // // // // // // //                       averageColor={averageColor}
// // // // // // // // // // // // // // // // //                     />
// // // // // // // // // // // // // // // // //                   );
// // // // // // // // // // // // // // // // //                 })}
// // // // // // // // // // // // // // // // //               </div>
// // // // // // // // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // // //           ))
// // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // export default SurveyResultsCharts;



// // // // // // // // // // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // // // // // // // // //   const valueColors = {
// // // // // // // // // // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // // // // // // //       : results;
  
// // // // // // // // // // // // // // // // // //     const aggregatedResponses = {};
  
// // // // // // // // // // // // // // // // // //     filteredResults.forEach((submission) => {
// // // // // // // // // // // // // // // // // //       for (let i = 1; i <= 20; i++) {
// // // // // // // // // // // // // // // // // //         const questionKey = `Question${i}`;
// // // // // // // // // // // // // // // // // //         const responseKey = `Response${i}`;
// // // // // // // // // // // // // // // // // //         const questionText = submission[questionKey];
// // // // // // // // // // // // // // // // // //         const response = submission[responseKey];
  
// // // // // // // // // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);
  
// // // // // // // // // // // // // // // // // //         if (question && response) {
// // // // // // // // // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // // // // // // // // //           }
  
// // // // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // // // // // // //           }
  
// // // // // // // // // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;
  
// // // // // // // // // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // // //               }
  
// // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // // //             });
// // // // // // // // // // // // // // // // // //           } else {
// // // // // // // // // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;
  
// // // // // // // // // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // // //             }
  
// // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // // //           }
// // // // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // //     });
  
// // // // // // // // // // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // // // // // // //       dimension,
// // // // // // // // // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // // // // // // //         question,
// // // // // // // // // // // // // // // // // //         ...responses,
// // // // // // // // // // // // // // // // // //       })),
// // // // // // // // // // // // // // // // // //     }));

// // // // // // // // // // // // // // // // // //     console.log('Aggregated Data:', aggregatedData); // Log aggregated data
  
// // // // // // // // // // // // // // // // // //     return aggregatedData;
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // // // // // // // // //   }, [results]);

// // // // // // // // // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // // // //       const average = totalResponses > 0 ? questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses : 0;
// // // // // // // // // // // // // // // // // //       return qSum + average;
// // // // // // // // // // // // // // // // // //     }, 0);
// // // // // // // // // // // // // // // // // //   }, 0);

// // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // // // // // // // // //           <MultiSelect
// // // // // // // // // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // // // // // // // // //             searchable
// // // // // // // // // // // // // // // // // //             clearable
// // // // // // // // // // // // // // // // // //           />
// // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // //       </div>

// // // // // // // // // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // // // // // // // // // //                   const colors = getColors(keys);
          
// // // // // // // // // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // // // // // // // // //                   const averageColor = getAverageColor(average);
          
// // // // // // // // // // // // // // // // // //                   return (
// // // // // // // // // // // // // // // // // //                     <SurveyResultsMulti
// // // // // // // // // // // // // // // // // //                       key={index}
// // // // // // // // // // // // // // // // // //                       questionData={questionData}
// // // // // // // // // // // // // // // // // //                       keys={keys}
// // // // // // // // // // // // // // // // // //                       colors={colors}
// // // // // // // // // // // // // // // // // //                       average={average}
// // // // // // // // // // // // // // // // // //                       averageColor={averageColor}
// // // // // // // // // // // // // // // // // //                     />
// // // // // // // // // // // // // // // // // //                   );
// // // // // // // // // // // // // // // // // //                 })}
// // // // // // // // // // // // // // // // // //               </div>
// // // // // // // // // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // // // //           ))
// // // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // export default SurveyResultsCharts;



// // // // // // // // // // // // // // // // // // // import React, { useState, useMemo } from 'react';
// // // // // // // // // // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // // // // // // // // // import SurveyResultsMulti from './SurveyResultsMulti';
// // // // // // // // // // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver';
// // // // // // // // // // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // // // // // // // // // //   const valueColors = {
// // // // // // // // // // // // // // // // // // //     1: '#2c294b', 
// // // // // // // // // // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // // // // // // // // // //     5: '#e08a3c',
// // // // // // // // // // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   // const aggregateData = (results) => {
// // // // // // // // // // // // // // // // // // //   //   const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // // // // // // // //   //     ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // // // // // // // //   //     : results;
  
// // // // // // // // // // // // // // // // // // //   //   const aggregatedResponses = {};
  
// // // // // // // // // // // // // // // // // // //   //   filteredResults.forEach((submission) => {
// // // // // // // // // // // // // // // // // // //   //     for (let i = 1; i <= 20; i++) {
// // // // // // // // // // // // // // // // // // //   //       const questionKey = `Question${i}`;
// // // // // // // // // // // // // // // // // // //   //       const responseKey = `Response${i}`;
// // // // // // // // // // // // // // // // // // //   //       const questionText = submission[questionKey];
// // // // // // // // // // // // // // // // // // //   //       const response = submission[responseKey];
  
// // // // // // // // // // // // // // // // // // //   //       const question = surveyQuestions.questions.find(q => q.text === questionText);
  
// // // // // // // // // // // // // // // // // // //   //       if (question && response) {
// // // // // // // // // // // // // // // // // // //   //         const dimension = question.dimension || 'General';
// // // // // // // // // // // // // // // // // // //   //         if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension] = {};
// // // // // // // // // // // // // // // // // // //   //         }
  
// // // // // // // // // // // // // // // // // // //   //         if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // // // // // // // //   //         }
  
// // // // // // // // // // // // // // // // // // //   //         if (Array.isArray(response)) {
// // // // // // // // // // // // // // // // // // //   //           response.forEach((resp) => {
// // // // // // // // // // // // // // // // // // //   //             const answerText = `${resp} - ${question.options[resp - 1]}`;
  
// // // // // // // // // // // // // // // // // // //   //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // // // //   //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // // // //   //             }
  
// // // // // // // // // // // // // // // // // // //   //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // // // //   //             aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // // // // // // // //   //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // // // //   //           });
// // // // // // // // // // // // // // // // // // //   //         } else {
// // // // // // // // // // // // // // // // // // //   //           const answerText = `${response} - ${question.options[response - 1]}`;
  
// // // // // // // // // // // // // // // // // // //   //           if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // // // //   //             aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // // // //   //           }
  
// // // // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // // // // // // // //   //           aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // // // //   //         }
// // // // // // // // // // // // // // // // // // //   //       }
// // // // // // // // // // // // // // // // // // //   //     }
// // // // // // // // // // // // // // // // // // //   //   });
  
// // // // // // // // // // // // // // // // // // //   //   return Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // // // // // // // //   //     dimension,
// // // // // // // // // // // // // // // // // // //   //     questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // // // // // // // //   //       question,
// // // // // // // // // // // // // // // // // // //   //       ...responses,
// // // // // // // // // // // // // // // // // // //   //     })),
// // // // // // // // // // // // // // // // // // //   //   }));
// // // // // // // // // // // // // // // // // // //   // };

// // // // // // // // // // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // // // // // // // //       : results;
  
// // // // // // // // // // // // // // // // // // //     const aggregatedResponses = {};
  
// // // // // // // // // // // // // // // // // // //     filteredResults.forEach((submission) => {
// // // // // // // // // // // // // // // // // // //       for (let i = 1; i <= 20; i++) {
// // // // // // // // // // // // // // // // // // //         const questionKey = `Question${i}`;
// // // // // // // // // // // // // // // // // // //         const responseKey = `Response${i}`;
// // // // // // // // // // // // // // // // // // //         const questionText = submission[questionKey];
// // // // // // // // // // // // // // // // // // //         const response = submission[responseKey];
  
// // // // // // // // // // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);
  
// // // // // // // // // // // // // // // // // // //         if (question && response) {
// // // // // // // // // // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // // // // // // // // // //           }
  
// // // // // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // // // // // // // //           }
  
// // // // // // // // // // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;
  
// // // // // // // // // // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // // // //               }
  
// // // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // // // //             });
// // // // // // // // // // // // // // // // // // //           } else {
// // // // // // // // // // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;
  
// // // // // // // // // // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // // // //             }
  
// // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // // // //           }
// // // // // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // //     });
  
// // // // // // // // // // // // // // // // // // //     const aggregatedData = Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // // // // // // // //       dimension,
// // // // // // // // // // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // // // // // // // //         question,
// // // // // // // // // // // // // // // // // // //         ...responses,
// // // // // // // // // // // // // // // // // // //       })),
// // // // // // // // // // // // // // // // // // //     }));
  
// // // // // // // // // // // // // // // // // // //     console.log('Aggregated Data:', aggregatedData); // Log aggregated data
  
// // // // // // // // // // // // // // // // // // //     return aggregatedData;
// // // // // // // // // // // // // // // // // // //   };
  
// // // // // // // // // // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // // // // // // // // // //   }, [results]);

// // // // // // // // // // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // // // // // // // // // //       return valueColors[value] || '#2c294b';
// // // // // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // // // // //       const average = totalResponses > 0 ? questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses : 0;
// // // // // // // // // // // // // // // // // // //       return qSum + average;
// // // // // // // // // // // // // // // // // // //     }, 0);
// // // // // // // // // // // // // // // // // // //   }, 0);

// // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // // // // // // // // // //           <MultiSelect
// // // // // // // // // // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // // // // // // // // // //             searchable
// // // // // // // // // // // // // // // // // // //             clearable
// // // // // // // // // // // // // // // // // // //           />
// // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // //       </div>

// // // // // // // // // // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b));
          
// // // // // // // // // // // // // // // // // // //                   const colors = getColors(keys);
          
// // // // // // // // // // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // // // // // // // // // //                   const averageColor = getAverageColor(average);
          
// // // // // // // // // // // // // // // // // // //                   return (
// // // // // // // // // // // // // // // // // // //                     <SurveyResultsMulti
// // // // // // // // // // // // // // // // // // //                       key={index}
// // // // // // // // // // // // // // // // // // //                       questionData={questionData}
// // // // // // // // // // // // // // // // // // //                       keys={keys}
// // // // // // // // // // // // // // // // // // //                       colors={colors}
// // // // // // // // // // // // // // // // // // //                       average={average}
// // // // // // // // // // // // // // // // // // //                       averageColor={averageColor}
// // // // // // // // // // // // // // // // // // //                     />
// // // // // // // // // // // // // // // // // // //                   );
// // // // // // // // // // // // // // // // // // //                 })}
// // // // // // // // // // // // // // // // // // //               </div>
// // // // // // // // // // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // // // // //           ))
// // // // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // export default SurveyResultsCharts;


// // // // // // // // // // // // // // // // // // // // import React, { useState, useMemo, useCallback } from 'react';
// // // // // // // // // // // // // // // // // // // // import { ResponsiveBar } from '@nivo/bar';
// // // // // // // // // // // // // // // // // // // // import { MultiSelect } from '@mantine/core';
// // // // // // // // // // // // // // // // // // // // import useDebouncedResizeObserver from './useDebouncedResizeObserver'; // Adjust the import path as necessary
// // // // // // // // // // // // // // // // // // // // import SurveyRubric from './SurveyRubric';

// // // // // // // // // // // // // // // // // // // // const SurveyResultsCharts = ({ results, surveyQuestions, surveyID }) => {
// // // // // // // // // // // // // // // // // // // //   const valueColors = {
// // // // // // // // // // // // // // // // // // // //     1: '#2c294b', // Dark purple
// // // // // // // // // // // // // // // // // // // //     2: '#3b3484',
// // // // // // // // // // // // // // // // // // // //     3: '#7174b0',
// // // // // // // // // // // // // // // // // // // //     4: '#ebb844',
// // // // // // // // // // // // // // // // // // // //     5: '#e08a3c', // Dark orange
// // // // // // // // // // // // // // // // // // // //     6: '#de6736', 
// // // // // // // // // // // // // // // // // // // //     7: '#c5316a', 
// // // // // // // // // // // // // // // // // // // //     8: '#762861', 
// // // // // // // // // // // // // // // // // // // //     9: '#ebe5df', 
// // // // // // // // // // // // // // // // // // // //     10: '#958e86', 
// // // // // // // // // // // // // // // // // // // //     11: '#373737', 
// // // // // // // // // // // // // // // // // // // //     12: '#5d3c23', 
// // // // // // // // // // // // // // // // // // // //     13: '#80ba55',
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   const [selectedJobFunctions, setSelectedJobFunctions] = useState([]);

// // // // // // // // // // // // // // // // // // // //   const handleFilterChange = (values) => {
// // // // // // // // // // // // // // // // // // // //     setSelectedJobFunctions(values || []);
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   const aggregateData = (results) => {
// // // // // // // // // // // // // // // // // // // //     const filteredResults = selectedJobFunctions.length > 0
// // // // // // // // // // // // // // // // // // // //       ? results.filter(submission => selectedJobFunctions.includes(submission.jobFunction))
// // // // // // // // // // // // // // // // // // // //       : results;
  
// // // // // // // // // // // // // // // // // // // //     const aggregatedResponses = {};
  
// // // // // // // // // // // // // // // // // // // //     filteredResults.forEach((submission) => {
// // // // // // // // // // // // // // // // // // // //       for (let i = 1; i <= 20; i++) {
// // // // // // // // // // // // // // // // // // // //         const questionKey = `Question${i}`;
// // // // // // // // // // // // // // // // // // // //         const responseKey = `Response${i}`;
// // // // // // // // // // // // // // // // // // // //         const questionText = submission[questionKey];
// // // // // // // // // // // // // // // // // // // //         const response = submission[responseKey];
  
// // // // // // // // // // // // // // // // // // // //         const question = surveyQuestions.questions.find(q => q.text === questionText);
  
// // // // // // // // // // // // // // // // // // // //         if (question && response) {
// // // // // // // // // // // // // // // // // // // //           const dimension = question.dimension || 'General';
// // // // // // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension]) {
// // // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension] = {};
// // // // // // // // // // // // // // // // // // // //           }
  
// // // // // // // // // // // // // // // // // // // //           if (!aggregatedResponses[dimension][question.text]) {
// // // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text] = { responses: [], total: 0 };
// // // // // // // // // // // // // // // // // // // //           }
  
// // // // // // // // // // // // // // // // // // // //           if (Array.isArray(response)) {
// // // // // // // // // // // // // // // // // // // //             // Handle multi-select responses
// // // // // // // // // // // // // // // // // // // //             response.forEach((resp) => {
// // // // // // // // // // // // // // // // // // // //               const answerText = `${resp} - ${question.options[resp - 1]}`;
  
// // // // // // // // // // // // // // // // // // // //               if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // // // // //                 aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // // // // //               }
  
// // // // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].responses.push(resp);
// // // // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // // // // //             });
// // // // // // // // // // // // // // // // // // // //           } else {
// // // // // // // // // // // // // // // // // // // //             // Handle single-choice responses
// // // // // // // // // // // // // // // // // // // //             const answerText = `${response} - ${question.options[response - 1]}`;
  
// // // // // // // // // // // // // // // // // // // //             if (!aggregatedResponses[dimension][question.text][answerText]) {
// // // // // // // // // // // // // // // // // // // //               aggregatedResponses[dimension][question.text][answerText] = 0;
// // // // // // // // // // // // // // // // // // // //             }
  
// // // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text][answerText] += 1;
// // // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].responses.push(response);
// // // // // // // // // // // // // // // // // // // //             aggregatedResponses[dimension][question.text].total += 1;
// // // // // // // // // // // // // // // // // // // //           }
// // // // // // // // // // // // // // // // // // // //         }
// // // // // // // // // // // // // // // // // // // //       }
// // // // // // // // // // // // // // // // // // // //     });
  
// // // // // // // // // // // // // // // // // // // //     return Object.entries(aggregatedResponses).map(([dimension, questions]) => ({
// // // // // // // // // // // // // // // // // // // //       dimension,
// // // // // // // // // // // // // // // // // // // //       questions: Object.entries(questions).map(([question, responses]) => ({
// // // // // // // // // // // // // // // // // // // //         question,
// // // // // // // // // // // // // // // // // // // //         ...responses,
// // // // // // // // // // // // // // // // // // // //       })),
// // // // // // // // // // // // // // // // // // // //     }));
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   const data = aggregateData(results);

// // // // // // // // // // // // // // // // // // // //   const jobFunctions = useMemo(() => {
// // // // // // // // // // // // // // // // // // // //     return [...new Set(results.map(submission => submission.jobFunction).filter(Boolean))].map(value => ({ value, label: value }));
// // // // // // // // // // // // // // // // // // // //   }, [results]);

// // // // // // // // // // // // // // // // // // // //   const hasJobFunctionData = jobFunctions.length > 0;

// // // // // // // // // // // // // // // // // // // //   const getColors = (keys) => {
// // // // // // // // // // // // // // // // // // // //     return keys.map(key => {
// // // // // // // // // // // // // // // // // // // //       const value = parseInt(key.split(' - ')[0]);
// // // // // // // // // // // // // // // // // // // //       return valueColors[value] || '#2c294b'; // Default to dkpurple if no color found
// // // // // // // // // // // // // // // // // // // //     });
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   const getAverageColor = (average) => {
// // // // // // // // // // // // // // // // // // // //     if (average < 2) return valueColors[1];
// // // // // // // // // // // // // // // // // // // //     if (average < 3) return valueColors[2];
// // // // // // // // // // // // // // // // // // // //     if (average < 4) return valueColors[3];
// // // // // // // // // // // // // // // // // // // //     if (average < 5) return valueColors[4];
// // // // // // // // // // // // // // // // // // // //     return valueColors[5];
// // // // // // // // // // // // // // // // // // // //   };


// // // // // // // // // // // // // // // // // // // //   const handleResize = (entries) => {
// // // // // // // // // // // // // // // // // // // //     // Your resize logic here
// // // // // // // // // // // // // // // // // // // //   };

// // // // // // // // // // // // // // // // // // // //   useDebouncedResizeObserver(handleResize);

// // // // // // // // // // // // // // // // // // // //   // Calculate overall average score
// // // // // // // // // // // // // // // // // // // //   const overallAverageScore = data.reduce((sum, dimensionData) => {
// // // // // // // // // // // // // // // // // // // //     return sum + dimensionData.questions.reduce((qSum, questionData) => {
// // // // // // // // // // // // // // // // // // // //       const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // // // // // //       const average = totalResponses > 0 ? questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses : 0;
// // // // // // // // // // // // // // // // // // // //       return qSum + average;
// // // // // // // // // // // // // // // // // // // //     }, 0);
// // // // // // // // // // // // // // // // // // // //   }, 0);

// // // // // // // // // // // // // // // // // // // //   return (
// // // // // // // // // // // // // // // // // // // //     <div>
// // // // // // // // // // // // // // // // // // // //       <div className="navheader-container">
// // // // // // // // // // // // // // // // // // // //         <div className="navheader-title">Results: {surveyQuestions.title} Survey</div>
// // // // // // // // // // // // // // // // // // // //         {hasJobFunctionData && (
// // // // // // // // // // // // // // // // // // // //         <div className="navheader-filter">
// // // // // // // // // // // // // // // // // // // //           <MultiSelect
// // // // // // // // // // // // // // // // // // // //             data={jobFunctions}
// // // // // // // // // // // // // // // // // // // //             value={selectedJobFunctions}
// // // // // // // // // // // // // // // // // // // //             onChange={handleFilterChange}
// // // // // // // // // // // // // // // // // // // //             placeholder="Filter by Job Function"
// // // // // // // // // // // // // // // // // // // //             searchable
// // // // // // // // // // // // // // // // // // // //             clearable
// // // // // // // // // // // // // // // // // // // //           />
// // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // // // // //         <div className="navheader-logo">
// // // // // // // // // // // // // // // // // // // //           <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
// // // // // // // // // // // // // // // // // // // //         </div>
// // // // // // // // // // // // // // // // // // // //       </div>

// // // // // // // // // // // // // // // // // // // //       <div className='surveyResultsContainer'>
// // // // // // // // // // // // // // // // // // // //         <SurveyRubric overallAverageScore={overallAverageScore} surveyID={surveyID} surveyTitle={surveyQuestions.title} />
// // // // // // // // // // // // // // // // // // // //         {data.length === 0 ? (
// // // // // // // // // // // // // // // // // // // //           <div>No data available</div>
// // // // // // // // // // // // // // // // // // // //         ) : (
// // // // // // // // // // // // // // // // // // // //           data.map((dimensionData, dimIndex) => (
// // // // // // // // // // // // // // // // // // // //             <div key={dimIndex} className='surveyQuestions'>
// // // // // // // // // // // // // // // // // // // //               <h2 className='standard-pad no-btm'>{dimensionData.dimension}</h2>
// // // // // // // // // // // // // // // // // // // //               <div style={{ display: 'flex', flexWrap: 'wrap' }} className='standard-pad no-top'>
// // // // // // // // // // // // // // // // // // // //                 {dimensionData.questions.map((questionData, index) => {
// // // // // // // // // // // // // // // // // // // //                   const keys = Object.keys(questionData)
// // // // // // // // // // // // // // // // // // // //                     .filter(key => key !== 'question' && key !== 'responses' && key !== 'total')
// // // // // // // // // // // // // // // // // // // //                     .sort((a, b) => parseInt(a) - parseInt(b)); // Sort keys numerically
          
// // // // // // // // // // // // // // // // // // // //                   const colors = getColors(keys);
          
// // // // // // // // // // // // // // // // // // // //                   const totalResponses = questionData.responses.length;
// // // // // // // // // // // // // // // // // // // //                   const average = (totalResponses > 0 ? (questionData.responses.reduce((sum, value) => sum + value, 0) / totalResponses).toFixed(2) : 0);
// // // // // // // // // // // // // // // // // // // //                   const averageColor = getAverageColor(average);
          
// // // // // // // // // // // // // // // // // // // //                   return (
// // // // // // // // // // // // // // // // // // // //                     <div key={index} style={{ width: '45%', margin: '2.5%', height: 450 }}> {/* Set fixed height */}
// // // // // // // // // // // // // // // // // // // //                       <h6>{questionData.question}</h6>
          
// // // // // // // // // // // // // // // // // // // //                       <ResponsiveBar
// // // // // // // // // // // // // // // // // // // //                         data={[questionData]}
// // // // // // // // // // // // // // // // // // // //                         keys={keys}
// // // // // // // // // // // // // // // // // // // //                         indexBy="question"
// // // // // // // // // // // // // // // // // // // //                         margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
// // // // // // // // // // // // // // // // // // // //                         innerPadding={10} // Add padding between bars
// // // // // // // // // // // // // // // // // // // //                         groupMode="grouped"
// // // // // // // // // // // // // // // // // // // //                         colors={colors} // Dynamically get colors
// // // // // // // // // // // // // // // // // // // //                         borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// // // // // // // // // // // // // // // // // // // //                         axisTop={null}
// // // // // // // // // // // // // // // // // // // //                         axisRight={null}
// // // // // // // // // // // // // // // // // // // //                         axisBottom={{
// // // // // // // // // // // // // // // // // // // //                           tickSize: 5,
// // // // // // // // // // // // // // // // // // // //                           tickPadding: 5,
// // // // // // // // // // // // // // // // // // // //                           tickRotation: 0,
// // // // // // // // // // // // // // // // // // // //                           legend: 'Response',
// // // // // // // // // // // // // // // // // // // //                           legendPosition: 'middle',
// // // // // // // // // // // // // // // // // // // //                           legendOffset: 32,
// // // // // // // // // // // // // // // // // // // //                         }}
// // // // // // // // // // // // // // // // // // // //                         axisLeft={{
// // // // // // // // // // // // // // // // // // // //                           tickSize: 5,
// // // // // // // // // // // // // // // // // // // //                           tickPadding: 5,
// // // // // // // // // // // // // // // // // // // //                           tickRotation: 0,
// // // // // // // // // // // // // // // // // // // //                           legend: 'Count',
// // // // // // // // // // // // // // // // // // // //                           legendPosition: 'middle',
// // // // // // // // // // // // // // // // // // // //                           legendOffset: -40,
// // // // // // // // // // // // // // // // // // // //                           format: value => Number.isInteger(value) ? value : '', // Show only whole numbers
// // // // // // // // // // // // // // // // // // // //                         }}
// // // // // // // // // // // // // // // // // // // //                         labelSkipWidth={12}
// // // // // // // // // // // // // // // // // // // //                         labelSkipHeight={12}
// // // // // // // // // // // // // // // // // // // //                         labelTextColor="white" // Set label text color to white
// // // // // // // // // // // // // // // // // // // //                         tooltip={({ id, value, color }) => (
// // // // // // // // // // // // // // // // // // // //                           <div
// // // // // // // // // // // // // // // // // // // //                             style={{
// // // // // // // // // // // // // // // // // // // //                               padding: '12px',
// // // // // // // // // // // // // // // // // // // //                               color,
// // // // // // // // // // // // // // // // // // // //                               background: 'white',
// // // // // // // // // // // // // // // // // // // //                               border: `1px solid ${color}`,
// // // // // // // // // // // // // // // // // // // //                               borderRadius: '4px',
// // // // // // // // // // // // // // // // // // // //                             }}
// // // // // // // // // // // // // // // // // // // //                           >
// // // // // // // // // // // // // // // // // // // //                             <strong>
// // // // // // // // // // // // // // // // // // // //                               {id.split(' - ')[1]} (Count: {value})
// // // // // // // // // // // // // // // // // // // //                             </strong>
// // // // // // // // // // // // // // // // // // // //                           </div>
// // // // // // // // // // // // // // // // // // // //                         )}
// // // // // // // // // // // // // // // // // // // //                         legends={[
// // // // // // // // // // // // // // // // // // // //                           {
// // // // // // // // // // // // // // // // // // // //                             dataFrom: 'keys',
// // // // // // // // // // // // // // // // // // // //                             anchor: 'bottom-right',
// // // // // // // // // // // // // // // // // // // //                             direction: 'column',
// // // // // // // // // // // // // // // // // // // //                             justify: false,
// // // // // // // // // // // // // // // // // // // //                             translateX: 120,
// // // // // // // // // // // // // // // // // // // //                             translateY: 0,
// // // // // // // // // // // // // // // // // // // //                             itemsSpacing: 2,
// // // // // // // // // // // // // // // // // // // //                             itemWidth: 100,
// // // // // // // // // // // // // // // // // // // //                             itemHeight: 20,
// // // // // // // // // // // // // // // // // // // //                             itemDirection: 'left-to-right',
// // // // // // // // // // // // // // // // // // // //                             itemOpacity: 0.85,
// // // // // // // // // // // // // // // // // // // //                             symbolSize: 20,
// // // // // // // // // // // // // // // // // // // //                             effects: [
// // // // // // // // // // // // // // // // // // // //                               {
// // // // // // // // // // // // // // // // // // // //                                 on: 'hover',
// // // // // // // // // // // // // // // // // // // //                                 style: {
// // // // // // // // // // // // // // // // // // // //                                   itemOpacity: 1,
// // // // // // // // // // // // // // // // // // // //                                 },
// // // // // // // // // // // // // // // // // // // //                               },
// // // // // // // // // // // // // // // // // // // //                             ],
// // // // // // // // // // // // // // // // // // // //                           },
// // // // // // // // // // // // // // // // // // // //                         ]}
// // // // // // // // // // // // // // // // // // // //                         animate={true}
// // // // // // // // // // // // // // // // // // // //                         motionStiffness={90}
// // // // // // // // // // // // // // // // // // // //                         motionDamping={15}
// // // // // // // // // // // // // // // // // // // //                       />
// // // // // // // // // // // // // // // // // // // //                       <div style={{ textAlign: 'center', fontSize: '1.5em', color: averageColor }}>
// // // // // // // // // // // // // // // // // // // //                         Average: {average}
// // // // // // // // // // // // // // // // // // // //                       </div>
// // // // // // // // // // // // // // // // // // // //                     </div>
// // // // // // // // // // // // // // // // // // // //                   );
// // // // // // // // // // // // // // // // // // // //                 })}
// // // // // // // // // // // // // // // // // // // //               </div>
// // // // // // // // // // // // // // // // // // // //               <hr className='standard-pad'></hr>
// // // // // // // // // // // // // // // // // // // //             </div>
// // // // // // // // // // // // // // // // // // // //           ))
// // // // // // // // // // // // // // // // // // // //         )}
// // // // // // // // // // // // // // // // // // // //       </div>
// // // // // // // // // // // // // // // // // // // //     </div>
// // // // // // // // // // // // // // // // // // // //   );
// // // // // // // // // // // // // // // // // // // // };

// // // // // // // // // // // // // // // // // // // // export default SurveyResultsCharts;
