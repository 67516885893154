import React, { useState, useEffect } from 'react';
import { TextInput } from '@mantine/core';

const SanitizedInput = ({ value: externalValue, onChange, ...props }) => {
    const [internalValue, setInternalValue] = useState(externalValue || '');

    // console.log("SanitizedInput received value:", externalValue);

    useEffect(() => {
        // console.log("Sani Input External value changed:", externalValue);
        setInternalValue(externalValue || '');
    }, [externalValue]);

    const sanitizeValue = (inputValue) => {
        // console.log("sanitizeValue input:", inputValue);
        const safeInputValue = inputValue || '';
        const regex = /[^*{}[\]<>\\()\\|;]+/g;
        return safeInputValue.match(regex)?.join('') || '';
    };

    const handleInputChange = (event) => {
        // console.log("Sani Input Input changed:", event.target.value);
        const sanitizedValue = sanitizeValue(event.target.value);
        setInternalValue(sanitizedValue);

        if (onChange) {
            onChange({ target: { name: props.name, value: sanitizedValue } }); // Ensure name is included
        }
    };

    return <TextInput value={internalValue} onChange={handleInputChange} {...props} />;
};

export default SanitizedInput;
