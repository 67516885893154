import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import ScrollToTopButton from '../components/ScrollButton'; // Adjust the path as needed
import SEO from '../components/SEO';

const HeroBanner = () => {
    return (
      <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-colleagues_sit_conference_room.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Jobs to be done, <em>easier!</em></h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

const ThriveBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-cognitive_partners_in_accelerated_learning.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer-top"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">A Time of Evolution</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

  const ServicesBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-idea_synthesis_acceleration_for_every_industry.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer-top"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Services</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

  const TestimonialsBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-young_employee_sparkle_motion.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Testimonials</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


  const ContactBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners_bot_helps_at_home.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Get in Touch</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

  const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
  });
  
  const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
        className={className}
      >
        {children}
      </motion.div>
    );
  };
  

  const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
  };
  
const Homepage = () => {
    return (
        <div className='homepage'>
        <HeroBanner />
            <h1 className='standard-pad'>Boost Efficiency and Drive Innovation</h1>
            <h3 className='standard-pad'>AI-enabled organizations enhance productivity and decision-making</h3>
        <p className='standard-pad'>
        With HYE Partners, AI becomes an accessible tool to amplify your team's capabilities. We focus on reducing complexity and 
        providing clear, actionable strategies to integrate AI seamlessly into your operations. By focusing on jobs to be done, teams experience 
        significant improvements in workflow efficiency and strategic decision-making.
        </p>
        <p className='standard-pad no-btm'>
        At our consultancy, we make AI adoption effortless, enabling your teams to focus on what they do best. From refining workflows to 
        boosting decision-making capabilities, we provide bespoke AI integration strategies that deliver tangible results and elevate your 
        business performance.
        </p>

        <ScrollToTopButton to="/contact"       
        className="inlink-button standard-pad no-top"
        style={{
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          alignSelf: 'center'
        }}>
          Book free AI consultation
        </ScrollToTopButton>

        <TestimonialsBanner />

        <h3 className='standard-pad'>Testimonials about Principal Consultant, Adam New-Waterson</h3>
<ParallaxBox x={50} y={5} className='standard-pad testimonial'>
<img src="/headshots/Headshot-Aaron_Bird.jpeg" alt="Aaron Bird, CEO of Inflection" className="sm-image-circ-r"></img>
<p>"Adam has more experience operationalizing AI than 99% of the people I know. The things he's done solo are truly impressive."</p>
<h5>Aaron Bird, CEO of Inflection</h5>    
</ParallaxBox>


<ParallaxBox x={50} y={5} className='standard-pad testimonial'>
<img src="/headshots/Headshot-Jenn_Steele.jpeg" alt="Jenn Steele, CEO of SoundGTM" className="sm-image-circ-l"></img>
<p>"If you want someone to refine your team's prompts, go with someone else. If you want someone to align with your business strategy to help your team achieve things you had no idea were possible, go with HYE Partners."</p>
<h5>Jenn Steele, CEO of SoundGTM</h5>    
</ParallaxBox>



<ParallaxBox x={-50} y={-5}  className='standard-pad testimonial'>
<img src="/headshots/Headshot-Jake_Milstein.jpeg" alt="Jake Milstein, CRO at Critical Insight" className="sm-image-circ-r"></img>

<p>"Running a lean team, there's always more work than is humanly possible. HYE Partners helped us use AI to achieve more than was humanly possible, which led to outsized marketing and sales wins."</p>
<h5>Jake Milstein, CRO at Critical Insight</h5>    
</ParallaxBox>

<ParallaxBox x={50} y={5}  className='standard-pad testimonial'>
<img src="/headshots/Headshot-Russ_Somers.jpeg" alt="Russ Somers, VPM at Quantified.AI" className="sm-image-circ-l"></img>

<p>"To use AI across your business you need someone who's not only an AI expert, but also an expert across business functions. Adam has led almost every B2B Tech function and as a founder built and scaled a business using AI. That puts HYE Partners light-years ahead of other consultancies in the space."</p>
<h5>Russ Somers, VPM at Quantified.AI</h5>    
</ParallaxBox>

<ServicesBanner />

        <h3 className='standard-pad'>AI Consulting Services</h3>
        <p className='standard-pad no-btm'>
Our suite of services is designed to help your business harness the power of AI quickly and effectively, without the need for specialized development. From the initial assessment to ongoing optimization, we're with you every step of the way.
</p>

    <motion.div className="twobytwo">
      <ParallaxGlowBox 
        glowColor="rgba(61, 52, 139, 0.125)" 
        bgColor="bg-ltgray"
        x="-10%"
        y="-10%"
      >
        <h4>Assessment & Strategy Blueprint</h4>
        <ul><li>We start by evaluating your current AI readiness with our comprehensive AI Assessment Framework. This identifies gaps, opportunities, and the best path forward. Our AI Strategy Blueprint then aligns specific AI opportunities with your business goals for maximum impact.</li></ul>
      </ParallaxGlowBox>
      <ParallaxGlowBox 
        glowColor="rgba(118, 120, 237, 0.125)" 
        bgColor="bg-ltgray"
        x="10%"
        y="-10%"
      >
        <h4>Implementation & Integration</h4>
        <ul><li>Seamlessly integrate AI models into your workflows to boost productivity and decision-making. Our modular PoC Toolkit ensures that AI solutions are prototyped quickly, while the Implementation Playbook helps your teams adopt the tools smoothly.</li></ul>
      </ParallaxGlowBox>
      <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="-10%"
        y="10%"
      >
        <h4>Training & Change Management</h4>
        <ul><li>Empower your teams to confidently use AI models with our specialized training materials and change management strategies. Our comprehensive guides and workshops enable your staff to maximize productivity while fostering a positive outlook on AI.</li></ul>
      </ParallaxGlowBox>
      <ParallaxGlowBox 
        glowColor="rgba(217, 3, 104, 0.125)" 
        bgColor="bg-ltgray"
        x="10%"
        y="10%"
      >
        <h4>Performance Optimization</h4>
        <ul><li>Track your AI success with our Performance Measurement Framework, offering clear KPIs and ROI templates. This ongoing support ensures that models remain effective, compliant, and aligned with business goals.</li></ul>
      </ParallaxGlowBox>
    </motion.div>
        
    <ScrollToTopButton 
      to="/plan" 
      className="inlink-button standard-pad no-top"
      style={{
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignSelf: 'center'
      }}
    >Design a Sample Workshop
        </ScrollToTopButton>

        <ThriveBanner />

        <h3 className='standard-pad no-btm'>How to help your team thrive</h3>
        <p className='standard-pad no-btm'>
Stretched thin, and wearing more hats than they can handle, is the current state of tech workers. HYE Partners' services are designed to reduce the burden on employees so they can focus on being creative and solving hard challenges.
</p>

    <motion.div  className='glow-flow standard-pad no-top no-btm'>
      <ParallaxGlowBox 
        glowColor="rgba(243, 91, 4, 0.25)" 
        bgColor="rgba(254, 254, 254, 00.25)"
        x="-10%"
        y="-10%"
      >
        <img className="w-33" src="/drawings/hyepartners-everyone_stressed_busy.webp" alt="Everyone is stressed and busy"></img>
        <h2 className='dkorange'>Everyone is too stressed and busy to do anything other than survive.</h2>
        
      </ParallaxGlowBox>
      </motion.div>

      <motion.div  className='glow-flow standard-pad no-top no-btm'>
      <ParallaxGlowBox 
        glowColor="rgba(243, 91, 4, 0.25)" 
        bgColor="rgba(254, 254, 254, 00.25)"
        x="-10%"
        y="-10%"
      >
        <h2 className='dkorange'>Shift in macroeconomics required everyone to take on more jobs, doing more with less.</h2>
        <img className="w-33" src="/drawings/hyepartners-everyone_do_more_with_less.webp" alt="Everyone has to do more with less, wear many hats"></img>
        
      </ParallaxGlowBox>
      </motion.div>

      <motion.div  className='glow-flow standard-pad no-top no-btm'>
      <ParallaxGlowBox 
        glowColor="rgba(235, 184, 68, 0.25)" 
        bgColor="rgba(254, 254, 254, 00.25)"
        x="-10%"
        y="-10%"
      >
        <img className="w-33" src="/drawings/hyepartners-thrive_with_superpowers.webp" alt="Teams can thrive by lightening their load with Artificial Intelligence"></img>
        <h2 className='ltorange'>It's time to lighten their load with Artificial Intelligence</h2>
        
      </ParallaxGlowBox>
      </motion.div>


      <motion.div  className='glow-flow no-top no-btm'>
      <ParallaxGlowBox 
        glowColor="rgba(235, 184, 68, 0.25)" 
        bgColor="rgba(254, 254, 254, 00.25)"
        x="-10%"
        y="-10%"
      >
        <h2 className='ltorange'>Automate monotonous tasks freeing up time for collaboration, creativity and solving hard challenges</h2>
        <img className="w-33" src="/drawings/hyepartners-uniquely_human_free_time_collaboration_creativity.webp" alt="Being Uniquely Human - free up time for collaboration, creativity and solving hard challenges"></img>
        
      </ParallaxGlowBox>
      </motion.div>

      <motion.div  className='glow-flow no-top no-btm'>
      <ParallaxGlowBox 
        glowColor="rgba(118, 30, 97, 0.25)" 
        bgColor="rgba(254, 254, 254, 00.25)"
        x="-10%"
        y="-10%"
      >
        <img className="w-33" src="/drawings/hyepartners-requires_change_ethos_not_brave_few.webp" alt="Change ethos for all, not just bold few"></img>
        <h2 className='standard-pad cranberry'>Requires a shift in ethos for everyone, not just the bold few</h2>
        
      </ParallaxGlowBox>
      </motion.div>

      <motion.div  className='glow-flow no-top no-btm'>
      <ParallaxGlowBox 
        glowColor="rgba(197, 49, 106, 00.25)" 
        bgColor="rgba(254, 254, 254, 00.25)"
        x="-10%"
        y="-10%"
      >
        <h2 className='magenta'>If every meeting began reviewing a first draft, no time wasted in meetings about meetings</h2>
        <img className="w-33" src="/drawings/hyepartners-every_meeting_has_a_rough_draft.webp" alt="Start with a first rough draft, not a meeting about more meetings"></img>
        
      </ParallaxGlowBox>
      </motion.div>

      <motion.div  className='glow-flow standard-pad no-top no-btm'>
      <ParallaxGlowBox 
        glowColor="rgba(197, 49, 106, 00.25)" 
        bgColor="rgba(254, 254, 254, 0.25)"
        x="-10%"
        y="-10%"
      >
        <img className="w-33" src="/drawings/hyepartners-every_happier_more_productive.webp" alt="Happier, more productive, with concrete proposals instead of hypotheticals"></img>
        <h2 className='magenta'>Everyone happier, more productive by starting with concrete proposals instead of vague hypotheticals</h2>
        
      </ParallaxGlowBox>
      </motion.div>

<ContactBanner />
<h3 className='standard-pad'>Time to 10x your team</h3>
<p className='standard-pad no-btm'>Ready to simplify your AI journey and maximize productivity? Contact us today to schedule a consultation or learn more about 
    how our AI consultancy can help you boost employee satisfaction along with productivity.</p>
<p className='standard-pad no-top'><a href="/contact">Request a meeting</a></p>

<p>
    &nbsp;
</p>
<p>
    &nbsp;
</p>
        </div>
    );
};

const Home = () => {
    return (
      <ParallaxProvider>
        <Homepage />
        <SEO
                title="HYE Partners Artificial Intellignce Consulting"
                description="Boost efficiency & drive innovation with AI. Focusing on Jobs to Be Done."
                url="/"
              />
      </ParallaxProvider>
    );
  };
  
  export default Home;