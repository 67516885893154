import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const FooterNav = ({ pages }) => {
  const navigate = useNavigate(); // Hook to manage redirection

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto', // optional for a smooth scroll
    });
  };

  const handleClick = (route) => {
    return () => {
      scrollToTop();
      navigate(route);
    };
  };

  return (
    <div className="footernav-container">
      <div className="footernav-link-container">
        {pages
          .filter(page => page.route !== "/auth")
          .map((page, index) => (
            <NavLink
              key={index}
              to={page.route}
              className="footernav-link"
              activeClassName="filter-active"
              onClick={handleClick(page.route.replace('/', ''))}
            >
              {page.name}
            </NavLink>
          ))}
      </div>

      <div className="footernav-copyright">
        <p className='white'>
          ©2024 HYE Partners, LLC.
        </p>
      </div>

      <div className="footernav-logo">
        <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
      </div>
    </div>
  );
};

export default FooterNav;
