import React from 'react';
// import ReactDOM from 'react-dom';
import '@mantine/core/styles.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);

// ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals(console.log);

// This code should be added at the top of your main entry file (e.g., index.js or App.js)
if (typeof window !== "undefined") {
    const resizeObserverLoopErr = () => {
      let resizeObserverErr;
      window.addEventListener("error", (e) => {
        if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
          resizeObserverErr = e;
          e.stopImmediatePropagation();
        }
      });
  
      const resizeObserverErrHandler = () => {
        if (resizeObserverErr) {
          resizeObserverErr.preventDefault();
          resizeObserverErr.stopImmediatePropagation();
        }
        requestAnimationFrame(resizeObserverErrHandler);
      };
  
      requestAnimationFrame(resizeObserverErrHandler);
    };
  
    resizeObserverLoopErr();
  }
  
// window.addEventListener('beforeunload', function() {
//     for (let key in localStorage) {
//         if (key !== 'authToken') {
//             localStorage.removeItem(key);
//         }
//     }
    
// });