import React from 'react';
import { RadioGroup, Radio, CheckboxGroup, Checkbox, TextInput, Slider } from '@mantine/core';

const SurveyQuestions = ({ question, response, onResponseChange }) => {
  const renderQuestion = () => {
    switch (question.type) {
      case 'single_choice':
        return (
          <RadioGroup value={response} onChange={(value) => onResponseChange(question.text, value)} className="custom-radio">
            {question.options.map((option) => (
              <div key={option} className="mantine-Radio-labelWrapper custom-radio">
                <Radio id={`${question.text}-${option}`} value={option} />
                <label className="mantine-Radio-label" htmlFor={`${question.text}-${option}`}>
                  {option}
                </label>
              </div>
            ))}
          </RadioGroup>
        );
      case 'multi_select':
        return (
          <CheckboxGroup value={response || []} onChange={(value) => onResponseChange(question.text, value)} className="custom-checkbox">
            {question.options.map((option) => (
              <div key={option} className="mantine-Radio-labelWrapper custom-radio">
                <Checkbox id={`${question.text}-${option}`} value={option} />
                <label className="mantine-Radio-label" htmlFor={`${question.text}-${option}`}>
                  {option}
                </label>
              </div>
            ))}
          </CheckboxGroup>
        );
      case 'text':
        return (
          <TextInput
            value={response || ''}
            onChange={(event) => onResponseChange(question.text, event.currentTarget.value)}
          />
        );
      case 'slider':
        return (
          <Slider
            value={response || 0}
            onChange={(value) => onResponseChange(question.text, value)}
            min={0}
            max={100}
            step={10}
          />
        );
      default:
        return null;
    }
  };

  console.log('Rendering question:', question);

  return (
    <div className="questionBlock">
      <h3>{question.text}</h3>
      <div>{renderQuestion()}</div>
    </div>
  );
};

export default SurveyQuestions;


// import React from 'react';
// import { RadioGroup, Radio, CheckboxGroup, Checkbox, TextInput, Slider } from '@mantine/core';

// const SurveyQuestions = ({ question, response, onResponseChange }) => {
//   const renderQuestion = () => {
//     switch (question.type) {
//       case 'single_choice':
//         return (
//           <RadioGroup value={response} onChange={(value) => onResponseChange(question.text, value)} className="custom-radio">
//             {question.options.map((option) => (
//               <div key={option} className="mantine-Radio-labelWrapper custom-radio">
//                 <Radio id={option} value={option} />
//                 <label className="mantine-Radio-label" htmlFor={option}>
//                   {option}
//                 </label>
//               </div>
//             ))}
//           </RadioGroup>
//         );
//       case 'multi_select':
//         return (
//           <CheckboxGroup value={response || []} onChange={(value) => onResponseChange(question.text, value)} className="custom-checkbox">
//             {question.options.map((option) => (
//               <div key={option} className="mantine-Radio-labelWrapper custom-radio">
//                 <Checkbox id={option} value={option} />
//                 <label className="mantine-Radio-label" htmlFor={option}>
//                   {option}
//                 </label>
//               </div>
//             ))}
//           </CheckboxGroup>
//         );
//       case 'text':
//         return (
//           <TextInput
//             value={response || ''}
//             onChange={(event) => onResponseChange(question.text, event.currentTarget.value)}
//           />
//         );
//       case 'slider':
//         return (
//           <Slider
//             value={response || 0}
//             onChange={(value) => onResponseChange(question.text, value)}
//             min={0}
//             max={100}
//             step={10}
//           />
//         );
//       default:
//         return null;
//     }
//   };

//   console.log('Rendering question:', question);

//   return (
//     <div className="questionBlock">
//       <h3>{question.text}</h3>
//       <div>{renderQuestion()}</div>
//     </div>
//   );
// };

// export default SurveyQuestions;


// // import React from 'react';
// // import { RadioGroup, Radio, CheckboxGroup, Checkbox, TextInput, Slider } from '@mantine/core';

// // const SurveyQuestions = ({ question, response, onResponseChange }) => {
// //   const renderQuestion = () => {
// //     switch (question.type) {
// //       case 'single_choice':
// //         return (
// //           <RadioGroup value={response} onChange={(value) => onResponseChange(question.text, value)} className="custom-radio">
// //             {question.options.map((option) => (
// //               <div key={option} className="mantine-Radio-labelWrapper custom-radio">
// //                 <Radio id={option} value={option} />
// //                 <label className="mantine-Radio-label" htmlFor={option}>
// //                   {option}
// //                 </label>
// //               </div>
// //             ))}
// //           </RadioGroup>
// //         );
// //       case 'multi_select':
// //         return (
// //           <CheckboxGroup value={response || []} onChange={(value) => onResponseChange(question.text, value)} className="custom-checkbox">
// //             {question.options.map((option) => (
// //               <div key={option} className="mantine-Radio-labelWrapper custom-radio">
// //                 <Checkbox id={option} value={option} />
// //                 <label className="mantine-Radio-label" htmlFor={option}>
// //                   {option}
// //                 </label>
// //               </div>
// //             ))}
// //           </CheckboxGroup>
// //         );
// //       case 'text':
// //         return (
// //           <TextInput
// //             value={response || ''}
// //             onChange={(event) => onResponseChange(question.text, event.currentTarget.value)}
// //           />
// //         );
// //       case 'slider':
// //         return (
// //           <Slider
// //             value={response || 0}
// //             onChange={(value) => onResponseChange(question.text, value)}
// //             min={0}
// //             max={100}
// //             step={10}
// //           />
// //         );
// //       default:
// //         return null;
// //     }
// //   };

// //   console.log('Rendering question:', question);

// //   return (
// //     <div className="questionBlock">
// //       <h3>{question.text}</h3>
// //       <div>{renderQuestion()}</div>
// //     </div>
// //   );
// // };

// // export default SurveyQuestions;



// // // import React from 'react';
// // // import { RadioGroup, Radio, CheckboxGroup, Checkbox, TextInput, Slider } from '@mantine/core';

// // // const SurveyQuestions = ({ question, response, onResponseChange }) => {
// // //   const renderQuestion = () => {
// // //     switch (question.type) {
// // //       case 'single_choice':
// // //         return (
// // //           <RadioGroup value={response} onChange={onResponseChange} className="custom-radio">
// // //             {question.options.map((option) => (
// // //               <div key={option} className="mantine-Radio-labelWrapper custom-radio">
// // //                 <Radio id={option} value={option} />
// // //                 <label className="mantine-Radio-label" htmlFor={option}>
// // //                   {option}
// // //                 </label>
// // //               </div>
// // //             ))}
// // //           </RadioGroup>
// // //         );
// // //       case 'multi_select':
// // //         return (
// // //           <CheckboxGroup value={response || []} onChange={onResponseChange} className="custom-checkbox">
// // //             {question.options.map((option) => (
// // //               <div key={option} className="mantine-Radio-labelWrapper custom-radio">
// // //                 <Checkbox id={option} value={option} />
// // //                 <label className="mantine-Radio-label" htmlFor={option}>
// // //                   {option}
// // //                 </label>
// // //               </div>
// // //             ))}
// // //           </CheckboxGroup>
// // //         );
// // //       case 'text':
// // //         return (
// // //           <TextInput
// // //             value={response || ''}
// // //             onChange={(event) => onResponseChange(event.currentTarget.value)}
// // //           />
// // //         );
// // //       case 'slider':
// // //         return (
// // //           <Slider
// // //             value={response || 0}
// // //             onChange={onResponseChange}
// // //             min={0}
// // //             max={100}
// // //             step={10}
// // //           />
// // //         );
// // //       default:
// // //         return null;
// // //     }
// // //   };

// // //   console.log('Rendering question:', question);

// // //   return (
// // //     <div className="questionBlock">
// // //       <h3>{question.text}</h3>
// // //       <div>{renderQuestion()}</div>
// // //     </div>
// // //   );
// // // };

// // // export default SurveyQuestions;


// // // // import React from 'react';
// // // // import { RadioGroup, Radio, CheckboxGroup, Checkbox, TextInput, Slider } from '@mantine/core';

// // // // const SurveyQuestions = ({ question, response, onResponseChange }) => {
// // // //   const renderQuestion = () => {
// // // //     switch (question.type) {
// // // //       case 'single_choice':
// // // //         return (
// // // //           <RadioGroup value={response} onChange={onResponseChange} className="custom-radio">
// // // //             {question.options.map((option) => (
// // // //               <div key={option} className="mantine-Radio-labelWrapper custom-radio">
// // // //                 <Radio id={option} value={option} />
// // // //                 <label className="mantine-Radio-label" htmlFor={option}>
// // // //                   {option}
// // // //                 </label>
// // // //               </div>
// // // //             ))}
// // // //           </RadioGroup>
// // // //         );
// // // //       case 'multiple_choice':
// // // //         return (
// // // //           <CheckboxGroup value={response} onChange={onResponseChange} className="custom-checkbox">
// // // //             {question.options.map((option) => (
// // // //               <div key={option} className="mantine-Radio-labelWrapper custom-radio">
// // // //                 <Checkbox id={option} value={option} />
// // // //                 <label className="mantine-Radio-label" htmlFor={option}>
// // // //                   {option}
// // // //                 </label>
// // // //               </div>
// // // //             ))}
// // // //           </CheckboxGroup>
// // // //         );
// // // //       case 'text':
// // // //         return (
// // // //           <TextInput
// // // //             value={response || ''}
// // // //             onChange={(event) => onResponseChange(event.currentTarget.value)}
// // // //           />
// // // //         );
// // // //       case 'slider':
// // // //         return (
// // // //           <Slider
// // // //             value={response || 0}
// // // //             onChange={onResponseChange}
// // // //             min={0}
// // // //             max={100}
// // // //             step={10}
// // // //           />
// // // //         );
// // // //       default:
// // // //         return null;
// // // //     }
// // // //   };

// // // //   console.log('Rendering question:', question);

// // // //   return (
// // // //     <div className="questionBlock">
// // // //       <h3>{question.text}</h3>
// // // //       <div>{renderQuestion()}</div>
// // // //     </div>
// // // //   );
// // // // };

// // // // export default SurveyQuestions;
