import React, { useState, useEffect } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import SanitizedInput from '../components/SanitizedInput';

const ContactUs = ({ jsonData, calendarRef, apiUrl }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    yourName: '',
    yourEmail: '',
  });
  const [formValid, setFormValid] = useState({
    companyName: false,
    yourName: false,
    yourEmail: false,
  });
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [calendarRef]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setFormValid({
      ...formValid,
      [name]: value.trim() !== '',
    });
  };

  const isFormValid = () => {
    return Object.values(formValid).every((valid) => valid);
  };

  const captureScreenshot = async () => {
    if (!calendarRef.current) return null;
    const canvas = await html2canvas(calendarRef.current);
    return canvas.toDataURL('image/png');
  };

  const capturePDF = async () => {
    if (!calendarRef.current) return null;
    const canvas = await html2canvas(calendarRef.current);
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF();
    pdf.addImage(imgData, 'PNG', 0, 0);
    return pdf.output('datauristring');
  };

  const sendEmail = async () => {
    const screenshot = await captureScreenshot();
    const pdf = await capturePDF();

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: formData.yourEmail,
        contactDetails: formData,
        jsonData,
        // screenshot,
        // pdf,
      }),
    });

    if (!response.ok) {
      console.error('Failed to send email');
    } else {
      console.log('Email sent successfully');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      sendEmail();
    } else {
      console.log('Form is not valid');
    }
  };

  const inputClassName = (field, valid) => {
    return valid ? 'input-valid' : 'input-invalid';
  };

  return (
    <div>
      <h2>Interested in turning this {jsonData.engagementType} into reality?</h2>
      <p>Fill out the form and together we'll design the perfect engagement.</p>
      <form onSubmit={handleSubmit}>
        <label className="form-label">Company Name</label>
        <SanitizedInput
          type="text"
          className={inputClassName('companyName', formValid.companyName)}
          name="companyName"
          value={formData.companyName}
          onChange={handleInputChange}
        />
        <label className="form-label">Your Name</label>
        <SanitizedInput
          className={inputClassName('yourName', formValid.yourName)}
          type="text"
          name="yourName"
          value={formData.yourName}
          onChange={handleInputChange}
        />
        <label className="form-label">Your Email</label>
        <SanitizedInput
          className={inputClassName('yourEmail', formValid.yourEmail)}
          type="text"
          name="yourEmail"
          value={formData.yourEmail}
          onChange={handleInputChange}
        />
        {/* <button className="form-button" type="submit" disabled={!isFormValid()}>
          Submit
        </button> */}
        <button
        className="form-button"
        type="submit"
        disabled={!isFormValid()}
        style={{
          backgroundColor: '#2c294b',
          color: '#ffffff', // Assuming you want white text on the button
        }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#3b3484'}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#2c294b'}
      >
        Submit
      </button>

      </form>
    </div>
  );
};

export default ContactUs;




// import React, { useState, useRef } from 'react';
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import SanitizedInput from '../components/SanitizedInput';

// const ContactUs = ({ jsonData, CalendarComponent, apiUrl }) => {
//   const [formData, setFormData] = useState({
//     companyName: '',
//     yourName: '',
//     yourEmail: '',
//   });
//   const [formValid, setFormValid] = useState({
//     companyName: false,
//     yourName: false,
//     yourEmail: false,
//   });
//   const calendarRef = useRef(null);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     setFormValid({
//       ...formValid,
//       [name]: value.trim() !== '',
//     });
//   };

//   const isFormValid = () => {
//     return Object.values(formValid).every((valid) => valid);
//   };

//   const captureScreenshot = async () => {
//     if (!calendarRef.current) return null;
//     const canvas = await html2canvas(calendarRef.current);
//     return canvas.toDataURL('image/png');
//   };

//   const capturePDF = async () => {
//     if (!calendarRef.current) return null;
//     const canvas = await html2canvas(calendarRef.current);
//     const imgData = canvas.toDataURL('image/png');
//     const pdf = new jsPDF();
//     pdf.addImage(imgData, 'PNG', 0, 0);
//     return pdf.output('datauristring');
//   };

//   const sendEmail = async () => {
//     const screenshot = await captureScreenshot();
//     const pdf = await capturePDF();

//     const response = await fetch(apiUrl, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         email: formData.yourEmail,
//         contactDetails: formData,
//         jsonData,
//         screenshot,
//         pdf,
//       }),
//     });

//     if (!response.ok) {
//       console.error('Failed to send email');
//     } else {
//       console.log('Email sent successfully');
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (isFormValid()) {
//       sendEmail();
//     } else {
//       console.log('Form is not valid');
//     }
//   };

//   const inputClassName = (field, valid) => {
//     return valid ? 'input-valid' : 'input-invalid';
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <label className="form-label">Company Name</label>
//         <SanitizedInput
//           type="text"
//           className={inputClassName('companyName', formValid.companyName)}
//           name="companyName"
//           value={formData.companyName}
//           onChange={handleInputChange}
//         />
//         <label className="form-label">Your Name</label>
//         <SanitizedInput
//           className={inputClassName('yourName', formValid.yourName)}
//           type="text"
//           name="yourName"
//           value={formData.yourName}
//           onChange={handleInputChange}
//         />
//         <label className="form-label">Your Email</label>
//         <SanitizedInput
//           className={inputClassName('yourEmail', formValid.yourEmail)}
//           type="text"
//           name="yourEmail"
//           value={formData.yourEmail}
//           onChange={handleInputChange}
//         />
//         <button className="form-button" type="submit" disabled={!isFormValid()}>
//           Submit
//         </button>
//       </form>
//       <div ref={calendarRef}>
//         <CalendarComponent suggestions={jsonData.suggestions} />
//       </div>
//     </div>
//   );
// };

// export default ContactUs;


// // import React, { useState, useRef } from 'react';
// // import html2canvas from 'html2canvas';
// // import jsPDF from 'jspdf';
// // import SanitizedInput from '../components/SanitizedInput';
// // import SanitizedText from '../components/SanitizedText';
// // import axios from 'axios';


// // const ContactUs = ({ jsonData, CalendarComponent, apiUrl }) => {
// //   const [formData, setFormData] = useState({
// //     companyName: '',
// //     yourName: '',
// //     yourEmail: '',
// //   });
// //   const [formValid, setFormValid] = useState({
// //     companyName: false,
// //     yourName: false,
// //     yourEmail: false,
// //   });
// //   const calendarRef = useRef(null);

// //   const handleInputChange = (e) => {
// //     const { name, value } = e.target;
// //     setFormData({
// //       ...formData,
// //       [name]: value,
// //     });
// //     setFormValid({
// //       ...formValid,
// //       [name]: value.trim() !== '',
// //     });
// //   };

// //   const isFormValid = () => {
// //     return Object.values(formValid).every((valid) => valid);
// //   };

// //   const captureScreenshot = async () => {
// //     if (!calendarRef.current) return null;
// //     const canvas = await html2canvas(calendarRef.current);
// //     return canvas.toDataURL('image/png');
// //   };

// //   const capturePDF = async () => {
// //     if (!calendarRef.current) return null;
// //     const canvas = await html2canvas(calendarRef.current);
// //     const imgData = canvas.toDataURL('image/png');
// //     const pdf = new jsPDF();
// //     pdf.addImage(imgData, 'PNG', 0, 0);
// //     return pdf.output('datauristring');
// //   };

// //   const sendEmail = async () => {
// //     const screenshot = await captureScreenshot();
// //     const pdf = await capturePDF();

// //     const response = await fetch(apiUrl, {
// //       method: 'POST',
// //       headers: {
// //         'Content-Type': 'application/json',
// //       },
// //       body: JSON.stringify({
// //         email: formData.yourEmail,
// //         contactDetails: formData,
// //         jsonData,
// //         screenshot,
// //         pdf,
// //       }),
// //     });

// //     if (!response.ok) {
// //       console.error('Failed to send email');
// //     } else {
// //       console.log('Email sent successfully');
// //     }
// //   };

// //   const handleSubmit = (e) => {
// //     e.preventDefault();
// //     if (isFormValid()) {
// //       sendEmail();
// //     } else {
// //       console.log('Form is not valid');
// //     }
// //   };

// //   const inputClassName = (field, valid) => {
// //     return valid ? 'input-valid' : 'input-invalid';
// //   };

// //   return (
// //     <div>
// //       <form onSubmit={handleSubmit}>
// //         <label className="form-label">Company Name</label>
// //         <SanitizedInput
// //           type="text"
// //           className={inputClassName('companyName', formValid.companyName)}
// //           name="companyName"
// //           value={formData.companyName}
// //           onChange={handleInputChange}
// //         />
// //         <label className="form-label">Your Name</label>
// //         <SanitizedInput
// //           className={inputClassName('yourName', formValid.yourName)}
// //           type="text"
// //           name="yourName"
// //           value={formData.yourName}
// //           onChange={handleInputChange}
// //         />
// //         <label className="form-label">Your Email</label>
// //         <SanitizedInput
// //           className={inputClassName('yourEmail', formValid.yourEmail)}
// //           type="text"
// //           name="yourEmail"
// //           value={formData.yourEmail}
// //           onChange={handleInputChange}
// //         />
// //         <button className="form-button" type="submit" disabled={!isFormValid()}>
// //           Submit
// //         </button>
// //       </form>
// //       <div ref={calendarRef}>
// //         <CalendarComponent suggestions={jsonData.suggestions} onMonthlyView={() => {}} />
// //       </div>
// //     </div>
// //   );
// // };

// // export default ContactUs;
