import React from 'react';
import { ParallaxProvider, ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import GlowBox from '../components/GlowBox';
import { motion, useInView } from 'framer-motion';
import SEO from '../components/SEO';


const HeroBanner = () => {
    return (
      <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-man_works_intently.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Pricing</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


const LevelOneBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-new_tools_at_fingertips.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">AI In Their Cloud</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

  const LevelTwoBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-purple_strobe_of_AI_information.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">AI Running Locally</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };
  const LevelThreeBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-vast_computing_resources.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">AI In Your Cloud</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };

  const ContactBanner = () => {
    return (
        <ParallaxBanner style={{ aspectRatio: '2 / 1' , height: '500px' }} className="parallax-hero">
        <ParallaxBannerLayer
          image="/images/hyepartners-bot_helping_work.webp"
          speed={-20}
          expanded={true}
          className="parallax-banner-layer"
        />
        <ParallaxBannerLayer speed={5}>
          <div className="parallax-hero-text-div">
            <h1 className="parallax-hero-text">Designing Perfect Solutions</h1>
          </div>
        </ParallaxBannerLayer>
      </ParallaxBanner>
    );
  };


  const fadeInOut = (x, y) => ({
    hidden: { opacity: 0, x, y },
    visible: {
      opacity: 1,
      x: '0%',
      y: '0%',
      transition: { duration: 0.5, ease: 'easeInOut' }
    },
    exit: { opacity: 0, x: -x, y: -y, transition: { duration: 0.5, ease: 'easeInOut' } }
  });
  
  const ParallaxBox = ({ children, x, y, className }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
        className={className}
      >
        {children}
      </motion.div>
    );
  };
  

  const ParallaxGlowBox = ({ children, glowColor, bgColor, x, y }) => {
    const ref = React.useRef();
    const isInView = useInView(ref, { amount: 0.5 });
  
    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        exit="exit"
        variants={fadeInOut(x, y)}
      >
        <GlowBox glowColor={glowColor} bgColor={bgColor}>
          {children}
        </GlowBox>
      </motion.div>
    );
  };

const Solutions = () => {
    return (
        <div className='homepage'>
        <HeroBanner />
            <h1 className='standard-pad'>Pricing to support any company size</h1>
            {/* <h3 className='standard-pad'>Not a one-sized-fits-all recommendation for all businesses</h3> */}
        {/* <p className='standard-pad'>
        At HYE Partners, we help companies implement a range of AI solutions tailored to meet the unique needs of your business. 
        These solutions are designed to enhance productivity, streamline workflows, and ensure the security and confidentiality of your data. 
        </p> */}
    
        <div className="twobytwo">
        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="10%"
        y="10%"
        >
        <h4>Assessment Survey + Maturity Report</h4> 
        <ul>
        <li>Benchmark your team's confidence to chart the course for future learning.</li>
        <li>$500-$1,500</li>
        </ul>
        </ParallaxGlowBox>
        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="-10%"
        y="10%"
        >
        <h4>Training Course</h4>
        <ul>
        <li>Two or four-hour course to jump start innovative thinking.</li>
        <li>$2,000-$3,500</li>
        </ul>
        </ParallaxGlowBox>
        {/* </div>

        <div className="twobytwo"> */}
        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="10%"
        y="10%"
        >
        <h4>Team Building Workshop & Hackathon</h4> 
        <ul>
        <li>Upskill your team in just one week. Workshops virtually or in person.</li>
        <li>$8,000-$12,000</li>
        </ul>
        </ParallaxGlowBox>
        <ParallaxGlowBox 
        glowColor="rgba(130, 2, 99, 0.125)" 
        bgColor="bg-ltgray"
        x="-10%"
        y="10%"
        >
        <h4>Long-term Consulting</h4>
        <ul>
        <li>Reorient business trajectory with one to three-month consulting and change management.</li>
        <li>$15,000-$25,000</li>
        </ul>
        </ParallaxGlowBox>
        </div>

<ContactBanner />
<h3 className='standard-pad'>Choosing the Right Solution</h3>
<p className='standard-pad'>HYE Partners understands that each business has unique needs and priorities. 
We help you choose the AI solutions to best fits your organization's requirements. Whether you opt for third-party tools, open-source technology, or a centrally managed cloud-hosted LLM, we are here to guide you every step of the way.
</p>
<p className='standard-pad no-top'><a href="/contact">Contact us today to learn more</a> about how we accelerate the power of AI to surpass your goals.</p>

<p>
    &nbsp;
</p>
<p>
    &nbsp;
</p>
        </div>
    );
};

const Solute = () => {
    return (
      <ParallaxProvider>
        <Solutions />
        <SEO
                title="How to use AI"
                description="Refine your approach to working with AI, whether it's local, in the cloud, or your own servers."
                url="/pricing"
              />
      </ParallaxProvider>
    );
  };
  
  export default Solute;