import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

const NavHeader = ({ pages }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  const handleClick = (route) => () => {
    scrollToTop();
    navigate(route);
    setIsOpen(false); // Close the menu on link click
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navheader-container">
      <div className="navheader-logo">
        <img src="/Logo-HYEPartners-Long_White.png" alt="logo" />
      </div>
      <div className="navheader-link-container">
        {pages
          .filter(page => page.route !== "/auth")
          .map((page, index) => (
            <NavLink
              key={index}
              to={page.route}
              className="navheader-link"
              activeClassName="filter-active"
              onClick={handleClick(page.route.replace('/', ''))}
            >
              {page.name}
            </NavLink>
          ))}
      </div>
      <div className="hamburger-container md:hidden">
        <button
          className="hamburger-button"
          onClick={toggleMenu}
        >
          <span className="hamburger-icon">&#9776;</span>
        </button>
      </div>
      {isOpen && (
        <div className="navheader-link-container-mobile">
          {pages
            .filter(page => page.route !== "/auth")
            .map((page, index) => (
              <NavLink
                key={index}
                to={page.route}
                className="navheader-link-mobile"
                activeClassName="filter-active"
                onClick={handleClick(page.route.replace('/', ''))}
              >
                {page.name}
              </NavLink>
            ))}
        </div>
      )}
    </div>
  );
};

export default NavHeader;








// import React, { useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';

// const NavHeader = ({ pages, onNavigate }) => {
//   const navigate = useNavigate(); // Hook to manage redirection

//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'auto', // optional for a smooth scroll
//     });
//   };
  
//   const handleClick = (route) => {
//     return () => {
//       scrollToTop();
//       navigate(route);
//     };
//   };
  
//     return (
//     <div className="navheader-container">
//       <div className="navheader-logo">
//         <img src="/Logo-HYEPartners-Long_White.png" alt="logo"></img>
//       </div>
//     <div className="navheader-link-container">
//       {pages
//           .filter(page => page.route !== "/auth")
//           .map((page, index) => (
//             <NavLink 
//             key={index}
//             to={page.route}
//             className="navheader-link"
//             activeClassName="filter-active"
//             onClick={handleClick(page.route.replace('/', ''))}
//           >
//             {page.name}
//           </NavLink>
//       ))}
//       </div>
//     </div>
//   );
// };

// export default NavHeader;
