import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const SurveyResultsMulti = ({ questionData, keys, colors, average, averageColor, surveyQuestions }) => {
  console.log('Rendering SurveyResultsMulti with data:', { questionData, keys, colors, average, averageColor });

  const question = surveyQuestions.find(q => q.text === questionData.question);

  const negativeResults = question.options
    .map((option, index) => ({
      id: `${index + 1} - ${option}`,
      value: 1,
    }))
    .filter(option => !keys.includes(option.id));

  const negativeData = negativeResults.reduce((acc, item) => {
    acc[item.id] = item.value;
    return acc;
  }, {});

  const negativeColors = negativeResults.map(() => '#cccccc');

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '2.5% 0' }}>
      <div style={{ width: '48%', height: 450 }}>
        <h6>{questionData.question}</h6>
        <ResponsiveBar
          data={[questionData]}
          keys={keys}
          indexBy="question"
          margin={{ top: 50, right: 120, bottom: 50, left: 80 }} // Increased right margin
          innerPadding={10}
          layout="horizontal"
          groupMode="grouped"
          colors={colors}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Response',
            legendPosition: 'middle',
            legendOffset: 32,
            tickValues: 'every 1',
            format: value => Number.isInteger(value) ? value : '',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Count',
            legendPosition: 'middle',
            legendOffset: -40,
            tickValues: 'every 1',
            format: value => Number.isInteger(value) ? value : '',

          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="white"
          tooltip={({ id, value, color }) => (
            <div
              style={{
                padding: '12px',
                color, // Set hover text color to dark purple
                background: 'white',
                border: `1px solid ${color}`,
                borderRadius: '4px',
              }}
            >
              <strong>{id.split(' - ')[1]} (Count: {value})</strong>
            </div>
          )}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 110,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
      {negativeResults.length > 0 && (
        <div style={{ width: '48%', height: 450 }}>
          <h6><br/>Team lacks experience with:</h6>
          <ResponsiveBar
            data={[negativeData]}
            keys={negativeResults.map(item => item.id)}
            indexBy="question"
            margin={{ top: 50, right: 120, bottom: 50, left: 80 }} // Increased right margin
            innerPadding={10}
            layout="horizontal"
            groupMode="grouped"
            colors={negativeColors}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Response',
              legendPosition: 'middle',
              legendOffset: 32,
              tickValues: 'every 1',
              format: value => Number.isInteger(value) ? value : '',
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: 'Count',
              legendPosition: 'middle',
              legendOffset: -40,
              tickValues: 'every 1',
              format: value => Number.isInteger(value) ? value : '',
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor="white"
            tooltip={({ id, value, color }) => (
              <div
                style={{
                  padding: '12px',
                  color: '#2c294b', // Set hover text color to dark purple
                  background: 'white',
                  border: `1px solid ${color}`,
                  borderRadius: '4px',
                }}
              >
                <strong>{id.split(' - ')[1]} (Count: {value})</strong>
              </div>
            )}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 110,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      )}
    </div>
  );
};

export default SurveyResultsMulti;




// import React from 'react';
// import { ResponsiveBar } from '@nivo/bar';

// const SurveyResultsMulti = ({ questionData, keys, colors, average, averageColor, surveyQuestions }) => {
//   console.log('Rendering SurveyResultsMulti with data:', { questionData, keys, colors, average, averageColor });

//   const question = surveyQuestions.find(q => q.text === questionData.question);

//   const negativeResults = question.options
//     .map((option, index) => ({
//       id: `${index + 1} - ${option}`,
//       value: 1,
//     }))
//     .filter(option => !keys.includes(option.id));

//   const negativeData = negativeResults.reduce((acc, item) => {
//     acc[item.id] = item.value;
//     return acc;
//   }, {});

//   const negativeColors = negativeResults.map(() => '#cccccc');

//   return (
//     <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '2.5% 0' }}>
//       <div style={{ width: '48%', height: 450 }}>
//         <h6>{questionData.question}</h6>
//         <ResponsiveBar
//           data={[questionData]}
//           keys={keys}
//           indexBy="question"
//           margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
//           innerPadding={10}
//           layout="horizontal"
//           groupMode="grouped"
//           colors={colors}
//           borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
//           axisTop={null}
//           axisRight={null}
//           axisBottom={{
//             tickSize: 5,
//             tickPadding: 5,
//             tickRotation: 0,
//             legend: 'Response',
//             legendPosition: 'middle',
//             legendOffset: 32,
//           }}
//           axisLeft={{
//             tickSize: 5,
//             tickPadding: 5,
//             tickRotation: 0,
//             legend: 'Count',
//             legendPosition: 'middle',
//             legendOffset: -40,
//             format: value => Number.isInteger(value) ? value : '',
//           }}
//           labelSkipWidth={12}
//           labelSkipHeight={12}
//           labelTextColor="white"
//           tooltip={({ id, value, color }) => (
//             <div
//               style={{
//                 padding: '12px',
//                 color, // Set hover text color to dark purple
//                 background: 'white',
//                 border: `1px solid ${color}`,
//                 borderRadius: '4px',
//               }}
//             >
//               <strong>{id.split(' - ')[1]} (Count: {value})</strong>
//             </div>
//           )}
//           legends={[
//             {
//               dataFrom: 'keys',
//               anchor: 'bottom-right',
//               direction: 'column',
//               justify: false,
//               translateX: 120,
//               translateY: 0,
//               itemsSpacing: 2,
//               itemWidth: 100,
//               itemHeight: 20,
//               itemDirection: 'left-to-right',
//               itemOpacity: 0.85,
//               symbolSize: 20,
//               effects: [
//                 {
//                   on: 'hover',
//                   style: {
//                     itemOpacity: 1,
//                   },
//                 },
//               ],
//             },
//           ]}
//           animate={true}
//           motionStiffness={90}
//           motionDamping={15}
//         />
//       </div>
//       {negativeResults.length > 0 && (
//         <div style={{ width: '48%', height: 450 }}>
//           <h6><br/>Team lacks experience with:</h6>
//           <ResponsiveBar
//             data={[negativeData]}
//             keys={negativeResults.map(item => item.id)}
//             indexBy="question"
//             margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
//             innerPadding={10}
//             layout="horizontal"
//             groupMode="grouped"
//             colors={negativeColors}
//             borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
//             axisTop={null}
//             axisRight={null}
//             axisBottom={{
//               tickSize: 5,
//               tickPadding: 5,
//               tickRotation: 0,
//               legend: 'Response',
//               legendPosition: 'middle',
//               legendOffset: 32,
//             }}
//             axisLeft={{
//               tickSize: 5,
//               tickPadding: 5,
//               tickRotation: 0,
//               legend: 'Count',
//               legendPosition: 'middle',
//               legendOffset: -40,
//               format: value => Number.isInteger(value) ? value : '',
//             }}
//             labelSkipWidth={12}
//             labelSkipHeight={12}
//             labelTextColor="white"
//             tooltip={({ id, value, color }) => (
//               <div
//                 style={{
//                   padding: '12px',
//                   color: '#2c294b', // Set hover text color to dark purple
//                   background: 'white',
//                   border: `1px solid ${color}`,
//                   borderRadius: '4px',
//                 }}
//               >
//                 <strong>{id.split(' - ')[1]} (Count: {value})</strong>
//               </div>
//             )}
//             legends={[
//               {
//                 dataFrom: 'keys',
//                 anchor: 'bottom-right',
//                 direction: 'column',
//                 justify: false,
//                 translateX: 120,
//                 translateY: 0,
//                 itemsSpacing: 2,
//                 itemWidth: 110,
//                 itemHeight: 20,
//                 itemDirection: 'left-to-right',
//                 itemOpacity: 0.85,
//                 symbolSize: 20,
//                 effects: [
//                   {
//                     on: 'hover',
//                     style: {
//                       itemOpacity: 1,
//                     },
//                   },
//                 ],
//               },
//             ]}
//             animate={true}
//             motionStiffness={90}
//             motionDamping={15}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default SurveyResultsMulti;



// // import React from 'react';
// // import { ResponsiveBar } from '@nivo/bar';

// // const SurveyResultsMulti = ({ questionData, keys, colors, average, averageColor, surveyQuestions }) => {
// //   console.log('Rendering SurveyResultsMulti with data:', { questionData, keys, colors, average, averageColor });

// //   const question = surveyQuestions.find(q => q.text === questionData.question);

// //   const negativeResults = question.options
// //     .map((option, index) => ({
// //       id: `${index + 1} - ${option}`,
// //       value: 1,
// //     }))
// //     .filter(option => !keys.includes(option.id));

// //   const negativeData = negativeResults.reduce((acc, item) => {
// //     acc[item.id] = item.value;
// //     return acc;
// //   }, {});

// //   const negativeColors = negativeResults.map(() => '#cccccc');

// //   return (
// //     <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '2.5% 0' }}>
// //       <div style={{ width: '48%', height: 450 }}>
// //         <h6>{questionData.question}</h6>
// //         <ResponsiveBar
// //           data={[questionData]}
// //           keys={keys}
// //           indexBy="question"
// //           margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
// //           innerPadding={10}
// //           layout="horizontal"
// //           groupMode="grouped"
// //           colors={colors}
// //           borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// //           axisTop={null}
// //           axisRight={null}
// //           axisBottom={{
// //             tickSize: 5,
// //             tickPadding: 5,
// //             tickRotation: 0,
// //             legend: 'Response',
// //             legendPosition: 'middle',
// //             legendOffset: 32,
// //           }}
// //           axisLeft={{
// //             tickSize: 5,
// //             tickPadding: 5,
// //             tickRotation: 0,
// //             legend: 'Count',
// //             legendPosition: 'middle',
// //             legendOffset: -40,
// //             format: value => Number.isInteger(value) ? value : '',
// //           }}
// //           labelSkipWidth={12}
// //           labelSkipHeight={12}
// //           labelTextColor="white"
// //           tooltip={({ id, value, color }) => (
// //             <div
// //               style={{
// //                 padding: '12px',
// //                 color, // Set hover text color to dark purple
// //                 background: 'white',
// //                 border: `1px solid ${color}`,
// //                 borderRadius: '4px',
// //               }}
// //             >
// //               <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// //             </div>
// //           )}
// //           legends={[
// //             {
// //               dataFrom: 'keys',
// //               anchor: 'bottom-right',
// //               direction: 'column',
// //               justify: false,
// //               translateX: 120,
// //               translateY: 0,
// //               itemsSpacing: 2,
// //               itemWidth: 100,
// //               itemHeight: 20,
// //               itemDirection: 'left-to-right',
// //               itemOpacity: 0.85,
// //               symbolSize: 20,
// //               effects: [
// //                 {
// //                   on: 'hover',
// //                   style: {
// //                     itemOpacity: 1,
// //                   },
// //                 },
// //               ],
// //             },
// //           ]}
// //           animate={true}
// //           motionStiffness={90}
// //           motionDamping={15}
// //         />
// //       </div>
// //       {negativeResults.length > 0 && (
// //         <div style={{ width: '48%', height: 450 }}>
// //           <h6><br/>Team lacks experience with:</h6>
// //           <ResponsiveBar
// //             data={[negativeData]}
// //             keys={negativeResults.map(item => item.id)}
// //             indexBy="question"
// //             margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
// //             innerPadding={10}
// //             layout="horizontal"
// //             groupMode="grouped"
// //             colors={negativeColors}
// //             borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// //             axisTop={null}
// //             axisRight={null}
// //             axisBottom={{
// //               tickSize: 5,
// //               tickPadding: 5,
// //               tickRotation: 0,
// //               legend: 'Response',
// //               legendPosition: 'middle',
// //               legendOffset: 32,
// //             }}
// //             axisLeft={{
// //               tickSize: 5,
// //               tickPadding: 5,
// //               tickRotation: 0,
// //               legend: 'Count',
// //               legendPosition: 'middle',
// //               legendOffset: -40,
// //               format: value => Number.isInteger(value) ? value : '',
// //             }}
// //             labelSkipWidth={12}
// //             labelSkipHeight={12}
// //             labelTextColor="white"
// //             tooltip={({ id, value, color }) => (
// //               <div
// //                 style={{
// //                   padding: '12px',
// //                   color: '#2c294b', // Set hover text color to dark purple
// //                   background: 'white',
// //                   border: `1px solid ${color}`,
// //                   borderRadius: '4px',
// //                 }}
// //               >
// //                 <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// //               </div>
// //             )}
// //             legends={[
// //               {
// //                 dataFrom: 'keys',
// //                 anchor: 'bottom-right',
// //                 direction: 'column',
// //                 justify: false,
// //                 translateX: 120,
// //                 translateY: 0,
// //                 itemsSpacing: 2,
// //                 itemWidth: 100,
// //                 itemHeight: 20,
// //                 itemDirection: 'left-to-right',
// //                 itemOpacity: 0.85,
// //                 symbolSize: 20,
// //                 effects: [
// //                   {
// //                     on: 'hover',
// //                     style: {
// //                       itemOpacity: 1,
// //                     },
// //                   },
// //                 ],
// //               },
// //             ]}
// //             animate={true}
// //             motionStiffness={90}
// //             motionDamping={15}
// //           />
// //         </div>
// //       )}
// //     </div>
// //   );
// // };

// // export default SurveyResultsMulti;




// // // import React from 'react';
// // // import { ResponsiveBar } from '@nivo/bar';

// // // const SurveyResultsMulti = ({ questionData, keys, colors, average, averageColor, surveyQuestions }) => {
// // //   console.log('Rendering SurveyResultsMulti with data:', { questionData, keys, colors, average, averageColor });

// // //   const question = surveyQuestions.find(q => q.text === questionData.question);

// // //   const negativeResults = question.options
// // //     .map((option, index) => ({
// // //       id: `${index + 1} - ${option}`,
// // //       value: 1,
// // //     }))
// // //     .filter(option => !keys.includes(option.id));

// // //   const negativeData = negativeResults.reduce((acc, item) => {
// // //     acc[item.id] = item.value;
// // //     return acc;
// // //   }, {});

// // //   const negativeColors = negativeResults.map(() => '#cccccc');

// // //   return (
// // //     <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '2.5% 0' }}>
// // //       <div style={{ width: '48%', height: 450 }}>
// // //         <h6>{questionData.question}</h6>
// // //         <ResponsiveBar
// // //           data={[questionData]}
// // //           keys={keys}
// // //           indexBy="question"
// // //           margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
// // //           innerPadding={10}
// // //           layout="vertical"
// // //           groupMode="grouped"
// // //           colors={colors}
// // //           borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// // //           axisTop={null}
// // //           axisRight={null}
// // //           axisBottom={{
// // //             tickSize: 5,
// // //             tickPadding: 5,
// // //             tickRotation: 0,
// // //             legend: 'Response',
// // //             legendPosition: 'middle',
// // //             legendOffset: 32,
// // //           }}
// // //           axisLeft={{
// // //             tickSize: 5,
// // //             tickPadding: 5,
// // //             tickRotation: 0,
// // //             legend: 'Count',
// // //             legendPosition: 'middle',
// // //             legendOffset: -40,
// // //             format: value => Number.isInteger(value) ? value : '',
// // //           }}
// // //           labelSkipWidth={12}
// // //           labelSkipHeight={12}
// // //           labelTextColor="white"
// // //           tooltip={({ id, value, color }) => (
// // //             <div
// // //               style={{
// // //                 padding: '12px',
// // //                 color,
// // //                 background: 'white',
// // //                 border: `1px solid ${color}`,
// // //                 borderRadius: '4px',
// // //               }}
// // //             >
// // //               <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// // //             </div>
// // //           )}
// // //           legends={[
// // //             {
// // //               dataFrom: 'keys',
// // //               anchor: 'bottom-right',
// // //               direction: 'column',
// // //               justify: false,
// // //               translateX: 120,
// // //               translateY: 0,
// // //               itemsSpacing: 2,
// // //               itemWidth: 100,
// // //               itemHeight: 20,
// // //               itemDirection: 'left-to-right',
// // //               itemOpacity: 0.85,
// // //               symbolSize: 20,
// // //               effects: [
// // //                 {
// // //                   on: 'hover',
// // //                   style: {
// // //                     itemOpacity: 1,
// // //                   },
// // //                 },
// // //               ],
// // //             },
// // //           ]}
// // //           animate={true}
// // //           motionStiffness={90}
// // //           motionDamping={15}
// // //         />
// // //       </div>
// // //       {negativeResults.length > 0 && (
// // //         <div style={{ width: '48%', height: 450 }}>
// // //           <h6><br/>Team lacks experience with:</h6>
// // //           <ResponsiveBar
// // //             data={[negativeData]}
// // //             keys={negativeResults.map(item => item.id)}
// // //             indexBy="question"
// // //             margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
// // //             innerPadding={10}
// // //             layout="horizontal"
// // //             groupMode="grouped"
// // //             colors={negativeColors}
// // //             borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// // //             axisTop={null}
// // //             axisRight={null}
// // //             axisBottom={{
// // //               tickSize: 5,
// // //               tickPadding: 5,
// // //               tickRotation: 0,
// // //               legend: 'Response',
// // //               legendPosition: 'middle',
// // //               legendOffset: 32,
// // //             }}
// // //             axisLeft={{
// // //               tickSize: 5,
// // //               tickPadding: 5,
// // //               tickRotation: 0,
// // //               legend: 'Count',
// // //               legendPosition: 'middle',
// // //               legendOffset: -40,
// // //               format: value => Number.isInteger(value) ? value : '',
// // //             }}
// // //             labelSkipWidth={12}
// // //             labelSkipHeight={12}
// // //             labelTextColor="white"
// // //             tooltip={({ id, value, color }) => (
// // //               <div
// // //                 style={{
// // //                   padding: '12px',
// // //                   color: '#2c294b', // Set hover text color to dark purple
// // //                   background: 'white',
// // //                   border: `1px solid ${color}`,
// // //                   borderRadius: '4px',
// // //                 }}
// // //               >
// // //                 <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// // //               </div>
// // //             )}
// // //             legends={[
// // //               {
// // //                 dataFrom: 'keys',
// // //                 anchor: 'bottom-right',
// // //                 direction: 'column',
// // //                 justify: false,
// // //                 translateX: 120,
// // //                 translateY: 0,
// // //                 itemsSpacing: 2,
// // //                 itemWidth: 100,
// // //                 itemHeight: 20,
// // //                 itemDirection: 'left-to-right',
// // //                 itemOpacity: 0.85,
// // //                 symbolSize: 20,
// // //                 effects: [
// // //                   {
// // //                     on: 'hover',
// // //                     style: {
// // //                       itemOpacity: 1,
// // //                     },
// // //                   },
// // //                 ],
// // //               },
// // //             ]}
// // //             animate={true}
// // //             motionStiffness={90}
// // //             motionDamping={15}
// // //           />
// // //         </div>
// // //       )}
// // //     </div>
// // //   );
// // // };

// // // export default SurveyResultsMulti;


// // // // import React from 'react';
// // // // import { ResponsiveBar } from '@nivo/bar';

// // // // const SurveyResultsMulti = ({ questionData, keys, colors, average, averageColor, surveyQuestions }) => {
// // // //   console.log('Rendering SurveyResultsMulti with data:', { questionData, keys, colors, average, averageColor });

// // // //   const question = surveyQuestions.find(q => q.text === questionData.question);

// // // //   const negativeResults = question.options
// // // //     .map((option, index) => ({
// // // //       id: `${index + 1} - ${option}`,
// // // //       value: 1,
// // // //     }))
// // // //     .filter(option => !keys.includes(option.id));

// // // //   const negativeData = negativeResults.reduce((acc, item) => {
// // // //     acc[item.id] = item.value;
// // // //     return acc;
// // // //   }, {});

// // // //   const negativeColors = negativeResults.map(() => '#cccccc');

// // // //   return (
// // // //     <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', margin: '2.5% 0' }}>
// // // //       <div style={{ width: '48%', height: 450 }}>
// // // //         <h6>{questionData.question}</h6>
// // // //         <ResponsiveBar
// // // //           data={[questionData]}
// // // //           keys={keys}
// // // //           indexBy="question"
// // // //           margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
// // // //           innerPadding={10}
// // // //           groupMode="grouped"
// // // //           colors={colors}
// // // //           borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// // // //           axisTop={null}
// // // //           axisRight={null}
// // // //           axisBottom={{
// // // //             tickSize: 5,
// // // //             tickPadding: 5,
// // // //             tickRotation: 0,
// // // //             legend: 'Response',
// // // //             legendPosition: 'middle',
// // // //             legendOffset: 32,
// // // //           }}
// // // //           axisLeft={{
// // // //             tickSize: 5,
// // // //             tickPadding: 5,
// // // //             tickRotation: 0,
// // // //             legend: 'Count',
// // // //             legendPosition: 'middle',
// // // //             legendOffset: -40,
// // // //             format: value => Number.isInteger(value) ? value : '',
// // // //           }}
// // // //           labelSkipWidth={12}
// // // //           labelSkipHeight={12}
// // // //           labelTextColor="white"
// // // //           tooltip={({ id, value, color }) => (
// // // //             <div
// // // //               style={{
// // // //                 padding: '12px',
// // // //                 color,
// // // //                 background: 'white',
// // // //                 border: `1px solid ${color}`,
// // // //                 borderRadius: '4px',
// // // //               }}
// // // //             >
// // // //               <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// // // //             </div>
// // // //           )}
// // // //           legends={[
// // // //             {
// // // //               dataFrom: 'keys',
// // // //               anchor: 'bottom-right',
// // // //               direction: 'column',
// // // //               justify: false,
// // // //               translateX: 120,
// // // //               translateY: 0,
// // // //               itemsSpacing: 2,
// // // //               itemWidth: 100,
// // // //               itemHeight: 20,
// // // //               itemDirection: 'left-to-right',
// // // //               itemOpacity: 0.85,
// // // //               symbolSize: 20,
// // // //               effects: [
// // // //                 {
// // // //                   on: 'hover',
// // // //                   style: {
// // // //                     itemOpacity: 1,
// // // //                   },
// // // //                 },
// // // //               ],
// // // //             },
// // // //           ]}
// // // //           animate={true}
// // // //           motionStiffness={90}
// // // //           motionDamping={15}
// // // //         />
// // // //       </div>
// // // //       {negativeResults.length > 0 && (
// // // //         <div style={{ width: '48%', height: 450 }}>
// // // //           <h6><br/>Team lacks experience with:</h6>
// // // //           <ResponsiveBar
// // // //             data={[negativeData]}
// // // //             keys={negativeResults.map(item => item.id)}
// // // //             indexBy="question"
// // // //             margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
// // // //             innerPadding={10}
// // // //             groupMode="grouped"
// // // //             colors={negativeColors}
// // // //             borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// // // //             axisTop={null}
// // // //             axisRight={null}
// // // //             axisBottom={{
// // // //               tickSize: 5,
// // // //               tickPadding: 5,
// // // //               tickRotation: 0,
// // // //               legend: 'Response',
// // // //               legendPosition: 'middle',
// // // //               legendOffset: 32,
// // // //             }}
// // // //             axisLeft={{
// // // //               tickSize: 5,
// // // //               tickPadding: 5,
// // // //               tickRotation: 0,
// // // //               legend: 'Count',
// // // //               legendPosition: 'middle',
// // // //               legendOffset: -40,
// // // //               format: value => Number.isInteger(value) ? value : '',
// // // //             }}
// // // //             labelSkipWidth={12}
// // // //             labelSkipHeight={12}
// // // //             labelTextColor="white"
// // // //             tooltip={({ id, value, color }) => (
// // // //               <div
// // // //                 style={{
// // // //                   padding: '12px',
// // // //                   color,
// // // //                   background: 'white',
// // // //                   border: `1px solid ${color}`,
// // // //                   borderRadius: '4px',
// // // //                 }}
// // // //               >
// // // //                 <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// // // //               </div>
// // // //             )}
// // // //             legends={[
// // // //               {
// // // //                 dataFrom: 'keys',
// // // //                 anchor: 'bottom-right',
// // // //                 direction: 'column',
// // // //                 justify: false,
// // // //                 translateX: 120,
// // // //                 translateY: 0,
// // // //                 itemsSpacing: 2,
// // // //                 itemWidth: 100,
// // // //                 itemHeight: 20,
// // // //                 itemDirection: 'left-to-right',
// // // //                 itemOpacity: 0.85,
// // // //                 symbolSize: 20,
// // // //                 effects: [
// // // //                   {
// // // //                     on: 'hover',
// // // //                     style: {
// // // //                       itemOpacity: 1,
// // // //                     },
// // // //                   },
// // // //                 ],
// // // //               },
// // // //             ]}
// // // //             animate={true}
// // // //             motionStiffness={90}
// // // //             motionDamping={15}
// // // //           />
// // // //         </div>
// // // //       )}
// // // //     </div>
// // // //   );
// // // // };

// // // // export default SurveyResultsMulti;



// // // // // import React from 'react';
// // // // // import { ResponsiveBar } from '@nivo/bar';

// // // // // const SurveyResultsMulti = ({ questionData, keys, colors, average, averageColor, surveyQuestions }) => {
// // // // //   console.log('Rendering SurveyResultsMulti with data:', { questionData, keys, colors, average, averageColor });

// // // // //   const question = surveyQuestions.find(q => q.text === questionData.question);

// // // // //   const negativeResults = question.options
// // // // //     .map((option, index) => ({
// // // // //       id: `${index + 1} - ${option}`,
// // // // //       value: 1,
// // // // //     }))
// // // // //     .filter(option => !keys.includes(option.id));

// // // // //   const negativeData = negativeResults.reduce((acc, item) => {
// // // // //     acc[item.id] = item.value;
// // // // //     return acc;
// // // // //   }, {});

// // // // //   const negativeColors = negativeResults.map(() => '#cccccc');

// // // // //   return (
// // // // //     <div style={{ width: '45%', margin: '2.5%', height: 450 }}>
// // // // //       <h6>{questionData.question}</h6>
// // // // //       <ResponsiveBar
// // // // //         data={[questionData]}
// // // // //         keys={keys}
// // // // //         indexBy="question"
// // // // //         margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
// // // // //         innerPadding={10}
// // // // //         groupMode="grouped"
// // // // //         colors={colors}
// // // // //         borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// // // // //         axisTop={null}
// // // // //         axisRight={null}
// // // // //         axisBottom={{
// // // // //           tickSize: 5,
// // // // //           tickPadding: 5,
// // // // //           tickRotation: 0,
// // // // //           legend: 'Response',
// // // // //           legendPosition: 'middle',
// // // // //           legendOffset: 32,
// // // // //         }}
// // // // //         axisLeft={{
// // // // //           tickSize: 5,
// // // // //           tickPadding: 5,
// // // // //           tickRotation: 0,
// // // // //           legend: 'Count',
// // // // //           legendPosition: 'middle',
// // // // //           legendOffset: -40,
// // // // //           format: value => Number.isInteger(value) ? value : '',
// // // // //         }}
// // // // //         labelSkipWidth={12}
// // // // //         labelSkipHeight={12}
// // // // //         labelTextColor="white"
// // // // //         tooltip={({ id, value, color }) => (
// // // // //           <div
// // // // //             style={{
// // // // //               padding: '12px',
// // // // //               color,
// // // // //               background: 'white',
// // // // //               border: `1px solid ${color}`,
// // // // //               borderRadius: '4px',
// // // // //             }}
// // // // //           >
// // // // //             <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// // // // //           </div>
// // // // //         )}
// // // // //         legends={[
// // // // //           {
// // // // //             dataFrom: 'keys',
// // // // //             anchor: 'bottom-right',
// // // // //             direction: 'column',
// // // // //             justify: false,
// // // // //             translateX: 120,
// // // // //             translateY: 0,
// // // // //             itemsSpacing: 2,
// // // // //             itemWidth: 100,
// // // // //             itemHeight: 20,
// // // // //             itemDirection: 'left-to-right',
// // // // //             itemOpacity: 0.85,
// // // // //             symbolSize: 20,
// // // // //             effects: [
// // // // //               {
// // // // //                 on: 'hover',
// // // // //                 style: {
// // // // //                   itemOpacity: 1,
// // // // //                 },
// // // // //               },
// // // // //             ],
// // // // //           },
// // // // //         ]}
// // // // //         animate={true}
// // // // //         motionStiffness={90}
// // // // //         motionDamping={15}
// // // // //       />
// // // // //       <div style={{ textAlign: 'center', fontSize: '1.5em', color: averageColor }}>
// // // // //         Average: {average}
// // // // //       </div>
// // // // //       {negativeResults.length > 0 && (
// // // // //         <div style={{ width: '45%', margin: '2.5%', height: 450 }}>
// // // // //           <h6>Unselected Options</h6>
// // // // //           <ResponsiveBar
// // // // //             data={[negativeData]}
// // // // //             keys={negativeResults.map(item => item.id)}
// // // // //             indexBy="question"
// // // // //             margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
// // // // //             innerPadding={10}
// // // // //             groupMode="grouped"
// // // // //             colors={negativeColors}
// // // // //             borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// // // // //             axisTop={null}
// // // // //             axisRight={null}
// // // // //             axisBottom={{
// // // // //               tickSize: 5,
// // // // //               tickPadding: 5,
// // // // //               tickRotation: 0,
// // // // //               legend: 'Response',
// // // // //               legendPosition: 'middle',
// // // // //               legendOffset: 32,
// // // // //             }}
// // // // //             axisLeft={{
// // // // //               tickSize: 5,
// // // // //               tickPadding: 5,
// // // // //               tickRotation: 0,
// // // // //               legend: 'Count',
// // // // //               legendPosition: 'middle',
// // // // //               legendOffset: -40,
// // // // //               format: value => Number.isInteger(value) ? value : '',
// // // // //             }}
// // // // //             labelSkipWidth={12}
// // // // //             labelSkipHeight={12}
// // // // //             labelTextColor="white"
// // // // //             tooltip={({ id, value, color }) => (
// // // // //               <div
// // // // //                 style={{
// // // // //                   padding: '12px',
// // // // //                   color,
// // // // //                   background: 'white',
// // // // //                   border: `1px solid ${color}`,
// // // // //                   borderRadius: '4px',
// // // // //                 }}
// // // // //               >
// // // // //                 <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// // // // //               </div>
// // // // //             )}
// // // // //             legends={[
// // // // //               {
// // // // //                 dataFrom: 'keys',
// // // // //                 anchor: 'bottom-right',
// // // // //                 direction: 'column',
// // // // //                 justify: false,
// // // // //                 translateX: 120,
// // // // //                 translateY: 0,
// // // // //                 itemsSpacing: 2,
// // // // //                 itemWidth: 100,
// // // // //                 itemHeight: 20,
// // // // //                 itemDirection: 'left-to-right',
// // // // //                 itemOpacity: 0.85,
// // // // //                 symbolSize: 20,
// // // // //                 effects: [
// // // // //                   {
// // // // //                     on: 'hover',
// // // // //                     style: {
// // // // //                       itemOpacity: 1,
// // // // //                     },
// // // // //                   },
// // // // //                 ],
// // // // //               },
// // // // //             ]}
// // // // //             animate={true}
// // // // //             motionStiffness={90}
// // // // //             motionDamping={15}
// // // // //           />
// // // // //         </div>
// // // // //       )}
// // // // //     </div>
// // // // //   );
// // // // // };

// // // // // export default SurveyResultsMulti;


// // // // // // import React from 'react';
// // // // // // import { ResponsiveBar } from '@nivo/bar';

// // // // // // const SurveyResultsMulti = ({ questionData, keys, colors, average, averageColor }) => {
// // // // // //   return (
// // // // // //     <div style={{ width: '45%', margin: '2.5%', height: 450 }}>
// // // // // //       <h6>{questionData.question}</h6>
// // // // // //       <ResponsiveBar
// // // // // //         data={[questionData]}
// // // // // //         keys={keys}
// // // // // //         indexBy="question"
// // // // // //         margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
// // // // // //         innerPadding={10}
// // // // // //         groupMode="grouped"
// // // // // //         colors={colors}
// // // // // //         borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// // // // // //         axisTop={null}
// // // // // //         axisRight={null}
// // // // // //         axisBottom={{
// // // // // //           tickSize: 5,
// // // // // //           tickPadding: 5,
// // // // // //           tickRotation: 0,
// // // // // //           legend: 'Response',
// // // // // //           legendPosition: 'middle',
// // // // // //           legendOffset: 32,
// // // // // //         }}
// // // // // //         axisLeft={{
// // // // // //           tickSize: 5,
// // // // // //           tickPadding: 5,
// // // // // //           tickRotation: 0,
// // // // // //           legend: 'Count',
// // // // // //           legendPosition: 'middle',
// // // // // //           legendOffset: -40,
// // // // // //           format: value => Number.isInteger(value) ? value : '',
// // // // // //         }}
// // // // // //         labelSkipWidth={12}
// // // // // //         labelSkipHeight={12}
// // // // // //         labelTextColor="white"
// // // // // //         tooltip={({ id, value, color }) => (
// // // // // //           <div
// // // // // //             style={{
// // // // // //               padding: '12px',
// // // // // //               color,
// // // // // //               background: 'white',
// // // // // //               border: `1px solid ${color}`,
// // // // // //               borderRadius: '4px',
// // // // // //             }}
// // // // // //           >
// // // // // //             <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// // // // // //           </div>
// // // // // //         )}
// // // // // //         legends={[
// // // // // //           {
// // // // // //             dataFrom: 'keys',
// // // // // //             anchor: 'bottom-right',
// // // // // //             direction: 'column',
// // // // // //             justify: false,
// // // // // //             translateX: 120,
// // // // // //             translateY: 0,
// // // // // //             itemsSpacing: 2,
// // // // // //             itemWidth: 100,
// // // // // //             itemHeight: 20,
// // // // // //             itemDirection: 'left-to-right',
// // // // // //             itemOpacity: 0.85,
// // // // // //             symbolSize: 20,
// // // // // //             effects: [
// // // // // //               {
// // // // // //                 on: 'hover',
// // // // // //                 style: {
// // // // // //                   itemOpacity: 1,
// // // // // //                 },
// // // // // //               },
// // // // // //             ],
// // // // // //           },
// // // // // //         ]}
// // // // // //         animate={true}
// // // // // //         motionStiffness={90}
// // // // // //         motionDamping={15}
// // // // // //       />
// // // // // //       <div style={{ textAlign: 'center', fontSize: '1.5em', color: averageColor }}>
// // // // // //         Average: {average}
// // // // // //       </div>
// // // // // //     </div>
// // // // // //   );
// // // // // // };

// // // // // // export default SurveyResultsMulti;


// // // // // // // import React from 'react';
// // // // // // // import { ResponsiveBar } from '@nivo/bar';

// // // // // // // const SurveyResultsMulti = ({ questionData, keys, colors, average, averageColor }) => {
// // // // // // //   return (
// // // // // // //     <div style={{ width: '45%', margin: '2.5%', height: 450 }}>
// // // // // // //       <h6>{questionData.question}</h6>
// // // // // // //       <ResponsiveBar
// // // // // // //         data={[questionData]}
// // // // // // //         keys={keys}
// // // // // // //         indexBy="question"
// // // // // // //         margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
// // // // // // //         innerPadding={10}
// // // // // // //         groupMode="grouped"
// // // // // // //         colors={colors}
// // // // // // //         borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
// // // // // // //         axisTop={null}
// // // // // // //         axisRight={null}
// // // // // // //         axisBottom={{
// // // // // // //           tickSize: 5,
// // // // // // //           tickPadding: 5,
// // // // // // //           tickRotation: 0,
// // // // // // //           legend: 'Response',
// // // // // // //           legendPosition: 'middle',
// // // // // // //           legendOffset: 32,
// // // // // // //         }}
// // // // // // //         axisLeft={{
// // // // // // //           tickSize: 5,
// // // // // // //           tickPadding: 5,
// // // // // // //           tickRotation: 0,
// // // // // // //           legend: 'Count',
// // // // // // //           legendPosition: 'middle',
// // // // // // //           legendOffset: -40,
// // // // // // //           format: value => Number.isInteger(value) ? value : '',
// // // // // // //         }}
// // // // // // //         labelSkipWidth={12}
// // // // // // //         labelSkipHeight={12}
// // // // // // //         labelTextColor="white"
// // // // // // //         tooltip={({ id, value, color }) => (
// // // // // // //           <div
// // // // // // //             style={{
// // // // // // //               padding: '12px',
// // // // // // //               color,
// // // // // // //               background: 'white',
// // // // // // //               border: `1px solid ${color}`,
// // // // // // //               borderRadius: '4px',
// // // // // // //             }}
// // // // // // //           >
// // // // // // //             <strong>{id.split(' - ')[1]} (Count: {value})</strong>
// // // // // // //           </div>
// // // // // // //         )}
// // // // // // //         legends={[
// // // // // // //           {
// // // // // // //             dataFrom: 'keys',
// // // // // // //             anchor: 'bottom-right',
// // // // // // //             direction: 'column',
// // // // // // //             justify: false,
// // // // // // //             translateX: 120,
// // // // // // //             translateY: 0,
// // // // // // //             itemsSpacing: 2,
// // // // // // //             itemWidth: 100,
// // // // // // //             itemHeight: 20,
// // // // // // //             itemDirection: 'left-to-right',
// // // // // // //             itemOpacity: 0.85,
// // // // // // //             symbolSize: 20,
// // // // // // //             effects: [
// // // // // // //               {
// // // // // // //                 on: 'hover',
// // // // // // //                 style: {
// // // // // // //                   itemOpacity: 1,
// // // // // // //                 },
// // // // // // //               },
// // // // // // //             ],
// // // // // // //           },
// // // // // // //         ]}
// // // // // // //         animate={true}
// // // // // // //         motionStiffness={90}
// // // // // // //         motionDamping={15}
// // // // // // //       />
// // // // // // //       <div style={{ textAlign: 'center', fontSize: '1.5em', color: averageColor }}>
// // // // // // //         Average: {average}
// // // // // // //       </div>
// // // // // // //     </div>
// // // // // // //   );
// // // // // // // };

// // // // // // // export default SurveyResultsMulti;
