import React, { useState, useEffect } from 'react';
import SurveyBody from './SurveyBody';
import axios from 'axios';
import Cookies from 'js-cookie';

const SurveyContainer = ({ surveyData, companyId, surveyId }) => {
  const [responses, setResponses] = useState({});
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const isSubmitted = Cookies.get(`survey_${companyId}_${surveyId}`);
    if (isSubmitted) {
      setSubmitted(true);
    }
  }, [companyId, surveyId]);

  const handleResponseChange = (questionId, response) => {
    setResponses((prevResponses) => {
      const newResponses = {
        ...prevResponses,
        [questionId]: response,
      };
      console.log('Updated Responses in SurveyContainer:', newResponses);
      return newResponses;
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    await submitSurvey();
  };

  const submitSurvey = async () => {
    const payload = {
      companyId,
      surveyId,
      demographics: {},
      responses: []
    };

    surveyData.questions.forEach((question) => {
      if (question.dimension === 'Demographics') {
        payload.demographics[question.text] = responses[question.text];
      } else {
        const mappedResponse = mapResponseToNumber(question, responses[question.text]);
        payload.responses.push({ question: question.text, response: mappedResponse });
      }
    });

    console.log('Payload to be sent:', payload);

    try {
      const response = await axios.post(`${process.env.REACT_APP_GO_BASE}/survey`, payload);
      console.log('Survey submitted successfully:', response.data);
      Cookies.set(`survey_${companyId}_${surveyId}`, 'submitted', { expires: 31 });
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting survey:', error);
    }
  };

  const mapResponseToNumber = (question, response) => {
    if (question.type === 'multi_select') {
      return response.map((option) => {
        const index = question.options.indexOf(option);
        return index !== -1 ? index + 1 : option;
      });
    } else {
      const index = question.options.indexOf(response);
      return index !== -1 ? index + 1 : response;
    }
  };

  // if (submitted) {
  //   return <div>Your results have been sent. Thank you for participating. You may close this window now.</div>;
  // }

  if (submitted) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', textAlign: 'center' }}>
        <h1 style={{ color: '#2c294b' }}>Your results have been sent.<br/>
        Thank you for participating.</h1>
        <h2 style={{ marginTop: '30px' }}>You may close this window now.</h2>
        <img src="/Logo-HYEPartners-Square_Colors.png" alt="logo" style={{ marginTop: '200px', width: '25%', height: 'auto' }} />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="surveyContainer">
        <h1>{surveyData.title}</h1>
        <p>{surveyData.description}</p>
        <SurveyBody
          questions={surveyData.questions}
          responses={responses}
          onResponseChange={handleResponseChange}
          filterby={surveyData.filterby}
          submitSurvey={submitSurvey}  // Pass submit handler
        />
      </div>
    </form>
  );
};

export default SurveyContainer;


// import React, { useState, useEffect } from 'react';
// import SurveyBody from './SurveyBody';
// import axios from 'axios';
// import Cookies from 'js-cookie';

// const SurveyContainer = ({ surveyData, companyId, surveyId }) => {
//   const [responses, setResponses] = useState({});
//   const [submitted, setSubmitted] = useState(false);

//   useEffect(() => {
//     const isSubmitted = Cookies.get(`survey_${companyId}_${surveyId}`);
//     if (isSubmitted) {
//       setSubmitted(true);
//     }
//   }, [companyId, surveyId]);

//   const handleResponseChange = (questionId, response) => {
//     setResponses((prevResponses) => {
//       const newResponses = {
//         ...prevResponses,
//         [questionId]: response,
//       };
//       console.log('Updated Responses in SurveyContainer:', newResponses);
//       return newResponses;
//     });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const payload = {
//       companyId,
//       surveyId,
//       demographics: {},
//       responses: []
//     };

//     surveyData.questions.forEach((question) => {
//       if (question.dimension === 'Demographics') {
//         payload.demographics[question.text] = responses[question.text];
//       } else {
//         const mappedResponse = mapResponseToNumber(question, responses[question.text]);
//         payload.responses.push({ question: question.text, response: mappedResponse });
//       }
//     });

//     console.log('Payload to be sent:', payload);

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_GO_BASE}/survey`, payload);
//       console.log('Survey submitted successfully:', response.data);
//       Cookies.set(`survey_${companyId}_${surveyId}`, 'submitted', { expires: 31 });
//       setSubmitted(true);
//     } catch (error) {
//       console.error('Error submitting survey:', error);
//     }
//   };

//   const mapResponseToNumber = (question, response) => {
//     if (question.type === 'multi_select') {
//       return response.map((option) => {
//         const index = question.options.indexOf(option);
//         return index !== -1 ? index + 1 : option;
//       });
//     } else {
//       const index = question.options.indexOf(response);
//       return index !== -1 ? index + 1 : response;
//     }
//   };

//   if (submitted) {
//     return <div>Your results have been sent. Thank you for participating. You may close this window now.</div>;
//   }

//   return (
//     <form onSubmit={handleSubmit}>
//       <div className="surveyContainer">
//         <h1>{surveyData.title}</h1>
//         <p>{surveyData.description}</p>
//         <SurveyBody
//           questions={surveyData.questions}
//           responses={responses}
//           onResponseChange={handleResponseChange}
//           filterby={surveyData.filterby}
//           submitSurvey={handleSubmit}  // Pass submit handler
//         />
//       </div>
//     </form>
//   );
// };

// export default SurveyContainer;



// // import React, { useState, useEffect } from 'react';
// // import SurveyBody from './SurveyBody';
// // import axios from 'axios';
// // import Cookies from 'js-cookie';

// // const SurveyContainer = ({ surveyData, companyId, surveyId }) => {
// //   const [responses, setResponses] = useState({});
// //   const [submitted, setSubmitted] = useState(false);

// //   useEffect(() => {
// //     const isSubmitted = Cookies.get(`survey_${companyId}_${surveyId}`);
// //     if (isSubmitted) {
// //       setSubmitted(true);
// //     }
// //   }, [companyId, surveyId]);

// //   const handleResponseChange = (questionId, response) => {
// //     setResponses((prevResponses) => {
// //       const newResponses = {
// //         ...prevResponses,
// //         [questionId]: response,
// //       };
// //       console.log('Updated Responses in SurveyContainer:', newResponses);
// //       return newResponses;
// //     });
// //   };

// //   const handleSubmit = async (event) => {
// //     event.preventDefault();

// //     const payload = {
// //       companyId,
// //       surveyId,
// //       demographics: {},
// //       responses: []
// //     };

// //     surveyData.questions.forEach((question) => {
// //       if (question.dimension === 'Demographics') {
// //         payload.demographics[question.text] = responses[question.text];
// //       } else {
// //         const mappedResponse = mapResponseToNumber(question, responses[question.text]);
// //         payload.responses.push({ question: question.text, response: mappedResponse });
// //       }
// //     });

// //     console.log('Payload to be sent:', payload);

// //     try {
// //       const response = await axios.post(`${process.env.REACT_APP_GO_BASE}/survey`, payload);
// //       console.log('Survey submitted successfully:', response.data);
// //       Cookies.set(`survey_${companyId}_${surveyId}`, 'submitted', { expires: 31 });
// //       setSubmitted(true);
// //     } catch (error) {
// //       console.error('Error submitting survey:', error);
// //     }
// //   };

// //   const mapResponseToNumber = (question, response) => {
// //     if (question.type === 'multi_select') {
// //       return response.map((option) => {
// //         const index = question.options.indexOf(option);
// //         return index !== -1 ? index + 1 : option;
// //       });
// //     } else {
// //       const index = question.options.indexOf(response);
// //       return index !== -1 ? index + 1 : response;
// //     }
// //   };

// //   if (submitted) {
// //     return <div>Your results have been sent. Thank you for participating. You may close this window now.</div>;
// //   }

// //   return (
// //     <form onSubmit={handleSubmit}>
// //       <div className="surveyContainer">
// //         <h1>{surveyData.title}</h1>
// //         <p>{surveyData.description}</p>
// //         <SurveyBody
// //           questions={surveyData.questions}
// //           responses={responses}
// //           onResponseChange={handleResponseChange}
// //           filterby={surveyData.filterby}
// //         />
// //       </div>
// //     </form>
// //   );
// // };

// // export default SurveyContainer;



// // // import React, { useState, useEffect } from 'react';
// // // import SurveyBody from './SurveyBody';
// // // import axios from 'axios';
// // // import Cookies from 'js-cookie';

// // // const SurveyContainer = ({ surveyData, companyId, surveyId }) => {
// // //   const [responses, setResponses] = useState({});
// // //   const [submitted, setSubmitted] = useState(false);

// // //   useEffect(() => {
// // //     const isSubmitted = Cookies.get(`survey_${companyId}_${surveyId}`);
// // //     if (isSubmitted) {
// // //       setSubmitted(true);
// // //     }
// // //   }, [companyId, surveyId]);

// // //   // const handleResponseChange = (questionId, response) => {
// // //   //   setResponses((prevResponses) => ({
// // //   //     ...prevResponses,
// // //   //     [questionId]: response,
// // //   //   }));
// // //   // };

// // //   const handleResponseChange = (questionId, response) => {
// // //     setResponses((prevResponses) => ({
// // //       ...prevResponses,
// // //       [questionId]: response,
// // //     }));
// // //   };
  
// // //   const handleSubmit = async (event) => {
// // //     event.preventDefault();

// // //     const payload = {
// // //       companyId,
// // //       surveyId,
// // //       demographics: {},
// // //       responses: []
// // //     };

// // //     surveyData.questions.forEach((question) => {
// // //       if (question.dimension === 'Demographics') {
// // //         payload.demographics[question.text] = responses[question.text];
// // //       } else {
// // //         const mappedResponse = mapResponseToNumber(question, responses[question.text]);
// // //         payload.responses.push({ question: question.text, response: mappedResponse });
// // //       }
// // //     });

// // //     console.log('Payload to be sent:', payload);

// // //     try {
// // //       const response = await axios.post(`${process.env.REACT_APP_GO_BASE}/survey`, payload);
// // //       console.log('Survey submitted successfully:', response.data);
// // //       Cookies.set(`survey_${companyId}_${surveyId}`, 'submitted', { expires: 31 });
// // //       setSubmitted(true);
// // //     } catch (error) {
// // //       console.error('Error submitting survey:', error);
// // //     }
// // //   };

// // //   const mapResponseToNumber = (question, response) => {
// // //     if (question.type === 'multi_select') {
// // //       return response.map((option) => {
// // //         const index = question.options.indexOf(option);
// // //         return index !== -1 ? index + 1 : option;
// // //       });
// // //     } else {
// // //       const index = question.options.indexOf(response);
// // //       return index !== -1 ? index + 1 : response;
// // //     }
// // //   };

// // //   if (submitted) {
// // //     return <div>Your results have been sent. Thank you for participating. You may close this window now.</div>;
// // //   }

// // //   return (
// // //     <form onSubmit={handleSubmit}>
// // //       <div className="surveyContainer">
// // //         <h1>{surveyData.title}</h1>
// // //         <p>{surveyData.description}</p>
// // //         <SurveyBody
// // //           questions={surveyData.questions}
// // //           responses={responses}
// // //           onResponseChange={handleResponseChange}
// // //           filterby={surveyData.filterby} // Pass the filterby property
// // //         />
// // //       </div>
// // //     </form>
// // //   );
// // // };

// // // export default SurveyContainer;


// // // // import React, { useState, useEffect } from 'react';
// // // // import SurveyBody from './SurveyBody';
// // // // import axios from 'axios';
// // // // import Cookies from 'js-cookie';

// // // // const SurveyContainer = ({ surveyData, companyId, surveyId }) => {
// // // //   const [responses, setResponses] = useState({});
// // // //   const [submitted, setSubmitted] = useState(false);

// // // //   useEffect(() => {
// // // //     const isSubmitted = Cookies.get(`survey_${companyId}_${surveyId}`);
// // // //     if (isSubmitted) {
// // // //       setSubmitted(true);
// // // //     }
// // // //   }, [companyId, surveyId]);

// // // //   const handleResponseChange = (questionId, response) => {
// // // //     setResponses((prevResponses) => ({
// // // //       ...prevResponses,
// // // //       [questionId]: response,
// // // //     }));
// // // //   };

// // // //   // const mapResponseToNumber = (question, response) => {
// // // //   //   if (question.type === 'multi_select') {
// // // //   //     return response.map((option) => {
// // // //   //       const index = question.options.indexOf(option);
// // // //   //       return index !== -1 ? index + 1 : option;
// // // //   //     });
// // // //   //   } else {
// // // //   //     const index = question.options.indexOf(response);
// // // //   //     return index !== -1 ? index + 1 : response;
// // // //   //   }
// // // //   // };

// // // //   // const handleSubmit = async (event) => {
// // // //   //   event.preventDefault();

// // // //   //   const payload = {
// // // //   //     companyId,
// // // //   //     surveyId,
// // // //   //     demographics: {},
// // // //   //     responses: []
// // // //   //   };

// // // //   //   surveyData.questions.forEach((question) => {
// // // //   //     if (question.dimension === 'Demographics') {
// // // //   //       payload.demographics[question.text] = responses[question.text];
// // // //   //     } else {
// // // //   //       const mappedResponse = mapResponseToNumber(question, responses[question.text]);
// // // //   //       payload.responses.push({ question: question.text, response: mappedResponse });
// // // //   //     }
// // // //   //   });

// // // //   //   console.log('Payload to be sent:', payload);

// // // //   //   try {
// // // //   //     const response = await axios.post(`${process.env.REACT_APP_GO_BASE}/survey`, payload);
// // // //   //     console.log('Survey submitted successfully:', response.data);
// // // //   //     Cookies.set(`survey_${companyId}_${surveyId}`, 'submitted', { expires: 31 });
// // // //   //     setSubmitted(true);
// // // //   //   } catch (error) {
// // // //   //     console.error('Error submitting survey:', error);
// // // //   //   }
// // // //   // };

// // // //   const handleSubmit = async (event) => {
// // // //     event.preventDefault();
  
// // // //     const payload = {
// // // //       companyId,
// // // //       surveyId,
// // // //       demographics: {},
// // // //       responses: []
// // // //     };
  
// // // //     surveyData.questions.forEach((question) => {
// // // //       if (question.dimension === 'Demographics') {
// // // //         payload.demographics[question.text] = responses[question.text];
// // // //       } else {
// // // //         const mappedResponse = mapResponseToNumber(question, responses[question.text]);
// // // //         payload.responses.push({ question: question.text, response: mappedResponse });
// // // //       }
// // // //     });
  
// // // //     console.log('Payload to be sent:', payload);
  
// // // //     try {
// // // //       const response = await axios.post(`${process.env.REACT_APP_GO_BASE}/survey`, payload);
// // // //       console.log('Survey submitted successfully:', response.data);
// // // //       Cookies.set(`survey_${companyId}_${surveyId}`, 'submitted', { expires: 31 });
// // // //       setSubmitted(true);
// // // //     } catch (error) {
// // // //       console.error('Error submitting survey:', error);
// // // //     }
// // // //   };
  
// // // //   const mapResponseToNumber = (question, response) => {
// // // //     if (question.type === 'multi_select') {
// // // //       return response.map((option) => {
// // // //         const index = question.options.indexOf(option);
// // // //         return index !== -1 ? index + 1 : option;
// // // //       });
// // // //     } else {
// // // //       const index = question.options.indexOf(response);
// // // //       return index !== -1 ? index + 1 : response;
// // // //     }
// // // //   };
  
// // // //   if (submitted) {
// // // //     return <div>Your results have been sent. Thank you for participating. You may close this window now.</div>;
// // // //   }

// // // //   return (
// // // //     <form onSubmit={handleSubmit}>
// // // //       <div className="surveyContainer">
// // // //         <h1>{surveyData.title}</h1>
// // // //         <p>{surveyData.description}</p>
// // // //         <SurveyBody
// // // //           questions={surveyData.questions}
// // // //           responses={responses}
// // // //           onResponseChange={handleResponseChange}
// // // //         />
// // // //       </div>
// // // //     </form>
// // // //   );
// // // // };

// // // // export default SurveyContainer;


// // // // // import React, { useState, useEffect } from 'react';
// // // // // import SurveyBody from './SurveyBody';
// // // // // import axios from 'axios';
// // // // // import Cookies from 'js-cookie';

// // // // // const SurveyContainer = ({ surveyData, companyId, surveyId }) => {
// // // // //   const [responses, setResponses] = useState({});
// // // // //   const [submitted, setSubmitted] = useState(false);

// // // // //   useEffect(() => {
// // // // //     const isSubmitted = Cookies.get(`survey_${companyId}_${surveyId}`);
// // // // //     if (isSubmitted) {
// // // // //       setSubmitted(true);
// // // // //     }
// // // // //   }, [companyId, surveyId]);

// // // // //   const handleResponseChange = (questionId, response) => {
// // // // //     setResponses((prevResponses) => ({
// // // // //       ...prevResponses,
// // // // //       [questionId]: response,
// // // // //     }));
// // // // //   };

// // // // //   const mapResponseToNumber = (question, response) => {
// // // // //     const index = question.options.indexOf(response);
// // // // //     return index !== -1 ? index + 1 : response; // Adding 1 because index is 0-based
// // // // //   };

// // // // //   const handleSubmit = async (event) => {
// // // // //     event.preventDefault();

// // // // //     const payload = {
// // // // //       companyId,
// // // // //       surveyId,
// // // // //       demographics: {},
// // // // //       responses: []
// // // // //     };

// // // // //     surveyData.questions.forEach((question) => {
// // // // //       if (question.dimension === 'Demographics') {
// // // // //         payload.demographics[question.text] = responses[question.text];
// // // // //       } else {
// // // // //         const mappedResponse = mapResponseToNumber(question, responses[question.text]);
// // // // //         payload.responses.push({ question: question.text, response: mappedResponse });
// // // // //       }
// // // // //     });

// // // // //     console.log('Payload to be sent:', payload);

// // // // //     try {
// // // // //       const response = await axios.post(`${process.env.REACT_APP_GO_BASE}/survey`, payload);
// // // // //       console.log('Survey submitted successfully:', response.data);
// // // // //       Cookies.set(`survey_${companyId}_${surveyId}`, 'submitted', { expires: 31 });
// // // // //       setSubmitted(true);
// // // // //     } catch (error) {
// // // // //       console.error('Error submitting survey:', error);
// // // // //     }
// // // // //   };

// // // // //   if (submitted) {
// // // // //     return <div>Your results have been sent. Thank you for participating. You may close this window now.</div>;
// // // // //   }

// // // // //   return (
// // // // //     <form onSubmit={handleSubmit}>
// // // // //       <div className="surveyContainer">
// // // // //         <h1>{surveyData.title}</h1>
// // // // //         <p>{surveyData.description}</p>
// // // // //         <SurveyBody
// // // // //           questions={surveyData.questions}
// // // // //           responses={responses}
// // // // //           onResponseChange={handleResponseChange}
// // // // //         />
// // // // //       </div>
// // // // //     </form>
// // // // //   );
// // // // // };

// // // // // export default SurveyContainer;
