import React, { forwardRef, useRef } from 'react';
import { motion } from 'framer-motion';

const GlowBox = forwardRef(({ children, glowColor, bgColor }, ref) => {
  const internalRef = useRef(null);
  const boxRef = ref || internalRef;
  const glowRef = useRef(null);

  const handleMouseMove = (e) => {
    const box = boxRef.current;
    const glow = glowRef.current;
    if (box && glow) {
      const rect = box.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      glow.style.left = `${x - glow.offsetWidth / 2}px`;
      glow.style.top = `${y - glow.offsetHeight / 2}px`;

      const centerX = rect.width / 2;
      const centerY = rect.height / 2;
      const rotateX = (y - centerY) / centerY * 7; // Max 7 degrees tilt
      const rotateY = (x - centerX) / centerX * 7; // Max 7 degrees tilt

      box.style.transform = `scale(1.05) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    }
  };

  const handleMouseOut = () => {
    const box = boxRef.current;
    if (box) {
      box.style.transform = 'scale(1) rotateX(0deg) rotateY(0deg)';
    }
  };

  return (
    <motion.div
      className="glow-box"
      style={{ backgroundColor: bgColor }}
      ref={boxRef}
      onMouseMove={handleMouseMove}
      onMouseOut={handleMouseOut}
    >
      <div
        className="glow"
        ref={glowRef}
        style={{
        //   position: 'absolute',
        //   width: '200px', // Adjust size as needed
        //   height: '200px', // Adjust size as needed
        //   pointerEvents: 'none',
          background: `radial-gradient(circle, ${glowColor} 10%, transparent 50%)`,
          transform: 'translate(-1%, 1%)',
          zIndex: 10,
        }}
      ></div>
      {children}
    </motion.div>
  );
});

export default GlowBox;



// import React, { forwardRef, useRef } from 'react';
// import { motion } from 'framer-motion';

// const GlowBox = forwardRef(({ children, glowColor, bgColor }, ref) => {
//   const internalRef = useRef(null);
//   const boxRef = ref || internalRef;
//   const glowRef = useRef(null);

//   const handleMouseMove = (e) => {
//     const box = boxRef.current;
//     const glow = glowRef.current;
//     if (box && glow) {
//       const rect = box.getBoundingClientRect();
//       const x = e.clientX - rect.left;
//       const y = e.clientY - rect.top;

//       glow.style.left = `${x}px`;
//       glow.style.top = `${y}px`;

//       const centerX = rect.width / 2;
//       const centerY = rect.height / 2;
//       const rotateX = (y - centerY) / centerY * 7; // Max 7 degrees tilt
//       const rotateY = (x - centerX) / centerX * 7; // Max 7 degrees tilt

//       box.style.transform = `scale(1.05) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
//     }
//   };

//   const handleMouseOut = () => {
//     const box = boxRef.current;
//     if (box) {
//       box.style.transform = 'scale(1) rotateX(0deg) rotateY(0deg)';
//     }
//   };

//   return (
//     <motion.div
//       className={`glow-box ${bgColor}`}
//       ref={boxRef}
//       onMouseMove={handleMouseMove}
//       onMouseOut={handleMouseOut}
//     >
//       <div
//         className="glow"
//         ref={glowRef}
//         style={{ background: `radial-gradient(circle, ${glowColor} 10%, transparent 50%)`, backgroundColor: bgColor }}
//       ></div>
//       {children}
//     </motion.div>
//   );
// });

// export default GlowBox;



// // import React, { forwardRef, useRef } from 'react';

// // const GlowBox = forwardRef(({ children, glowColor = 'rgba(255, 255, 255, 0.5)', bgColor = 'rgba(235, 236, 251, 1)' }, ref) => {
// //   const internalRef = useRef(null);
// //   const boxRef = ref || internalRef;
// //   const glowRef = useRef(null);

// //   const handleMouseMove = (e) => {
// //     const box = boxRef.current;
// //     const glow = glowRef.current;
// //     if (box && glow) {
// //       const rect = box.getBoundingClientRect();
// //       const x = e.clientX - rect.left;
// //       const y = e.clientY - rect.top;

// //       glow.style.left = `${x}px`;
// //       glow.style.top = `${y}px`;

// //       // Calculate the tilt based on mouse position
// //       const centerX = rect.width / 2;
// //       const centerY = rect.height / 2;
// //       const rotateX = (y - centerY) / centerY * 5; // Max 5 degrees tilt
// //       const rotateY = (x - centerX) / centerX * 5; // Max 5 degrees tilt

// //       box.style.transform = `scale(1.05) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
// //     }
// //   };

// //   const handleMouseOut = () => {
// //     const box = boxRef.current;
// //     if (box) {
// //       box.style.transform = 'scale(1) rotateX(0deg) rotateY(0deg)';
// //     }
// //   };

// //   return (
// //     <div
// //       className="glow-box"
// //       ref={boxRef}
// //       onMouseMove={handleMouseMove}
// //       onMouseOut={handleMouseOut}
// //     >
// //       <div
// //         className="glow"
// //         ref={glowRef}
// //         // style={{ background: `radial-gradient(circle, ${glowColor} 10%, transparent 50%)` }}
// //         style={{ background: `radial-gradient(circle, ${glowColor} 10%, transparent 50%)`, backgroundColor: {bgColor} }}
// //       ></div>
// //       {children}
// //     </div>
// //   );
// // });

// // export default GlowBox;

// // import React, { useRef } from 'react';

// // const GlowBox = ({ children, glowColor = 'rgba(255, 255, 255, 0.5)', bgColor = 'rgba(235, 236, 251, 1)' }) => {
// //   const boxRef = useRef(null);
// //   const glowRef = useRef(null);

// //   const handleMouseMove = (e) => {
// //     const box = boxRef.current;
// //     const glow = glowRef.current;
// //     if (box && glow) {
// //       const rect = box.getBoundingClientRect();
// //       const x = e.clientX - rect.left;
// //       const y = e.clientY - rect.top;

// //       glow.style.left = `${x}px`;
// //       glow.style.top = `${y}px`;

// //       // Calculate the tilt based on mouse position
// //       const centerX = rect.width / 2;
// //       const centerY = rect.height / 2;
// //       const rotateX = (y - centerY) / centerY * 5; // Max 5 degrees tilt
// //       const rotateY = (x - centerX) / centerX * 5; // Max 5 degrees tilt

// //       box.style.transform = `scale(1.05) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
// //     }
// //   };

// //   const handleMouseOut = () => {
// //     const box = boxRef.current;
// //     if (box) {
// //       box.style.transform = 'scale(1) rotateX(0deg) rotateY(0deg)';
// //     }
// //   };

// //   return (
// //     <div
// //       className="glow-box"
// //       ref={boxRef}
// //       onMouseMove={handleMouseMove}
// //       onMouseOut={handleMouseOut}
// //     >
// //       <div
// //         className="glow"
// //         ref={glowRef}
// //         style={{ background: `radial-gradient(circle, ${glowColor} 10%, transparent 50%)`, backgroundColor: {bgColor} }}
// //       ></div>
// //       {children}
// //     </div>
// //   );
// // };

// // export default GlowBox;
